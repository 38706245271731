/*
Author       : Dreamguys
Template Name: Truelysell - Bootstrap Html Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Bootstrap Classes
3. Avatar
4. Nav Tabs
5. Table
6. Modal
7. Header
8. Mobile Menu
9. Slider
10. Home
11. About Us
12. Services
13. Booking
14. search
15. Error
16. Contact Us
17. Login
18. Customer Profile
19. Customer Dashboard
20. Customer Reviews
21. Booking List
22. FAQ
23. Pricing
24. Category
25. Maintenance
26. Coming Soon
27. Terms & Conditions
28. Provider Header
29. Sidebar
30. Provider Dashboard
31. Provider Signup
32. Provider Services
33. Provider Payout
34. Provider Availability
35. Coupon
36. Provider Settings
37. Provider Plan
38. Add Service Information
39. Orders
40. Calendar
41. Cursor
42. Booking Details
43. Chat
44. Blog List
45. Works
46. Invoice
47. Provider Details
48. Service list-item
49. Header Two
50. Home Two
51. Footer
52. Responsive
53. PreLoading

========================================*/

/*-----------------
    1. General
-----------------------*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
  background-color: #fff;
  color: #74788d;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  height: 100%;
  overflow-x: hidden;
  line-height: 1.6;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #28283c;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}
a {
  color: #2a313d;
  text-decoration: none;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
a:hover,
a:focus {
  color: #2ca384;
  text-decoration: none;
  outline: none;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
button:focus {
  outline: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/*-----------------
    2. Bootstrap Classes
-----------------------*/

.btn.focus,
.btn:focus {
  box-shadow: none;
}
.btn.btn-white {
  background: #fff;
  border: none !important;
  color: #28cea2 !important;
}
.withdraw-btn {
  padding: 12px 15px;
  font-weight: bold;
}
.btn-primary {
  background-color: #28cea2;
  border: 1px solid #28cea2;
  box-shadow: inset 0 0 0 #2ca384;
  border-radius: 5px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  padding: 10px 15px;

  font-weight: 500;
  font-size: 16px;
}
.btn-primary:hover,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #2ca384;
  border: 1px solid #2ca384;
  box-shadow: inset 0 70px 0 0 #2ca384;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary:focus,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #2ca384;
  border: 1px solid #2ca384;
  box-shadow: inset 0 70px 0 0 #2ca384;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #f4effd;
  border: 1px solid #f4effd;
  box-shadow: inset 70px 0 0 0 #f4effd;
  color: #74788d;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #f4effd;
  border: 1px solid #f4effd;
  box-shadow: inset 70px 0 0 0 #f4effd;
  color: #74788d;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-outline-primary {
  background-color: #fff;
  border: 1px solid #28cea2;
  color: #28cea2;
  box-shadow: inset 0 0 0 #fff;
  border-radius: 5px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.active,
.btn-outline-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #28cea2;
  border: 1px solid #28cea2;
  box-shadow: inset 0 70px 0 0 #28cea2;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-outline-primary.active.focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.active:hover,
.btn-outline-primary.focus:active,
.btn-outline-primary:active:focus,
.btn-outline-primary:active:hover,
.open > .dropdown-toggle.btn-outline-primary.focus,
.open > .dropdown-toggle.btn-outline-primary:focus,
.open > .dropdown-toggle.btn-outline-primary:hover {
  background-color: #28cea2;
  border: 1px solid #28cea2;
  box-shadow: inset 0 70px 0 0 #28cea2;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary {
  background: #f7f7ff;
  border: 1px solid #f7f7ff;
  box-shadow: inset 0 0 0 0 #fff;
  color: #28cea2;
  border-radius: 4px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
  background-color: #f7f7ff;
  border: 1px solid #f7f7ff;
  box-shadow: inset 0 70px 0 0 #f7f7ff;
  color: #2ca384;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover {
  background-color: #fff;
  border: 1px solid #28cea2;
  box-shadow: inset 70px 0 0 0 #fff;
  color: #28cea2;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary.active:not(:disabled):not(.disabled),
.btn-secondary:active:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle {
  background-color: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.btn-danger {
  background: #f2535b;
  border: 1px solid #f2535b;
  box-shadow: inset 0 0 0 0 #fff;
  color: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-color: #fff;
  border: 1px solid #f2535b;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #f2535b;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background-color: #fff;
  border: 1px solid #f2535b;
  box-shadow: inset 70px 0 0 0 #fff;
  color: #f2535b;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary.active:not(:disabled):not(.disabled),
.btn-secondary:active:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle {
  background-color: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.btn-danger-light {
  background: #fff5f5;
  border: 1px solid #fff5f5;
  color: #f82424;
  border-radius: 4px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-danger-light:hover {
  background-color: #fff;
  border: 1px solid #ff4949;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #f82424;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-light-success {
  background: #f3fff6;
  border: 1px solid #f3fff6;
  color: #6dcc76;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-light-success:hover {
  background-color: #fff;
  border: 1px solid #34c85d;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #34c85d;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-light-warning {
  background: #fff9e8;
  border: 1px solid #fff9e8;
  color: #ffc107;
  border-radius: 6px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-light-warning:hover {
  background-color: #fff;
  border: 1px solid #ffc107;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #ffc107;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary-outline {
  background: #f7f7ff;
  border: 1px solid #28cea2;
  color: #28cea2;
  border-radius: 4px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary-outline:hover {
  background-color: #28cea2;
  border: 1px solid #28cea2;
  box-shadow: inset 0 70px 0 0 #28cea2;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}
.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}
.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}
.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}
.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}
.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}
.text-light-success {
  color: #28a745 !important;
}
.text-light-danger {
  color: #dc3545 !important;
}
.form-group {
  margin-bottom: 1rem;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-control {
  border-color: #dcdcdc;
  color: #28283c;
  min-height: 42px;
  padding: 6px 15px;
  border-radius: 5px;
}
.form-control:focus {
  border-color: #bbb;
  box-shadow: none;
  outline: 0 none;
}
.breadcrumb-title h2 {
  font-weight: 500;
  font-size: 25px;
  margin: 0;
}
.breadcrumb-title span {
  color: #28283c;
}
.breadcrumbs {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
}
.breadcrumbs li {
  float: left;
  margin: 0;
  position: relative;
  padding: 0 11px;
}
.breadcrumbs li a {
  float: left;
  font-size: 13px;
  color: #8d8d8d;
}
.breadcrumbs li::before {
  position: absolute;
  right: -3px;
  top: 50%;
  content: '>';
  font-size: 10px;
  color: #8d8d8d;
  line-height: 10px;
  margin-top: -3px;
}
.breadcrumbs li:last-child {
  padding-right: 0;
}
.breadcrumbs li:last-child::before {
  display: none;
}
.text-body {
  color: #74788d !important;
}
.pagination {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.pagination > ul {
  float: left;
  width: 100%;
  text-align: center;
  margin: 0;
}
.pagination > ul li {
  float: none;
  display: inline-block;
  margin: 0;
}
.pagination > ul li a {
  float: left;
  width: 35px;
  height: 35px;
  background: #fff;
  border: 1px solid #e8ecec;
  line-height: 34px;
  font-size: 13px;
  color: #8d8d8d;
}
.pagination > ul li span {
  float: left;
  width: 35px;
  height: 35px;
  background: #fff;
  border: 1px solid #e8ecec;
  line-height: 34px;
  font-size: 13px;
  color: #8d8d8d;
}
.pagination > ul li.arrow a {
  background: #f1f1f1;
  font-size: 17px;
  margin: 0 9px;
}
.pagination > ul li.active a {
  background: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #28cea2;
  border: 1px solid #28cea2;
}
.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link:hover {
  background: #28cea2;
  color: #fff;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #28cea2;
  background: #fff;
  border: 1px solid #28cea2;
}
.card {
  background: #fff;
  border: 2px solid #ebedf0;
  border-radius: 6px;
  margin-bottom: 24px;
}
.card .card-body {
  padding: 20px;
}
.text-primary {
  color: #28cea2 !important;
}
.text-danger {
  color: #ff0000 !important;
}

/*-----------------
	3. Avatar
-----------------------*/

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #20c0f3;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: '';
  border: 2px solid #fff;
}
.avatar-online::before {
  background-color: #0c9e6f;
}
.avatar-offline::before {
  background-color: #ff0100;
}
.avatar-away::before {
  background-color: #ffbc34;
}
.avatar .border {
  border-width: 3px !important;
}
.avatar .rounded {
  border-radius: 6px !important;
}
.avatar .avatar-title {
  font-size: 18px;
}
.avatar-xs {
  width: 29px;
  height: 29px;
}
.avatar-xs .border {
  border-width: 2px !important;
}
.avatar-xs .rounded {
  border-radius: 4px !important;
}
.avatar-xs .avatar-title {
  font-size: 12px;
}
.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
  border-width: 1px;
}
.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-sm .border {
  border-width: 3px !important;
}
.avatar-sm .rounded {
  border-radius: 4px !important;
}
.avatar-sm .avatar-title {
  font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}
.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
.avatar-lg .border {
  border-width: 3px !important;
}
.avatar-lg .rounded {
  border-radius: 8px !important;
}
.avatar-lg .avatar-title {
  font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}
textarea {
  resize: none;
}

/*-----------------
	4. Nav Tabs
-----------------------*/

.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
}
.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #272b41;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #272b41;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.tab-content {
  padding-top: 20px;
}
.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #272b41;
}
.nav-link:focus,
.nav-link:hover {
  color: #858585;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #272b41;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}
.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #28cea2;
}
.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #28cea2;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}
.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #dcdcdc;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #dcdcdc;
  border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

/*-----------------
    5. Table
-----------------------*/

.table tbody td,
.table th,
.table tr {
  padding: 15px;
  font-size: 15px;
  border-color: #dcdcdc;
  color: #74788d;
}
.table td,
.table th {
  vertical-align: middle;
  white-space: nowrap;
}
.table > :not(:first-child) {
  border-top: 0;
}
table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
table.table td h2.table-avatar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
table.table td h2 a {
  font-weight: 500;
  font-size: 15px;
  color: #28283c;
}
table.table td h2 a:hover {
  color: #28cea2;
}
table.table td h2 span {
  color: #74788d;
  display: block;
  font-size: 13px;
  margin-top: 7px;
  font-weight: normal;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: '\f0de';
  font-family: 'Font Awesome 5 Free';
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 1em;
  content: '\f0dd';
  font-family: 'Font Awesome 5 Free';
}
.dataTables_paginate .pagination .previous,
.dataTables_paginate .pagination .next {
  display: none;
}
#tablelength label {
  font-weight: 500;
  font-size: 14px;
  color: #68616d;
}
#tablelength label select {
  width: auto;
  display: inline-block;
  border: 0.5px solid #dcdcdc;
  border-radius: 5px;
  padding: 4px 10px;
  min-height: auto;
  color: #68616d;
  margin: 0 8px;
}
#tablepagination ul {
  margin: 0;
}
.table-ingopage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-ingopage .dataTables_info {
  font-weight: 500;
  font-size: 14px;
  color: #68616d;
  margin-right: 10px;
}

/*-----------------
    6. Modal
-----------------------*/

.custom-modal .modal-header {
  padding: 30px;
}
.custom-modal .modal-body {
  padding: 30px;
}
.custom-modal .modal-content {
  border-radius: 4px;
  border: 0;
  padding: 0;
}
.custom-modal .close-btn {
  background: #f82424;
  border: 0;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 99;
}
.custom-modal .close-btn:hover {
  background: #28cea2;
  color: #fff;
}
.modal-title {
  font-weight: 500;
  font-size: 24px;
}

/*-----------------
    7. Header
-----------------------*/

.header {
  background: #fff;
  position: sticky;
  box-shadow: 0px 10px 40px rgba(202, 202, 202, 0.2);
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.header.fixed {
  position: fixed;
  background-color: #fff;
  box-shadow: 0px 3px 53px rgb(197 197 197 / 27%);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.navbar-brand.logo {
  margin-right: 0;
}
.logo img {
  height: auto;
  max-width: 100%;
  max-height: 90px;
}
.logo a {
  float: left;
}
.logo a img {
  float: left;
  max-height: 32px;
}
.header-nav-new {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  height: 85px;
  padding: 0;
  margin-bottom: 0;
}
.main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.main-nav > li > a {
  color: #28283c;
}
.main-nav > li.active > a {
  color: #28cea2;
}
.main-nav > li .submenu li a {
  display: block;
  padding: 8px 15px 8px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #68616d;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  position: relative;
}
.main-nav > li .submenu > li.has-submenu > a::after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 13px;
}
.main-nav > li .submenu li {
  position: relative;
}
.main-nav li a {
  display: block;
  font-weight: 500;
}
.main-nav li a:hover {
  color: #28cea2;
}
.main-nav li.login-link {
  display: none;
}
.header-navbar-rht {
  margin: 0;
  padding: 0;
}
.header-navbar-rht > li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-right: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.header-navbar-rht > li:last-child {
  padding-right: 0px;
}
.header-navbar-rht > li .dropdown-menu {
  border: 0;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
}
.header-navbar-rht > li .fa-bell {
  font-size: 24px;
}
.header-navbar-rht > li .fa-comments {
  font-size: 24px;
}
.header-navbar-rht .dropdown-toggle::after {
  display: none;
}
.header-navbar-rht > li .dropdown-menu .dropdown-item {
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
  font-size: 0.9375rem;
}
.header-navbar-rht > li .dropdown-menu .dropdown-item:first-child {
  border-top: 0;
  border-radius: 5px 5px 0 0;
}
.header-navbar-rht > li .dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 5px 5px;
}
.header-navbar-rht > li a.header-login:hover,
.header-navbar-rht > li a.header-login:focus {
  background-color: #fff;
  border: 1px solid #28cea2;
  box-shadow: inset 210px 0 0 0 #fff;
  color: #28cea2;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.header-navbar-rht li > .dropdown-menu a:hover {
  color: #28cea2;
  background-color: #fff;
}
.header-navbar-rht li > a.header-login {
  background-color: #28cea2;
  border: 1px solid #28cea2;
  color: #fff;
  box-shadow: inset 0 0 0 #2ca384;
  border-radius: 5px;
  padding: 9px 20px;
  font-weight: 500;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  min-height: 46px;
}
.header-navbar-rht li > a.header-login:hover {
  background-color: #2ca384;
  border: 1px solid #2ca384;
  box-shadow: inset 0 70px 0 0 #2ca384;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.header-navbar-rht li > a.header-reg {
  font-weight: 500;
  color: #28283c;
  text-decoration: none;
  padding: 0;
}
.header-navbar-rht li > a.header-reg:hover {
  color: #2ca384;
}
.header-navbar-rht li > a.header-login img {
  margin-right: 7px;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #7e84a3;
  border-right: 2px solid #7e84a3;
  content: '';
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-right: 10px;
}
.header .has-arrow .dropdown-toggle[aria-expanded='true']:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
}
.user-menu.nav > li > a {
  color: #fff;
  font-size: 14px;
  line-height: 58px;
  padding: 0 15px;
  height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
  color: #fff;
}
.user-img {
  display: inline-block;
  position: relative;
  -webkit-animation: pulse 1s ease-out;
  animation: pulse 2s infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.user-img > img {
  height: 40px;
  object-fit: cover;
  width: 40px;
  border: 3px solid #fff;
  outline: 3px solid #d9d9d9;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(76, 64, 237, 0.7);
    border-radius: 50%;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(76, 64, 237, 0);
    border-radius: 50%;
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(76, 64, 237, 0);
    border-radius: 50%;
  }
}
.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.header-navbar-rht .dropdown-menu {
  min-width: 200px;
  padding: 0;
  right: 0;
  left: unset;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
  padding: 7px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item:hover {
  color: #28cea2;
}
.user-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
}
.user-header .user-text {
  margin-left: 10px;
}
.user-header .user-text h6 {
  font-size: 15px;
  margin-bottom: 2px;
}
.header-navbar-rht .logged-item .nav-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  height: 85px;
  padding: 0 10px;
  color: #fff;
  position: relative;
}
.header-navbar-rht .logged-item .nav-link .badge {
  position: absolute;
  top: 15px;
  right: 0;
}
.header-navbar-rht.log-rht li {
  margin-right: 43px;
}
.header-navbar-rht.log-rht li a {
  color: #28283c;
  padding: 0;
}
.header-navbar-rht.log-rht li a:hover {
  color: #28cea2;
}
.header-navbar-rht.log-rht li:last-child {
  margin-right: 0;
}
.header .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #757575;
  border-right: 2px solid #757575;
  content: '';
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-left: 10px;
}
.flag-nav > a {
  color: #28283c;
  border: 1px solid #e4e4e8;
  border-radius: 8px;
  font-size: 14px;
  padding: 11px 13px;
}
.flag-nav > a img {
  margin-right: 5px;
}
.flag-nav .dropdown-item img {
  margin-right: 10px;
  border-radius: 50%;
}
.header-navbar-rht .logged-item .nav-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 41px;
  height: 41px;
  font-size: 16px;
  background: #f6f6fe;
  border-radius: 50%;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.header-navbar-rht .logged-item .nav-link:hover {
  background: #28cea2;
  color: #fff;
}
.header-navbar-rht .logged-item .nav-link:hover > img {
  filter: brightness(0) invert(1);
}
.header-navbar-rht .logged-item .nav-link::after {
  display: none;
}
.noti-dropdown .dropdown-toggle {
  color: #fff;
  font-size: 14px;
  line-height: 23px;
  padding: 0 15px;
}
.noti-dropdown .dropdown-toggle::after {
  display: none;
}
.noti-dropdown .dropdown-toggle span {
  background-color: #f43f3f;
  display: block;
  font-size: 10px;
  font-weight: bold;
  min-height: 15px;
  min-width: 15px;
  position: absolute;
  right: 3px;
  top: 5px;
}
.noti-dropdown .dropdown-toggle i {
  float: right;
  line-height: 50px;
  margin-left: 15px;
  font-size: 21px;
  float: left;
  line-height: 50px;
  color: #fff;
  margin: 0px;
  margin-left: 0px;
  cursor: pointer;
}
.user-header {
  background-color: #f9f9f9;
  display: flex;
  padding: 10px 15px;
}
.noti-dropdown .dropdown-menu {
  top: 63px !important;
  font-size: 14px;
}
.notification-message {
  margin-bottom: 0;
}
.notifications {
  padding: 0;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));
}
.notifications .notification-time {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.35;
  color: #7e84a3;
}
.notifications .notify-btns .btn {
  margin: 0 8px 8px 0;
}
.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
  border-bottom: none;
}
.notifications .media a {
  display: block;
  padding: 0;
  border-radius: 2px;
}
.notifications .media a:hover {
  background-color: #fafafa;
}
.notifications .media .avatar {
  margin-right: 15px;
  width: 38px;
  height: 38px;
}
.notifications .media-list .media-left {
  padding-right: 8px;
}
.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
}
.topnav-dropdown-header {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.topnav-dropdown-header,
.topnav-dropdown-footer {
  height: 45px;
  line-height: 45px;
  padding-left: 15px;
  padding-right: 15px;
}
.topnav-dropdown-header select {
  border: 0;
  font-size: 12px;
  color: #74788d;
}
.topnav-dropdown-header select:focus-visible {
  outline: 0;
}
.topnav-dropdown-footer {
  border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #28cea2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topnav-dropdown-footer a:hover {
  color: #28cea2;
}
.topnav-dropdown-footer a img {
  margin-left: 5px;
  filter: brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2990%)
    hue-rotate(239deg) brightness(91%) contrast(105%);
}
.topnav-dropdown-footer a:hover i {
  color: #28cea2;
  margin-left: 8px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.user-menu.nav > li > a .badge {
  background-color: #f43f3f;
  display: block;
  font-size: 10px;
  font-weight: bold;
  min-height: 15px;
  min-width: 15px;
  position: absolute;
  right: 3px;
  top: 6px;
}
.user-menu.nav > li > a > i {
  font-size: 1.5rem;
  line-height: 60px;
}
.noti-details {
  font-size: 14px;
  color: #28283c;
  margin-bottom: 8px;
  font-weight: 500;
}
.noti-details span {
  color: #74788d;
  font-weight: 400;
}
.notifications .noti-content::-webkit-scrollbar {
  width: 6px;
  background: #eef1fe;
  height: 10px;
}
.notifications .noti-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(231, 231, 231);
  border-radius: 4px;
}
.notifications .noti-content::-webkit-scrollbar-thumb {
  background: #8a8a95;
  border-radius: 4px;
}
.notifications .noti-content::-webkit-scrollbar-thumb:hover {
  background: #1b2559;
}
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.notifications ul.notification-list > li {
  margin-top: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
  border-bottom: none;
}
.notifications ul.notification-list > li a {
  display: block;
  border-radius: 2px;
}
.notifications ul.notification-list > li > a:hover {
  background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}
.noti-pop-detail li.noti-wrapper .notify-blk {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
}
.noti-pop-detail li.noti-wrapper .notify-blk .topnav-dropdown-header {
  padding: 15px;
  border-radius: 10px;
  background: #fafafa;
  border: none;
  margin-bottom: 15px;
}
.noti-pop-detail
  li.noti-wrapper
  .notify-blk
  .noti-content
  ul.notification-list
  li.notification-message {
  padding: 10px;
}
.noti-pop-detail li.noti-wrapper .notify-blk {
  width: 410px;
  height: 580px;
}
.noti-pop-detail
  li.noti-wrapper
  .notify-blk
  .noti-content
  ul.notification-list
  li
  .media-body
  p.noti-details {
  margin-bottom: 5px;
  position: relative;
}
.noti-pop-detail li.noti-wrapper .notify-blk .topnav-dropdown-footer {
  border: none;
}
.noti-pop-detail .noti-img span img {
  border-radius: 45px;
  border: 2px solid #e1e1e1;
}
.topnav-dropdown-header .notification-title {
  display: block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}
.topnav-dropdown-header .notification-title span {
  padding: 3px 6px;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background: #28cea2;
}
.topnav-dropdown-header .clear-noti {
  color: #28283c;
  float: right;
  font-size: 14px;
}
.topnav-dropdown-header .clear-noti:hover {
  color: #28cea2;
}
.notification-message h6 {
  font-size: 14px;
  font-weight: 400;
  color: #28283c;
  position: relative;
  padding-left: 10px;
  margin-bottom: 8px;
}
.notification-message h6:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  background: #dddee1;
  border-radius: 2px;
  width: 4px;
  height: 100%;
}
.notification-message .avatar-online::before {
  top: 0;
  background: #6dcc76;
}
.noti-time {
  font-size: 14px;
  margin: 0;
}
.flag-dropdown .nav-link {
  color: #28283c;
  padding: 0.5rem 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
}
.account-item .nav-link {
  padding: 0.5rem 0;
}
.user-info {
  margin-left: 10px;
}
.user-info h6 {
  font-weight: 600;
  font-size: 16px;
  color: #28283c;
  margin-bottom: 4px;
}
.user-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.user-infos {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.main-nav li.megamenu > ul {
  width: 100%;
}
.megamenu-wrapper {
  padding: 25px 25px 0;
}
.single-demo {
  transition: 0.4s;
  margin-bottom: 25px;
}
.single-demo:hover {
  transform: translateY(-20px);
}
.single-demo.active .demo-info a {
  color: #2229c1;
}
.header-one .single-demo.active .demo-info a {
  color: #28cea2;
}
.header-four .single-demo.active .demo-info a {
  color: #28cea2;
}
.header-five .bar-icon span {
  background-color: #2229c1;
}
.header-five .main-nav .single-demo.active .demo-info a {
  color: #2229c1;
}
.single-demo.active .demo-img {
  box-shadow:
    0px 4.8px 24.4px -12px rgba(19, 16, 34, 0.1),
    0px 4px 13px -2px rgba(19, 16, 34, 0.06);
}
.single-demo:hover .demo-info {
  opacity: 1;
  bottom: -30px;
}
.demo-info {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  transition: 0.4s;
  margin-top: 10px;
}

/*-----------------
	8. Mobile Menu
-----------------------*/

.header-six .bar-icon span {
  background-color: #2229c1;
}
.header-nine .bar-icon span {
  background-color: #2229c1;
}
.header-two .bar-icon span {
  background-color: #2229c1;
}
.sidebar-overlay {
  background-color: rgba(255, 255, 255, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1039;
}
.menu-opened .main-menu-wrapper {
  transform: translateX(0);
}
.menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  display: none;
}
.menu-logo img {
  height: auto;
  max-width: 180px;
}
.menu-close {
  font-size: 18px;
  color: #28cea2;
}
.bar-icon {
  display: inline-block;
  width: 31px;
}
.bar-icon span {
  background-color: #28cea2;
  display: block;
  float: left;
  height: 2px;
  margin-bottom: 6px;
  width: 30px;
  border-radius: 2px;
}
.bar-icon span:nth-child(3) {
  margin-bottom: 0;
}
#mobile_btn {
  display: none;
  margin-right: 30px;
}
html.menu-opened body {
  overflow: hidden;
}
.main-menu-wrapper {
  margin: 0 15px 0;
}
.navbar-brand.logo-small {
  display: none;
}
.navbar-brand.logo-small img {
  max-height: 30px;
}

.mobile-menu {
  position: fixed; /* Fix the position of the menu */
  top: 0; /* Align the menu to the top of the screen */
  left: 0; /* Align the menu to the left of the screen */
  width: 100%; /* Make the menu the full width of the screen */
  height: 100%; /* Make the menu the full height of the screen */
  background: #fff; /* Change this to match your design */
  z-index: 1000; /* Make sure the menu appears above other content */
  overflow-y: auto; /* Add scroll if the content is taller than the screen */
  transform: translateX(
    -100%
  ); /* Start the menu off the left side of the screen */
  transition: transform 0.3s ease-in-out; /* Animate the menu sliding in and out */
}

.menu-opened .mobile-menu {
  transform: translateX(
    0
  ); /* Slide the menu onto the screen when the menu is open */
}

/*-----------------
	9. Slider
-----------------------*/

.owl-nav button.owl-next,
.owl-nav button.owl-prev {
  width: 32px;
  height: 32px;
  background: #fff !important;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  text-shadow: none;
  top: 4px;
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  color: #28283c;
  margin-bottom: 30px;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.owl-nav button.owl-next:hover,
.owl-nav button.owl-prev:hover {
  background-color: #28cea2 !important;
  color: #fff;
}
.owl-nav button.owl-next span,
.owl-nav button.owl-prev span {
  font-size: 34px;
  line-height: 25px;
}
.owl-nav button.owl-next {
  margin-left: 10px;
}
.testimonial-slider .owl-nav button.owl-next,
.testimonial-slider .owl-nav button.owl-prev {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  margin-bottom: 0;
}
.testimonial-slider .owl-nav button.owl-prev {
  left: -60px;
}
.testimonial-slider .owl-nav button.owl-next {
  right: -60px;
}
.gallery-widget img {
  border-radius: 5px;
}

/*-----------------
	9. Slick Slider
-----------------------*/

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  background: #fff;
  z-index: 9999;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: #2229c1;
  color: #fff;
  opacity: 1;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  color: #fff;
  opacity: 1;
}
.banner-slider .slick-prev:hover:before,
.banner-slider .slick-prev:focus:before,
.banner-slider .slick-next:hover:before,
.banner-slider .slick-next:focus:before {
  color: #fff;
  opacity: 1;
  border: solid #fff;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #383838;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: 100px;
  z-index: 1;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}
.slick-next {
  right: 100px;
  z-index: 1;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}
.banner-slider .slick-prev:before {
  font-family: 'slick';
  font-size: 10px;
  line-height: 1;
  opacity: 0.75;
  color: #3e4265;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: solid #3e4265;
  border-width: 0 3px 3px 0 !important;
  display: inline-block;
  padding: 3px;
  margin-right: -3px;
  content: '';
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.banner-slider .slick-next:before {
  font-family: 'slick';
  font-size: 10px;
  line-height: 1;
  opacity: 0.75;
  color: #3e4265;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: solid #3e4265;
  border-width: 0 3px 3px 0 !important;
  display: inline-block;
  padding: 3px;
  margin-left: -3px;
  content: '';
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/*-----------------
    10. Home
-----------------------*/

.hero-section {
  position: relative;
  min-height: 660px;
}
.hero-section:before {
  content: '';
  background-image: url('../img/banner-right.png');
  background-repeat: no-repeat;
  background-position: 100% 0;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.home-banner {
  min-height: 660px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.banner-imgs .banner-1 {
  position: absolute;
  z-index: 2;
  top: 50px;
  left: 0;
  max-width: 203px;
  max-height: 203px;
}
.banner-imgs .banner-2 {
  position: absolute;
  z-index: 2;
  max-width: 350px;
  top: 0;
  right: 0;
}
.banner-imgs .banner-3 {
  position: absolute;
  z-index: 2;
  max-width: 300px;
  bottom: -60px;
  left: -40px;
}
.banner-imgs .banner-1:before {
  position: absolute;
  content: '';
  background: url(../img/icons/banner-arrow.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  left: -100px;
  z-index: -1;
}
.banner-imgs .banner-4 {
  position: absolute;
  z-index: 2;
  max-width: 193px;
  bottom: 30px;
  right: 80px;
}
.banner-imgs {
  padding: 65px 0 0;
  position: relative;
  height: 100%;
  min-height: 540px;
}
.banner-imgs img {
  border-radius: 50%;
}
.banner-homepage5 .banner-imgs .banner-1 {
  max-width: 256px;
  max-height: 256px;
}
.banner-homepage5 .banner-imgs .banner-1 {
  position: absolute;
  z-index: 2;
  top: 30px;
  bottom: 0;
  right: 0;
  left: 0;
  max-width: 356px;
  margin: auto;
  max-height: 356px;
}
.shape-1 {
  animation: hero-thumb-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb-animation 2.5s linear infinite alternate;
  -o-animation: hero-thumb-animation 2.5s linear infinite alternate;
}
.shape-2 {
  animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -o-animation: hero-thumb1-animation 2.5s linear infinite alternate;
}
.shape-3 {
  animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -o-animation: hero-thumb2-animation 2.5s linear infinite alternate;
}
@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes hero-thumb1-animation {
  0% {
    transform: translateY(-20px) translateX(50px);
  }
  to {
    transform: translateY(-20px) translateX(0);
  }
}
@keyframes hero-thumb2-animation {
  0% {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
.breadcrumb {
  margin-bottom: 0;
}
.section-search {
  position: relative;
  z-index: 9;
  max-width: 709px;
}
.section-search h1 {
  font-size: 50px;
  color: #2a313d;
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.section-search p {
  font-size: 18px;
  color: #74788d;
  font-weight: 500;
  margin: 0;
}
.search-box {
  width: 100%;
  background: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 3px 53px rgba(197, 197, 197, 0.27);
  border-radius: 10px;
  padding: 15px;
  margin-top: 48px;
  display: inline-block;
}
.search-box form {
  float: left;
  width: 100%;
}
.search-input {
  float: left;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}
.search-box ::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  color: #959595;
  opacity: 1;
}
.search-box :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: #959595;
  opacity: 1;
}
.search-box ::placeholder {
  font-size: 14px;
  color: #959595;
  opacity: 1;
}
.search-input {
  width: 42%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.search-input.line {
  width: 32%;
  margin-right: 25px;
}
.search-input label {
  font-weight: 500;
  color: #28283c;
  font-size: 16px;
  margin-bottom: 0;
}
.search-input input,
.search-input.line input {
  height: 20px;
  min-height: 20px;
  border: 0;
  padding: 0;
  padding-right: 12px;
}
.search-input.line::before {
  position: absolute;
  right: 0;
  top: 50%;
  width: 2px;
  height: 43px;
  background: #f2f2f2;
  content: '';
  z-index: 1;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.search-input .current-loc-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0px;
  color: #858585;
  font-size: 18px;
}
.search-input .current-loc-icon i {
  position: unset;
  transform: unset;
}
.search-group-icon {
  width: 43px;
  height: 43px;
  background: #f2f2ff;
  border-radius: 50%;
  font-size: 20px;
  color: #28cea2;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1000;
  background-color: white;
  color: #28cea2;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  background-color: #ffffff;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: #fafafa;
}

.providerset {
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  padding: 18px;
  position: relative;
  top: 0;
  transition: all 0.5s;
  background: #fff;
  margin-bottom: 1.5rem;
}
.providerset:hover {
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
}
.providerset-img img {
  width: 100%;
}
.providerset-img {
  margin-bottom: 18px;
}
.providerset-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.offer-paths {
  background-color: #28cea2;
  border-radius: 50px;
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  margin-top: -180px;
  padding: 0 104px;
  z-index: 1;
}
.providers-section {
  padding-bottom: 366px;
}
.providers-section {
  background: url(../img/bg/provider-bg.png);
  background-repeat: no-repeat;
  background-position: right center;
}
.pricing-sections {
  background: url(../img/bg/pricing.png), url(../img/bg/pricing2.png);
  background-repeat: no-repeat;
  background-position:
    right center,
    left center;
}
.hero-section {
  background: url(../img/bg/bg-banner.png), url(../img/bg/bg-banner.png);
  background-repeat: no-repeat;
  background-position:
    left top,
    left bottom;
}
.offer-paths::after {
  position: absolute;
  content: '';
  background: url(../img/offer-bg.png) no-repeat 0 0 / 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.pricing-plans {
  border: 2px solid #f5f5f5;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #fff;
}
.pricing-planshead {
  border-bottom: 1px solid #f5f5f5;
  text-align: center;
  padding-bottom: 15px;
}
.pricing-planshead h4 {
  font-size: 24px;
  color: #28283c;
}
.pricing-planshead h5 {
  font-size: 14px;
  color: #74788d;
  font-weight: 400;
}
.pricing-planshead h6 {
  font-size: 24px;
}
.pricing-planshead h6 span {
  color: #c2c9d1;
  font-size: 13px;
}
.pricing-planscontent {
  padding-top: 15px;
}
.pricing-planscontent li + li {
  margin-top: 15px;
}
.pricing-planscontent li {
  color: #28283c;
  display: flex;
  align-items: baseline;
}
.pricing-plans.active {
  box-shadow: 0px 10px 20px -5px #4c40ed14;
}
.pricing-plans:hover {
  box-shadow: 0px 10px 20px -5px #4c40ed14;
}
.pricing-popular .btn {
  padding: 12px;
  opacity: 0;
  width: 95%;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 600;
}
.pricing-popular {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-popular.active .btn {
  opacity: 1;
}
.pricing-planscontent li.inactive {
  opacity: 0.2;
  pointer-events: none;
}
.pricing-planscontent ul {
  margin-bottom: 30px;
}
.pricing-btn {
  text-align: center;
}
.offer-pathimg {
  margin-top: -120px;
}
.offer-path-content h3 {
  font-size: 38px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 8px;
}
.offer-path-content p {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
}
.btn-views {
  background: #fff;
  color: #28283c;
  font-weight: 500;
  display: inline-block;
  border: 1px solid #fff;
  padding: 12px 20px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  display: inline-flex;
  align-items: center;
}
.btn-views:hover {
  background-color: #fff;
  border: 1px solid #28cea2;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #28cea2;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-views i {
  margin-left: 8px;
}
.offer-path-content {
  padding-left: 48px;
}
.providerset-name h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}
.providerset-name h4 a {
  margin-right: 6px;
}
.providerset-name h4 i {
  color: #6dcc76;
}
.providerset-name span {
  font-weight: 400;
}
.providerset-prices h6 {
  font-size: 18px;
  margin: 0;
}
.providerset-prices h6 span {
  font-size: 14px;
  color: #74788d;
  margin-left: 3px;
  font-weight: 400;
}
.provider-rating .rating {
  margin: 0;
}
.provider-rating .rating span {
  color: #74788d;
  margin-left: 5px;
}
.search-btn {
  float: right;
}
.theme-text {
  color: #28cea2;
}
.search-btn .btn {
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  padding: 11px 20px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
}
.section-heading {
  margin-bottom: 50px;
}
.section-heading h2 {
  font-size: 40px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 15px;
}
.section-heading p {
  font-size: 16px;
  color: #74788d;
  margin-bottom: 0;
}
.section-heading .owl-nav button.owl-next,
.section-heading .owl-nav button.owl-prev {
  margin-bottom: 0;
  line-height: 0;
}
.feature-section {
  background: url(../img/bg/feature-bg-01.png), url(../img/bg/feature-bg-02.png),
    #f7f7ff;
  background-repeat: no-repeat;
  background-position:
    left center,
    right center;
  padding: 90px 0 66px;
}
.feature-box {
  background: #fff;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  padding: 25px;
  text-align: center;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  position: relative;
  display: block;
  z-index: 1;
  overflow: hidden;
}
.feature-icon span {
  z-index: 1;
}
.feature-icon span {
  width: 94px;
  height: 94px;
  background: #f7f7ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  margin: 0 auto 20px;
}
.feature-box h5 {
  margin-bottom: 0;
}
.feature-box .feature-overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: -1;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  width: 100%;
}
.feature-box .feature-overlay img {
  border-radius: 10px;
}
.feature-box:hover .feature-overlay {
  opacity: 1;
  left: 0;
}
.feature-box:hover .feature-overlay:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 50%);
  border-radius: 10px;
}
.feature-box:hover h5 {
  color: #fff;
}
.btn-view {
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 500;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.btn-view img,
.btn-view i {
  margin-left: 8px;
}
.btn-view:hover img {
  filter: invert(91%) sepia(125%) saturate(7510%) hue-rotate(258deg)
    brightness(112%) contrast(100%);
}
.service-section {
  padding: 90px 0;
}
.service-widget {
  background-color: #fff;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  margin-bottom: 24px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.service-widget:hover {
  box-shadow: 0px 10px 20px -5px rgb(76 64 237 / 8%);
}
.service-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px 10px 0 0;
}
.service-img .serv-img {
  border-radius: 10px 10px 0 0;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
  height: 100%;
}
.service-img:hover .serv-img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.service-content {
  padding: 20px;
}
.service-content .title {
  font-size: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.service-content p {
  color: #74788d;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.service-content p .rate {
  color: #74788d;
  float: right;
}
.service-content p .rate i {
  font-size: 15px;
}
.service-content p .rate i.filled {
  color: #ffbc35 !important;
}
.addrs-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.price {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1rem;
}
.price .old-price {
  color: #74788d;
  text-decoration: line-through;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
}
.price .price-hr {
  font-size: 13px;
  color: #c2c9d1;
  font-weight: 400;
}
.service-widget .serv-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.service-widget .serv-info h6 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
}
.service-widget .serv-info h6 .old-price {
  font-size: 16px;
  color: #74788d;
  text-decoration: line-through;
  margin-left: 5px;
}
.service-widget .service-content .title a:hover,
.service-widget .service-content .title a:hover {
  color: #28cea2;
}
.service-widget .btn-book {
  background: #f7f7ff;
  border-radius: 5px;
  color: #28cea2;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 20px;
  box-shadow: inset 0 0 0 0 #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.service-widget .btn-book:hover {
  background: #fff;
  border: 1px solid #28cea2;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #28cea2;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.service-widget .fav-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 20px 10px;
  z-index: 1;
}
.servicecontent .btn-book {
  padding: 10px 20px;
}
.provide-social.social-icon li {
  display: inline-block;
  margin-right: 7px;
}
.provide-social.social-icon li a {
  width: 36px;
  height: 36px;
  background: #f7f7ff;
  border-radius: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #74788d;
}
.provide-social.social-icon li a:hover {
  background-color: #28cea2;
  color: #fff;
}
.item-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 20px 20px;
  z-index: 1;
}
.item-cat {
  background: #f7f7ff;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 10px;
  display: inline-block;
  color: #28cea2;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  line-height: normal;
}
.item-cat:hover {
  background: #28cea2;
  color: #fff;
}
.item-img {
  display: inline-block;
  float: right;
}
.item-img img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.fav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  font-size: 14px;
}
.fav-item .fav-icon {
  width: 43px;
  height: 43px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
}
.fav-item .fav-icon:hover,
.fav-icon.selected {
  background-color: #2229c1;
  color: #fff;
}
.fav-item .serv-rating {
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: #28283c;
  display: inline-flex;
  align-items: center;
}
.fav-item .serv-rating i {
  color: #ffbc35;
  font-size: 12px;
  margin-right: 5px;
}
.service-content .item-info {
  position: relative;
  padding-left: 0;
}
.btn-sec {
  text-align: center;
}
.btn-sec .btn {
  display: inline-flex;
  margin-top: 26px;
}
.work-section {
  background: url(../img/bg/work-bg-01.png), url(../img/bg/work-bg-02.png),
    #f7f7ff;
  background-repeat: no-repeat;
  background-position:
    left center,
    right 0 bottom 25%;
  padding: 80px 0 66px;
}
.work-box {
  background: #fff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  margin-bottom: 24px;
  position: relative;
}
.work-icon span {
  width: 83px;
  height: 72px;
  background: #f7f7ff;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 0 auto 36px;
}
.work-box h5 {
  font-size: 20px;
  margin-bottom: 15px;
}
.work-box p {
  color: #74788d;
  margin-bottom: 0;
}
.work-box h4 {
  font-weight: 600;
  font-size: 60px;
  color: #edecf8;
  margin-bottom: 0;
  position: absolute;
  top: -30px;
  left: 0;
}
.rating {
  margin-bottom: 17px;
}
.rating i {
  color: #e9eef3;
}
.rating i.active {
  color: #ffbc35;
}
.client-section {
  padding: 90px 0 66px;
}
.client-widget {
  background: #fff;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  padding: 1.5rem;
  text-align: center;
  margin: 60px 0px 24px;
}
.client-widget .testimonial-author h4 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.client-img {
  margin: -83px 0 20px;
}
.client-img img {
  width: 110px !important;
  height: 110px;
  border-radius: 50%;
  margin: 0 auto;
}
.client-content p {
  margin-bottom: 24px;
}
.client-content h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}
.client-content h6 {
  color: #74788d;
  font-weight: 400;
  margin-bottom: 0;
}
.blog-section {
  padding: 90px 0 66px;
}
.blog {
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 20px;
}
.blog:hover {
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
}
.blog-image {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}
.blog-image img {
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.blog:hover .blog-image img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.blog-content {
  padding-top: 20px;
}
.blog-content .blog-item {
  display: inline-flex;
  display: -webkit-inline-flex;
}
.blog-content .blog-item li {
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #74788d;
  margin: 0 25px 15px 0;
  margin-right: 25px;
}
.blog-content .blog-item li a {
  color: #68616d;
}
.blog-content .blog-item li a:hover {
  color: #28cea2;
}
.blog-content .blog-item li:last-child {
  margin-right: 0;
}
.blog-content .blog-item li i {
  margin-right: 0.5rem;
  color: #28cea2;
}
.blog-content .blog-title {
  font-size: 20px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
}
.blog-content .blog-title a {
  color: #28283c;
}
.blog-content .blog-title a:hover {
  color: #28cea2;
}
.client-section {
  background: url(../img/bg/testimonial-bg-01.png),
    url(../img/bg/testimonial-bg-02.png), #f9f9f9;
  background-repeat: no-repeat;
  background-position:
    left center,
    right bottom;
}
.app-section {
  padding: 70px 0 90px;
}
.partner-img {
  border: 2px solid #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #fff;
}
.partner-img img {
  width: auto !important;
  margin: 0 auto;
  height: 35px;
}
.partner-img:hover {
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
}
.app-sec {
  background: #28cea2;
  border-radius: 50px;
  padding: 60px;
  position: relative;
}
.app-sec:before {
  position: absolute;
  content: '';
  border-radius: 10px;
  background-position: right top 10px;
  background-repeat: no-repeat;
  top: 10px;
  right: 20px;
  width: 100%;
  height: 100%;
}
.app-sec h2 {
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  margin-bottom: 8px;
}
.app-sec p {
  margin-bottom: 15px;
  color: #fff;
}
.app-sec h6 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #fff;
}
.downlaod-btn {
  display: flex;
}
.downlaod-btn a {
  margin-right: 15px;
  display: inline-flex;
}
.downlaod-btn a:last-child {
  margin-right: 0;
}
.appimg-set {
  margin-top: -128px;
  position: relative;
}
.scan-img {
  position: relative;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  display: inline-block;
}
.swal2-actions .btn {
  margin: 0 5px;
  border-radius: 5px;
}
.swal2-title {
  color: #28283c !important;
  font-size: 22px !important;
}
#swal2-content {
  font-size: 14px;
  color: #68616d;
}
.swal2-actions .swal2-cancel {
  min-height: 46px;
}
/*-----------------
	11. About Us
-----------------------*/

.about-banner {
  background: url(../img/bg/about-bg.png);
  margin-bottom: 47px;
}
.quality-box {
  background: #f7f7ff;
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
.quality-box:hover {
  border: 2px solid #28cea2;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
}
.quality-box:hover .quality-img {
  background: #28cea2;
}
.quality-box:hover .quality-img img {
  filter: brightness(0) invert(1);
}
.quality-img {
  width: 58px;
  height: 58px;
  background: #fff;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}
.quality-img img {
  width: 28px;
}
.quality-info h6 {
  font-weight: 500;
  margin-bottom: 10px;
}
.quality-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.about-sec {
  padding: 90px 0;
}
.about-img {
  position: relative;
}
.about-img img {
  border-radius: 10px;
}
.about-img .about-exp span {
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  display: inline-block;
  margin: 240px -112px;
  transform: rotate(-90deg);
}
.about-img .about-exp {
  position: absolute;
  background: #28cea2;
  border-radius: 10px;
  width: 370px;
  height: 100%;
}
.abt-img {
  padding: 30px 0 30px 60px;
  position: relative;
  z-index: 1;
}
.about-content {
  margin-left: 50px;
}
.about-content h6 {
  font-size: 16px;
  color: #28cea2;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.about-content h2 {
  font-size: 38px;
  margin-bottom: 30px;
}
.about-content p {
  margin-bottom: 20px;
}
.about-content ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
}
.about-content ul li:before {
  position: absolute;
  top: 4px;
  left: 0;
  content: '\f058';
  font-family: 'FontAwesome';
  color: #28cea2;
}
.bgimg4 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.work-bg.work-section {
  background: url(../img/bg/about-bg-02.png), #f7f7ff;
  background-repeat: no-repeat;
  background-position: right 0 bottom 25%;
}
.abt-provider {
  padding-bottom: 66px;
}
.chooseus-sec {
  padding: 90px 0 70px;
}
.choose-content h2 {
  font-size: 38px;
  margin-bottom: 8px;
}
.choose-content p {
  margin-bottom: 40px;
}
.choose-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
}
.choose-icon img {
  margin-right: 20px;
}
.choose-info h5 {
  font-size: 32px;
  margin-bottom: 2px;
}
.choose-info p {
  margin-bottom: 0;
}
.chooseus-img,
.choose-content {
  margin-bottom: 50px;
}
.choose-content {
  margin-right: 50px;
}
.support-card {
  margin-bottom: 20px;
}
.support-card:last-child {
  margin-bottom: 0;
}
.support-title {
  background: #f4f7ff;
  border-radius: 5px;
  padding: 20px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}
.support-title a {
  color: #28283c;
  display: block;
  position: relative;
  padding-right: 60px;
}
.support-title > a:after {
  font-family: 'Fontawesome';
  font-weight: 900;
  font-size: 15px;
  content: '\f078';
  position: absolute;
  top: 0;
  right: 0;
  color: #292d32;
}
.support-card p {
  margin: 15px 0 0;
}
.about-offer {
  padding: 0 67px;
  border-radius: 20px;
  margin: 0;
  align-items: flex-end;
}
.about-offer .offer-path-content {
  padding: 67px 42px 67px 0;
}
.about-offer.offer-paths::after {
  content: none;
}
.about-offer .offer-pathimg {
  margin-top: 0;
}
.service-offer {
  position: relative;
  padding-bottom: 90px;
}
.service-offer:before {
  position: absolute;
  content: '';
  background: #f9f9f9;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
}
.about-offer .offer-path-content h3 {
  margin-bottom: 25px;
}
.about-offer .btn-views {
  min-width: 138px;
  color: #28cea2;
}

/*-----------------
	12. Services
-----------------------*/

.react-calendar {
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar__navigation button {
  color: #28cea2;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
.react-calendar__month-view__days__day--weekend {
  color: #222;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #28cea2;
  border-radius: 6px;
}
.react-calendar__tile--now {
  background: #52cead;
  border-radius: 6px;
  font-weight: bold;
  color: #222;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #52cead;
  border-radius: 6px;
  font-weight: bold;
  color: #28cea2;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #28cea2;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #28cea2;
  color: white;
}

.service-wrap {
  margin-bottom: 30px;
}
.service-wrap p:last-child {
  margin-bottom: 0;
}
.provide-service.service-wrap {
  margin-bottom: 15px;
}
.service-wrap h5 {
  font-weight: 600;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.service-wrap .owl-nav button.owl-next,
.service-wrap .owl-nav button.owl-prev {
  margin-bottom: 20px;
}
.serv-profile {
  margin: 0 0 22px;
}
.serv-profile span.badge {
  font-weight: 400;
  font-size: 16px;
  background: #f7f7ff;
  color: #28cea2;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 8px 10px;
  display: inline-block;
}
.serv-profile h2 {
  font-size: 36px;
  margin-bottom: 8px;
}
.serv-profile ul li {
  font-size: 16px;
  display: inline-block;
  margin-right: 25px;
}
.serv-profile ul li:last-child {
  margin-right: 0;
}
.serv-profile .serv-pro {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.serv-pro-info h6 {
  font-weight: 500;
  margin-bottom: 6px;
}
.serv-profile .serv-pro img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 10px;
}
.serv-profile .serv-review {
  margin-bottom: 0;
}
.serv-profile .serv-review span {
  font-size: 14px;
}
.service-map i {
  color: #c2c9d1;
}
.serv-action {
  text-align: right;
  margin: 35px 0 22px;
}
.serv-action ul li {
  margin-right: 12px;
  display: inline-block;
}
.serv-action ul li:last-child {
  margin-right: 0;
}
.serv-action ul li a {
  width: 47px;
  height: 47px;
  background: #fff;
  border: 2px solid #e6e8ec;
  color: #74788d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
}
.serv-action ul li a:hover {
  background: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.serv-action ul li a:hover img {
  filter: brightness(0) invert(1);
}
.serv-action ul li a img {
  width: 15px;
}
.service-images {
  position: relative;
  margin-bottom: 10px;
}
.service-images img {
  width: 100%;
  border-radius: 10px;
}
.small-gallery .circle-icon {
  display: none;
}
.small-gallery:hover .circle-icon {
  display: flex;
}
.circle-icon {
  width: 58px;
  height: 58px;
  color: #000000;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.provide-box {
  margin-bottom: 1.5rem;
  display: flex;
}
.provide-box > span {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7ff;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
}
.provide-info h6 {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 5px;
}
.provide-info p {
  font-size: 15px;
  color: #74788d;
  word-break: break-word;
  margin-bottom: 0;
}
.provide-box > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 12px;
}
.review-box {
  background: #fff;
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}
.review-box:last-child {
  margin-bottom: 20px;
}
.review-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.review-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.review-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.review-name h6 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}
.review-box p {
  font-size: 16px;
  color: #74788d;
  margin-bottom: 15px;
}
.review-name p {
  color: #74788d;
  margin-bottom: 0;
}
.review-profile .rating {
  margin-bottom: 10px;
}
.recommend-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}
.recommend-item > a {
  font-weight: 500;
  font-size: 14px;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.recommend-item > a i {
  color: #74788d;
}
.recommend-info {
  display: inline-flex;
  align-items: center;
}
.recommend-info p {
  font-size: 14px;
  font-weight: 500;
  color: #28283c;
  margin-bottom: 0;
}
.recommend-info a {
  background: #f7f7ff;
  border-radius: 4px;
  border-radius: 5px;
  color: #28cea2;
  padding: 6px 15px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  margin-left: 10px;
}
.recommend-info a:hover {
  background: #e1e1e1;
}
.btn-review {
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 27px;
}
.btn-primary:hover img {
  filter: brightness(0) invert(1);
}
.reply-area {
  margin: 15px 0 0;
}
.reply-area textarea {
  margin-bottom: 10px;
}
.reply-area .btn {
  font-weight: 500;
  font-size: 14px;
  color: #28283c;
}
.available-widget span.avail-icon {
  width: 60px;
  height: 60px;
  background: #f4f5f6;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 20px;
  flex-shrink: 0;
}
.package-widget {
  background: #f7f7ff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}
.package-widget h5 {
  font-size: 24px;
  margin-bottom: 20px;
}
.package-widget ul li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 24px;
  color: #74788d;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.package-widget ul li:last-child {
  margin-bottom: 0;
}
.package-widget ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\f058';
  font-weight: 900;
  font-family: 'Font Awesome 6 Free';
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  color: #28cea2;
}
.pack-service.package-widget ul li {
  padding-left: 0;
}
.pack-service .custom_check .checkmark {
  border: 1px solid #c2c9d1;
  background: transparent;
}
.pack-service.package-widget ul li:before {
  content: none;
}
.service-gal {
  margin-bottom: 40px;
}
.big-gallery a.btn-show {
  background: #fff;
  border-radius: 8px;
  font-weight: 500;
  padding: 10px 15px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}
.big-gallery a.btn-show:hover {
  background: #28cea2;
  color: #fff;
}
.card-provide {
  border: 2px solid #f5f5f5;
}
.card-provide .card-body {
  padding: 20px;
}
.card-provide .btn {
  font-weight: 500;
  font-size: 16px;
  padding: 11px 9px;
  width: 100%;
}
.provide-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 30px;
}
.service-amount h5 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 7px;
}
.service-amount h5 span {
  font-size: 29px;
}
.service-amount h5 span {
  color: #74788d;
  font-size: 20px;
  margin-left: 5px;
  text-decoration: line-through;
}
.service-amount .serv-review {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.service-amount .serv-review span {
  font-size: 16px;
  font-weight: 600;
  color: #28283c;
}
.service-amount p,
.serv-review {
  font-weight: 400;
  font-size: 17px;
  color: #74788d;
}
.service-amount p span {
  font-weight: 600;
  font-size: 18px;
  color: #313131;
}
.provide-info .serv-review {
  font-size: 14px;
}
.serv-review {
  font-size: 12px;
  color: #74788d;
}
.serv-review span {
  color: #28283c;
  font-weight: 600;
  font-size: 14px;
}
.service-amount p i,
.serv-review i {
  color: #ffbc35;
}
.serv-proimg {
  position: relative;
}
.serv-proimg img {
  width: 61px;
  height: 61px;
  border-radius: 50%;
}
.serv-proimg span {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 15px;
  color: #58c27d;
  background: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-block;
  text-align: center;
}
.card-available {
  background: #f7f7ff;
  border-radius: 8px;
  border: 0;
}
.card-available .card-body {
  padding: 15px;
}
.available-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.available-info {
  flex: 1;
}
.available-info h5 {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.available-info ul li {
  color: #28283c;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 14px;
}
.available-info ul li:last-child {
  margin-bottom: 0;
}
.available-info ul li span {
  float: right;
  color: #74788d;
}
.available-info ul li span.text-danger {
  color: #f82424 !important;
}
.available-info p span {
  color: #808080;
  font-size: 15px;
  font-weight: 400;
  margin-left: 40px;
}
.add-serving {
  width: calc(100% - 65px);
}
.add-serving,
.add-serv-item {
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.add-serv-img {
  margin-right: 8px;
}
.add-serv-img img {
  width: 48px;
  height: 48px;
  border-radius: 5px;
  flex-shrink: 0;
}
.add-serv-info,
.add-serv-item {
  overflow: hidden;
}
.add-serv-info h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-serv-info p {
  font-size: 14px;
  color: #74788d;
  margin-bottom: 0;
}
.add-serv-info p i {
  color: #c2c9d1;
}
.add-serv-amt h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}
.card-provide .map-grid {
  margin-bottom: 20px;
}
.card-provide .contact-map {
  height: 250px;
  border-radius: 10px;
}
#background-video {
  width: 100%;
  height: 300px;
  position: relative;
  background: url('../img/video-bg.jpg') top center no-repeat;
  border-radius: 5px;
  background-size: cover;
}
#background-video iframe {
  width: 100%;
  height: 100%;
  display: none;
}
#background-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.progress-wrap {
  position: fixed;
  right: 25px;
  bottom: 25px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(112, 201, 134, 0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap::after {
  position: absolute;
  content: '\f062';
  font-family: 'FontAwesome';
  text-align: center;
  line-height: 35px;
  font-size: 17px;
  color: #28cea2;
  left: 0;
  top: 0;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: #28cea2;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.service-fav .item-info {
  position: unset;
  padding: 0;
  width: auto;
}
.serv-user {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.serv-user img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 10px;
}
.serv-user-info h5 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
}
.serv-user-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.service-fav .service-content {
  padding: 15px;
}
.service-fav .btn {
  font-weight: 500;
  font-size: 13px;
  padding: 6px 15px;
  border-radius: 4px;
}
#datetimepickershow {
  background: #fff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 20px;
}
#datetimepickershow .bootstrap-datetimepicker-widget table th.next {
  float: right;
  margin-right: 6px;
  margin-bottom: 15px;
}
#datetimepickershow .bootstrap-datetimepicker-widget table th.prev {
  float: left;
  margin-left: 6px;
  margin-bottom: 15px;
}
#datetimepickershow .bootstrap-datetimepicker-widget table td {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}
#datetimepickershow .bootstrap-datetimepicker-widget table td.active {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
}
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td.day {
  font-size: 14px;
}
#datetimepickershow .bootstrap-datetimepicker-widget table td.day {
  font-size: 14px;
  line-height: 40px;
}

/*-----------------
	13. Booking
-----------------------*/

.step-register {
  margin: 0 0 40px;
}
.step-register li {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-bottom: 3px solid rgba(20, 85, 141, 0.1);
  position: relative;
  margin-bottom: 15px;
  padding: 0 0 24px;
  opacity: 0.5;
}
.step-register li:first-child {
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}
.step-register li.active::before {
  content: '';
  border-bottom: 3px solid #28cea2;
  border-radius: 5px;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
}
.step-register li.active,
.step-register li.activate {
  opacity: 1;
}
.step-register li.active .multi-step-icon,
.step-register li.activate .multi-step-icon {
  background: #28cea2;
}
.step-register li .multi-step-icon {
  width: 50px;
  height: 50px;
  background: rgba(76, 64, 237, 0.19);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 10px;
  flex-shrink: 0;
}
.step-register li .multi-step-info h6 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 7px;
}
.step-register li .multi-step-info p {
  color: #74788d;
  margin-bottom: 0;
  font-size: 15px;
}
.booking-service {
  margin-bottom: 25px;
}
.service-book {
  display: inline-flex;
  display: -webkit-inline-flex;
}
.service-book .serv-profile {
  margin: 0 0 25px;
}
.service-book-img {
  margin: 0 20px 25px 0;
}
.service-book-img img {
  width: 175px;
  border-radius: 10px;
}
.service-book .serv-profile h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.provide-info h5 {
  font-size: 20px;
  font-weight: bold;
  color: #28cea2;
  margin-bottom: 0;
}
.visits {
  width: 14.28%;
  padding: 0 8px;
  margin-right: -4px !important;
}
.visit-btns {
  color: #272b41;
  background-color: #fff;
  width: 100%;
  margin-bottom: 15px;
  display: block;
  outline: unset;
  cursor: pointer;
}
.visits input:checked ~ .visit-rsn,
.visits .visit-rsn:hover {
  background: #28cea2;
  border: 2px solid rgba(220, 220, 220, 0.3);
  color: #fff;
  border-radius: 4px;
}
.visits input:checked ~ .visit-rsn:before {
  opacity: 1;
}
.visits input:disabled ~ .visit-rsn {
  background-color: #e30303;
  color: #fff;
  border-radius: 5px;
}
.visits .form-check-input {
  opacity: 0;
}
.visits input.form-check-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  margin-left: 0;
}
.visits span.visit-rsn {
  text-align: center;
  background: #f7f7ff;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  color: #74788d;
  padding: 7px 5px;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}
.book-submit {
  margin-top: 80px;
}
.book-submit .btn {
  font-weight: 500;
  padding: 10px 20px;
}
.book-submit .btn + .btn {
  margin-left: 24px;
}
.book-title h5 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
}
.book-option {
  text-align: right;
}
.book-option ul li {
  display: inline-block;
  font-size: 14px;
  color: #68616d;
  margin-right: 30px;
}
.book-option ul li:last-child {
  margin-right: 0;
}
.book-option ul li .custom_check {
  padding-left: 30px;
}
.book-option ul li .custom_check .checkmark {
  margin-right: 10px;
}
.book-avail .custom_check .checkmark {
  border: 1px solid #dcdcdc;
}
.book-select .custom_check .checkmark {
  border: 1px solid #dcdcdc;
  background: #28cea2;
}
.book-notavail .custom_check .checkmark {
  background: #68616d;
  border: 1px solid #dcdcdc;
}
.book-form {
  margin-bottom: 30px;
}
.book-form .select2-container--default .select2-selection--single {
  border: 1px solid #c2c9d1;
  border-radius: 5px;
  height: 42px;
}
.book-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 14px;
  color: #74788d;
  line-height: 42px;
}
.book-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 42px;
}
.book-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #68616d;
}
.book-form .col-form-label {
  color: #28283c;
  padding: 0 0 8px;
}
.booking-done h6 {
  font-size: 36px;
  margin-bottom: 10px;
}
.booking-done p {
  color: #74788d;
  margin-bottom: 30px;
}
.booking-done .book-submit {
  margin-top: 0;
}
.summary-box {
  background: #f7f7ff;
  border-radius: 8px;
  margin-bottom: 24px;
}
.booking-info {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #f5f5f5;
}
.booking-info .service-book-img img {
  width: 114px;
  height: 101px;
  object-fit: cover;
}
.booking-info .service-book-img {
  margin: 0 10px 10px 0;
}
.booking-info .serv-profile span.badge {
  margin-bottom: 5px;
}
.booking-info .serv-profile h2 {
  font-size: 18px;
  margin-bottom: 15px;
}
.booking-info .serv-profile ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.booking-info .serv-profile ul li {
  font-size: 14px;
  margin: 0 10px 10px 0;
}
.booking-info .serv-profile .serv-pro img {
  width: 26px;
  height: 26px;
  margin-right: 0;
}
.booking-info .service-book .serv-profile {
  margin: 0;
}
.booking-date {
  border-bottom: 1px solid #f5f5f5;
  padding: 15px;
}
.booking-date li {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: #28283c;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.booking-date li span {
  float: right;
  text-align: right;
  font-size: 14px;
  color: #74788d;
}
.booking-date li:last-child {
  margin-bottom: 0;
}
.booking-total {
  padding: 15px;
}
.booking-total ul li span {
  font-size: 16px;
  font-weight: 600;
  color: #28283c;
}
.booking-total ul li .total-cost {
  float: right;
}
.payment-card {
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 24px;
}
.booking-info-tab .payment-card:last-child {
  margin-bottom: 0;
}
.payment-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.payment-head label {
  display: inline-block;
}
.payment-head span {
  font-size: 15px;
  text-align: right;
  margin-left: 10px;
}
.payment-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.payment-title h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.payment-title .custom_radio .checkmark {
  margin-top: -2px;
}
.payment-list .form-control {
  line-height: 42px;
  min-height: 42px;
  padding: 0 15px;
}
.payment-list .col-form-label {
  font-weight: 500;
  color: #28283c;
}
.payment-list .form-control::placeholder {
  color: #74788d;
}
.pay-title {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 24px;
}
.booking-pay,
.booking-coupon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.booking-pay .btn-pay {
  margin-right: 24px;
}
.btn-pay {
  font-weight: 700;
  padding: 12px 15px;
}
.btn-skip {
  font-weight: 700;
  padding: 12px 15px;
  color: #28cea2;
  min-width: 94px;
  border-radius: 5px;
}
.coupon-icon {
  position: relative;
  margin-right: 24px;
}
.coupon-icon span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}
.coupon-icon .form-control {
  padding: 5px 10px 5px 27px;
  min-height: 42px;
}
.apply-btn {
  font-weight: 500;
  padding: 8px 15px;
  min-width: 88px;
  min-height: 42px;
}
.save-offer {
  background: #f3fff6;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 24px;
}
.save-offer p:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  background: #6dcc76;
  border-radius: 6px;
  width: 7px;
  height: 100%;
}
.save-offer p {
  color: #6dcc76;
  font-weight: 500;
  padding-left: 17px;
  margin-bottom: 0;
  position: relative;
}
.custom_check {
  display: inline-block;
  position: relative;
  font-size: 14px !important;
  margin-bottom: 15px;
  padding-left: 25px;
  cursor: pointer;
  font-weight: 400 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 18px;
}
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
  background-color: #28cea2;
  border-color: #28cea2;
}
.custom_check .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #e4e4e8;
  background-color: #fff;
  border-radius: 5px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_check .checkmark::after {
  content: '\e92b';
  font-family: 'feather' !important;
  font-weight: 900;
  position: absolute;
  display: none;
  left: 2px;
  top: 52%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 10px;
}
.custom_check input:checked ~ .checkmark:after {
  display: block;
}
.custom_radio {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  margin: 1px 0 0 0;
  border: 1px solid #e0e0e0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom_radio .checkmark::after {
  position: absolute;
  left: 4px;
  top: 4px;
  content: '';
  width: 9px;
  height: 9px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -o-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom_radio input:checked ~ .checkmark::after {
  background-color: #28cea2;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  visibility: visible;
}
.form-focus {
  position: relative;
}
.form-focus .col-form-label {
  position: absolute;
  top: -7px;
  left: 10px;
  background: #fff;
  font-weight: 500;
  font-size: 12px;
  color: #68616d;
  padding: 0 5px;
  z-index: 1;
}
/*-----------------
    14. search
-----------------------*/

.content {
  padding: 50px 0;
}
.page-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.btn-back {
  border: 2px solid #e6e8ec;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 500;
  color: #1a1a1a;
}
.btn-back:hover {
  background: #e6e8ec;
  color: #1a1a1a;
}
.page-breadcrumb ol {
  background-color: transparent;
  font-size: 17px;
  margin-bottom: 0;
  padding: 0;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: '\f111';
  font-weight: 900;
  font-family: 'Font Awesome 6 Free';
  padding: 9px 15px 0 15px;
  color: #28cea2;
  font-size: 6px;
}
.page-breadcrumb ol li a:hover {
  color: #28cea2;
}
.page-breadcrumb ol li.active {
  color: #929292;
  font-weight: 600;
}
.breadcrumb-bar {
  background-image: url('../img/bg/b.png');
  text-align: center;
  position: relative;
  padding: 53px 0;
}
.breadcrumb-bar:before {
  position: absolute;
  top: 0;
  left: 0;
  background: #f7f7ff;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
}
.breadcrumb-title {
  font-size: 42px;
  margin-bottom: 15px;
}
.breadcrumb-bar .page-breadcrumb ol {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.breadcrumb-bar .page-breadcrumb ol li a,
.breadcrumb-bar .page-breadcrumb ol li {
  font-size: 17px;
  font-weight: 400;
}
.filter-content .rating i {
  color: #ffbc35;
}
.filter-content .rating i.filled {
  color: #ffbc35;
  font-weight: 600;
}
.page-title h2 {
  font-size: 26px;
  font-weight: 600;
  color: #28283c;
  margin: 0;
}
.page-title {
  margin-bottom: 40px;
}
.filter-content .select2-container--default .select2-selection--single {
  height: 40px;
}
.filter-content
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px;
  font-size: 14px;
  color: #74788d;
}
.filter-content
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 40px;
  color: #74788d;
}
.filter-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.filter-head h5 {
  font-size: 24px;
  color: #28283c;
  font-weight: 500;
  margin: 0;
}
a.reset-link {
  color: #28cea2;
  font-size: 14px;
  font-weight: 500;
}
a.reset-link:hover {
  text-decoration: underline;
}
.filter-content {
  padding: 20px;
  background: #f7f7ff;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  margin-bottom: 18px;
}
.rating-set li {
  margin-bottom: 15px;
}
.rating-set li:last-child .rating,
.rating-set li:last-child {
  margin: 0;
}
.filter-checkbox li .checkboxs span {
  background-color: #fff;
}
.rating-set li .checkboxs span {
  top: 2px;
  background-color: #fff;
}
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #261f84;
}
.irs-min,
.irs-max {
  display: none;
}
.filter-div .filter-content:last-child {
  margin-bottom: 0;
}
.filter-content h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.filter-content h2 span {
  float: right;
}
.filter-content .form-control {
  min-height: 46px;
  border: 1px solid #c2c9d1;
  color: #74788d;
}
.filter-div .btn {
  font-weight: 500;
  width: 100%;
  padding: 10px 20px;
}
#fill-more {
  height: 180px;
  overflow: hidden;
}
.more-view {
  font-size: 13px;
  font-weight: 500;
  color: #28cea2;
  display: inline-flex;
  align-items: center;
}
.servicecontent .fav-icon i {
  transition: all 0.3s;
}
.servicecontent:hover .fav-icon a {
  background: #f7f7ff;
}
.servicecontent:hover .fav-icon i {
  transform: rotateY(360deg);
}
.group-img {
  position: relative;
}
.group-img .form-control {
  padding: 6px 30px 6px 15px;
}
.group-img img,
.group-img i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #28283c;
}
.average-rating {
  font-size: 12px;
  color: #74788d;
}
.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 18px;
  height: 18px;
  margin-left: -1px;
  background-color: #28cea2;
  border-radius: 50px;
  border: 3px solid #fff;
}
.irs--flat .irs-line {
  top: 28px;
  height: 6px;
  background-color: #e1e5ea;
  border-radius: 4px;
}
.irs--flat .irs-bar {
  top: 28px;
  height: 6px;
  background-color: #28cea2;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: white;
  font-size: 14px;
  line-height: 1.333;
  text-shadow: none;
  padding: 4px 12px;
  background-color: #0b2540;
  border-radius: 4px;
  top: -10px;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: #0b2540;
  left: 60%;
}
.filter-range-amount {
  margin-top: 15px;
}
.filter-range-amount h5 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #74788d;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #3c4758;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 25px;
  font-size: 15px;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  height: 42px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 42px;
  right: 6px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  position: absolute;
  top: 50%;
  left: 50%;
  border-color: #3c4758;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  margin-left: -10px;
  margin-top: -3px;
  width: 0;
  transform: rotate(45deg) translateY(-50%);
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #000;
  border-width: 2px 0 0 2px;
  margin-top: 3px;
  padding: 3px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #28cea2;
  color: #fff;
}
.servicecontent .service-content .title {
  font-size: 20px;
  margin-bottom: 10px;
}
.servicecontent .fav-item .fav-icon {
  width: 32px;
  height: 32px;
}
.servicecontent .service-widget .btn-book {
  padding: 5px 15px;
}
.servicecontent .service-content p i,
.service-widget .service-content p i {
  margin-right: 7px;
  color: #c2c9d1;
}
.servicecontent.service-widget .serv-info h6 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.servicecontent:hover .fav-item .serv-rating i {
  color: #28cea2;
}
.sorting-div {
  margin-bottom: 0;
}
.servicecontent.service-widget .serv-info h6 b {
  font-size: 15px;
  font-weight: 500;
}
.bg-img {
  position: relative;
}
img.bgimg1 {
  position: absolute;
  top: 0;
}
img.bgimg2 {
  position: absolute;
  top: 350px;
}
.bgimg3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.filter-checkbox {
  margin-top: 20px;
}
.checkboxs {
  cursor: pointer;
  display: flex;
  min-height: 16px;
  align-items: center;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #607d8b;
  font-weight: normal;
  margin-bottom: 0;
}
.checkboxs input {
  display: none;
}
.checkboxs span {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  border: 1px solid #c2c9d1;
  border-radius: 2px;
  box-sizing: border-box;
  overflow: hidden;
  margin-right: 8px;
  background: #f7f7ff;
}
.checkboxs span i {
  position: relative;
  width: 15px;
  height: 15px;
  margin-top: 0px;
  margin-left: 2px;
  transform: rotate(-45deg) translateX(0%) translateY(0%) scale(0.7);
  transform-origin: center left;
}
.filter-checkbox li {
  margin-bottom: 15px;
}
.checkboxs .check-content {
  color: #28283c;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  padding-top: 0;
}
.checkboxs span i:before,
.checkboxs span i:after {
  content: '';
  position: absolute;
  background-color: white;
  border-radius: 20px;
}
.checkboxs span i:before {
  height: 0%;
  top: 50%;
  left: 0;
  width: 3.5px;
}
.checkboxs span i:after {
  width: 0%;
  bottom: 0;
  left: 0;
  height: 3px;
}
.checkboxs input:checked ~ span {
  border-color: #28cea2;
  background-color: #28cea2;
}
.checkboxs input:checked ~ span i:before {
  height: 50%;
  transition: height 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.checkboxs input:checked ~ span i:after {
  width: 100%;
  transition: width 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.checkboxs input:checked ~ b {
  color: #263238;
}
.count-search h6 {
  font-size: 14px;
  font-weight: 500;
  color: #28283c;
  margin: 4px 0 0;
}
.sorting-div .grid-listview a {
  width: 40px;
  height: 40px;
}
.sorting-div .grid-listview a.active,
.sorting-div .grid-listview a:hover {
  background: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.sorting-div .grid-listview a:hover > img {
  filter: brightness(0) invert(1);
}
.sortbyset {
  display: flex;
  align-items: center;
  margin: 0 10px 20px 0;
}
.sortbyset h4 {
  font-size: 16px;
  font-weight: 500;
  margin-right: 6px;
  margin-bottom: 0;
}
.sorting-select {
  min-width: 178px;
}
.grid-listview {
  margin-bottom: 20px;
}
.grid-listview ul {
  display: flex;
  align-items: center;
}
.grid-listview a {
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #edecf8;
  color: #74788d;
  border-radius: 7px;
}
.grid-listview a:hover > img {
  filter: brightness(0) saturate(100%) invert(34%) sepia(84%) saturate(5841%)
    hue-rotate(240deg) brightness(92%) contrast(102%);
}
.grid-listview li + li {
  margin-left: 8px;
}
.grid-listview a.active,
.grid-listview a:hover {
  background: #f7f7ff;
  color: #28cea2;
}
.sorting-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #74788d;
  line-height: 38px;
  font-weight: 500;
}
.sorting-select .select2-container--default .select2-selection--single {
  height: 40px;
  font-size: 14px;
}
.sorting-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 38px;
}
.fav-icon.active {
  background: #28cea2;
  color: #fff;
}
.modal {
  z-index: 1050;
}
.modal-backdrop {
  z-index: 1040;
}

/*-----------------
	15. Error
-----------------------*/

.error-page .bgimg3 {
  width: 40%;
}
.error-page .content {
  padding: 30px 0;
}
.error-wrap .error-logo {
  margin-bottom: 30px;
}
.error-wrap .error-logo img {
  max-height: 35px;
}
.error-wrap h2 {
  font-size: 38px;
  margin-bottom: 10px;
}
.error-wrap .error-img {
  margin-bottom: 30px;
}
.error-page .error-wrap {
  text-align: center;
}
.error-wrap p {
  margin-bottom: 30px;
}
.error-wrap .btn {
  font-weight: 500;
  padding: 10px 25px;
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*-----------------
	16. Contact Us
-----------------------*/

.contact-details {
  margin-bottom: 66px;
}
.contact-img {
  position: relative;
  margin-right: 25px;
}
.contact-img img {
  border-radius: 10px;
}
.contact-img:before {
  position: absolute;
  content: '';
  background: url('../img/bg/contact-bg.png') no-repeat;
  left: -15px;
  top: -15px;
  width: 113px;
  height: 113px;
  z-index: -1;
}
.contact-img:after {
  position: absolute;
  content: '';
  background: url('../img/bg/contact-bg.png') no-repeat;
  right: -6px;
  bottom: 120px;
  width: 113px;
  height: 113px;
  z-index: -1;
}
.contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 24px;
}
.contact-info:hover {
  background: #fff;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
}
.contact-info span {
  font-size: 30px;
  color: #28cea2;
  width: 80px;
  height: 80px;
  background: #f7f7ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-shrink: 0;
  border-radius: 50%;
}
.contact-data {
  margin-left: 15px;
}
.contact-data h4 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 8px;
}
.contact-data p {
  color: #74788d;
  margin-bottom: 0;
}
.contact-data p a {
  color: #68616d;
}
.contact-map {
  border: 0;
  display: block;
  width: 100%;
  height: 450px;
  margin: 0;
}
.col-form-label {
  color: #28283c;
}
.form-icon {
  position: relative;
}
.form-icon .cus-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  height: auto;
}
.form-icon .form-control {
  padding-right: 30px;
}
.form-icon.form-msg span {
  top: 20px;
}
.form-control::placeholder {
  color: #74788d;
}
.contact-queries h2 {
  font-size: 38px;
  margin-bottom: 40px;
  font-family: 'Poppins', sans-serif;
}
.contact-queries .btn {
  font-weight: 500;
  padding: 12px 22px;
  margin-top: 22px;
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-queries .form-group {
  margin-bottom: 18px;
}
.contact-queries .form-control {
  min-height: 46px;
  padding: 9px 30px 8px 15px;
}

/*-----------------
	17. Login
-----------------------*/

.login-back {
  margin-bottom: 40px;
}
.login-back.manage-log {
  margin-bottom: 36px;
}
.login-back a {
  font-size: 14px;
  color: #74788d;
}
.login-back a:hover {
  color: #28cea2;
}
.login-header {
  margin-bottom: 30px;
}
.login-header h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  margin-bottom: 13px;
}
.login-header p {
  font-size: 14px;
  margin-bottom: 8px;
}
.login-header h6 {
  font-size: 14px;
  font-weight: 400;
  color: #28283c;
  margin-bottom: 10px;
}
.login-header h6 a {
  color: #28cea2;
}
.login-header h6 a:hover {
  color: #2ca384;
}
.login-wrap .col-form-label {
  font-size: 16px;
  color: #28283c;
  padding: 0;
  margin-bottom: 8px;
}
.login-wrap .form-control {
  border-radius: 5px;
  border-color: #c2c9d1;
}
.login-wrap .form-control::placeholder {
  color: #d1d1d1;
  font-size: 14px;
}
.char-length p {
  font-size: 13px;
  margin-bottom: 20px;
}
.forgot-link {
  font-size: 13px;
  font-weight: 500;
  color: #28cea2;
  text-decoration: underline;
  display: inline-block;
}
.login-wrap .custom_check {
  font-size: 14px;
}
.login-wrap .login-btn {
  font-weight: 500;
  border-radius: 5px;
  padding: 12px 10px;
  margin-bottom: 20px;
}
.login-or {
  color: #818181;
  margin-bottom: 30px;
  margin-top: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}
.or-line {
  background-color: #edecf8;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}
.span-or {
  font-size: 13px;
  background-color: #fff;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  text-align: center;
  top: 1px;
  padding: 0 10px;
}
.btn-google {
  background: #f7f7ff;
  border-radius: 5px;
  color: #28283c;
  font-weight: 500;
  font-size: 14px;
  padding: 9px;
}
.btn-google:hover {
  border-color: #28cea2;
  color: #28cea2;
  background: #fff;
}
.social-login .btn {
  font-weight: 500;
  padding: 10px 20px;
}
.social-login .btn + .btn {
  margin-top: 10px;
}
.iti__selected-flag {
  padding-left: 15px;
}
.no-acc {
  font-size: 14px;
  color: #74788d;
  text-align: center;
  margin: 30px 0 0 0;
}
.no-acc a {
  font-size: 14px;
  font-weight: 500;
  color: #28cea2;
}
.no-acc a:hover {
  color: #2ca384;
}
.login-wrap .custom_check .checkmark {
  border-radius: 2px;
}
.passcode-wrap input {
  background: #f6f6f6;
  box-shadow: inset 0px 0px 9px rgba(76, 64, 237, 0.12);
  width: 74px;
  height: 74px;
  border: 0;
  border-radius: 50%;
  text-align: center;
  color: #28283c;
  font-size: 32px;
  font-weight: 500;
  margin-right: 18px;
}
.passcode-wrap input:last-child {
  margin-right: 0;
}
.time-expiry {
  font-size: 13px;
  background: #f7f7ff;
  border-radius: 5px;
  padding: 3px 8px;
  color: #28cea2;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 50px;
}
.login-wrap {
  max-width: 420px;
  margin: 0 auto;
}
.log-form {
  padding-bottom: 26px;
}
.otp-img {
  margin-bottom: 18px;
}
.login-header p span {
  color: #28283c;
  font-weight: 500;
}
.otp-wrap .no-acc {
  margin: 0 0 20px;
}
.pass-group {
  position: relative;
}
.toggle-password {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: #797979;
  cursor: pointer;
}
.feather-eye-off.feather-eye::before {
  content: '\e95c' !important;
}
.password-strength {
  display: flex;
  margin-top: 18px;
}
.password-strength span {
  width: 100%;
  height: 5px;
  background: #e4e6ef;
  margin-right: 10px;
  border-radius: 5px;
}
.password-strength span:last-child {
  margin-right: 0;
}
#passwordInfo {
  font-size: 13px;
  margin-top: 10px;
  color: #9e9e9e;
}
.choose-signup {
  background: #fff;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  padding: 25px;
  text-align: center;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.choose-signup h6 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}
.choose-signup p {
  font-size: 12px;
  margin-bottom: 17px;
}
.choose-signup .btn {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}
.choose-signup:hover {
  border: 2px solid #28cea2;
}
.choose-signup .btn:hover {
  background: #28cea2;
  color: #fff;
  box-shadow: inset 0 0 0 0 #fff;
}
.choose-signup:hover .btn {
  border-color: #28cea2;
  background: #28cea2;
  color: #fff;
}
.choose-img {
  margin-bottom: 16px;
}
.choose-img img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
.choose-signup:hover .btn img {
  filter: brightness(0) invert(1);
}
.poor-active span.active#poor,
.poor-active span.active#poors {
  background: #ff0000;
}
.avg-active span.active#poor,
.avg-active span.active#weak,
.avg-active span.active#poors,
.avg-active span.active#weaks {
  background: #ffb54a;
}
.strong-active span.active#poor,
.strong-active span.active#weak,
.strong-active span.active#strong,
.strong-active span.active#poors,
.strong-active span.active#weaks,
.strong-active span.active#strongs {
  background: #1d9cfd;
}
.heavy-active span.active#poor,
.heavy-active span.active#weak,
.heavy-active span.active#strong,
.heavy-active span.active#heavy,
.heavy-active span.active#poors,
.heavy-active span.active#weaks,
.heavy-active span.active#strongs,
.heavy-active span.active#heavys {
  background: #159f46;
}
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
.domain-grp {
  border: 1px solid #c2c9d1;
  border-radius: 5px;
}
.domain-grp .form-control {
  border: 0;
}
.domain-grp .input-group-text {
  background: #fff;
  border-color: #fff;
  color: #28cea2;
  font-size: 14px;
  font-weight: 500;
}

/*-----------------
	18. Customer Profile
-----------------------*/

.settings-header {
  padding: 24px;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
}
.settings-header img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #fff;
  outline: 4px solid rgba(0, 0, 0, 0.07);
}
.settings-img {
  margin-bottom: 12px;
}
.settings-header h6 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
}
.settings-header p {
  font-size: 14px;
  color: #68616d;
  margin-bottom: 0;
}
.settings-widget {
  background: #f7f7ff;
  border: 2px solid #f5f5f5;
  border-radius: 8px;
}
.settings-menu {
  padding: 24px;
}
.settings-menu ul li:not(:last-child) {
  margin-bottom: 20px;
}
.settings-menu ul li a {
  color: #74788d;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.settings-menu ul li a.active,
.settings-menu ul li a:hover {
  color: #28cea2;
}
.settings-menu ul li i,
.settings-menu ul li a img {
  margin-right: 10px;
  font-size: 20px;
  min-width: 25px;
}
.settings-menu ul li a:hover img,
.settings-menu ul li a.active img {
  filter: invert(29%) sepia(76%) saturate(7498%) hue-rotate(246deg)
    brightness(99%) contrast(88%);
}
.pro-picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.pro-img {
  margin-right: 13px;
}
.pro-img img {
  width: 61px;
  height: 61px;
  border-radius: 50%;
}
.widget-title h4 {
  font-weight: 600;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 24px;
}
.user-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.img-upload {
  background: #28cea2;
  border-radius: 4px;
  color: #fff;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.img-upload:hover {
  background: #2ca384;
  color: #fff;
}
.img-upload input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.pro-picture {
  margin-bottom: 30px;
}
.pro-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.pro-info .img-upload {
  margin: 0 15px 10px 0;
}
.pro-info .btn-remove {
  background: #fff5f5;
  border: 1px solid #f82424;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  color: #f82424;
  margin-bottom: 10px;
}
.pro-info .btn-remove:hover {
  background-color: #ff4949;
  color: #fff;
}
.form-info .col-form-label {
  font-weight: 500;
}
.form-info .form-control {
  background: #f6f6f6;
  border-color: #f6f6f6;
  min-height: 46px;
}
.form-info .form-control::placeholder {
  color: #d1d1d1;
}
.form-info .select2-container--default .select2-selection--single {
  background: #f6f6f6;
  border-color: #f6f6f6;
}
.brief-bio {
  font-size: 14px;
  font-weight: 400;
  color: #74788d;
}
.linked-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.linked-acc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}
.linked-acc .link-icon {
  width: 46px;
  height: 46px;
  background: #f9f9fb;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  flex-shrink: 0;
}
.linked-info h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 6px;
}
.linked-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.linked-info p span {
  color: #28283c;
}
.linked-action {
  margin-left: auto;
}
.btn-connect {
  font-size: 14px;
  font-weight: 500;
  padding: 9px 23px;
  border-radius: 4px;
  vertical-align: top;
}
.btn-acc {
  background: #fff5f5;
  border-radius: 4px;
  color: #f82424;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 24px;
}
.btn-acc:hover {
  background: #ff4949;
  color: #fff;
}
.acc-submit {
  text-align: right;
}
.acc-submit .btn {
  font-weight: 500;
  padding: 12px 20px;
  border-radius: 5px;
}
.acc-submit .btn + .btn {
  margin-left: 24px;
}
.setting-submenu {
  padding: 5px 0 0 35px;
}
.setting-submenu li {
  font-size: 13px;
  margin-bottom: 12px !important;
}
.setting-submenu li:last-child {
  margin-bottom: 0 !important;
}
.btn-set {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 19px;
  min-width: 99px;
  text-align: center;
  border-radius: 4px;
}
.link-check {
  color: #6dcc76;
  margin-right: 30px !important;
}
.link-close {
  color: #f82424;
  margin-right: 30px !important;
}
.linked-item a {
  display: inline-block;
  margin-right: 10px;
}
.linked-item a:last-child {
  margin-right: 0;
}
.linked-item:last-child .linked-acc,
.linked-item:last-child .btn-set {
  margin-bottom: 0;
}
.custom-table .badge-success,
.custom-table .badge-danger,
.custom-table .badge-warning {
  min-width: 79px;
  font-size: 15px;
  font-weight: 500;
  padding: 6px 8px;
  text-align: center;
  display: inline-block;
  border-radius: 4px;
}
.badge-success {
  background: #f3fff6;
  border-radius: 6px;
  color: #6dcc76;
  padding: 6px 15px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.sml-status .checktoggle {
  width: 42px;
  height: 24px;
}
.sml-status .check:checked + .checktoggle {
  background-color: #6dcc76;
  border: 1px solid #6dcc76;
}
.sml-status .checktoggle:after {
  width: 20px;
  height: 20px;
  transform: translate(2px, -50%);
}
.sml-status .check:checked + .checktoggle:after {
  transform: translate(calc(-100% - 2px), -50%);
}
.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.checktoggle {
  background-color: #cccfe0;
  border: 1px solid #cccfe0;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 22px;
  margin-bottom: 0;
  position: relative;
  width: 41px;
}
.checktoggle:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(3px, -50%);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition:
    left 300ms ease,
    transform 300ms ease;
}
.check:checked + .checktoggle {
  background-color: #00cc45;
  border: 1px solid #00cc45;
}
.check:checked + .checktoggle:after {
  background-color: #fff;
  left: 100%;
  transform: translate(calc(-100% - 3px), -50%);
}
.blue-tog .check:checked + .checktoggle {
  background: #2ca384;
  border: 1px solid #2ca384;
}
.status-tog .checktoggle {
  height: 24px;
  width: 42px;
  border-radius: 999px;
}
.status-tog .check:checked + .checktoggle:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  background-image: url(../img/icons/x-icon.svg);
  background-repeat: no-repeat;
  background-size: 13px 21px;
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}
.status-tog .checktoggle:before {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  background-image: url(../img/icons/check.svg);
  background-repeat: no-repeat;
  background-size: 11px 20px;
  width: 20px;
  height: 20px;
  transform: translate(3px, -50%);
}
.status-tog .checktoggle:after {
  width: 20px;
  height: 20px;
  background-image: url(../img/icons/x-icon.svg);
  background-repeat: no-repeat;
  background-size: 12px 21px;
  transform: translate(1px, -50%);
  background-position: center;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.06);
}
.status-tog .check:checked + .checktoggle:after {
  background-image: url(../img/icons/check.svg);
  background-repeat: no-repeat;
  background-size: 12px 21px;
  transform: translate(calc(-100% - 2px), -50%);
  background-position: center;
}
.notification-table .table-thead {
  background: #edf3f9;
}
.notify-item h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}
.notify-item p {
  font-size: 14px;
  color: #74788d;
  margin-bottom: 0;
}
.notification-table .table {
  border: 1px solid #f1f1f1;
  margin-bottom: 40px;
}
.notification-table .table thead th:first-child {
  border-radius: 5px 0 0px 0px;
}
.notification-table .table thead th:last-child {
  border-radius: 0 5px 0px 0px;
}
.notification-table .table:not(:last-child) {
  margin-bottom: 40px;
}
.linked-item a.btn-gconnect {
  background: #f3fff6;
  border-radius: 4px;
  padding: 7px 17px;
  font-weight: 500;
  font-size: 13px;
  color: #6dcc76;
  margin-right: 20px;
}
.provide-table.manage-table {
  margin-bottom: 30px;
}
.write-review p {
  color: #74788d;
  margin-bottom: 20px;
}

/*-----------------
	19. Customer Dashboard
-----------------------*/

.dash-widget {
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.dash-icon {
  width: 35px;
  height: 35px;
  background: #fff2e5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 8px;
}
.dash-icon.bg-yellow {
  background: #fffdef;
}
.dash-icon.bg-blue {
  background: #f4f7ff;
}
.dash-value.text-danger {
  color: #f82424;
}
.dash-info {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.dash-info h6 {
  font-size: 14px;
  font-weight: 400;
  color: #68616d;
  margin-bottom: 20px;
}
.dash-info h5 {
  font-size: 24px;
  margin-bottom: 0;
  text-align: right;
}
.dash-value {
  font-size: 12px;
  color: #6dcc76;
  display: inline-block;
}
.bg-light-primary {
  background: #d8e6ff;
}
.bg-light-success {
  background: #f3fff6;
}
.bg-light-danger {
  background: #fff5f5;
}
.recent-booking,
.transaction-table {
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  padding: 0 15px;
}
.btn-light-danger {
  background: #fff5f5;
  border-radius: 4px;
  color: #f82424;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 12px;
}
.btn-light-danger:hover {
  background: #fcd9d9;
  color: #f82424;
}
.table-user .avatar {
  width: 40px;
  height: 40px;
}
.table-user .avatar > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
table.table td h2.table-user span {
  font-size: 13px;
  margin-top: 5px;
  font-weight: normal;
}
.recent-booking tr td:first-child,
.transaction-table tr td:first-child {
  padding-left: 0;
}
.recent-booking tr td:last-child,
.transaction-table tr td:last-child {
  padding-right: 0;
}
.recent-booking tr:last-child,
.recent-booking tr:last-child td,
.transaction-table tr:last-child,
.transaction-table tr:last-child td {
  border: 0;
}
.book-img {
  width: 42px;
  height: 42px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background: #f7f7ff;
  border-radius: 50%;
  margin-right: 10px;
}
.table-book h6 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}
.table-book p {
  font-size: 13px;
  margin-bottom: 0;
}
.table-book p i {
  color: #c2c9d1;
  margin-right: 5px;
}
.table-book p span {
  margin-left: 10px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.trans-amt {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}
.thead-light {
  background: #edf3f9;
}
.custom-table {
  border: 1px solid #f1f1f1;
  border-radius: 6px;
}
.custom-table thead th:first-child {
  border-radius: 10px 0 0 0;
}
.custom-table thead th {
  color: #28283c;
}
.custom-table thead th:last-child {
  border-radius: 0 10px 0 0;
}
.dash-wallet .dash-icon {
  width: 45px;
  height: 45px;
  background: #f6f6f6;
  margin: 0 12px 0 0;
}
.dash-wallet .dash-icon img {
  width: 20px;
}
.dash-wallet .dash-info h6 {
  color: #74788d;
  font-size: 14px;
  margin-bottom: 5px;
}
.dash-wallet .dash-info h5 {
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}
.btn-wallet {
  padding: 6px 15px;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 30px;
}
.bank-selection input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
}
.bank-selection label {
  background: #f4f7ff;
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  width: 100%;
  position: relative;
  height: 42px;
  margin-bottom: 30px;
}
.bank-selection {
  position: relative;
}
.bank-selection .role-check {
  position: absolute;
  top: -8px;
  right: -8px;
  color: #00cc45;
}
.bank-selection input ~ label .role-check {
  display: none;
}
.bank-selection input:checked ~ label .role-check {
  display: block;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #28cea2;
  text-shadow: unset;
}
#add-payout .bank-selection label {
  margin-bottom: 20px;
}
.day-cont .form-group {
  margin-bottom: 8px;
}

/*-----------------
	20. Customer Reviews
-----------------------*/

.review-list {
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.review-list:last-child {
  border-bottom: 0;
}
.review-imgs {
  flex: 0 0 87px;
  max-width: 87px;
  margin: 0 10px 12px 0;
}
.review-imgs img {
  width: 87px;
  height: 60px;
  object-fit: cover;
}
.review-info {
  margin-bottom: 12px;
}
.review-list p {
  color: #74788d;
  margin: 0;
}
.review-count {
  flex: 0 0 auto;
  margin-left: auto;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.review-count .rating {
  font-size: 14px;
  margin-bottom: 0;
}
.review-info h5 {
  margin-bottom: 10px;
}
.review-info a:hover {
  color: #28cea2;
}
.review-info h5 span {
  font-weight: 500;
  color: #28283c;
}
.review-user {
  color: #28283c;
}
.review-user .avatar {
  width: 26px;
  height: 26px;
  margin-right: 5px;
}
.review-date {
  font-size: 14px;
  color: #74788d;
}
.review-info p {
  margin-bottom: 0;
  font-size: 14px;
}
/* .review-sort {
	margin-bottom: 20px;
} */
.review-sort p {
  font-weight: 500;
  color: #28283c;
  padding-right: 6px;
  display: inline-block;
  margin-bottom: 0;
}
.review-entries span {
  font-weight: 500;
  font-size: 14px;
}
.review-sort .select2-container {
  width: auto !important;
  min-width: 125px;
  color: #74788d;
  font-size: 15px;
  text-align: left;
}
.review-sort .select2-container--default .select2-selection--single {
  border: 1px solid #edecf8;
  border-radius: 5px;
  height: 40px;
}
.review-sort
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 39px;
  color: #74788d;
}
.review-sort
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 40px;
}
.review-entries select {
  margin: 0 10px 0;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 30px 5px 10px;
  color: #28283c;
  font-weight: 500;
  font-size: 14px;
}
.review-pagination {
  text-align: right;
}
.review-pagination p {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}
.review-pagination ul {
  margin-top: 0;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
  float: none;
}
.review-pagination ul li {
  margin-left: 10px;
}
.review-pagination ul li a,
.page-item .page-link {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background: #f4f7ff;
  border: 0;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #74788d;
}
.review-pagination ul li.active a,
.page-item.active .page-link,
.page-item .page-link:hover {
  background-color: #28cea2 !important;
  border-color: #28cea2 !important;
  color: #fff;
}
.badge-danger {
  background: #fff5f5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  color: #f82424;
  padding: 6px 20px;
  display: inline-block;
}
.customer-chat .chat-cont-left .chat-scroll {
  max-height: calc(100vh - 330px);
}
.customer-chat .chat-scroll {
  max-height: calc(100vh - 330px);
}
.customer-chat .chat-cont-profile .card-body {
  max-height: calc(100vh - 330px);
}

/*-----------------
	21. Booking List
-----------------------*/

.booking-list {
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
}
.booking-widget .fav-item .fav-icon:hover {
  background: #28cea2;
}
.booking-list .booking-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  text-align: left;
  flex: 0 0 calc(100% - 235px);
  overflow: hidden;
}
.booking-list .booking-img {
  position: relative;
  margin-right: 15px;
  flex: 0 0 160px;
  max-width: 160px;
}
.booking-list .booking-img .fav-item {
  position: absolute;
  top: 5px;
  right: 5px;
}
.fav-item .fav-icon {
  width: 31px;
  height: 31px;
}
.booking-list .booking-widget .booking-img img {
  border-radius: 8px;
  height: 160px;
  object-fit: cover;
  width: 160px;
}
.booking-det-info h3 {
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 15px;
}
.booking-det-info h3 a {
  margin-right: 5px;
}
.booking-details li {
  font-size: 14px;
  margin-bottom: 10px;
}
.booking-details li:last-child {
  margin-bottom: 0;
}
.booking-details li span.book-item {
  font-weight: 500;
  font-size: 14px;
  color: #28283c;
  width: 100px;
  display: inline-block;
}
.booking-details li span.book-item > i {
  display: inline-block;
  width: 18px;
}
.booking-details li span.badge-grey {
  background: #f7f7ff;
  border-radius: 5px;
  color: #28cea2;
  padding: 2px 8px;
  display: inline-block;
}
.booking-details li span.badge {
  color: #fff;
  width: auto;
  flex: 0 0 auto;
  margin-left: 5px;
  font-weight: normal;
  padding: 4px 9px;
  height: 20px;
}
.booking-details li p {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin: 0 10px 0 0;
}
.booking-details li p:before {
  position: absolute;
  top: 4px;
  left: 0;
  content: '\f111';
  font-family: 'FontAwesome';
  font-size: 8px;
  color: #ebebeb;
}
.booking-action {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex: 0 0 235px;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.booking-action a {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 15px;
  margin: 3px 0;
  border-radius: 5px;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
}
.booking-action a + a {
  margin-left: 10px;
}
.booking-action a i {
  margin-right: 5px;
}
.btn-reshedule {
  background: #fff;
  border: 2px solid #e1e2e5;
  border-radius: 6px;
  color: #3a3a3a;
}
.btn-reshedule:hover,
.btn-rebook:hover {
  background: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.btn-rebook:hover img {
  filter: brightness(0) invert(1);
}
.btn-rebook {
  background: #edf3f9;
  border-radius: 6px;
  color: #494949;
}
.user-book {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
}
.user-book .avatar {
  width: 26px;
  height: 26px;
  margin-right: 5px;
}
.booking-det-info h3 .badge {
  padding: 9px 16px;
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
  border-radius: 4px;
}
.badge-warning {
  background: #fffdef;
  border-radius: 5px;
  color: #ffe30f;
  padding: 6px 20px;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}
.badge-info {
  background: #f4f7ff;
  color: #326cfb;
}
.booking-action .rating {
  margin-bottom: 10px;
}
.booking-action .view-btn {
  color: #28cea2;
  font-weight: 500;
  margin: 0;
  padding: 0 15px;
}
.booking-action .view-btn:hover {
  color: #28cea2;
}
.view-action {
  margin: 25px auto 0;
}
.review-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}
.rev-img {
  margin-right: 10px;
}
.rev-img img {
  width: 55px;
  height: 55px;
  border-radius: 8px;
}
.rev-info h6 {
  margin-bottom: 5px;
}
.rev-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.modal-submit .btn {
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 5px;
}

/*-----------------
	22. FAQ
-----------------------*/

.faq-card {
  background: #f7f7ff;
  border: 1px solid #edecf8;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}
.faq-card:last-child {
  margin-bottom: 0;
}
.faq-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}
.faq-title a {
  color: #28283c;
  display: block;
  position: relative;
  padding-right: 60px;
}
.faq-title > a:after {
  font-family: 'Fontawesome';
  font-weight: 900;
  content: '\f055';
  position: absolute;
  top: 0;
  right: 0;
  color: #292d32;
}
.faq-title > a:not(.collapsed) {
  color: #28cea2;
}
.faq-title > a:not(.collapsed):after {
  content: '\f056';
  color: #28cea2;
}
.faq-card .card-collapse p {
  margin: 10px 0 0;
}
.faq-sec {
  text-align: center;
}
.faq-sec h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.faq-sec p {
  font-size: 14px;
  margin-bottom: 0;
}

/*-----------------
	23. Pricing
-----------------------*/

.price-card {
  background: #fff;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  padding: 20px;
}
.price-head {
  border-bottom: 1px solid #edecf8;
  padding-bottom: 15px;
}
.price-head h6 {
  font-weight: 400;
  color: #74788d;
  margin-bottom: 5px;
}
.price-head h1 {
  font-size: 38px;
  margin-bottom: 0;
}
.price-head h1 span {
  font-size: 16px;
  color: #74788d;
  font-weight: normal;
}
.price-level {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.price-level .badge-success {
  background: #6dcc76;
  font-weight: 400;
  font-size: 13px;
  color: #fff;
  padding: 3px 8px;
}
.price-level .badge-success i {
  font-size: 8px;
}
.price-body {
  padding-top: 15px;
}
.price-body ul {
  margin-bottom: 30px;
}
.price-body ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 25px;
}
.price-body ul li:last-child {
  margin-bottom: 0;
}
.price-body ul li:before {
  position: absolute;
  content: '';
  top: 5px;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.price-body ul li.active:before {
  background-image: url('../img/icons/check-icon.svg');
}
.price-body ul li.inactive:before {
  background-image: url('../img/icons/close-icon.svg');
}
.price-body .btn-choose img {
  filter: brightness(0) saturate(100%) invert(24%) sepia(85%) saturate(5324%)
    hue-rotate(243deg) brightness(96%) contrast(93%);
}
.price-body .btn-choose {
  background: #edf3f9;
  border-radius: 8px;
  font-weight: 500;
  color: #28cea2;
  padding: 10px 20px;
  margin: 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}
.price-body .btn-choose i {
  margin-left: 5px;
}
.price-card:hover {
  background: #28cea2;
}
.price-card:hover .price-head h6,
.price-card:hover .price-head h1,
.price-card:hover .price-body ul li,
.price-card:hover .price-head h1 span {
  color: #fff;
}
.price-toggle {
  font-weight: 500;
  font-size: 14px;
  color: #28283c;
  text-align: center;
  margin-bottom: 40px;
}
.price-toggle .checktoggle {
  margin: 0 8px;
  background: #28cea2;
  border-color: #28cea2;
}
.price-toggle .check:checked + .checktoggle {
  background-color: #28cea2;
  border: 1px solid #28cea2;
}

/*-----------------
	24. Category
-----------------------*/

.category-card {
  background: #fff;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  margin-bottom: 24px;
}
.category-img {
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}
.category-img img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.category-img:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.category-info {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.category-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.category-icon {
  width: 45px;
  height: 45px;
  background: #f7f7ff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 10px;
}
.category-name h6 {
  font-size: 20px;
  margin-bottom: 0;
}
.category-name h6 a {
  color: #28283c;
}
.category-name h6 a:hover {
  color: #28cea2;
}
.category-info p {
  font-size: 16px;
  color: #74788d;
  margin-bottom: 0;
}

/*-----------------
	25. Maintenance
-----------------------*/

.maintenance-wrap h2 {
  font-size: 38px;
  margin-bottom: 8px;
}
.maintenance-wrap p {
  color: #74788d;
  margin-bottom: 30px;
}
.maintenance-wrap h6 {
  font-weight: 500;
  font-size: 18px;
  margin: 0 10px 15px 0;
}
.maintenance-wrap .social-icon ul {
  margin: 0 0 15px;
}
.social-icon.media-icon ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style: none;
  padding: 0;
}
.media-icon.social-icon ul li {
  margin-right: 16px;
}
.media-icon.social-icon ul li:last-child {
  margin-right: 0;
}
.media-icon.social-icon ul li a {
  color: #74788d;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  transition: all 0.4s ease 0s;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
}
.media-icon.social-icon ul li a:hover {
  background-color: #28cea2;
  color: #fff;
}
.maintenance-wrap .btn {
  font-weight: 700;
  padding: 10px 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.maintenance-sec {
  padding: 80px 0;
}
.maintenance-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

/*-----------------
	26. Coming Soon
-----------------------*/

.countdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.countdown-container .countdown-el {
  margin-right: 20px;
  text-align: center;
  position: relative;
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 10px;
  min-width: 100px;
  min-height: 100px;
}
.countdown-container .countdown-el:last-child {
  margin-right: 0;
}
.countdown-container .countdown-el:last-child:before {
  content: none;
}
.countdown-container .countdown-el p {
  font-weight: bold;
  font-size: 30px;
  color: #28283c;
  margin-bottom: 0;
}
.countdown-container .countdown-el span {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}
.email-notify {
  margin-bottom: 30px;
}
.email-notify .form-info,
.email-notify .btn {
  margin-bottom: 15px;
}
.email-notify .btn {
  font-weight: 500;
  font-size: 14px;
  padding: 11px 36px;
  min-width: auto;
  border-radius: 8px;
}
.coming-back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.coming-back p {
  margin: 0 23px 0 0;
}

/*-----------------
	27. Terms & Conditions
-----------------------*/

.terms-content p {
  margin-bottom: 24px;
  color: #74788d;
}
.terms-content ul {
  margin-bottom: 24px;
}
.terms-content ul li {
  position: relative;
  color: #74788d;
  margin-bottom: 10px;
  padding-left: 30px;
}
.terms-content ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'FontAwesome';
  font-weight: 900;
  content: '\f058';
  color: #28cea2;
}
.terms-btn .btn {
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 20px;
  margin-right: 15px;
}
.terms-btn .btn:last-child {
  margin-right: 0;
}
.privacy-cont p:last-child {
  margin-bottom: 0;
}

/*-----------------
	28. Provider Header
-----------------------*/

.provider-body .header {
  background: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  height: 76px;
  width: auto;
  transition: all 0.45s ease 0s;
  border-bottom: 2px solid rgba(236, 238, 246, 0.85);
}
.header .header-left {
  float: left;
  height: 70px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 270px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}
.header .header-left .logo img {
  max-height: 24px;
  width: auto;
}
.page-headers h6 {
  margin: 0;
  font-size: 22px;
  color: #28283c;
  font-weight: 500;
}
.page-headers .search-bar .form-control {
  border: 1px solid #c2c9d1;
  border-radius: 8px;
  padding: 9px 9px 9px 36px;
}
.page-headers .search-bar {
  position: relative;
  width: 250px;
}
.page-headers .search-bar span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}
.header-left .logo.logo-small {
  display: none;
}
.header-split {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}
.header .dropdown-menu > li > a {
  position: relative;
}
.header .dropdown-toggle:after {
  display: none;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #68616d;
  border-right: 2px solid #68616d;
  content: '';
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
}
.header .has-arrow .dropdown-toggle[aria-expanded='true']:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
  align-items: center;
}
.dropdown-heads > a {
  background: #f7f7fa;
  width: 40px;
  height: 40px !important;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
}
.dropdown-heads {
  margin: 0 5px;
}
.provider-body .user-menu.nav > li > a {
  color: #74788d;
  font-size: 13px;
  padding: 0 15px;
  margin: 15px 0px;
  line-height: unset;
  height: auto;
}
.provider-body .user-menu.nav > li > a > i {
  font-size: 18px;
}
.provider-body .flag-nav > a img {
  margin-right: 0;
}
.provider-body .user-menu.nav > li.account-item > a:hover,
.provider-body .user-menu.nav > li.account-item > a:focus {
  background-color: transparent;
}
.provider-body .user-menu.nav > li > a.viewsite {
  display: inline-flex;
  align-items: center;
  margin: 0;
}
.provider-body .user-menu.nav > li > a.viewsite:hover,
.provider-body .user-menu.nav > li > a.viewsite:hover i {
  color: #28cea2;
  background: transparent;
}
.provider-body .header .has-arrow.account-item .dropdown-toggle:after {
  content: none;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: #28cea2;
}
.user-menu.nav > li.view-btn > a:hover i,
.user-menu.nav > li.view-btn > a:focus i {
  color: #fff;
}
.user-menu.nav > li.view-btn > a:focus {
  background-color: #923564 !important;
}
.user-link {
  display: flex;
  align-items: center;
}
span.user-content {
  padding-left: 8px;
}
span.user-name {
  display: block;
  color: #28283c;
  font-size: 14px;
  font-weight: 600;
}
span.user-details {
  color: #9e9e9e;
  font-size: 12px;
  font-weight: 400;
}
.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.user-menu .dropdown-menu {
  min-width: 200px;
  padding: 0;
  margin-top: 10px !important;
}
.user-menu .dropdown-menu .dropdown-item {
  padding: 7px 15px;
}
.user-menu .dropdown-menu .dropdown-item:first-child {
  border-top: 0;
}
.user-menu .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
  font-size: 14px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
  color: #28cea2;
}
.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
  background-color: #28cea2;
  color: #fff;
}
.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
  color: #fff;
}
.header .dropdown-menu > li > a {
  padding: 10px 18px;
}
.header .dropdown-menu > li > a i {
  color: #28cea2;
  margin-right: 10px;
  text-align: center;
  width: 18px;
}
.header .user-menu .dropdown-menu > li > a i {
  color: #28cea2;
  font-size: 16px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center;
}
.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
  color: #fff;
}
.mobile_btn {
  display: none;
  float: left;
}
.slide-nav .sidebar {
  margin-left: 0;
}
.user-header {
  background-color: #f9f9f9;
  display: flex;
  padding: 10px 15px;
}
.user-header .user-text {
  margin-left: 10px;
}
.user-header .user-text h6 {
  margin-bottom: 2px;
}
.menu-title {
  color: #a3a3a3;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0 25px;
}
.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.sidebar-overlay.opened {
  display: block;
}
html.menu-opened {
  overflow: hidden;
}
html.menu-opened body {
  overflow: hidden;
}
.user-menu.nav > li.view-btn > a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 9px 15px;
  border-radius: 6px;
  background-color: #28cea2;
}
.user-menu.nav > li.view-btn > a:hover {
  background-color: #e60073;
  color: #fff;
}
.user-menu.nav > li.view-btn > a i {
  font-size: 17px;
  position: relative;
}
.view-btn {
  margin-right: 15px;
}
.select-by {
  min-width: 134px;
  margin-right: 25px;
}
.delete-wrap {
  margin-bottom: 30px;
}
.delete-wrap h2 {
  font-size: 24px;
  margin-bottom: 25px;
}
.modal-content.doctor-profile {
  border-radius: 4px;
}
.delete-wrap p {
  font-size: 24px;
  margin-bottom: 20px;
}
.del-icon {
  margin-bottom: 23px;
}
.del-icon i {
  font-size: 60px;
  color: #f0142f;
  margin-bottom: 23px;
}
.submit-section {
  text-align: center;
  margin-top: 25px;
}
.del-icon {
  margin-bottom: 23px;
}
.modal.contentmodal .modal-content .submit-section .btn {
  padding: 7px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  min-width: 150px;
  margin-top: 5px;
}
.main-wrapper {
  width: 100%;
  /* overflow-x: hidden; */
}
.mobile_btns {
  display: none;
  float: left;
}

/*-----------------
	29. Sidebar
-----------------------*/

.sidebar {
  background-color: #fff;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 90px;
  transition: all 0.2s ease-in-out 0s;
  width: 270px;
  z-index: 1038;
}
.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
  padding: 10px;
}
.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.sidebar-menu li a {
  color: #74788d;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: auto;
  padding: 0 20px;
}
.sidebar-menu li a:hover {
  color: #28cea2;
}
.sidebar-menu > ul > li > a:hover {
  color: #28cea2;
}
.sidebar-menu > ul > li.active > a:hover {
  color: #28cea2;
}
.sidebar-menu li.active > a {
  color: #28cea2;
}
.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 2px;
}
.sidebar-menu .menu-arrow:before {
  content: '\f105';
}
.sidebar-menu li a.subdrop .menu-arrow {
  -transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
  top: 10px;
  right: 0;
}
.menu-title {
  color: #9e9e9e;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 10px 5px 20px;
  white-space: nowrap;
  margin-top: 30px;
}
.menu-title h6 {
  margin: 0;
}
.siderbar-toggle .switch {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding: 0 20px;
}
.siderbar-toggle {
  position: relative;
}
.sidebar-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 27px;
  justify-content: space-between;
}
.sidebar-logo .logo {
  height: 27px;
}
.siderbar-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.siderbar-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #cccfe0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 41px;
  height: 21px;
}
.siderbar-toggle .slider:before {
  position: absolute;
  content: '';
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.siderbar-toggle input:checked + .slider {
  background-color: #28cea2;
}
.siderbar-toggle input:focus + .slider {
  box-shadow: 0 0 1px #28cea2;
}
.siderbar-toggle input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -5px;
}
.siderbar-toggle .slider.round {
  border-radius: 34px;
}
.siderbar-toggle .slider.round:before {
  border-radius: 50%;
}
.menu-title > i {
  float: right;
  line-height: 40px;
}
.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}
.card-heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-heads {
  display: flex;
}
.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
  color: #28cea2;
}
.mobile_btn {
  display: none;
  float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}
.sidebar-menu ul ul a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #b3b3b3;
  padding: 7px 10px 7px 68px;
  position: relative;
}
.sidebar-menu ul ul a:before {
  position: absolute;
  content: '\f101';
  font-family: 'FontAwesome';
  left: 50px;
  top: 8px;
}
.sidebar-menu ul ul li:last-child a {
  padding-bottom: 0;
}
.sidebar-menu ul ul {
  display: none;
  padding-top: 12px;
}
.sidebar-menu ul ul ul a {
  padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
  padding-left: 85px;
}
.sidebar-menu > ul > li {
  margin-bottom: 20px;
  position: relative;
}
.sidebar-menu > ul > li:last-child {
  margin-bottom: 0;
}
.sidebar-menu .menu-arrow i {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 20px;
  display: inline-block;
  text-rendering: auto;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  top: 10px;
  width: auto;
}
.sidebar-menu li a.subdrop .menu-arrow i {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
  top: 10px;
}
.sidebar-menu > ul > li > a {
  align-items: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu li.active > a {
  color: #28cea2;
}
.sidebar-menu li.active > a img,
.sidebar-menu li > a:hover img {
  filter: invert(29%) sepia(76%) saturate(7498%) hue-rotate(246deg)
    brightness(99%) contrast(88%);
}
.mini-sidebar .logo-small {
  display: block;
  min-width: 20px;
}
.mini-sidebar .sidebar-header {
  padding: 15px 0;
  justify-content: center;
}
.mini-sidebar .menu-user-info,
.mini-sidebar .menu-user span {
  display: none;
}
.mini-sidebar.expand-menu .sidebar-header {
  padding: 27px;
  justify-content: space-between;
}
.mini-sidebar .logo {
  display: none;
}
.logo-small {
  display: none;
}
.mini-sidebar.expand-menu .logo {
  display: block;
}
.mini-sidebar.expand-menu .siderbar-toggle {
  opacity: 1;
  width: auto;
}
.mini-sidebar #toggle_btn .slider {
  background-color: #28cea2;
}
.mini-sidebar #toggle_btn .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -5px;
}
.sidebar-menu {
  height: calc(100vh - 76px);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}
.sidebar-menu li a > .badge {
  color: #fff;
}
.menu-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  align-self: end;
  justify-content: space-between;
  margin: 50px 0 0;
  padding: 0 15px 15px;
  width: 100%;
}
.menu-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menu-user-img {
  position: relative;
  margin-right: 10px;
}
.menu-user-img img {
  width: 41px;
  height: 41px;
  border-radius: 50%;
}
.menu-user-info h6 {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 3px;
}
.menu-user-info p {
  font-size: 12px;
  margin-bottom: 0;
}
.sidebar > div {
  position: static !important;
}
.select-set {
  color: #ced1da;
}
.user-drop {
  border: 0;
  box-shadow: 0px 0px 30px rgba(69, 69, 69, 0.15);
  padding: 20px;
  display: none;
  position: absolute;
  bottom: 15px;
  left: 100%;
  width: 300px;
}
#dropboxes::before {
  border: 8px solid #fff;
  border-color: transparent transparent #fff #fff;
  box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
  content: '';
  left: 0;
  position: absolute;
  bottom: 16px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.user-drop ul,
.set-user {
  border-top: 2px solid #f4f4f4;
}
.set-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}
.set-user p {
  font-weight: 500;
  font-size: 14px;
  color: #9f9f9f;
  margin-bottom: 0;
}
.user-drop ul li {
  margin-bottom: 10px;
}
.user-drop ul li:last-child {
  margin-bottom: 0;
}
.sidebar-menu .set-menu {
  padding: 15px 0;
}
.sidebar-menu .help-menu {
  padding: 15px 0 0;
}
.user-drop ul li a {
  padding: 0;
  font-size: 14px;
  color: #6b6b6b;
}
.help-menu li a {
  font-weight: 400;
  text-decoration: underline;
}
.user-drop .menu-user {
  margin-bottom: 15px;
}
.user-drop .menu-user-info p {
  color: #949494;
}

/*-----------------
	30. Provider Dashboard
-----------------------*/

.provider-body {
  margin-top: 0;
}
.provider-body .page-wrapper {
  background-color: #fff;
  padding-top: 76px;
  min-height: calc(100vh - 40px);
  margin-left: 270px;
  position: relative;
}
.provider-body .page-wrapper > .content {
  padding: 24px;
}
.main-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.dash-card {
  background: #fff;
  border: 2px solid rgba(236, 238, 246, 0.85);
  border-radius: 6px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.dash-header {
  padding: 20px;
}
.dash-widget-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px;
}
.dash-widget-icon {
  width: 44px;
  height: 44px;
  background: #f4f7ff;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 12px;
  flex-shrink: 0;
}
.dash-widget-info {
  margin-right: 20px;
}
.dash-widget-info h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.dash-widget-info h5 {
  font-size: 18px;
  margin-bottom: 0;
}
.dash-header p {
  font-size: 15px;
}
.dash-header p span {
  color: #28283c;
  font-weight: 500;
}
.dash-header select {
  background: #f7f7ff;
  border: 1px solid #28cea2;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  color: #28cea2;
  padding: 6px;
}
.dash-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dash-total p {
  margin: 0 10px 0 0;
}
.dash-total .badge-success {
  font-size: 13px;
  padding: 1px 7px;
  border-radius: 25px;
}
.dash-total .btn {
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 22px;
}
.dash-widget-img {
  margin-top: auto;
}
.dash-widget-img img {
  width: 100%;
}
.subhead-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
.plan-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.plan-price h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 2px;
  text-align: right;
}
.plan-price p {
  font-size: 14px;
  margin-bottom: 0;
}
.plan-term h6 {
  font-weight: 500;
  color: #74788d;
  margin-bottom: 0;
}
.plan-term h6 span {
  color: #28283c;
}
.plan-term h5 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 7px;
}
.plan-term p {
  font-size: 15px;
  margin-bottom: 20px;
}
.pay-next .plan-term h6 {
  font-size: 15px;
  font-weight: 400;
}
.pay-next .plan-info:last-child .plan-term {
  margin-bottom: 0;
}
.plan-price {
  margin-bottom: 15px;
}
.plan-price .btn {
  min-width: 70px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  padding: 7px 15px;
}
.plan-btns .btn {
  font-weight: 500;
  font-size: 14px;
  margin: 5px 14px 0 0;
}
.view-history {
  text-align: center;
}
.view-history .btn {
  font-weight: 500;
  font-size: 14px;
  padding: 9px 35px;
}

/*-----------------
	31. Provider Signup
-----------------------*/

.signup-list ul {
  border-bottom: 5px solid #f2f2f2;
}
.signup-list ul li {
  display: inline-block;
  margin-right: 20px;
}
.signup-list ul li:last-child {
  margin-right: 0;
}
.signup-list ul li a {
  display: inline-block;
  padding: 12px 27px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.22);
  position: relative;
}
.signup-list ul li a.active {
  color: #28283c;
}
.signup-list ul li a.active:before {
  position: absolute;
  content: '';
  background: #28cea2;
  border-radius: 25px;
  width: 100%;
  height: 5px;
  bottom: -4px;
  left: 0;
}
.price-tab ul li {
  margin: 0 14px 16px 0;
}
.price-tab ul li:last-child {
  margin-right: 0;
}
.price-tab ul li a {
  display: inline-block;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 24px;
  color: #28283c;
}
.price-tab ul li .active,
.price-tab ul li a:hover {
  background: #edf3f9;
  border-color: #edf3f9;
  color: #28283c;
}
.price-subscribe {
  margin-bottom: 20px;
}
.price-subscribe .price-card {
  margin-bottom: 30px;
}
.price-subscribe .price-card:hover {
  background: #f4f7ff;
}
.price-subscribe .price-card:hover .price-head h1,
.price-subscribe .price-card:hover .price-body ul li {
  color: #28283c;
}
.price-subscribe .price-card:hover .price-head h6,
.price-subscribe .price-card:hover .price-head h1 span {
  color: #74788d;
}
.price-subscribe .btn-choose {
  background: #f7f7ff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #28cea2;
  padding: 9px 24px;
  margin: 0 auto;
}
.price-subscribe .btn-choose:hover,
.price-subscribe .price-card :hover .btn-choose {
  background: #28cea2;
  color: #fff;
}
.price-subscribe .btn-choose:hover img,
.price-subscribe .price-card :hover .btn-choose img {
  filter: brightness(0) invert(1);
}
.price-btn {
  text-align: center;
}
.price-btn .btn {
  font-weight: 500;
  padding: 12px 20px;
}
.subscribe-box {
  border: 2px solid #f4f4f4;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 24px;
}
.subscribe-box ul li {
  font-weight: 500;
  margin-bottom: 10px;
}
.subscribe-box ul li:last-child {
  margin-bottom: 0;
}
.subscribe-box ul li span {
  font-weight: 400;
  font-size: 14px;
  float: right;
  margin-right: 40px;
}
.subscribe-box ul li span a {
  color: #0e82fd;
  text-decoration: underline;
}
.payment-subscribe .btn {
  font-weight: 700;
  padding: 12px 20px;
  margin-bottom: 30px;
  width: 100%;
}
.payment-methods .custom_radio {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 25px;
}
.payment-methods h6,
.payment-subscribe h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1.5rem;
}
.payment-methods h6 span {
  color: #999999;
  font-size: 15px;
}

/*-----------------
	32. Provider Services
-----------------------*/

.fav-item .item-info {
  position: unset;
  padding: 0;
  width: auto;
}
.serv-info .serv-edit {
  margin-right: 20px;
}
.serv-info .serv-edit,
.serv-info span {
  font-weight: 500;
  font-size: 14px;
  color: #74788d;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.serv-info .serv-edit i,
.serv-info span i {
  margin-right: 5px;
  color: #c2c9d1;
}
.serv-info a:hover,
.serv-info a:hover i,
.serv-info:hover span {
  color: #28cea2;
}
.pro-service .service-content {
  padding: 15px;
}
.pro-service .service-content .title {
  font-size: 16px;
  margin-bottom: 12px;
}
.pro-service .service-widget .btn-book {
  font-size: 14px;
}
.tab-list ul {
  margin-bottom: 10px;
}
.tab-list li {
  margin: 0 10px 20px 0;
}
.tab-list li a {
  background: #f7f7ff;
  border: 1px solid #28cea2;
  border-radius: 5px;
  font-weight: 500;
  color: #28cea2;
  padding: 6px 15px;
  display: inline-block;
}
.tab-list li a.active,
.tab-list li a:hover {
  background: #28cea2;
  border-color: #28cea2;
  color: #fff;
}
.edit-service .login-back {
  margin-bottom: 20px;
}
.edit-service h5 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 30px;
}
.edit-service h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 25px;
}
.category-rating {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.category-rating .item-cat {
  margin-right: 10px;
}
.category-rating .serv-rating {
  display: inline-block;
  background: #f9f9f9;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  color: #28283c;
  padding: 3px 9px;
  margin-bottom: 12px;
}
.category-rating .serv-rating i {
  color: #ffbc35;
  margin-right: 5px;
}
.service-action ul li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
}
.service-action ul li a {
  color: #74788d;
}
.service-action ul li a:hover,
.service-action ul li a:hover i {
  color: #28cea2;
}
.service-action ul li i {
  color: #c2c9d1;
  margin-right: 6px;
}

/*-----------------
	33. Provider Payout
-----------------------*/

.add-set {
  font-weight: 500;
  font-size: 14px;
  padding: 9px 15px;
}
.page-header .add-set {
  margin-bottom: 30px;
}
.balance-crad {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.balance-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.balance-head .balance-icon {
  width: 50px;
  height: 50px;
  background: #f7f7ff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}
.balance-head h6 {
  font-size: 15px;
  color: #74788d;
  font-weight: 400;
  margin-bottom: 5px;
}
.balance-head p {
  font-size: 14px;
  margin-bottom: 15px;
}
.balance-crad h3 {
  font-size: 32px;
  margin: 0;
}
.balance-crad .view-transaction {
  white-space: nowrap;
}
.balance-crad .view-transaction,
.balance-crad .btn-withdraw {
  font-size: 13px;
  font-weight: 500;
  padding: 5px 15px;
  border-radius: 4px;
}
.balance-crad .btn-withdraw {
  margin: 6px auto 0;
  display: block;
}
.balance-amt .badge-success {
  font-size: 12px;
  margin-bottom: 10px;
  padding: 4px 10px;
}
.provider-subtitle h6 {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 30px;
}
.provide-table {
  margin-bottom: 30px;
}

/*-----------------
	34. Provider Availability
-----------------------*/

.availability-sec h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}
.availability-sec .status-toggle {
  margin-bottom: 15px;
}
.availability-sec .input-group-text {
  background: #f6f6f6;
  border-radius: 10px 0px 0px 10px;
  min-width: 74px;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
}
.text-dark {
  color: #252525 !important;
}
.fw-500 {
  font-weight: 500;
}

/*-----------------
	35. Coupon
-----------------------*/

.action-set {
  background: #f7f7ff;
  color: #74788d;
  width: 36px;
  display: inline-flex;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}
.action-set:hover {
  background: #28cea2;
  color: #fff;
}
.table-action .action-set:last-child {
  margin-right: 0;
}
.page-header .review-sort,
.page-header .grid-listview {
  margin-bottom: 30px;
}
.coupon-submit {
  text-align: center;
}
.coupon-submit .btn {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  min-width: 187px;
}
.custom_radio.radio-success input:checked ~ .checkmark {
  border-color: #00cc45;
}
.custom_radio.radio-success input:checked ~ .checkmark::after {
  background-color: #00cc45;
}
.coupon-title h3 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 24px;
}

/*-----------------
	36. Provider Settings
-----------------------*/

.page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.content-side {
  min-width: 260px;
}
.content-sidebar {
  padding: 24px;
}
.content-sideheading {
  margin-bottom: 20px;
}
.content-sideheading h6 {
  font-size: 16px;
  color: #28283c;
  font-weight: 500;
  margin-bottom: 0;
}
.content-sidemenu ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}
.content-sidemenu ul li h5 {
  font-size: 13px;
  color: #526484;
  font-weight: 500;
  margin-bottom: 15px;
}
.content-sidemenu ul li a {
  color: #8094ae;
  font-size: 14px;
  font-weight: 500;
  padding-left: 25px;
  position: relative;
}
.content-sidemenu ul li a:before {
  position: absolute;
  content: '\f101';
  font-family: 'FontAwesome';
  color: #9597a9;
  left: 0;
  top: 0;
}
.content-sidemenu ul li a.active,
.content-sidemenu ul li a.active:before,
.content-sidemenu ul li a:hover,
.content-sidemenu ul li a:hover:before {
  color: #12151c;
}
.content-sidemenu ul li h4 {
  color: #8094ae;
  font-size: 13px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
}
.content-sidemenu ul li {
  margin-bottom: 10px;
  padding-left: 25px;
}
.content-sidemenu ul li.submenu-side a {
  padding-left: 40px;
}
.main-content {
  width: calc(100% - 260px);
  padding: 24px;
}
.bootstrap-tagsinput {
  display: block;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #dcdcdc;
  height: 46px;
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  box-shadow: none;
  overflow-y: auto;
}
.bootstrap-tagsinput .tag {
  margin-right: 5px;
  margin-bottom: 2px;
  color: #74788d;
  background: #f4f7ff !important;
  font-size: 14px;
  font-weight: 500;
  color: #74788d;
  border-radius: 5px;
  margin-top: 5px;
}
.bootstrap-tagsinput .tag:hover {
  color: #74788d;
}
.bootstrap-tagsinput .tag [data-role='remove'] {
  position: relative;
  left: 7px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role='remove']:after {
  content: 'x';
  padding: 0px 2px;
}
.bootstrap-tagsinput input {
  line-height: 36px;
  border: 1px solid transparent;
  background: transparent;
}
.bootstrap-tagsinput input:focus-visible {
  border: 1px solid transparent;
}
.label-text {
  font-size: 11px;
  color: #9d9d9d;
}
.form-checkbox .custom_check {
  margin-right: 22px;
  margin-bottom: 2px;
}
.slot-flex {
  border: 1px solid #c2c9d1;
  border-radius: 5px;
}
.slot-flex .form-control {
  border: 0;
}
.slot-flex .select2-container--default .select2-selection--single {
  border: 0;
}
.slot-flex .select2-container {
  width: auto !important;
  min-width: 100px;
}
.slot-flex
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #28cea2;
}
.remaind-select .bank-selection {
  display: inline-block;
  margin-right: 13px;
}
.remaind-select .bank-selection label {
  background: #f7f7ff;
  width: auto;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  height: auto;
  padding: 5px 14px;
}
.remaind-select .bank-selection input:checked ~ label {
  background: #28cea2;
  color: #fff;
}
.remaind-select .bank-selection input:checked ~ label img {
  filter: brightness(0) invert(1);
}
.remaind-select .bank-selection .role-check {
  background: #fff;
  height: 20px;
}
.appointment-sec .widget-title h4 {
  font-size: 22px;
  font-weight: 500;
}

/*-----------------
	37. Provider Plan
-----------------------*/

.card-payments h6 {
  font-weight: 500;
  color: #74788d;
  margin-bottom: 10px;
}
.card-num {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}
.card-num h6 {
  font-weight: 500;
  font-size: 16px;
  color: #28283c;
  margin-bottom: 0;
}
.visa-img {
  background: #fff;
  border: 1px solid #dfe0e3;
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 8px;
}
.card-payments {
  border-radius: 8px;
}
.card-payments.active {
  border: 2px solid #a1bcff;
}
.card-payments.active:before {
  position: absolute;
  content: '\f058';
  font-family: 'FontAwesome';
  color: #28cea2;
  right: -5px;
  top: -10px;
}
.card-payments a {
  font-weight: 500;
  font-size: 14px;
  background: #fff5f5;
  border: 1px solid #fff5f5;
  border-radius: 4px;
  color: #f82424;
  padding: 6px 15px;
  display: inline-block;
}
.card-payments a:hover {
  border: 1px solid #f82424;
}
.card-payments .card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}
.card-payment-add {
  background: #fff;
  border: 2px dashed #d9d9d9;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.card-payment-add span {
  width: 32px;
  height: 32px;
  background: #98a2b3;
  color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.pay-next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.pay-next .plan-info:last-child {
  margin: auto 0 0;
}
.pay-next .plan-info:last-child .plan-price {
  margin-bottom: 0;
}

/*-----------------
	38. Add Service Information
-----------------------*/

.form-duration {
  position: relative;
}
.form-duration .mins {
  position: absolute;
  background: #fff;
  border-radius: 6px;
  color: #28cea2;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 18px;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
}
.form-duration .form-control {
  padding: 10px 70px 10px 15px;
}
.ck-editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: 1px solid #e5e7eb;
  border-radius: 0 0 6px 6px;
}
.ck-editor .ck.ck-toolbar {
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 6px 6px 0 0 !important;
}
.sml-info {
  font-size: 12px;
  color: #929292;
}
.area-info {
  font-size: 11px;
  color: #b0b0b0;
}
.add-text {
  font-weight: 500;
  font-size: 14px;
  color: #28cea2;
}
#progressbar {
  margin-bottom: 20px;
  display: flex;
}
#progressbar li {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  margin: 0 24px 20px 0;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 10px;
  position: relative;
  text-align: left;
  width: 100%;
}
#progressbar li:last-child {
  margin-right: 0;
}
#progressbar li.active,
#progressbar li.activated {
  opacity: 1;
}
#progressbar li.active::after #progressbar li.activated::after {
  border-left: 15px solid #f7f7ff;
}
#progressbar .multi-step-info h6 {
  font-weight: 500;
  color: #28283c;
  margin-bottom: 2px;
}
#progressbar .multi-step-info p {
  font-size: 14px;
  color: #74788d;
  margin-bottom: 0;
}
#progressbar .multi-step-icon span {
  background: #fafafa;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  margin-right: 15px;
}
#progressbar li.active span {
  background: #28cea2;
  color: #fff;
}
#progressbar li.activated span {
  background-color: #000;
  color: #fff;
}
.sub-title h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 24px;
}
.schedule-nav {
  margin-bottom: 10px;
}
.schedule-nav ul li {
  margin: 5px 15px 10px 0;
  display: inline-block;
}
.schedule-nav ul li:last-child {
  margin-right: 0;
}
.schedule-nav ul > li > a {
  background: #f7f7ff;
  border: 1px solid #f7f7ff;
  border-radius: 5px;
  padding: 7px 15px;
  color: #28cea2;
  font-weight: 500;
  font-size: 14px;
}
.schedule-nav ul li a.active,
.schedule-nav ul li a:hover {
  background: #28cea2;
  border: 1px solid #28cea2;
  color: #fff;
}
.input-icon .input-group-addon {
  background: #f6f6f6;
  border-color: #f6f6f6;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-upload {
  background: #fff;
  border: 1px solid #c2c9d1;
  border-radius: 5px;
  padding: 60px 20px;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}
.file-upload img {
  margin-bottom: 20px;
}
.file-upload h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 6px;
}
.file-upload h6 span {
  color: #28cea2;
  text-decoration: underline;
}
.file-upload p {
  font-size: 12px;
  margin-bottom: 0;
}
.file-upload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.file-preview ul {
  margin-bottom: 5px;
}
.file-preview ul li {
  display: inline-block;
  margin: 0 15px 15px 0;
  position: relative;
}
.file-preview ul li h6 {
  font-weight: 400;
  font-size: 14px;
}
.file-preview ul li a {
  position: absolute;
  top: 3px;
  right: 3px;
  color: #f82424;
}
.file-preview ul li img {
  width: 83px;
  height: 72px;
  object-fit: cover;
  border-radius: 5px;
}
.img-preview {
  position: relative;
  display: inline-block;
}
.img-preview a {
  position: absolute;
  right: 0;
  top: 5px;
}
.field-btns {
  margin-top: 20px;
  text-align: right;
}
.field-btns .btn {
  font-weight: 500;
  font-size: 14px;
  padding: 9px 24px;
}
.timepicker-sec {
  display: none;
}
fieldset#first-field {
  display: block;
}
.service-inform-fieldset fieldset {
  display: none;
}
/*-----------------
	39. Orders
-----------------------*/

.page-topbar .back-link a {
  font-size: 15px;
  color: #68616d;
  display: inline-block;
  margin-bottom: 10px;
}
.page-topbar .page-breadcrumb ol {
  font-size: 16px;
  margin-bottom: 30px;
}
.page-topbar .page-breadcrumb ol li.active {
  color: #28283c;
}
.book-id {
  margin-bottom: 15px;
}
.book-id h4 {
  font-size: 20px;
  margin-bottom: 8px;
}
.book-id p {
  margin-bottom: 10px;
}
.book-id-action {
  text-align: right;
  margin-bottom: 30px;
}
.book-id-action .btn {
  padding: 9px 15px;
  font-weight: 500;
  font-size: 14px;
  margin: 0 14px 10px 0;
  border-radius: 5px;
}
.book-id-action .btn:last-child {
  margin-right: 0;
}
.chat-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-item .btn {
  font-size: 13px;
  font-weight: 500;
  padding: 5px 14px;
  margin-bottom: 10px;
}
.slot-box {
  background: #f8f8f8;
  padding: 20px 20px 10px;
  margin-bottom: 30px;
}
.slot-box h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
}
.slot-booked ul li i {
  color: #c2c9d1;
}
.slot-booked ul li {
  font-size: 14px;
  margin-bottom: 10px;
}
.slot-user-img,
.slot-chat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.slot-user-img img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 10px;
}
.slot-user-info {
  margin-right: 25px;
}
.slot-user-info p {
  font-size: 14px;
  margin-bottom: 6px;
}
.slot-action .btn {
  font-weight: 500;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 0 15px 10px 0;
}
.slot-action .btn:last-child {
  margin-right: 0;
}
.slot-action {
  float: right;
}
.slot-action .btn-light-success {
  color: #1ba345;
}
.order-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.slot-address h6 {
  font-size: 14px;
  margin-bottom: 13px;
}
.slot-address ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}
.slot-address ul li span {
  width: 40px;
  height: 40px;
  background: #f7f7ff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 10px;
}
.slot-address ul li h6 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 4px;
}
.slot-address ul li p {
  font-size: 14px;
  margin-bottom: 0;
}
.order-amt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.order-amt h5 {
  margin-bottom: 10px;
}
.order-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.order-img {
  margin-right: 8px;
}
.order-img img {
  width: 55px;
  height: 47px;
  border-radius: 8px;
}
.order-profile h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.order-profile p {
  font-size: 14px;
  margin-bottom: 0;
}
.ord-summary ul li {
  font-weight: 500;
  color: #28283c;
  margin-bottom: 15px;
}
.ord-summary ul li:last-child {
  margin-bottom: 0;
}
.ord-summary ul li .ord-amt {
  float: right;
  font-weight: 400;
  color: #74788d;
}
.ord-summary ul li.ord-total span {
  font-weight: 500;
  color: #282828;
}
.ord-code {
  background: #f7f7ff;
  border-radius: 5px;
  padding: 4px 9px;
  font-size: 13px;
  color: #28cea2;
  margin-left: 15px;
}
.order-info {
  margin-bottom: 20px;
}
.book-history ul li {
  position: relative;
  padding: 0 0 24px 20px;
  margin-left: 4px;
  border-left: 2px solid #e4e7ec;
}
.book-history ul li:before {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: -4px;
  width: 7px;
  height: 7px;
  background: #fff;
  outline: 4px solid #3d5ee1;
  border-radius: 50%;
}
.book-history ul li:last-child {
  border: 0;
  padding-bottom: 0;
}
.book-history ul li h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.book-history ul li p {
  font-size: 14px;
  margin-bottom: 0;
}
.book-history ul li p i {
  color: #28cea2;
}
.payment-summary {
  border-bottom: 1px solid #d8e2e8;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.slot-pay p {
  font-size: 13px;
}
.slot-title {
  font-weight: 500;
  font-size: 18px;
  color: #28283c;
  margin-right: 40px;
}
.slot-pay p .slot-img {
  background: #d8e3f3;
  border-radius: 4px;
  padding: 2px 1px;
  margin-left: 10px;
}
.order-reviews ul li .order-comment {
  margin-bottom: 20px;
}
.order-reviews ul li:last-child .order-comment {
  margin-bottom: 0;
}
.order-comment .rating {
  margin-bottom: 15px;
}
.order-comment h6 {
  font-size: 16px;
  font-weight: 500;
  color: #68616d;
  margin-bottom: 5px;
}
.order-comment p {
  font-size: 14px;
  margin-bottom: 0;
}
.order-comment p i {
  color: #28cea2;
}
.order-reviews .btn {
  font-weight: 500;
  font-size: 14px;
  padding: 9px 20px;
  margin-bottom: 20px;
}
.order-reviews .btn-rebook:hover {
  color: #fff;
}

/*-----------------
	40. Calendar
-----------------------*/

.fc .fc-toolbar-title {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
}
.fc .fc-col-header-cell-cushion {
  padding: 9px 4px;
  font-weight: 500;
  font-size: 16px;
  color: #969696 !important;
}
.fc-theme-bootstrap5 a:not([href]) {
  color: #969696;
}
.fc-toolbar-chunk .btn-primary.active,
.fc-toolbar-chunk .btn-primary.active:not(:disabled):not(.disabled) {
  background: #28cea2;
  border: 1px solid #28cea2;
  color: #fff;
  border-radius: 4px !important;
  box-shadow: none;
}
.fc-toolbar-chunk .btn-primary {
  background: #fff;
  border: 0;
  color: #28cea2;
  font-weight: 500;
  padding: 3px 12px;
}
.fc-toolbar-chunk .btn-primary:hover {
  color: #fff;
}
.fc-toolbar-chunk .fc-prev-button,
.fc-toolbar-chunk .fc-next-button {
  width: 35px;
  height: 35px;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #28cea2;
  color: #fff;
  border-radius: 50% !important;
}
.fc-toolbar-chunk .btn-group > .btn-group:not(:first-child),
.fc-toolbar-chunk .btn-group > .btn:not(:first-child) {
  margin-left: 10px;
}
.fc-toolbar-chunk .fc-prev-button:hover,
.fc-toolbar-chunk .fc-next-button:hover {
  background: #fff;
  color: #28cea2;
  border: 1px solid #28cea2 !important;
}
.fc-header-toolbar > .fc-toolbar-chunk > div {
  display: inline-block;
}
.fc-toolbar-chunk .fc-today-button.btn-primary {
  background: #eff6ff;
  border-color: #28cea2;
  border-radius: 4px !important;
  color: #2563eb;
  padding: 4px 15px;
  font-weight: 600;
  font-size: 16px;
  box-shadow: none;
  opacity: 1;
}
.leave-action .btn {
  font-weight: 500;
  font-size: 13px;
  padding: 5px 14px;
  border-radius: 4px;
  margin: 0 10px 30px 0;
}
.leave-action .btn:last-child {
  margin-right: 0;
}
.msg-alert {
  margin-bottom: 29px;
}
.msg-alert p {
  font-size: 14px;
  color: #9597a9;
  margin-bottom: 10px;
}
.msg-alert p span {
  color: #28283c;
  font-weight: 500;
}
.msg-alert p:last-child {
  margin-bottom: 0;
}

/*-----------------
	41. Cursor
-----------------------*/

.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}
.cursor-inner {
  margin-left: -4px;
  margin-top: -4px;
  width: 10px;
  height: 10px;
  z-index: 10000001;
  background: #28cea2 !important;
  -webkit-transition:
    width 0.3s ease-in-out,
    height 0.3s ease-in-out,
    margin 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  transition:
    width 0.3s ease-in-out,
    height 0.3s ease-in-out,
    margin 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}
.cursor-inner.cursor-hover {
  margin-left: -20px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: #28cea2 !important;
  opacity: 0.1;
}
.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  border: 2px solid #28cea2;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
.cursor-outer.cursor-hover {
  opacity: 0;
}
.mim_tm_all_wrap[data-magic-cursor='hide'] .mouse-cursor {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1111;
}

/*-----------------
	42. Booking Details
-----------------------*/

.toggle-sidebar {
  width: 400px;
  position: fixed;
  top: 0;
  right: -400px;
  height: 100vh;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
  z-index: 1040;
}
.toggle-sidebar.sidebar-popup {
  right: 0;
  width: 100%;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
}
.sidebar-layout {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 33px 74px 14px rgba(76, 64, 237, 0.08);
  border-radius: 20px 0px 0px 20px;
  max-width: 400px;
  width: 100%;
  position: absolute;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.toggle-sidebar .sidebar-header {
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 1px solid #f0f0f0;
}
.toggle-sidebar .sidebar-header h5 {
  font-size: 18px;
  margin-bottom: 0;
}
.toggle-sidebar .sidebar-close {
  background: #f82424;
  border: 0;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  width: 29px;
  height: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 0;
}
.toggle-sidebar .sidebar-close:hover {
  background: #28cea2;
  color: #fff;
}
.bk-wrap {
  border-bottom: 1px solid #f0f0f0;
  padding: 25px 25px 20px;
}
.bk-wrap h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.bk-wrap p {
  font-size: 12px;
  margin-bottom: 5px;
}
.bk-wrap .bk-price {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.book-customer h5 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}
.book-email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 17px 5px 0;
}
.book-email img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 10px;
}
.book-email p,
.book-email-info p {
  font-size: 12px;
  margin-bottom: 6px;
}
.book-email p:last-child,
.book-email-info p:last-child {
  margin-bottom: 0;
}
.book-email-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.btn-mail {
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 14px;
  margin-left: 10px;
}
.edit-book:hover {
  background: #28cea2;
  color: #fff;
}
.bk-service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.book-confirm h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.book-confirm h6 span {
  font-size: 13px;
  padding: 8px 16px;
  margin-left: 10px;
}
.book-confirm ul {
  margin-bottom: 25px;
}
.book-confirm ul li {
  font-size: 12px;
  margin-bottom: 10px;
}
.book-confirm ul li .bk-date {
  font-weight: 500;
  font-size: 14px;
  color: #28283c;
  min-width: 100px;
  display: inline-block;
}
.book-confirm ul li .bk-date i {
  color: #c2c9d1;
  width: 18px;
  display: inline-block;
}
.book-confirm ul li span {
  color: #28283c;
}
.book-confirm .btn {
  font-weight: 500;
  font-size: 13px;
}
.edit-book {
  width: 32px;
  height: 32px;
  background: #f7f7ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  color: #74788d;
  margin-bottom: 20px;
}
.bk-history h4 {
  font-size: 18px;
  margin-bottom: 20px;
}
.bk-history ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding-bottom: 40px;
}
.bk-history ul li:last-child {
  padding-bottom: 0;
}
.bk-history ul li:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  background: #eeeeee;
  left: 19px;
  top: 0;
}
.bk-history ul li:last-child:before {
  content: none;
}
.bk-history span {
  width: 38px;
  height: 38px;
  background: #f5f4fe;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  color: #28cea2;
  margin-right: 15px;
  z-index: 1;
}
.bk-history h6 {
  font-size: 14px;
  margin-bottom: 5px;
}
.bk-history p {
  font-size: 12px;
  margin-bottom: 0;
}
.wizard-book ul li {
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  position: relative;
  padding-right: 40px;
}
.wizard-book ul li:last-child {
  padding-right: 0;
}
.wizard-book ul li:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  top: 17px;
  left: 15px;
  background: #dbe3eb;
}
.wizard-book ul li:last-child:before {
  content: none;
}
.wizard-book ul li.activate:before {
  background: #28cea2;
}
.wizard-book ul li > div {
  text-align: center;
  z-index: 1;
}
.wizard-book ul li span {
  width: 37px;
  height: 37px;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #dbe3eb;
  color: #fff;
  border-radius: 50%;
  margin-bottom: 8px;
  z-index: 1;
}
.wizard-book ul li.active span,
.wizard-book ul li.activate span {
  background: #28cea2;
}
.wizard-book ul li h6 {
  font-size: 14px;
  margin-bottom: 10px;
}
.bk-action {
  text-align: center;
}
.bk-action .btn {
  margin-right: 19px;
  border-radius: 4px;
  padding: 5px 14px;
}
.bk-action .btn:last-child {
  margin-right: 0;
}

/*-----------------
    43. Chat
-----------------------*/

.chat-scroll {
  max-height: calc(100vh - 264px);
  overflow-y: auto;
}
.chat-cont-left .chat-scroll {
  max-height: calc(100vh - 286px);
  overflow-y: auto;
}
.contacts_body {
  padding: 0.75rem 0;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  overflow-y: auto;
}
.chat-cont-left .chat-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 15px;
}
.chat-cont-left .chat-header h6 {
  font-size: 20px;
  margin-bottom: 3px;
}
.chat-cont-left .chat-header p {
  margin-bottom: 0;
  font-size: 14px;
}
.chat-cont-left .chat-header.inner-chat-header {
  padding: 15px 0;
}
.chat-cont-left .chat-header.inner-chat-header h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.chat-list {
  margin: 0 auto;
  padding: 0 14px;
  text-align: center;
}
.chat-list li {
  display: inline-block;
  margin-bottom: 5px;
}
.chat-list li a {
  padding: 5px 11px;
  font-weight: 500;
  font-size: 12px;
  color: #34444c;
  border-radius: 50px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-list li a:hover {
  background-color: #f3f3f5;
}
.chat-list li a i {
  margin-right: 5px;
}
.chat-list li a.active {
  background-color: #f3f3f5;
}
.chat-compose {
  width: 37px;
  height: 37px;
  color: #34444c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #f9f9f9;
  border-radius: 5px;
  font-size: 18px;
}
.chat-cont-left .card {
  border: 2px solid rgba(220, 220, 220, 0.3);
  border-radius: 5px;
}
.chat-file-attachments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-file-attach {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-right: 20px;
}
.chat-file-download {
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.msg_card_body .media .media-body .msg-box .chat-file-icon {
  margin-right: 9px;
}
.msg_card_body .media .media-body .msg-box .chat-file-icon i {
  font-size: 25px;
}
.msg_card_body .media .media-body .msg-box .chat-file-info h6 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
.msg_card_body .media .media-body .msg-box .chat-file-info p {
  font-size: 12px;
  color: #828282;
  margin-bottom: 0;
}
.card-footer {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  border-top: 0;
}
.search {
  border-radius: 15px 0 0 15px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  color: #fff;
}
.search:focus {
  box-shadow: none;
  outline: 0px;
}
.btn-file {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;
  background: #edeef7;
  border: 1px solid #edeef7;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  position: absolute !important;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.type_msg {
  background: #fff;
  border: 1px solid #28cea2;
  box-shadow: 0px 4px 8px rgba(52, 68, 76, 0.04);
  border-radius: 50px;
  overflow-y: auto;
  min-height: 50px;
  padding: 10px 107px 10px 45px;
  border-radius: 50px !important;
  font-size: 14px;
}
.type_msg:focus {
  box-shadow: none;
  outline: 0px;
  z-index: unset !important;
}
.type_msg::placeholder {
  color: #b1b1b1;
}
.send-action {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
}
.send-action a {
  width: 30px;
  height: 30px;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background: #edeef7;
  border: 1px solid #f3f3f3;
  color: #575757;
  border-radius: 50%;
  margin-right: 7px;
  font-size: 12px;
}
.send-action a:hover {
  background: #28cea2;
  color: #fff;
}
.btn_send {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 0;
  font-size: 12px;
}
.search_btn {
  background-color: transparent;
  border: 0;
  line-height: 0;
}
.user_img {
  height: 46px;
  width: 46px;
}
.img_cont {
  position: relative;
  height: 45px;
  width: 45px;
}
.offline {
  background-color: #c23616;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user_info span {
  font-size: 18px;
  font-weight: 600;
  color: #28283c;
}
.user_info .user-name {
  color: #2c3038;
}
.user_info p {
  font-size: 14px;
  line-height: normal;
}
.user_info p.active {
  color: #48e100;
}
.user_info p.inactive {
  color: #c23616;
}
.chat-window .avatar-online::before {
  background-color: #47bc00;
}
.msg_head {
  position: relative;
  background-color: transparent;
  border-bottom: 0 !important;
}
.msg_card_body ul.list-unstyled {
  margin: 0 auto;
  padding: 1rem 0 0;
  width: 100%;
  border-top: 1px solid #e8ebed;
}
.msg_card_body ul.list-unstyled li:last-child {
  margin-bottom: 0;
}
.msg_card_body .media .avatar {
  height: 25px;
  width: 25px;
}
.msg_card_body .media .media-body {
  margin-left: 6px;
}
.msg_card_body .media .media-body .msg-box > div {
  padding: 12px 17px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
}
.msg_card_body .media .media-body .msg-box > div p {
  font-size: 14px;
  margin-bottom: 0;
}
.msg_card_body .media .media-body .msg-box + .msg-box {
  margin-top: 5px;
}
.msg_card_body .media.received {
  margin-bottom: 20px;
}
.msg_card_body .media.received .chat-msg-info li a {
  color: #ababab;
}
.msg_card_body .media:last-child {
  margin-bottom: 0;
}
.msg_card_body .media.received .media-body .msg-box > div {
  background-color: #858585;
  color: #fff;
}
.msg_card_body .media.sent {
  margin-bottom: 20px;
}
.msg_card_body .media.sent .media-body {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  margin-left: 0;
}
.msg_card_body .media.sent .media-body .msg-box > div {
  background: #f7f7f8;
}
.msg_card_body .chat-date {
  font-size: 13px;
  margin: 22px 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}
.msg_card_body .chat-date span {
  color: #a8a8a8;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 2px 10px;
  display: inline-block;
}
.chat-cont-right .chat-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
  position: relative;
}
.chat-cont-right .chat-footer .input-group {
  width: 100%;
}
.chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
}
.chat-cont-right .chat-footer .input-group .form-control:focus {
  background-color: #f5f5f6;
  border: none;
  box-shadow: none;
}
.chat-cont-right .chat-footer .input-group .input-group-prepend .btn,
.chat-cont-right .chat-footer .input-group .input-group-append .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f;
}
.chat-cont-right
  .chat-footer
  .input-group
  .input-group-append
  .btn.msg-send-btn {
  background-color: #0de0fe;
  border-color: #0de0fe;
  border-radius: 50%;
  color: #fff;
  margin-left: 10px;
  min-width: 46px;
  font-size: 20px;
}
.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
}
.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #fff;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.msg-typing span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}
.msg-typing span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}
.msg-typing span:nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}
.msg_card_body .media.received .media-body .msg-box {
  position: relative;
  display: inline-block;
}
.msg_card_body .media.received .media-body .msg-box:first-child > div {
  border-radius: 15px 15px 15px 0;
  margin-top: 0;
}
.msg_card_body .media.sent .media-body .msg-box {
  margin-left: 70px;
  position: relative;
}
.msg_card_body .media.sent .media-body .msg-box:first-child > div {
  border-radius: 15px 15px 0 15px;
  margin-top: 0;
  border: 1px solid #fafafa;
  background: #fafafa;
}
.msg_card_body .media .chat-msg-info li .dropdown-item {
  padding: 4px 6px;
}
.chat-msg-info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  clear: both;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  font-size: 10px;
  padding: 0;
  margin: 0 0 5px;
  bottom: -20px;
  left: 0;
  width: 100%;
  margin-top: 5px;
}
.chat-msg-info li {
  font-size: 13px;
  padding-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-msg-info li:last-child {
  padding-right: 0;
}
.chat-msg-info li .chat-time {
  margin-right: 10px;
}
.chat-msg-info li .drop-item {
  color: #ababab;
}
.media.sent .chat-msg-info li .chat-time {
  margin: 0 0 0 10px;
}
.msg_card_body .media.received .media-body .msg-box > div .chat-time {
  color: #ababab;
}
.msg_card_body .media.sent .media-body .msg-box > div .chat-time {
  color: rgba(50, 65, 72, 0.4);
}
.chat-msg-info li a {
  color: #777;
}
.chat-msg-info li a:hover {
  color: #2c80ff;
}
.chat-seen i {
  color: #00d285;
  font-size: 16px;
}
.chat-msg-attachments {
  padding: 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0 -1px;
  gap: 10px;
}
.chat-msg-attachments > div {
  margin: 0 1px;
}
.msg_card_body .media.sent .media-body .msg-box > div .chat-msg-info {
  flex-direction: row-reverse;
}
.msg_card_body .media.sent .media-body .msg-box > div .chat-msg-attachments {
  flex-direction: row-reverse;
}
.msg_card_body .media.sent .media-body .msg-box > div .chat-msg-info li {
  padding-left: 16px;
  padding-right: 0;
  position: relative;
}
.msg_card_body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-info
  li:last-child {
  padding-left: 0;
}
.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
}
.chat-attachment {
  border-radius: 0.25rem;
}
.chat-attachment:hover:before {
  opacity: 0.6;
}
.chat-attach-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all 0.4s;
}
.chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}
.chat-attach-download:hover {
  color: #495463;
  background: #fff;
}
.chat-attachment:hover .chat-attach-caption {
  opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
  opacity: 1;
}
.chat-attachment-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -5px;
}
.chat-attachment-list li {
  width: 33.33%;
  padding: 5px;
}
.chat-attachment-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 5px solid rgba(230, 239, 251, 0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.msg_card_body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
  opacity: 1;
}
.chat-msg-actions {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.4s;
  z-index: 2;
}
.chat-msg-actions > a {
  padding: 0 10px;
  color: #495463;
  font-size: 24px;
}
.chat-msg-actions > a:hover {
  color: #2c80ff;
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}
.msg_card_body .media.sent .avatar {
  -ms-flex-order: 3;
  order: 3;
}
.msg_card_body .media.sent .media-body {
  margin-right: 6px;
}
.card-body.chat-users-list {
  padding: 15px;
}
.chat-cont-left .chat-users-list a.media {
  border: 1px solid #fff;
  padding: 8px;
  transition: all 0.2s ease 0s;
}
.chat-cont-left .chat-users-list a.media .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap {
  margin-right: 10px;
  position: relative;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
  width: 35px;
  height: 35px;
}
.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-name,
.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-last-chat {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-name {
  color: #28283c;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 3px;
}
.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-last-chat {
  color: #7a7f9a;
  font-size: 13px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .badge {
  width: 15px;
  height: 15px;
  background: #28cea2;
  border-radius: 50%;
  color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-weight: 500;
  font-size: 9px;
  padding: 0;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
  text-align: right;
}
.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:last-child
  .last-chat-time {
  color: #7a7f9a;
  font-size: 13px;
}
.chat-cont-left .chat-users-list a.media.active {
  background-color: #f3f3f5;
  border-radius: 5px;
}
.chat-page .footer {
  display: none;
}
.chat-cont-right .card {
  border-color: rgba(220, 220, 220, 0.3);
  border-radius: 5px;
}
.chat-cont-right .card-header {
  padding: 15px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.chat-cont-right .img_cont {
  width: auto;
  height: auto;
}
.chat-cont-right .msg_head .back-user-list {
  display: none;
  padding-right: 10px;
  margin-left: -10px;
  padding-left: 10px;
}
.chat-search {
  padding: 0px 15px 24px;
  width: 100%;
}
.chat-search .input-group {
  width: 100%;
}
.chat-search .input-group .form-control {
  border: 1px solid #f7f7ff;
  border-radius: 7px !important;
  background-color: #fff;
  padding-left: 36px;
  min-height: 35px;
  font-size: 13px;
}
.chat-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}
.chat-search .input-group .input-group-prepend {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0;
  color: #888888;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}
.chat-options ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  display: -webkit-flex;
}
.chat-options ul li a {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  display: inline-block;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}
.chat-options ul li a i {
  color: #74788d;
}
.chat-options ul li:not(:last-child) {
  margin-right: 5px;
}
.chat-options ul li a:hover {
  background-color: #f3f3f5;
}
.chat-options ul li a.with-bg {
  background-color: #f3f3f5;
}
.msg-highlight a {
  font-size: 13px;
  color: #28cea2;
  text-decoration: underline;
}
.chat-cont-profile .card {
  border: 2px solid rgba(220, 220, 220, 0.3);
  border-radius: 5px;
}
.chat-cont-profile .card-header {
  padding: 14px 14px 0;
  background: #fff;
  border: 0;
}
.chat-cont-profile .card-body {
  padding: 14px;
  max-height: calc(100vh - 265px);
  overflow-y: auto;
}
.chat-profile {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}
.chat-profile-img {
  margin-right: 16px;
}
.chat-profile-img img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
.chat-profile-info h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 3px;
}
.chat-profile-info p {
  font-size: 11px;
  color: #959494;
  margin-bottom: 0;
}
.profile-wrap {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 14px;
}
.profile-wrap ul li {
  display: inline-block;
  margin-right: 15px;
}
.profile-wrap ul li:last-child {
  margin-right: 0;
}
.profile-wrap ul li a {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-color: #f3f3f5;
  color: #585858;
}
.profile-wrap ul li a:hover {
  background: #28cea2;
  color: #fff;
}
.chat-pro-list ul {
  padding: 18px 0;
  border-bottom: 1px solid #f2f2f2;
}
.chat-pro-list ul li {
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 13px;
}
.chat-pro-list ul li .role-info {
  font-weight: 400;
  color: #8b8b8b;
}
.chat-pro-list ul li:last-child {
  margin-bottom: 0;
}
.role-title {
  width: 100px;
  display: inline-block;
}
.role-title i {
  margin-right: 8px;
}
.media-list {
  text-align: center;
}
.media-list ul {
  margin-bottom: 10px;
  justify-content: center;
}
.media-list li {
  display: inline-block;
  margin-bottom: 10px;
}
.media-list li a {
  padding: 5px 12px;
  font-weight: 500;
  font-size: 12px;
  color: #34444c;
  border-radius: 50px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.media-list li a.active {
  background-color: #f3f3f5;
}
.photo-list ul li {
  display: inline-block;
  margin: 0 12px 15px 0;
}
.photo-list ul li img {
  width: 80px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
}
.input-group > .form-control:focus,
.input-group > .form-floating:focus-within,
.input-group > .form-select:focus {
  z-index: 3;
}
.chat-cont-profile .card-body::-webkit-scrollbar,
.chat-cont-left .chat-scroll::-webkit-scrollbar,
.chat-scroll::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  height: 3px;
}
.chat-cont-profile .card-body::-webkit-scrollbar-track,
.chat-cont-left .chat-scroll::-webkit-scrollbar-track,
.chat-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}
.chat-cont-profile .card-body::-webkit-scrollbar-thumb,
.chat-cont-left .chat-scroll::-webkit-scrollbar-thumb,
.chat-scroll::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 10px;
}
.chat-cont-profile .card-body::-webkit-scrollbar-thumb:hover,
.chat-cont-left .chat-scroll::-webkit-scrollbar-thumb:hover,
.chat-scroll::-webkit-scrollbar-thumb:hover {
  background: #d4d4d4;
}
.chat-notification ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 14px 0;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.chat-notification ul li p {
  font-weight: 500;
  font-size: 14px;
  color: #28283c;
  margin-bottom: 0;
}
.chat-notification ul li p i {
  color: #34444c;
  margin-right: 5px;
  width: 15px;
}
.chat-notification ul li .status-toggle .checktoggle {
  width: 49px;
}
.chat-media-title {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin: 18px 0 10px 0;
}
.chat-media-title h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.chat-media-title span {
  font-size: 12px;
  color: #28cea2;
  display: inline-block;
  margin-bottom: 10px;
}
.photo-link li a,
.photo-file {
  font-size: 12px;
}
.photo-link li a:hover {
  color: #28cea2;
}
.photo-file li {
  margin-bottom: 10px;
}
.clear-list ul {
  padding-top: 18px;
}
.clear-list ul li {
  font-weight: 500;
  font-size: 14px;
  color: #8c8c8c;
  margin-bottom: 14px;
}
.clear-list ul li:last-child {
  margin-bottom: 0;
}
.clear-list ul li a {
  color: #8c8c8c;
}
.clear-list ul li a i {
  margin-right: 10px;
}
.clear-list ul li a:hover {
  color: #28cea2;
}

/*-----------------
	44. Blog List
-----------------------*/

.blog-list {
  background: #fff;
  border: 1px solid #f3f3f8;
  margin-bottom: 30px;
}
.grid-blog .blog-image {
  margin: 0;
  border-radius: 0;
}
.grid-blog .blog-image img {
  border-radius: 0;
}
.blog-list .blog-image {
  border-radius: 10px 10px 0px 0px;
  margin: 0;
}
.blog-list .blog-image img {
  border-radius: 10px 10px 0px 0px;
}
.blog-list .blog-content {
  padding-top: 24px;
}
.blog-list .blog-content .blog-title {
  font-size: 32px;
  margin-bottom: 20px;
}
.blog-list .blog-content p {
  margin-bottom: 24px;
}
.blog-content-img {
  display: flex;
  display: -webkit-flex;
}
.blogimg {
  flex: 0 0 260px;
  margin-right: 15px;
}
.blog-pagination ul li {
  margin-right: 10px;
}
.blog-pagination ul li:first-child {
  margin-right: 20px;
}
.blog-pagination ul li:last-child {
  margin-right: 0;
  margin-left: 10px;
}
.blog-pagination .page-prev,
.blog-pagination .page-next {
  width: auto;
  height: auto;
  background: #fff;
  border: 0;
  font-size: 13px;
  padding: 0 3px;
  line-height: 40px;
}
.blog-pagination .page-prev:hover,
.blog-pagination .page-next:hover {
  background-color: #fff !important;
  color: #28cea2;
}
.blog-info ul li {
  display: inline-block;
  color: #71717a;
  font-size: 15px;
  margin: 0 35px 5px 0;
  padding-right: 35px;
  position: relative;
}
.blog-info ul li:last-child,
.grid-blog .blog-category ul li:last-child {
  padding: 0;
  margin: 0;
}
.blog-info ul li a {
  color: #74788d;
}
.blog-info .post-author img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}
.blog-category .post-author img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}
.blog-info .post-author span {
  font-size: 15px;
  color: #74788d;
}
.blog-category .post-author span {
  font-size: 16px;
  color: #74788d;
}
.cat-blog {
  background: #f7f7ff;
  border-radius: 5px;
  color: #28cea2;
  padding: 5px 10px;
  display: inline-block;
}
.blog-category ul {
  margin-bottom: 3px;
}
.blog-category ul li {
  display: inline-block;
  font-size: 14px;
  color: #74788d;
  margin: 0 10px 10px 0;
}
.blog-sidebar .card {
  background: #f8f8ff;
  border: 0;
  border-radius: 5px;
}
.blog-sidebar .card-body {
  padding: 24px;
}
.sin-post {
  margin-bottom: 17px;
}
.about-widget img {
  border-radius: 15px;
  margin-bottom: 20px;
  width: 100%;
}
.about-widget p {
  margin-bottom: 20px;
}
.about-widget .btn {
  font-weight: 500;
}
.search-widget .form-control {
  background: #fff;
  border: 0;
  border-radius: 4px;
  height: 46px;
}
.search-widget .input-group {
  background: #fff;
  border: 0;
  border-radius: 4px;
}
.search-widget .input-group .btn {
  width: 50px;
  margin: 0;
  height: 46px;
  border-radius: 4px !important;
  border: 0;
  background: transparent;
  color: #74788d !important;
}
.search-widget .input-group .btn:hover {
  background: transparent;
  box-shadow: none;
  color: #2ca384 !important;
}
.latest-posts {
  margin: 0;
  padding: 0;
}
.latest-posts li {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}
.latest-posts li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}
.post-thumb {
  border-radius: 4px;
  width: 75px;
  float: left;
  overflow: hidden;
}
.post-thumb a img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 5px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.post-thumb a:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.post-info {
  margin-left: 95px;
}
.post-info h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.post-info h4 a {
  color: #28283c;
}
.post-info h4 a:hover {
  color: #28cea2;
}
.post-info p {
  color: #71717a;
  font-size: 13px;
  margin-bottom: 7px;
}
.category-widget .categories {
  list-style: none;
  margin: 0;
  padding: 0;
}
.category-widget .categories li {
  margin-bottom: 15px;
}
.category-widget .categories li:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.category-widget .categories li a {
  color: #74788d;
}
.category-widget .categories li a span {
  float: right;
}
.category-widget .categories li a:hover {
  color: #28cea2;
}
.side-title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 24px;
}
.widget-archive ul li a {
  color: #74788d;
}
.widget-archive ul li a:hover {
  color: #28cea2;
}
.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.tags li {
  float: left;
}
.tag {
  background: #fff;
  border-radius: 4px;
  color: #74788d;
  padding: 6px 12px;
  position: relative;
  margin: 0 10px 15px 0;
  font-size: 16px;
  display: inline-block;
}
.tag:hover {
  background-color: #28cea2;
  color: #fff;
}
.tags-widget .card-body {
  padding: 28px 28px 13px;
}
.grid-blog {
  background: #fff;
  border: 1px solid #f3f3f8;
  border-radius: 0px;
  margin-bottom: 24px;
}
.grid-blog .blog-content {
  padding-top: 24px;
}
.grid-blog .blog-content .blog-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
  -webkit-line-clamp: 1;
}
.grid-blog .blog-content p {
  margin-bottom: 20px;
}
.grid-blog .blog-content .read-more {
  font-weight: 500;
  font-size: 16px;
  color: #28cea2;
  background: #f7f7ff;
  border-radius: 4px;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}
.grid-blog .blog-content .read-more i {
  margin-left: 5px;
}
.grid-blog .blog-content .read-more img {
  filter: brightness(0) saturate(100%) invert(24%) sepia(85%) saturate(5324%)
    hue-rotate(243deg) brightness(96%) contrast(93%);
}
.grid-blog .blog-content .read-more:hover {
  background: #28cea2;
  color: #fff;
}
.grid-blog .blog-content .read-more:hover img {
  filter: brightness(0) invert(1);
}
.grid-blog .blog-category ul li {
  padding-right: 10px;
  margin: 0 10px 12px 0;
}
.blog-list .blog-info ul li i {
  color: #28cea2;
}
.blog-pagination .pagination {
  margin-top: 26px;
}
.blog-list .blog-info ul li {
  margin: 0 20px 0 0;
  padding-right: 0;
}
.author-widget {
  margin-bottom: 60px;
}
.author-widget .card-body {
  padding: 30px;
}
.about-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.about-author-img {
  flex-shrink: 0;
  margin-right: 30px;
}
.about-author-img img {
  width: 84px;
  height: 84px;
  border: 2px solid #fff;
  outline: 7px solid #f4f4f4;
}
.author-details h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
}
.author-details p {
  font-size: 16px;
  color: #68616d;
  margin-bottom: 15px;
}
.new-comment h4,
.blog-review h4 {
  font-size: 24px;
  margin-bottom: 1.5rem;
  font-family: 'Poppins', sans-serif;
}
.new-comment .col-form-label {
  font-size: 16px;
}
.new-comment .submit-section {
  text-align: left;
}
.new-comment .submit-btn {
  font-weight: 700;
  font-size: 16px;
  padding: 12px 30px;
}
.blog-details .blog-content p {
  margin-bottom: 20px;
}
.blog-details .blog-content p:last-child {
  margin-bottom: 0;
}
.test-info {
  font-weight: 500;
  font-size: 16px;
  border-left: 2px solid #28cea2;
  padding-left: 15px;
}
.blog-head .blog-category ul li {
  font-size: 16px;
  color: #74788d;
}
.blog-head .blog-category ul li i {
  color: #28cea2;
}
.blog-head h3 {
  font-size: 36px;
  margin-bottom: 20px;
}
.social-widget {
  margin-bottom: 33px;
}
.ad-widget {
  margin-bottom: 10px;
}
.ad-widget ul li {
  display: inline-block;
  margin: 0 20px 10px 0;
}
.ad-widget ul li:last-child {
  margin-right: 0;
}
.ad-widget ul li a {
  background: #f8f8ff;
  border-radius: 5px;
  color: #74788d;
  padding: 6px 12px;
  position: relative;
  display: inline-block;
}
.ad-widget ul li a:hover {
  background-color: #28cea2;
  color: #fff;
}
.adicon-widget {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  margin-bottom: 10px;
}
.adicon-widget span {
  background: #f7f7ff;
  border-radius: 8px;
  color: #68616d;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 14px;
  display: inline-block;
  margin: 0 15px 10px 0;
}
.adicon-widget ul li {
  margin: 0 8px 10px 0;
  display: inline-block;
}
.adicon-widget ul li a {
  width: 32px;
  height: 32px;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 50%;
  color: #68616d;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.comments-reply li {
  margin-left: 70px;
}
.comments-reply li .review-box {
  border: 0;
  border-left: 1px solid #eee;
  border-radius: 0;
  padding-left: 20px;
}
.blog-review .review-box {
  padding: 10px 0;
  border: 0;
}
.reply-box {
  font-weight: 500;
  font-size: 14px;
  color: #28283c;
}
.reply-com {
  display: none;
}

/*-----------------
	45. Works
-----------------------*/

.work-wrap {
  margin-bottom: 170px;
  position: relative;
}
.work-wrap-acc:after {
  position: absolute;
  content: '';
  bottom: -94%;
  right: 40px;
  background: url('../img/bg/wrk-bg-01.png');
  width: 314px;
  height: 264px;
  background-repeat: no-repeat;
  background-size: contain;
}
.work-wrap-acc,
.work-wrap-earning {
  padding-right: 150px;
}
.work-wrap-post {
  padding-left: 150px;
}
.work-wrap-post:after {
  position: absolute;
  content: '';
  bottom: -100%;
  left: 0;
  background: url('../img/bg/wrk-bg-02.png');
  width: 314px;
  height: 264px;
  background-repeat: no-repeat;
  background-size: contain;
}

.work-wrap span {
  width: 50px;
  height: 50px;
  background: #28cea2;
  border-radius: 50%;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 15px;
}
.work-wrap h1 {
  font-size: 38px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.work-wrap p {
  margin-bottom: 25px;
}
.work-wrap p:last-child {
  margin-bottom: 25px;
}
.work-img {
  margin-bottom: 170px;
}

/*-----------------
	46. Invoice
-----------------------*/

.invoice-wrap {
  font-size: 15px;
}
.invoice-head {
  float: right;
  text-align: right;
}
.invoice-head {
  margin-bottom: 20px;
}
.invoice-head h5 {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 7px;
}
.invoice-head ul li i {
  color: #c2c9d1;
}
.invoice-head ul li {
  margin-bottom: 10px;
}
.invoice-head ul li:last-child {
  margin-right: 0;
}
.invoice-logo {
  margin-bottom: 35px;
}
.invoice-logo img {
  max-width: 220px;
}
.invoice-address {
  margin-bottom: 30px;
}
.invoice-address h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}
.invoice-address ul li {
  margin-bottom: 8px;
}
.invoice-to {
  float: right;
}
.invoice-table {
  margin-bottom: 30px;
}
.invoice-table table.table td h2 a {
  font-weight: 400;
  color: #68616d;
}
.invoice-table .avatar {
  width: 30px;
  height: 30px;
}
.invoice-table .custom-table thead th:first-child,
.invoice-table .custom-table thead th:last-child {
  border-radius: 0;
}
.invoice-payment h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}
.invoice-payment ul li {
  margin-bottom: 10px;
}
.invoice-payment ul li:last-child,
.invoice-total ul li:last-child {
  margin-bottom: 0;
}
.pay-amt {
  font-weight: 500;
  color: #28283c;
}
.pay-amt span {
  font-weight: 400;
  display: inline-block;
  color: #74788d;
  min-width: 124px;
}
.invoice-total,
.invoice-payment {
  margin-bottom: 35px;
}
.invoice-total ul li {
  font-weight: 500;
  color: #28283c;
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.invoice-total ul li.tot-amt span {
  font-weight: 500;
  color: #28283c;
}
.invoice-total {
  float: right;
}
.invoice-total ul li span {
  font-weight: 400;
  color: #74788d;
  min-width: 240px;
  display: inline-block;
  text-align: right;
}
.invoice-terms {
  font-size: 15px;
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  padding: 20px;
}
.invoice-terms h5 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
.invoice-terms ul li {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}
.invoice-terms ul li:before {
  position: absolute;
  content: '\f111';
  font-family: 'FontAwesome';
  left: 0;
  top: 7px;
  color: #28cea2;
  font-size: 6px;
}
.invoice-terms ul li:last-child {
  margin-bottom: 0;
}

/*-----------------
	46. Notification
-----------------------*/

.notification-item {
  display: -ms-flexbox;
  display: flex;
}
.notification-media .avatar {
  margin-right: 10px;
}
.notification-media .avatar,
.notification-media .avatar > img {
  width: 46px;
  height: 46px;
}
.notification-info h6 {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #74788d;
}
.notification-info h6 span {
  font-weight: 500;
  color: #28283c;
}
.notification-info p {
  font-size: 13px;
  color: #74788d;
  margin-bottom: 0;
}
.notification-details .login-back {
  margin-bottom: 28px;
}
.notification-details h4 {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1.5rem;
}
.detail-list li {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 30px;
}
.detail-list li:last-child {
  margin-bottom: 0;
}
.notify-head {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.notify-head > a {
  font-weight: 500;
  font-size: 16px;
  color: #28283c;
  margin-bottom: 15px;
}
.avatar-icon {
  width: 46px;
  height: 46px;
  background: #fff;
  border: 2px solid #f5f5f5;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}
.notification-dropdown > a {
  padding: 0;
}
.notification-dropdown .dropdown-menu {
  font-size: 14px;
  padding: 0;
}
.notify-btns {
  margin-top: 15px;
}
.notify-btns .btn {
  font-weight: 500;
  font-size: 13px;
  padding: 5px 15px;
  border-radius: 4px;
  margin-right: 16px;
}
.notify-btns .btn:last-child {
  margin-right: 0;
}
.notify-list {
  margin-bottom: 17px;
}
.notify-list li {
  margin: 0 20px 15px 0;
}
.notify-list li a {
  padding: 5px 20px 10px;
  position: relative;
  font-weight: 500;
  color: #74788d;
}
.notify-list li a.active {
  color: #28cea2;
}
.notify-list li a.active:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #28cea2;
  border-radius: 10px;
}

/*-----------------
	47. Provider Details
-----------------------*/

.provider-info {
  padding-left: 60px;
  margin-bottom: 60px;
}
.provider-info h2 {
  margin-bottom: 5px;
}
.provider-info h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
}
.provider-info p {
  font-size: 14px;
  margin-bottom: 24px;
}
.provider-info .provide-info p {
  margin-bottom: 0;
}
.provider-img {
  position: relative;
  margin: 30px 0 60px;
}
.provider-img img {
  position: relative;
}
.provider-img span {
  position: absolute;
  left: 0;
  bottom: 45px;
  background: #28cea2;
  font-weight: 700;
  font-size: 22px;
  color: #fff;
  padding: 20px;
}
.provider-img:after {
  position: absolute;
  content: '';
  top: -30px;
  left: -30px;
  background: url(../img/bg/pro-bg-02.png) no-repeat;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.provider-img:before {
  position: absolute;
  content: none;
  bottom: -30px;
  right: -50px;
  background: url(../img/bg/pro-bg-01.png) no-repeat;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.provider-details {
  margin-bottom: 60px;
}
.provider-details.provide-area {
  margin-bottom: 40px;
}
.provider-details:last-child {
  margin-bottom: 0;
}
.provider-details h5 {
  font-size: 20px;
  margin-bottom: 20px;
}
.provider-details p {
  margin-bottom: 20px;
}
.provider-details ul li {
  margin-bottom: 16px;
  padding-left: 24px;
  position: relative;
}
.provider-details ul li:last-child {
  margin-bottom: 0;
}
.provider-details ul li:before {
  position: absolute;
  content: '\f058';
  font-family: 'FontAwesome';
  left: 0;
  top: 0;
  color: #28cea2;
}
.construct-box {
  background: #fff;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  padding: 25px;
  margin-bottom: 20px;
}
.construct-box img {
  margin-bottom: 19px;
}
.construct-box h6 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

/*-----------------
	48. Service List
-----------------------*/

.service-list {
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.service-cont-img {
  position: relative;
  margin-right: 15px;
  flex: 0 0 210px;
  max-width: 210px;
}
.service-cont-img img {
  border-radius: 8px;
  height: 143px;
  object-fit: cover;
  width: 210px;
}
.service-cont-img .fav-item {
  position: absolute;
  top: 10px;
  left: 10px;
}
.service-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: auto;
  text-align: left;
  flex: 0 0 calc(100% - 315px);
  overflow: hidden;
}
.service-cont-info {
  overflow: hidden;
}
.service-cont-info .item-cat {
  display: inline-block;
  margin-bottom: 12px;
}
.service-cont-info h3 {
  font-size: 20px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.service-cont-info p {
  margin-bottom: 10px;
}
.service-cont-info p i {
  color: #c2c9d1;
  margin-right: 7px;
}
.service-pro-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.service-pro-img img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 8px;
}
.service-pro-img span i {
  color: #ffbc35;
  margin-right: 5px;
}
.service-action {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex: 0 0 315px;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.service-action h6 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
}
.service-action h6 .old-price {
  font-weight: 500;
  font-size: 16px;
  color: #74788d;
  text-decoration: line-through;
  margin-left: 5px;
}
.service-action .btn {
  font-size: 14px;
  font-weight: 500;
  margin-left: 30px;
  padding: 10px 23px;
}
.blog-pagination.rev-page .pagination {
  margin-top: 6px;
}

/*-----------------
    49. Header Two
-----------------------*/

.top-bar {
  background: #3e4265;
  padding: 10px 0;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-wrap: wrap;
  width: 100%;
}
.top-bar h6 {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin: 0 24px 0 0;
}
.top-bar ul li {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
  color: #2229c1;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 12px;
}
.top-bar ul li:last-child {
  margin-right: 0;
}
.top-close {
  color: #fff;
  position: absolute;
  right: 20px;
}
.top-close:hover {
  color: #2229c1;
}
.header-two.fixed {
  top: 0;
}
.header-two .menu-close,
.header-five .menu-close,
.header-six .menu-close,
.header-seven .menu-close,
.header-eight .menu-close,
.header-nine .menu-close {
  color: #2229c1;
}
.header-two .header-navbar-rht li > a.header-login {
  background: #2229c1;
  border: 1px solid #2229c1;
  box-shadow: inset 0 70px 0 0 #2229c1;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 500;
}
.header-two .header-navbar-rht li > a.header-login:hover {
  background: #e2017b;
  border: 1px solid #e2017b;
  box-shadow: inset 0 70px 0 0 #e2017b;
}
.header-two .main-nav li a:hover {
  color: #2229c1;
}

/*-----------------
    50. Header seven
-----------------------*/

.header.header-seven .main-nav > li > a {
  color: #28cea2;
}
.header-seven .bar-icon span {
  background-color: #2229c1;
}
.header.header-seven .header-navbar-rht li > a.header-login {
  background: #2229c1;
  border: 1px solid #2229c1;
  border-radius: 40px;
}
.header.header-seven .header-navbar-rht li > a.header-login:hover {
  background: #f5f7ff;
  border: 1px solid #f5f7ff;
  color: #28cea2;
  box-shadow: inset 0 70px 0 0 #f5f7ff;
}

/*-----------------
    51. Header Eight
-----------------------*/
.header.header-eight .main-nav > li > a {
  color: #151519;
}
.header.header-eight .header-navbar-rht li > a.header-login {
  background: #2229c1;
  border: 1px solid #2229c1;
  border-radius: 0;
}
.header.header-eight .header-navbar-rht li > a.header-login:hover {
  border: 1px solid #2a396d;
  color: #2a396d;
  background: #fff;
  box-shadow: inset 0 70px 0 0 #fff;
}

/*-----------------
    52. Header Nine
-----------------------*/
.top-bar-nine {
  padding: 17px 0;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
}
.top-bar-nine-all {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.top-bar-ryt {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.top-address {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-right: 40px;
}
.top-address span {
  font-size: 28px;
  color: rgba(32, 48, 102, 1);
  margin-right: 10px;
}
.top-address h6 {
  margin-bottom: 0;
  font-size: 12px;
  color: #28cea2;
}
.top-address p {
  margin-bottom: 0;
  color: #28cea2;
  position: relative;
}
.top-address p img {
  position: absolute;
  bottom: 10px;
  right: 0;
}
.top-select {
  outline: none;
  border: none;
  color: #28cea2;
  -webkit-appearance: none;
  background: none;
}

.top-addres-nine {
  position: relative;
}
.top-btn .btn-primary {
  padding: 12px 24px 12px 24px;
  background: #2229c1;
  color: #fff;
  border-radius: 0;
  border: 1px solid #2229c1;
}
.top-btn .btn-primary:hover {
  border: 1px solid #2ca384;
}
.top-bar-nine .top-close {
  color: #000000;
}
.header-navbar-rht-nine .nav-item .nav-link {
  color: #2229c1;
  padding: 0;
}
.header-navbar-rht-nine .nav-item .nav-link:hover {
  color: #28cea2;
}
.header.header-nine .navbar-header {
  display: none;
}
.header.header-nine .header-nav-new {
  height: 56px;
}
.header.header-nine.fixed .header-nav-new {
  height: 85px;
}
.header-nine .main-menu-wrapper {
  margin-left: 0;
}

/*-----------------
    53. Header Six
-----------------------*/
.hero-section-six {
  position: relative;
  background: url(../img/banner-six-bg-frst.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1010;
}
.header.header-six {
  background: none;
  box-shadow: none;
}
.header.header-six.fixed {
  background: #fff;
}
.main-nav.main-nav-six li a {
  color: #28cea2;
  font-weight: 500;
}
.header.header-six .header-navbar-rht .nav-item .header-button-six {
  padding: 12px 24px;
  background: #2229c1;
  color: #fff;
  border-radius: 40px;
  transition: 0.7s;
  font-weight: 500;
}
.header.header-six .header-navbar-rht .nav-item .header-button-six:hover {
  color: #28cea2;
  background: #fff;
  transition: 0.7s;
}

/*-----------------
    54. Header Three
-----------------------*/
.header-three.fixed {
  top: 0;
}
.header-three .navbar {
  padding: 0 37px;
}
.header-three .menu-close {
  color: #2229c1;
}
.header-three .header-navbar-rht li a.header-login {
  background: #2229c1;
  border: 1px solid #2229c1;
  box-shadow: inset 0 70px 0 0 #2229c1;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
}
.header-three .header-navbar-rht li a.header-login:hover {
  background-color: transparent;
  border: 1px solid #2229c1;
  box-shadow: inset 0 0px 0 0 transparent;
  color: #2229c1;
  transition: all 0.7s;
}
.header-three .main-nav li a:hover {
  color: #2229c1;
}

/*-----------------
    55. Header Four
-----------------------*/
.header-four .header-navbar-rht li a.header-login {
  border-radius: 95px;
  text-transform: uppercase;
}
.header-four .header-navbar-rht li a.header-login:hover {
  background-color: #fff;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #4c40ed;
}
.main-nav.main-nav-four li a {
  font-size: 16px;
  font-weight: 500;
  color: #101522;
}

/*-----------------
    56. Home Two
-----------------------*/

.hero-section-two {
  position: relative;
}
.banner:before {
  position: absolute;
  content: '';
  top: -6px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2030661f;
}
.banner-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  width: 100%;
}
.banner-search h1 {
  font-weight: 700;
  font-size: 50px;
  color: #fff;
  text-align: center;
  margin-bottom: 12px;
}
.banner-search p {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}
.search-box-two {
  width: 100%;
  background: #fff;
  border-radius: 50px;
  padding: 14px;
  display: table;
}
.search-input-new {
  width: 32%;
  float: left;
  position: relative;
}
.search-input-new.line {
  width: 53%;
}
.search-input-new input,
.search-input-new.line input {
  height: 52px;
  padding: 0px 40px 0px 50px;
  border: 0;
  color: #58627b;
}
.search-input-new input::placeholder,
.search-input-new.line input::placeholder {
  color: #58627b;
}
.search-input-new > i.bficon {
  right: inherit;
  left: 15px;
  z-index: 1;
}
.search-input-new i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #58627b;
  font-size: 18px;
}
.search-input-new.line::before {
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  height: 60px;
  background: #e2e6f1;
  content: '';
  z-index: 1;
  margin-top: -30px;
}
.search-box-two .search-btn {
  width: 15%;
}
.search-box-two .search-btn .btn {
  background: #2229c1;
  border-radius: 52px;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  padding: 14px 10px;
  align-items: center;
  justify-content: center;
}
.search-box-two .search-btn .btn:hover {
  background: #e2017b;
  border: 1px solid #e2017b;
}
.section-heading-two {
  position: relative;
  margin-bottom: 50px;
}
.section-heading-two:before {
  position: absolute;
  content: '';
  background: url('../img/bg/title-bg-01.png') no-repeat;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.section-heading-two h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2a313d;
}
.section-heading-two.white-text:after {
  background: -moz-linear-gradient(top, #2229c1 50%, #2229c1 50%);
  background: -webkit-linear-gradient(top, #2229c1 50%, #2229c1 50%);
  background: linear-gradient(to bottom, #2229c1 50%, #2229c1 50%);
}
.section-heading-two.white-text p,
.section-heading-two.white-text h2 {
  color: #fff;
}
.sec-header {
  position: relative;
}
.sec-header:before {
  position: absolute;
  content: '';
  background: url('../img/bg/title-bg-01.png') no-repeat;
  width: 114px;
  height: 100px;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
.feature-section-two {
  background: #fff;
  padding: 90px 0 66px;
}
.feature-widget {
  position: relative;
  margin-bottom: 24px;
}
.feature-img {
  overflow: hidden;
  border-radius: 8px;
}
.feature-img img {
  border-radius: 8px;
  width: 100%;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.feature-img:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.feature-widget .feature-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
}
.feature-widget .feature-icon span {
  width: 50px;
  height: 50px;
  background: #2229c1;
  border-radius: 8px 0px 0px 8px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
}
.feature-widget .feature-icon span img {
  width: 30px;
}
.feature-title {
  background: #28cea2;
  border-radius: 0 8px 8px 0;
  padding: 12px 14px;
  width: calc(100% - 50px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
}
.feature-title h5 {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
}
.feature-widget .feature-title p {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.btn-pink {
  border-color: #fceff8;
  background: #fceff8;
  color: #dd318e;
  padding: 12px 24px;
}
.btn-viewall {
  background: #fceff8;
  border-radius: 50px;
  color: #dd318e;
  font-weight: 500;
  padding: 10px 20px;
}
.btn-viewall:hover {
  background: #2229c1;
  border: 1px solid #2229c1;
  color: #fff;
}
.btn-viewall i {
  margin-left: 8px;
}
.service-section-two {
  background: #fff4fa;
  padding: 90px 0 66px;
}
.service-widget.service-two {
  border: 1px solid #e2e6f1;
  padding: 20px;
}
.service-widget.service-two .service-img {
  border-radius: 10px;
}
.service-widget.service-two .service-content {
  padding: 20px 0 0;
}
.service-widget.service-two .service-img .serv-img {
  border-radius: 8px;
}
.service-widget.service-two .item-cat {
  background: #fff4fa;
  border-radius: 50px;
  color: #2229c1;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
}
.serv-info .rating {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}
.serv-info .rating span {
  color: #9397ad;
}
.service-widget.service-two .service-content p {
  color: #74788d;
}
.service-widget.service-two .service-content p .rate {
  font-size: 15px;
  font-weight: 400;
  color: #74788d;
}
.service-widget.service-two .item-img {
  float: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
.service-widget.service-two .item-img img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 0;
  margin-right: 5px;
}
.service-widget.service-two .fav-item {
  padding: 20px 20px 0;
}
.service-widget.service-two .item-info {
  padding: 0 20px 20px;
}
.service-widget.service-two .item-info a {
  display: block;
}
.work-section-two {
  background: #fff;
  padding: 90px 0 66px;
}
.work-wrap-box {
  background: #fff4fa;
  border-radius: 50%;
  text-align: center;
  width: 358px;
  height: 358px;
  padding: 53px 46px;
  position: relative;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.work-wrap-box.work-first:before {
  position: absolute;
  content: '';
  background: url('../img/bg/wrk-bg-03.png') no-repeat;
  top: -17px;
  right: -83%;
  width: 100%;
  height: 100%;
}
.work-wrap-box.work-last:after {
  position: absolute;
  content: '';
  background: url('../img/bg/wrk-bg-04.png') no-repeat;
  bottom: -81%;
  right: -295px;
  width: 100%;
  height: 100%;
}
.work-wrap-box .work-icon span {
  width: 100px;
  height: 100px;
  background: #2229c1;
  border-radius: 50%;
  margin: 0 auto 24px;
}
.work-wrap-box h5 {
  margin-bottom: 15px;
}
.work-wrap-box p {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.popular-section,
.price-sections {
  padding: 0 0 66px;
}
.price-toggle .toggle-pink {
  font-size: 16px;
  font-weight: 700;
  color: #181059;
  margin-bottom: 32px;
}
.price-toggle .toggle-pink .checktoggle {
  background: #3e4265;
  border: 1px solid #3e4265;
  width: 56px;
  height: 24px;
}
.price-toggle .toggle-pink .checktoggle:after {
  background: #2229c1;
  transform: translate(4px, -50%);
}
.price-toggle .toggle-pink .check:checked + .checktoggle:after {
  background-color: #2229c1;
  transform: translate(calc(-100% - 3px), -50%);
}
.price-toggle .toggle-pink .check:checked + .checktoggle {
  background-color: #3e4265;
  border: 1px solid #3e4265;
}
.pricing-plans.price-new {
  background: #f3f6ff;
  border: 1px solid #e2e6f1;
  border-radius: 25px;
  padding: 20px;
  margin-top: 24px;
}
.pricing-plans.price-new:hover .btn {
  background: #2229c1;
}
.pricing-plans.price-new.active {
  background: #fff;
  border: 1px solid #e2e5f1;
  box-shadow: 3px 42px 34px rgba(255, 0, 138, 0.08);
  margin-top: 0;
}
.pricing-plans.price-new .pricing-planshead {
  border-bottom: 1px solid #e2e5f1;
  text-align: left;
  padding: 0;
}
.pricing-plans.price-new .pricing-planshead h6 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}
.pricing-plans.price-new .pricing-planshead h6 span {
  font-size: 16px;
  font-weight: 600;
  color: #9397ad;
}
.pricing-plans.price-new .pricing-planshead h4 {
  font-size: 28px;
  font-weight: 700;
  color: #101522;
  margin-bottom: 8px;
}
.pricing-plans.price-new .pricing-planshead h5 {
  font-size: 15px;
  color: #939aad;
  font-weight: 600;
  margin-bottom: 15px;
}
.pricing-plans.price-new .pricing-planscontent li {
  color: #58627b;
}
.pricing-plans.price-new .pricing-planscontent li i {
  color: #2229c1;
}
.pricing-plans.price-new .btn {
  background: #3e4265;
  border-radius: 40px;
  color: #fff;
  display: block;
  text-align: center;
}
.pricing-plans.price-new.active .btn {
  background: #2229c1;
  color: #fff;
}
.price-block {
  text-align: right;
}
.price-block .popular {
  background: #3e4265;
  border-radius: 13px;
  font-size: 10px;
  font-weight: 700;
  padding: 7px 15px;
  color: #fff;
  margin-bottom: 20px;
  display: inline-block;
}
.app-section-two {
  padding: 0 0 90px;
  background: url(../img/bg/off-bg.png) no-repeat;
  background-position: center right;
}
.blog.blog-new {
  border: 1px solid #e2e6f1;
}
.blog.blog-new .blog-image {
  position: relative;
}
.blog.blog-new .blog-image .date {
  background: #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 13px;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 20px;
  min-width: 55px;
  min-height: 55px;
  color: #2229c1;
  box-shadow:
    0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
}
.blog.blog-new .blog-image .date span {
  display: block;
}
.blog.blog-new .blog-content .blog-item {
  background: #3e4265;
  border-radius: 5px;
  padding: 4px 7px;
  margin-bottom: 10px;
}
.blog.blog-new .blog-content .blog-item li {
  margin: 0 10px 0 0;
  font-size: 14px;
  color: #fff;
}
.blog.blog-new .blog-content .blog-item li i {
  color: #fff;
}
.blog.blog-new .blog-content .blog-item li:last-child {
  margin-right: 0;
}
.blog.blog-new .blog-content .blog-item li + li {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  padding-left: 10px;
}
.blog.blog-new .blog-content .blog-item li a {
  color: #fff;
}
.blog.blog-new .blog-content .blog-title {
  -webkit-line-clamp: 2;
  margin-bottom: 12px;
}
.blog.blog-new .blog-content p {
  margin-bottom: 0;
}
.client-box {
  background: #fff;
  border: 1px solid #f0f0f7;
  box-shadow:
    0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 30px;
  padding: 25px;
  margin-bottom: 40px;
}
.client-box .client-content {
  border-bottom: 1px solid #e2e5f1;
}
.client-box .client-content h6 {
  font-weight: 500;
  font-size: 20px;
  color: #28283c;
  margin-bottom: 12px;
}
.client-box .rating {
  font-size: 12px;
  margin-bottom: 13px;
}
.client-box .client-img {
  margin: 23px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.client-box .client-img img {
  width: 48px !important;
  height: 48px;
  margin: 0 15px 0 0;
}
.client-name h5 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
}
.client-name h6 {
  font-weight: 400;
  font-size: 14px;
  color: #74788d;
  margin-bottom: 0;
}
.client-section-two {
  background: #fff4fa;
  padding: 90px 0;
}
.owl-dots {
  text-align: center;
}
.owl-dots .owl-dot {
  margin-right: 5px;
}
.owl-dots .owl-dot.active span {
  background: #2229c1;
}
.owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  line-height: 12px;
  margin: 0;
  background: #f1e2eb;
  border-radius: 50%;
  display: block;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.offer-sec.offer-paths {
  background: #2229c1;
  border-radius: 30px;
  padding: 0 80px;
  margin: 0;
  align-items: flex-end;
}
.offer-sec.offer-paths::after {
  position: absolute;
  content: '';
  background: url(../img/bg/offer-bg.png) no-repeat 0 0 / 100%;
  left: 0;
  top: 0;
  background-size: contain;
  background-position: top right;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 30px;
}
.offer-sec.offer-paths .offer-pathimg {
  margin: 0;
}
.offer-sec .section-heading-two {
  margin-bottom: 20px;
}
.offer-sec .section-heading-two:after {
  background: -moz-linear-gradient(top, #fff 50%, #28cea2 50%);
  background: -webkit-linear-gradient(top, #fff 50%, #28cea2 50%);
  background: linear-gradient(to bottom, #fff 50%, #28cea2 50%);
}
.offer-sec .section-heading-two p {
  color: #fff;
}
.offer-sec .btn-views {
  border-radius: 40px;
  color: #2229c1;
}
.offer-sec .btn-views:hover {
  background-color: #2229c1;
  box-shadow: inset 0 70px 0 0 #2229c1;
  color: #fff;
}
.offer-sec .offer-path-content {
  padding: 93px 0;
}
.download-sec {
  padding-left: 60px;
}
.download-sec p {
  color: #58627b;
}
.download-sec h6 {
  color: #28cea2;
  margin-bottom: 20px;
}
.providers-section-two {
  position: relative;
  padding: 90px 0 66px;
}
.providers-section-two:before {
  content: '';
  background: #3e4265;
  width: 100%;
  min-height: 396px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.provider-box {
  box-shadow:
    0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 8px;
  border: 0;
  margin-top: 85px;
}
.provider-box .providerset-img {
  text-align: center;
  margin: -105px 0 10px;
}
.provider-box .providerset-img img {
  width: 180px;
  height: 180px;
  border: 10px solid #fff;
  border-radius: 50%;
  object-fit: cover;
}
.provider-box .providerset-name h4 {
  font-weight: 700;
}

.providerset-prices h6 {
  font-weight: 600;
}
.provider-box .provider-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.provider-box .rate {
  background: #fff4fa;
  border-radius: 50px;
  font-size: 15px;
  color: #58627b;
  padding: 5px 12px;
  display: inline-block;
}
.provider-box .rate i {
  color: #ffbc35;
  margin-right: 6px;
}
.provider-box .btn {
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  background: #2229c1;
  border: 1px solid #2229c1;
  border-radius: 30px;
  padding: 5px 15px;
}
.provider-box .btn:hover {
  background: #e2017b;
  border: 1px solid #e2017b;
}
.section-offer {
  position: relative;
  padding-bottom: 60px;
}
.section-offer:before {
  content: '';
  background: #fff4fa;
  width: 100%;
  min-height: 300px;
  position: absolute;
  top: 50%;
  z-index: -1;
}
.section-offer .offer-sec .btn-views {
  font-weight: 700;
}
.service-section-two .owl-nav button.owl-next:hover,
.service-section-two .owl-nav button.owl-prev:hover,
.popular-section .owl-nav button.owl-next:hover,
.popular-section .owl-nav button.owl-prev:hover {
  background: #2229c1;
}

/*-----------------
    57. Home Three
-----------------------*/

.hero-section-three {
  position: relative;
  background: url(../img/banner-04.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 2;
}
.section-section-three h4 {
  color: #ffa621;
  font-size: 18px;
}
.section-section-three h1 {
  color: #fff;
}
.section-section-three p {
  color: #f9f9f9;
  font-size: 16px;
  margin-bottom: 20px;
}
.sidecircle-ryt {
  position: absolute;
  right: -115px;
  z-index: -1;
  top: -125px;
}
.make-appointment {
  border-radius: 5px;
  padding: 20px;
  background-color: #ff0080;
  color: #fff;
  border: 1px solid #2229c1;
  font-size: 14px;
  transition: all 0.5s;
  display: inline-block;
  font-weight: 500;
}
.make-appointment:hover {
  background-color: transparent;
  border: 1px solid #2ca384;
  box-shadow: inset 0 70px 0 0 #2ca384;
  color: #fff;
  transition: all 0.7s;
}
.cta-btn {
  background: #2229c1;
  border: 1px solid #2229c1;
  border-radius: 40px;
  box-shadow: inset 0 70px 0 0 #2229c1;
  color: #fff;
  border-radius: 4px;
  min-width: 176px;
  padding: 10px 20px;
  box-shadow: inset 0 0 0 0 #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.cta-btn:hover {
  background-color: transparent;
  border: 1px solid #2229c1;
  color: #2229c1;
  box-shadow: inset 0 0px 0 0 transparent;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.cta-btn a {
  padding: 0;
  border: 0;
  color: #fff;
  font-weight: 500;
}
.cta-btn:hover a {
  color: #2229c1;
}
.search-input.search-input-three i {
  color: #424242;
}
.services-section {
  position: relative;
  z-index: 1;
  padding: 130px 0 85px;
  background-color: #f9f9f9;
}
.services-section.stylists-section {
  padding: 90px 0 85px;
  background-color: #fff;
}
.services-all {
  position: relative;
}
.services-main-img {
  position: relative;
}
.services-main-img::before {
  background: linear-gradient(
    0deg,
    rgba(13, 21, 49, 0.6),
    rgba(13, 21, 49, 0.6)
  );
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  z-index: 0;
}
.services-main-img:hover::before {
  background: #ff008099;
  border-radius: 12px;
  transform: scale(0);
  transition: all 0.5s;
}
.services-main-img:hover::before {
  transform: scale(1);
}
.service-foot img {
  max-width: 45px;
  max-height: 45px;
}
.service-foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 33%;
  margin-bottom: 10px;
}
.service-foot h4 {
  color: #fff;
  font-size: 24px;
}
.service-foot h6 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.section-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.section-content p {
  max-width: 510px;
  text-align: center;
}
.our-img-all {
  position: relative;
  margin-bottom: 10px;
}
.our-img-all::after {
  width: 33px;
  height: 1px;
  position: absolute;
  content: '';
  background-color: #424242;
  top: 13px;
  left: 30px;
}
.our-img-all::before {
  width: 33px;
  height: 1px;
  position: absolute;
  content: '';
  background-color: #424242;
  top: 13px;
  right: 30px;
}
.section-content h2 {
  font-size: 40px;
  color: #151519;
}
.services-section .search-box {
  background: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
}
.search-box.search-box-three {
  padding: 18px;
}
.services-section .search-box {
  background: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
}
.hero-section-three .search-box .search-input {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  padding-right: 15px;
}
.hero-section-three .search-icon {
  width: 48px;
  height: 48px;
  background: #f2f2f2;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 15px;
}
.hero-section-three .search-box .form-group {
  min-width: 180px;
  width: 100%;
}
.hero-section-three .search-box label {
  font-size: 14px;
  color: #999999;
  font-size: 14px;
  margin-bottom: 0;
}
.form-control {
  border-color: #dcdcdc;
  color: #333;
  font-size: 15px;
  min-height: 42px;
  padding: 6px 15px;
}
.hero-section-three
  .search-box
  .select2-container--default
  .select2-selection--single {
  height: 25px;
  font-weight: 500;
  border: 0;
  min-width: 180px;
}
.searchbox-list {
  display: flex;
  justify-content: space-between;
  width: 87%;
}
.search-box-three {
  justify-content: space-between;
  margin-bottom: -50px;
}
.search-btn-three .btn-primary {
  background-color: #ff0080;
  border: 1px solid #ff0080;
  padding: 20px;
  font-size: 14px;
}
.search-btn-three .btn-primary:hover {
  border: 1px solid #2ca384;
}
.search-input-three {
  background: none;
}
.services-section .owl-nav .owl-prev {
  background: none !important;
  box-shadow: none;
}
.services-section .owl-nav button.owl-next,
.services-section .owl-nav button.owl-prev {
  margin-bottom: 0;
}
.services-section .owl-nav .owl-prev:hover {
  background: none !important;
  color: #ff0080;
}
.services-section .owl-nav .owl-next:hover {
  background: none !important;
  color: #ff0080;
}
.services-section .owl-nav .owl-next {
  background: none !important;
  box-shadow: none;
}
.search-input.search-input-three
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #898989;
  font-weight: 400;
  padding-left: 10px;
}
.search-input.search-input-three
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: -1px;
}
.search-input.search-input-three input::placeholder {
  color: #898989;
}
.search-btn.search-btn-three .btn-primary i {
  font-size: 18px;
}
.blog-section-three .blog {
  padding: 0;
}
.blog-section-three .blog-content {
  padding: 20px;
}
.blog-section-three .blog-view a {
  color: #ff0080;
}
.blog-section-three .cat-blog {
  background: #fff;
  color: #898989;
  padding: 2px 10px;
  border: 1px solid #898989;
}
.blog-section-three .blog.blog-new .blog-image .date {
  background-color: #ff0080;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}
.blog-section-three .blog.blog-new .blog-image .date span {
  font-weight: normal;
  font-size: 12px;
}
.services-section .owl-nav {
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
}
.service-img-top a {
  position: relative;
}
.service-img-top a::before {
  background: #0d153199;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.service-content ul {
  display: flex;
  flex-wrap: wrap;
}
.service-content ul li {
  padding: 5px 10px;
  background-color: #f9f9f9;
  margin: 0 13px 13px 0;
  font-size: 14px;
}
.saloon-content-top {
  display: flex;
  align-items: baseline;
}
.saloon-content-btn {
  display: flex;
  align-items: baseline;
}
.saloon-profile-left .saloon-img img {
  width: 61px;
  height: 61px;
  border-radius: 10px;
}
.saloon-profile-left .saloon-img {
  margin-right: 11px;
}
.saloon-profile-left {
  display: flex;
  align-items: center;
}
.service-show-img {
  position: relative;
}
.service-show-img::before {
  content: '';
  background-color: #0d153199;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 1;
}
.service-content.service-content-three .title a:hover {
  color: #ff0080;
}
.main-saloons-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.saloon-content-btn i,
.saloon-content-top i {
  margin-right: 7px;
}
.saloon-right span {
  font-size: 24px;
  color: #ff0080;
  font-weight: 600;
}
.saloon-bottom {
  display: flex;
}
.saloon-bottom a {
  padding: 10px;
  background-color: #ff0080;
  border: 1px solid #ff0080;
  border-radius: 5px;
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.saloon-bottom a:hover {
  background-color: transparent;
  border: 1px solid #2ca384;
  box-shadow: inset 0 70px 0 0 #2ca384;
  color: #fff;
  transition: all 0.7s;
}
.item-infos {
  padding: 0 20px 0;
}
.btn-saloons .btn-primary {
  background-color: #ff0080;
  padding: 10px 15px;
  border: 1px solid #ff0080;
  font-weight: 500;
}
.btn-saloons .btn-primary:hover {
  border-color: #2ca384;
}
.saloon-section-circle {
  position: absolute;
  left: -127px;
  bottom: -146px;
  z-index: 0;
}
.featured-saloons {
  position: relative;
  background-color: #fff;
}
.appointment-section {
  position: relative;
  background: url('../img/saloon-bg.jpg');
  background-repeat: no-repeat;
  padding: 90px 0;
  background-size: cover;
}
.appointment-section::before {
  content: '';
  background-color: #2a396d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
.appointment-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appointment-main h6 {
  font-size: 14px;
  font-weight: 700;
  color: #ffa621;
  z-index: 1;
}
.appointment-main h2 {
  font-size: 40px;
  color: #fff;
  z-index: 1;
}
.appointment-main p {
  color: #fff;
  max-width: 630px;
  text-align: center;
  z-index: 1;
}
.appointment-btn {
  z-index: 1;
}
.appointment-btn .btn-primary {
  background: #ff0080;
  border-color: #ff0080;
}
.appointment-btn .btn-primary:hover {
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}
.populars-section {
  background-color: #f9f9f9;
}
.owl-carousel.stylists-slider .stylists-main-img img {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  border: 2px dashed #2a396d;
  padding: 20px;
  margin-bottom: 25px;
}
.stylists-bottom {
  text-align: center;
}
.stylists-bottom h4 {
  font-size: 20px;
}
.stylists-bottom h6 {
  font-size: 14px;
  color: #74788d;
  font-weight: normal;
}
.stylists-all {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popular-content {
  text-align: center;
}
.popular-content h3 {
  font-size: 20px;
  color: #151519;
}
.popular-content h6 {
  font-size: 14px;
  color: #74788d;
  margin-bottom: 0;
  font-weight: normal;
}
.section-content {
  margin-bottom: 50px;
}
.works-section {
  padding: 90px 0;
}
.works-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.works-top-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s;
}
.works-top-img.works-load-profile {
  position: relative;
  transition: all 0.7s;
}
.works-top-img.works-load-profile::before {
  content: '';
  background-color: #2a396de5;
  position: absolute;
  border-radius: 16% 84% 50% 50% / 52% 47% 53% 48%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  transition: all 0.7s;
}
.works-top-img::before {
  content: '';
  background-color: #2a396de5;
  position: absolute;
  border-radius: 75% 25% 50% 50% / 52% 47% 53% 48%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  transition: all 0.7s;
}
.works-top-img:hover::before {
  background-color: #ff008099;
  transition: all 0.7s;
}
.works-top-img img {
  border-radius: 75% 25% 50% 50% / 52% 47% 53% 48%;
  width: 230px;
  height: 218px;
}
.works-load-profile img {
  border-radius: 16% 84% 50% 50% / 52% 47% 53% 48%;
}
.works-bottom {
  text-align: center;
}
.works-tops {
  position: relative;
  margin-bottom: 30px;
}
.works-inner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 9;
  background: #ff008099;
  transform: scale(0);
  transition: all 0.5s;
  border-radius: 75% 25% 50% 50% / 52% 47% 53% 48%;
}
.work-inner-content-left {
  border-radius: 16% 84% 50% 50% / 52% 47% 53% 48%;
}
.works-tops:hover .works-inner-content {
  transform: scale(1);
}
.works-top-img span {
  position: absolute;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  border: 5px solid #fff;
  border-radius: 50%;
  width: 71px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.works-bottom p {
  max-width: 300px;
  margin-bottom: 0;
}
.works-bottom h2 {
  font-size: 28px;
}
.client-sections {
  background: url(../img/client-bg.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: left center;
  position: relative;
  padding: 90px 0 80px;
  background-size: cover;
}
.client-sections::before {
  content: '';
  background-color: #2a396d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
.review-four .testimonial-thumb {
  padding: 10px 0;
}
.review-four .client-img.client-pro .testimonial-thumb img {
  width: 58px !important;
  height: 58px !important;
  border-radius: 60px;
}
.review-four .slider-nav {
  width: 300px;
  margin: auto;
}
.review-four .slick-list {
  padding: 0 !important;
}
.review-four .slider-nav .slick-current .testimonial-thumb {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.review-four .slider-nav .slick-current .testimonial-thumb img {
  border: 5px solid #ff0080;
  border-radius: 50px;
  padding: 2px;
}
.review-love-group {
  max-width: 680px;
  margin: auto;
}
.review-passage {
  text-align: center;
  max-width: 600px;
  margin: auto;
  margin-bottom: 25px;
}
.quote-love-img img {
  margin: auto;
}
.quote-love-img {
  margin-bottom: 30px;
}
.review-profile-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 110px;
  padding: 8px;
  margin-right: 15px;
  min-width: 200px;
}
.section-client h2 {
  color: #fff;
  z-index: 1;
}
.section-client p {
  color: #f9f9f9;
  z-index: 1;
}
.section-client .our-img-all::before,
.section-client .our-img-all::after {
  background: #fff;
}
.say-about .review-passage {
  color: #fff;
}
.say-name-blk h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.say-name-blk p {
  color: #ffa621;
}
.service-img.service-latest-img {
  position: relative;
}
.service-img.service-latest-img::before {
  content: '';
  background-color: #0d153180;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 1;
}
.latest-section {
  position: relative;
  padding: 80px 0 66px;
}
.service-latest-img {
  height: 312px;
}
.latest-content span {
  display: inline-block;
  font-size: 14px;
  color: #898989;
  padding: 5px;
  border: 1px solid #898989;
  margin-bottom: 10px;
  border-radius: 3px;
  line-height: normal;
}
.latest-content .latest-news-content {
  display: flex;
  color: #151519;
  font-size: 20px;
  white-space: normal;
  margin-bottom: 10px;
  font-weight: 500;
}
.latest-content .latest-news-content:hover {
  color: #ff0080;
}
.latest-content .latest-news {
  color: #ff0080;
  font-size: 14px;
}
.latest-content .latest-news:hover {
  color: #151519;
}
.latest-date {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px 10px;
  margin: 15px 0 0 15px;
  background-color: #ff0080;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
}
.latest-date span {
  font-size: 24px;
  color: #fff;
}
.register-section {
  background: url(../img/footer-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.register-section::before {
  content: '';
  background-color: #2a396d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
.register-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 69px 0px;
}
.register-content h2 {
  font-weight: 600;
  font-size: 40px;
  color: #fff;
  z-index: 1;
  max-width: 767px;
  margin-bottom: 0;
}
.register-content ul {
  z-index: 1;
}
.register-content .header-navbar-rht li > a.header-login {
  background: #2229c1;
  border: 1px solid #2229c1;
  box-shadow: inset 0 70px 0 0 #2229c1;
}
.register-btn {
  z-index: 1;
  background: #2229c1;
  border: 1px solid #2229c1;
  box-shadow: inset 0 70px 0 0 #2229c1;
  border-radius: 5px;
  font-size: 16px;
  padding: 15px;
  font-weight: 600;
}
.register-btn a {
  color: #fff;
}
.register-btn:hover {
  background-color: transparent;
  box-shadow: inset 0 70px 0 0;
  transition: all 0.7s;
  border: 1px solid #2229c1;
}
.bar-icon.bar-icon-three span {
  background-color: #ff0080;
}
.copyright-menu {
  float: right;
}
.copyright-menu .footer-menu li a:hover {
  color: #ff0080;
}
.progress-wrap.progress-wrap-three::after {
  color: #ff0080;
}
.progress-wrap.progress-wrap-three svg.progress-circle path {
  stroke: #ff0080;
}

/*-----------------
    58. Home Four
-----------------------*/

.catering-banner-section {
  background-color: #f3f3f3;
}
.search-three-form {
  display: flex;
  align-items: center;
}
.search-box.search-box-four .search-btn .btn {
  padding: 13px 50px;
  border-radius: 105px;
  font-size: 18px;
}
.section-search.section-search-four {
  max-width: 633px;
}
.search-box.search-box-four {
  border-radius: 90px;
  margin: 0 0 30px 0;
  box-shadow: none;
  padding: 10px 15px;
  padding-left: 30px;
}
.section-search.section-search-four span {
  color: #4c40ed;
}
.section-search.section-search-four p {
  margin-bottom: 30px;
}
.feature-section.nearby-section .section-heading h2 {
  font-weight: 700;
}
.feature-section.nearby-section .section-heading .btn-view {
  padding: 14px 20px;
  border-radius: 50px;
}
.service-widget.service-four {
  border: 1px solid #e2e6f1;
  padding: 20px;
}
.service-content-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.service-cater-img {
  display: flex;
  align-items: center;
}
.catering-banner-botton {
  display: flex;
  align-items: center;
}
.catering-btn-services {
  margin-right: 40px;
}
.catering-btn-services:last-child {
  margin-right: 0;
}
.catering-btn-icon {
  margin-bottom: 20px;
}
.catering-btn-icon-two img {
  color: #4c40ed;
  width: 23px;
  height: 30px;
  font-size: 24px;
  margin-bottom: 10px;
}
.catering-btn-services h5 {
  font-size: 18px;
  margin-bottom: 10px;
}
.catering-btn-services h6 {
  color: #74788d;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 0;
}
.service-content-bottom .service-cater-img img {
  width: 38px;
  height: 38px;
  border-radius: 50px;
  margin-right: 10px;
}
.service-cater-img p {
  margin: 0;
}
.service-content-bottom h6 {
  color: #4c40ed;
  margin: 0;
  font-weight: 600;
}
.catering-slider .service-two.service-four {
  padding: 0;
}
.service-two.service-four .service-common-four {
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
}
.catering-main-bottom .title {
  margin-bottom: 0;
}
.service-content-bottom {
  padding: 20px;
}

.catering-main-bottom .rating {
  font-size: 14px;
  margin-bottom: 7px;
}
.catering-main-bottom span {
  font-size: 14px;
}
.catering-main-bottom .title a {
  color: #101522;
}
.nearby-section {
  padding: 80px 0;
}
.categories-section {
  padding: 80px 0;
  position: relative;
  background-color: #f3f3f3;
}
.categories-main-all {
  padding: 30px 31px;
  border-radius: 10px;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-direction: column;
  background: #fff;
  margin-bottom: 27px;
}
.categories-img span {
  width: 100px;
  height: 100px;
  background-color: #f3f3f3;
  border: #f3f3f3;
  border-radius: 10px;
  margin-bottom: 25px;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.categories-main-all h6 {
  text-align: center;
  color: #58627b;
  max-width: 115px;
}
.categories-main-all .category-bottom {
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 24px;
  height: 24px;
  background-color: #f3f3f3;
  color: #fff;
  border-radius: 50px;
}
.categories-main-all:hover .categories-img span {
  background-color: #4c40ed;
  border: #4c40ed;
  transition: 0.7s;
}

.categories-main-all:hover .category-bottom {
  background-color: #4c40ed;
  color: #fff;
  transition: 0.7s;
}
.categories-main-all:hover .categories-img span img {
  filter: invert(1) brightness(2);
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.categories-img span img {
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.btn-catering .btn-view {
  border-radius: 95px;
  padding: 15px 26px;
  margin-top: 23px;
}
.section-heading.section-heading-four .btn-view {
  border-radius: 95px;
  padding: 15px 27px;
}
.features-four-section {
  position: relative;
  padding: 80px 0;
}
.rate-four {
  padding: 2px 10px;
  background-color: #fff;
  border-radius: 5px;
}
.rate-four i {
  color: #ffb800;
  margin-right: 3px;
}
.rate-four span {
  color: #000000;
  font-weight: 600;
}
.service-four-img {
  position: relative;
}
.service-four-img::before {
  content: '';
  background-color: #0c112399;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 1;
}
.service-widget.service-two
  .service-content.service-four-content
  .service-cater-bottom {
  color: #2a396d;
}
.service-widget.service-two .service-content.service-four-content p {
  white-space: normal;
}
.service-content.service-four-content ul li {
  color: #6c6c6c;
  font-size: 12px;
  padding: 5px;
}
.service-content.service-four-content ul li:last-child {
  margin-right: 0;
}
.category-feature-bottom {
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 5px;
}
.category-feature-bottom p {
  margin-bottom: 0;
  color: #2a396d;
}
.category-feature-bottom a {
  font-size: 14px;
  font-weight: 700;
  color: #4c40ed;
}
.category-feature-bottom a:hover {
  color: #0b2540;
}
.trust-us-section {
  position: relative;
  padding: 80px 0;
  background-color: #f9f9f9;
  z-index: 1;
}
.trust-us-section::before {
  background: url(../img/trust-bg.png) no-repeat;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-position: left;
}
.trust-us-main {
  padding: 40px;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 24px rgba(205, 205, 205, 0.25);
}
.trust-us-img {
  display: -webkit-flex;
  display: flex;
  font-size: 56px;
  color: #4c40ed;
  margin-bottom: 25px;
  padding: 12px 18px;
}
.trust-us-main h6 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.trust-us-main p {
  text-align: center;
  margin-bottom: 0;
}
.trust-us-main:hover .trust-us-img {
  background-color: #4c40ed;
  transition: 0.7s;
  color: #fff;
  padding: 12px 18px;
  border-radius: 10px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
.trust-us-main .trust-us-img i {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
.trust-us-main:hover .trust-us-img i {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.popular-four-section {
  position: relative;
  padding: 80px 0;
}
.popular-portfolio-img img {
  width: 100%;
  transition: 0.5s all;
}
.popular-portfolio-img:hover img {
  position: relative;
  z-index: 1;
  transition: 0.5s all;
}
.popular-portfolio-img {
  position: relative;
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.5s all;
}
.popular-portfolio-img::before {
  background-color: #00000080;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
  transition: 0.5s all;
}
.popular-portfolio-overlay {
  position: absolute;
  content: '';
  bottom: 0;
  padding: 20px;
  z-index: 1;
  transition: 0.5s all;
}
.popular-portfolio-overlay h6 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 5px;
}
.popular-portfolio-overlay p {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
}
.Working-four-section {
  position: relative;
  padding: 80px 0;
  background-color: #f9f9f9;
}
.search-input.search-input-three.search-input-four
  .form-group
  input::placeholder {
  color: #6c6c6c;
}
.search-input.search-input-three.search-input-four
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 45px;
  font-weight: 400;
  color: #6c6c6c;
}
.search-box.search-box-four
  .search-three-form
  .search-input.search-input-three {
  width: 100%;
}
.search-box.search-box-four .search-three-form .form-group {
  width: 85%;
}
.section-search.section-search-four span {
  border: none;
}
.search-box.search-box-four i {
  color: #424242;
}
.search-box.search-box-four .btn i {
  color: #fff;
}
.section-heading.working-four-heading h2 {
  font-size: 32px;
}
.working-four-main {
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  background-color: #4c40ed;
  border-radius: 10px;
}
.working-four-main h6 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px;
}
.working-four-img {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 60px;
}
.working-four-img::before {
  position: absolute;
  content: '';
  left: 29px;
  width: 43px;
  height: 5px;
  bottom: -33px;
  background-color: #ffb800;
}
.working-four-main h4 {
  font-size: 18px;
  color: #fff;
}
.working-four-main p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
}
.around-world-section {
  position: relative;
  padding: 80px 0;
}
.service-widget .fav-item.fav-item-four {
  right: 0;
  left: auto;
  width: auto;
}
.item-info.item-info-four {
  padding: 0 20px 0;
  font-size: 14px;
}
.service-content.service-four-content {
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #f1f1f1;
}
.service-content.service-four-content p {
  white-space: normal;
}
.service-content.service-four-content .mini-mealmenu {
  text-decoration: line-through;
  font-size: 18px;
  font-weight: 700;
  margin-right: 5px;
  color: #2a396d;
}
.service-content.service-four-content .mealmenu {
  font-size: 22px;
  font-weight: 700;
  color: #4c40ed;
}
.service-content-bottom .service-cater-img.service-world-img img {
  width: 61px;
  height: 61px;
  border-radius: 10px;
}
.service-world-img i {
  margin-right: 7px;
  color: #424242;
}
.service-world-img p {
  color: #424242;
}
.service-content-bottom span {
  display: flex;
  display: -webkit-flex;
  padding: 10px;
  border-radius: 50%;
  background-color: #4c40ed;
  color: #fff;
}
.useful-blog-section {
  position: relative;
  padding: 80px 0;
}
.service-content.service-four-blogs h3 {
  white-space: normal;
  color: #101522;
  font-weight: 700;
  font-size: 23px;
  margin-bottom: 20px;
}
.service-content.service-four-blogs p {
  white-space: normal;
}
.usefull-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.usefull-bottom h6 {
  font-weight: 500;
  color: #2a396d;
}
.owl-carousel.useful-four-slider .useful-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.review-client-section {
  position: relative;
  padding: 80px 0;
  background-color: #f9f9f9;
}
.client-review-top {
  position: relative;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 46px;
}
.client-review-top::before {
  content: '\A';
  border-style: solid;
  border-width: 32px 55px 0px 0px;
  border-color: transparent #fff transparent transparent;
  border-radius: 0px;
  position: absolute;
  bottom: -31px;
  transform: rotate(180deg);
}
.common-four-slider .owl-dots .owl-dot.active span {
  background-color: #4c40ed;
  position: relative;
}
.common-four-slider .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  margin-right: 5px;
}
.common-four-slider .owl-dots .owl-dot.active span::before {
  width: 19px;
  height: 19px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #4c40ed;
  position: absolute;
  content: '';
  top: -5px;
  left: -5px;
}
.client-review-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.client-review-img {
  margin-bottom: 55px;
  float: left;
}
.owl-carousel.client-four-slider .client-review-img img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 5px solid #fff;
}
.client-review-name h6 {
  font-size: 20px;
}
.client-review-name .rating {
  font-size: 12px;
}
.client-review-top p {
  margin-bottom: 0;
}
.client-four-slider {
  margin-bottom: 55px;
  border-bottom: 1px solid #ebebeb;
}
.client-four-slider .owl-dots {
  margin-bottom: 55px;
}

/*-----------------
    59. Home Five
-----------------------*/
.header.header-five.fixed .main-nav li a {
  color: #28283c;
}
.header.header-five.fixed .header-button {
  color: #28283c;
}
.header.header-five.fixed .header-button.header-button-five {
  color: #fff;
}
.header-five {
  background: none;
  box-shadow: none;
}
.header-five .main-nav .has-submenu a {
  color: #fff;
}
.header-five .main-nav li .submenu a {
  color: #68616d;
}
.home-page-five .header-navbar-rht li > a.header-login {
  background-color: #2229c1;
  border: 1px solid #2229c1;
  box-shadow: inset 0 0 0 #e2017b;
}
.home-page-five .header-navbar-rht li > a.header-login:hover {
  background: #e2017b;
  border: 1px solid #e2017b;
  box-shadow: inset 0 70px 0 0 #e2017b;
}
.header-button.header-button-five {
  color: #fff;
  background: #2229c1;
  border-radius: 4px;
}
.header-button.header-button-five:hover {
  color: #2229c1;
  background: #fff;
  border-radius: 4px;
}
.header-button {
  color: #fff;
}
.hero-section-five {
  position: relative;
  background: url(../img/banner-fiver-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
}
.section-section-five h1 {
  color: #fff;
  font-weight: 700;
  font-size: 50px;
  max-width: 600px;
  margin-bottom: 30px;
}
.section-section-five h4 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 15px;
}
.section-section-five p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
  max-width: 430px;
  font-weight: normal;
}
.section-section-five span {
  color: #2229c1;
}
.search-group-icon-5 {
  color: #2229c1;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 0, 138, 0.05);
}
.search-box.search-box-five {
  padding: 12px;
  margin-top: 0;
}
.search-box.search-box-five .search-input {
  width: 50%;
  float: none;
}
.search-box.search-box-five
  .select2-container--default
  .select2-selection--single {
  border: none;
}
.search-box.search-box-five
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #626262;
  font-weight: 400;
  padding-left: 0;
}
.search-box.search-box-five .search-input .form-group {
  width: calc(100% - 40px);
}
.search-box.search-box-five .search-box-form {
  display: flex;
}
.floating-five-main {
  position: relative;
}
.floating-img {
  position: absolute;
  bottom: -75px;
  z-index: 1;
}
.car-five-arrow-img {
  position: relative;
}
.car-five-arrow-img img {
  position: absolute;
  content: '';
  top: -205px;
  left: 115px;
}
.floating-five-buttom {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  margin-right: 10px;
}
.floating-five-buttoms {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
}
.floating-five-main {
  margin-top: -200px;
  position: relative;
  z-index: 3;
}
.car-wash-feature {
  margin-top: 35px;
}
.floating-five-buttom-all {
  margin-right: 20px;
}
.floating-five-buttom-all h5 {
  font-size: 36px;
  margin-bottom: 10px;
}
.floating-five-buttom-all p {
  margin-bottom: 0;
}
.floating-five-buttom-img i {
  background-color: #28cea2;
  padding: 22px;
  border-radius: 50%;
  color: #fff;
}
.floating-five-buttom-img.floating-fives-buttom-img i {
  background-color: #2229c1;
}

.featured-categories-section {
  padding: 140px 0 90px 0;
  position: relative;
  background-color: #f3f6fc;
}
.featured-category-bg-img img {
  position: absolute;
  left: 0;
  top: 170px;
  z-index: 0;
}
.testimonals-five-section {
  background: url(../img/Testimonials-five-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0 160px 0;
}
.car-testimonials-five-slider .owl-nav {
  text-align: center;
}
.car-testimonials-five-slider .owl-nav button.owl-prev,
.car-testimonials-five-slider .owl-nav button.owl-next {
  background: #fff !important;
  color: #2229c1;
  margin-bottom: 0;
}

.feature-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-widget.service-two .service-content.service-content-five p {
  color: #28283c;
  font-weight: 600;
  font-size: 20px;
}
.service-content.service-content-five p {
  margin-bottom: 0;
}

.service-content.service-content-five a {
  color: #74788d;
}
.features-service-five {
  display: flex;
  align-items: center;
  justify-content: center;
}
.features-service-rating {
  padding: 7px;
  background: #fff;
  border-radius: 4px;
  margin-right: 8px;
}
.features-service-rating i {
  color: #ffba08;
  margin-right: 9px;
}
.features-service-rating span {
  color: #626262;
  font-size: 16px;
}
.features-service-five h6 {
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.feature-service-botton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feature-service-botton img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.feature-service-botton.feature-service-botton-five img {
  width: 50px !important;
}
.feature-service-btn a {
  padding: 12px 20px;
  background-color: #2229c1;
  border-radius: 8px;
  color: #fff;
  border: 1px solid #2229c1;
  font-weight: 500;
}
.feature-service-btn a:hover {
  background-color: #fff;
  border: 1px solid #626262;
  color: #626262;
}
.feature-services-five {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.feature-services-five h6 {
  margin-bottom: 0;
  font-size: 24px;
  color: #28cea2;
  margin-right: 8px;
}
.feature-services-five span {
  text-decoration: line-through;
}
.feature-category-section {
  padding: 90px 0;
  position: relative;
}
.feature-category-section::before {
  position: absolute;
  content: '';
  background: url(../img/bubbles-bottom-section.png) no-repeat 0 0 / 100%;
  left: 0;
  top: 163px;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.feature-category-section-five {
  position: relative;
  padding: 90px 0;
  background-color: #f3f6fc;
}
.feature-category-section-five::before {
  position: absolute;
  content: '';
  background: url(../img/car-blog-ng.jpg) no-repeat 0 0 / 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 0;
}
.top-providers-five .providerset .providerset-img a img {
  border-radius: 10px;
}
.top-providers-five .providerset {
  padding: 0;
  margin-bottom: 50px;
}
.top-providers-five .providerset .providerset-content {
  padding: 0 18px 18px 18px;
  text-align: center;
}
.top-providers-five .providerset .providerset-content h4 {
  margin-bottom: 10px;
}
.top-providers-five .providerset .providerset-content h4 a {
  font-size: 20px;
  margin-right: 4px;
}
.top-providers-five .providerset .providerset-content h4 i {
  font-size: 18px;
  color: #6dcc76;
}
.top-providers-five .providerset .providerset-content h5 {
  color: #626262;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
}
.top-providers-five .providerset .providerset-content .providerset-prices {
  margin-bottom: 20px;
}
.providers-section-five {
  position: relative;
  padding: 90px 0;
  background: #f3f6fc;
}
.providers-five-bg-car img {
  position: absolute;
  content: '';
  right: 0;
  bottom: 55px;
  z-index: 0;
}
.providers-section-five .providerset {
  border: 1px solid #fff;
}
.top-providers-five .owl-nav {
  text-align: center;
}
.top-providers-five .owl-nav button.owl-prev,
.top-providers-five .owl-nav button.owl-next {
  background: #2229c1 !important;
  color: #fff;
  margin-bottom: 0;
}
.top-providers-five .owl-nav button.owl-prev:hover,
.top-providers-five .owl-nav button.owl-next:hover {
  background: #fff !important;
  color: #2229c1;
}
.btn-sec.btn-sec-five .btn-view {
  background-color: #28cea2;
  padding: 15px 30px;
  border-radius: 8px;
}
.works-five-section {
  position: relative;
  padding: 90px 0;
}
.works-five-section::before {
  position: absolute;
  content: '';
  background: url(../img/bubbles-bottom-section.png) no-repeat 0 0 / 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.car-wash-img-five {
  display: flex;
  justify-content: center;
  align-items: center;
}
.works-it-five-button {
  text-align: center;
}
.works-it-five-button h4 {
  font-size: 40px;
  color: #bbbbbb;
  margin-bottom: 15px;
}
.works-it-dots {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.works-it-dots span {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: #28cea2;
  border-radius: 50%;
  margin-bottom: 55px;
}
.works-it-dots span::before {
  width: 38px;
  height: 38px;
  background-color: #22c55e;
  border-radius: 50%;
  position: absolute;
  content: '';
  top: -11px;
  left: -11px;
  z-index: -1;
}
.works-it-lines span {
  position: relative;
}
.works-it-lines span::before {
  width: 370px;
  height: 1px;
  background-color: #28cea2;
  position: absolute;
  content: '';
  top: -62px;
  left: 241px;
  z-index: -1;
}
.works-five-main {
  border: 1px solid #f3f6fc;
  border-radius: 8px;
  margin-bottom: 160px;
}
.works-five-img img {
  width: 100%;
}
.works-five-main:hover {
  box-shadow: 0px 10px 20px -5px rgb(76 64 237 / 8%);
}
.offering-five-all {
  position: relative;
  background-color: #28cea2;
  border-radius: 20px;
  z-index: 1;
}
.offering-five-all-img img {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  z-index: -1;
}
.offering-five-button .btn-primary {
  background-color: #2229c1;
  padding: 15px 30px;
  border: 1px solid #2229c1;
}
.offering-five-button .btn-primary:hover {
  border: 1px solid #2ca384;
}
.offering-five-button .btn-primary i {
  margin-left: 10px;
}
.offering-five-main h2 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 25px;
}
.offering-five-main p {
  color: #fff;
}
.offering-five-main {
  padding: 50px 29px 50px 50px;
}
.offering-five-img {
  margin: -86px 0 0 -60px;
}
.works-five-bottom {
  text-align: center;
  padding: 20px;
}
.works-five-bottom i {
  display: inline-flex;
  width: 54px;
  height: 54px;
  background: #2229c1;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
}
.fav-item .fav-icon.fav-icon-five {
  width: 35px;
  height: 35px;
}
.fav-item .fav-icon.fav-icon-five:hover {
  background-color: #2229c1;
}
.car-testimonials-main-five {
  background-color: #2229c1;
  border-radius: 50px;
}

.car-wash-bg-five {
  position: relative;
}
.car-wash-bg-five::before {
  position: absolute;
  content: '';
  background: #2229c1;
  width: 100%;
  height: 483px;
  border-radius: 50px;
  bottom: -60px;
}
.testimonials-five-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 50px;
  padding: 24px 50px;
  margin-bottom: 50px;
}
.testimonials-five-top h2 {
  font-size: 20px;
}
.testimonials-five-top p {
  text-align: center;
}
.testimonials-five-top h6 {
  font-size: 14px;
  color: #74788d;
  margin-bottom: 0;
  font-weight: normal;
}
.owl-carousel.car-testimonials-five-slider .test-five-img img {
  width: 119px;
  height: 119px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.blog-section.blog-five-section {
  padding: 90px 0 80px 0;
}
.car-blogs-section {
  position: relative;
  padding: 90px 0 0 0;
  background-color: #f3f6fc;
}
.car-blogs-section::before {
  position: absolute;
  content: '';
  background: url(../img/car-blog-ng.jpg) no-repeat 0 0 / 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 0;
}
.feature-service-five-slider .service-widget {
  margin-bottom: 50px;
}
.feature-service-five-slider .owl-nav {
  text-align: center;
}
.feature-service-five-slider .owl-nav button.owl-prev,
.feature-service-five-slider .owl-nav button.owl-next {
  background: #2229c1 !important;
  color: #fff;
  margin-bottom: 0;
}
.feature-service-five-slider .owl-nav button.owl-prev:hover,
.feature-service-five-slider .owl-nav button.owl-next:hover {
  background: #fff !important;
  color: #2229c1;
}
.pricing-btn-five .btn-view {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #28cea2;
  border: 1px solid #28cea2;
}
.pricing-service-five {
  position: relative;
  padding: 90px 0;
}
.pricing-service-topimg .pricing-five-img-one {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
}
.pricing-service-topimg .pricing-five-img-two {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
}
.pricing-service-five .nav-pills {
  justify-content: center;
  max-width: 254px;
  background: #ffedf7;
  margin: 0 auto 80px;
  border-radius: 580px;
  padding: 10px;
}
.pricing-service-five .nav-pills .nav-link {
  border: none;
  color: #28283c;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 24px;
  border-radius: 22px;
  background: none;
}
.pricing-service-five .nav-pills .nav-link.active {
  color: #fff;
  background: #2229c1;
  padding: 13px 24px;
  border-radius: 50px;
}
.pricing-plans.pricing-plans-five:hover .pricing-btn-five .btn-primary,
.pricing-plans.pricing-plans-five.active .pricing-btn-five .btn-primary {
  background-color: #2229c1;
  box-shadow: inset 0 70px 0 0 #2229c1;
  border: 1px solid #2229c1;
}
.providerset-prices-five {
  margin-bottom: 20px;
}
.providerset-prices-five h6 {
  font-size: 36px;
  margin: 0 10px 0 0;
}
.providerset-prices-five {
  display: flex;
  align-items: center;
  justify-content: start;
}
.pricing-plans.pricing-plans-five {
  border: 1px solid #d8e1ff;
  transition: 0.5s;
}
.pricing-plans.pricing-plans-five:hover,
.pricing-plans.pricing-plans-five.active {
  background-color: #28cea2;
  border: 1px solid #28cea2;
  transition: 0.5s;
}

.pricing-plans.pricing-plans-five:hover
  .pricing-planscontent.pricing-planscontent-five
  ul
  li
  span,
.pricing-plans.pricing-plans-five.active
  .pricing-planscontent.pricing-planscontent-five
  ul
  li
  span {
  color: #fff;
}

.pricing-plans.pricing-plans-five:hover h4,
.pricing-plans.pricing-plans-five.active h4 {
  color: #fff;
}

.pricing-plans.pricing-plans-five:hover .providerset-prices-five h6,
.pricing-plans.pricing-plans-five.active .providerset-prices-five h6 {
  color: #fff;
}

.pricing-plans.pricing-plans-five:hover .providerset-prices-five span,
.pricing-plans.pricing-plans-five.active .providerset-prices-five span {
  color: #fff;
}

.pricing-planscontent.pricing-planscontent-five {
  padding-top: 0;
}
.pricing-planscontent.pricing-planscontent-five ul li span {
  color: #74788d;
}
.pricing-plans.pricing-plans-five h4 {
  margin-bottom: 20px;
}
.item-cat.item-car {
  background-color: #4c82f7;
  color: #fff;
}
.item-cat.item-car:hover {
  background-color: #fff;
  color: #4c82f7;
}
.car-blog-slider .service-content p {
  white-space: normal;
}
.car-blog-slider .serv-info img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.car-blog-slider .serv-info a {
  background-color: #2229c1;
  color: #fff;
}
.car-blog-slider .service-widget {
  margin-bottom: 50px;
}
.car-blog-slider .owl-nav {
  text-align: center;
  margin-bottom: 50px;
}
.car-blog-slider .owl-nav button.owl-prev,
.car-blog-slider .owl-nav button.owl-next {
  background: #2229c1 !important;
  color: #fff;
  margin-bottom: 0;
}
.car-blog-slider .owl-nav button.owl-prev:hover,
.car-blog-slider .owl-nav button.owl-next:hover {
  background: #fff !important;
  color: #2229c1;
}
.section-heading.car-wash-heading {
  position: relative;
}
.section-heading.car-wash-heading h2 {
  position: relative;
}
.section-heading.car-wash-heading .car-wash-header-one {
  margin-right: 8px;
}
.section-heading.car-wash-heading .car-wash-header-two {
  margin-left: 8px;
}
.app-five-section {
  background-color: #f7f7ff;
  padding: 117px 0 160px 0;
}
.app-sec.app-sec-five .downlaod-btn {
  display: flex;
  align-items: center;
  justify-content: start;
}
.app-sec.app-sec-five .downlaod-btn .scan-img {
  margin: 0 17px 0 0;
}
.app-sec.app-sec-five .downlaod-btn .scan-img img {
  height: 45px;
  width: 50px;
}
.app-sec.app-sec-five .downlaod-btn a img {
  width: 100%;
  height: 60px;
}
.app-sec.app-sec-five .heading h2 {
  margin-bottom: 20px;
}
.app-sec.app-sec-five .heading h6 {
  margin-bottom: 20px;
}
.app-sec.app-sec-five::before {
  background: url(../img/car-blog-2.png);
  background-repeat: no-repeat;
  background-position: left;
}
.app-sec.app-sec-five::after {
  background: url(../img/car-blog-1.png);
  background-repeat: no-repeat;
}
.app-sec.app-sec-five {
  background: #28cea2;
  border-radius: 50px;
  padding: 95px 85px 0 85px;
}
.app-sec.app-sec-five .appimg-set img {
  margin-top: -105px;
}
.footer.footer-five .footer-widget.footer-menu ul li a:hover {
  color: #2229c1;
}
.footer.footer-five .footer-bottom .copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
}
.footer.footer-five .footer-bottom .copyright .payment-image ul {
  justify-content: start;
}
.footer.footer-five .footer-bottom .copyright .copyright-text p {
  text-align: center;
  color: #fff;
}
.footer.footer-five
  .footer-bottom
  .copyright
  .copyright-menu
  .policy-menu
  li
  a {
  color: #fff;
}
.footer.footer-five {
  background-color: #28cea2;
}
.footer.footer-five .footer-top .footer-widget .footer-content p {
  color: #fff;
}
.footer.footer-five .footer-widget.footer-menu .footer-title,
.footer.footer-five .footer-widget.footer-contact .footer-title,
.footer.footer-five .footer-widget .footer-title,
.footer.footer-five .footer-widget .footer-subtitle {
  color: #fff;
}
.footer.footer-five .footer-widget.footer-menu ul li a,
.footer.footer-five .footer-widget.footer-contact .footer-contact-info p {
  color: #fff;
}
.footer.footer-five .footer-widget.footer-contact .footer-contact-info span {
  color: #2229c1;
}
.footer.footer-five .social-icon ul li a:hover {
  background-color: #2229c1;
}

/*-----------------
    60. Home Six
-----------------------*/
.home-banner-main {
  position: relative;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.home-banner-six-bg img {
  position: absolute;
  content: '';
  z-index: -1;
  top: -125px;
  left: -40px;
}

.home-banner-six-detergent {
  justify-content: start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search-box-two.search-box-six {
  padding: 10px;
}
.search-box-two.search-box-six .search-input-new.line-six {
  width: 45%;
}
.search-box-two.search-box-six .search-input-new.line-two-six {
  width: 35%;
}
.search-box-two.search-box-six .search-btn {
  width: 20%;
}
.search-box-two.search-box-six .search-btn .btn {
  padding: 8px 16px;
}
.search-input-new.line-two-six input {
  padding: 0px 40px 0px 10px;
  height: 40px;
  min-height: 40px;
}
.search-input-new.line-six
  .select2-container--default
  .select2-selection--single {
  border: 0;
  height: 40px;
}
.search-input-new.line-two-six i {
  color: #2229c1;
  right: 12px;
}
.search-input-new.line-six
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #2229c1;
}
.side-social-media-six {
  position: relative;
}
.side-social-media-six ul {
  position: absolute;
  top: 260px;
  left: 0;
  z-index: 2;
}
.side-social-media-six ul li {
  margin-bottom: 20px;
}
.side-social-media-six ul li a {
  width: 24px;
  display: flex;
  height: 24px;
  background: #28cea2;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  transition: 0.5s;
}
.side-social-media-six ul li a:hover {
  background: #fff;
  color: #28cea2;
  transition: 0.5s;
}
.side-social-media-six ul li a i {
  font-size: 12px;
}
.home-banner-six-bottom {
  padding-top: 85px;
}
.home-banner-six-detergent {
  margin-bottom: 20px;
}
.home-banner-six-detergent h5 {
  margin-bottom: 0;
  color: #28cea2;
}
.home-banner-six-bottom h1 {
  color: #28cea2;
  max-width: 470px;
  margin-bottom: 23px;
}
.home-banner-six-bottom h1 span {
  color: #2229c1;
}
.home-banner-six-bottom p {
  color: #74788d;
  max-width: 375px;
  margin-bottom: 50px;
}
.search-box-two.search-box-six
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #74788d;
  font-size: 16px;
  font-weight: 400;
}
.search-input-new.line-two-six .form-group input::placeholder {
  color: #74788d;
  font-size: 16px;
  font-weight: 400;
}
.search-box-two.search-box-six {
  width: 93%;
}
.search-box-two.search-box-six .search-btn .btn {
  font-size: 16px;
  font-weight: 500;
}
.banner-six-side-img {
  position: relative;
}
.banner-six-side-img img {
  position: absolute;
  bottom: -33px;
  right: 0;
}
.banner-six-ellipse-img {
  position: relative;
}
.banner-six-ellipse-img .ellipe-six-one {
  position: absolute;
  top: 0;
}
.reason-choose-us {
  position: relative;
  padding: 60px 0 60px;
}
.reason-six {
  display: flex;
  justify-content: start;
  align-items: flex-end;
  margin-bottom: 10px;
}
.reason-six img {
  margin-right: 10px;
}
.reason-six p {
  color: #28cea2;
  font-weight: 600;
}
.section-heading.section-heading-six h2 {
  color: #28cea2;
  margin-bottom: 0;
}
.section-heading.section-heading-six h2 span {
  color: #2229c1;
}
.professional-cleaning-main {
  background: #edeef0;
  padding: 50px 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
}

.professional-cleaning-main img {
  margin-bottom: 10px;
}
.professional-cleaning-main h5 {
  color: #28cea2;
  margin-bottom: 0;
}
.total-client-all .total-client-half {
  display: flex;
  justify-content: start;
  align-items: center;
}
.total-client-all .total-client-half .total-client-mini {
  padding: 0 20px 0 20px;
}
.total-client-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.total-client-all .total-client-half .total-client-mini:first-child {
  padding-left: 0;
  border-right: 1px solid #d9d9d9;
}
.total-client-all .total-client-half .total-client-mini:nth-child(3) {
  padding-left: 0;
}
.total-client-all .total-client-half .total-client-mini .total-experts-main {
  display: inline-flex;
  align-items: center;
}
.total-experts-main h3 {
  color: #28cea2;
  font-size: 38px;
  margin: 0 10px 0 0;
}
.total-experts-main p {
  color: #74788d;
  font-weight: 500;
  margin-bottom: 0;
}
.total-experts-main p span {
  display: block;
}
.total-client-all ul li a img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 5px solid #fff;
}
.total-client-avatar li {
  display: inline-block;
  margin: 0 0 0 -15px;
}
.total-client-avatar li:first-child {
  margin-left: 0;
}
.total-client-avatar .more-set a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background: #28cea2;
  border-radius: 50%;
  color: #fff;
  border: 5px solid #fff;
  font-size: 14px;
  font-weight: 600;
}
.total-client-half li .total-client-content {
  margin: 0 0 0 40px;
  color: #74788d;
}
.total-client-half li .total-client-content span {
  display: block;
}
p.total-client-content {
  margin-bottom: 0;
  max-width: 480px;
}

.vehicle-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: -50px;
  margin-bottom: -50px;
}

.vehicle-image-luton {
  width: 120%;
  height: auto;
  object-fit: cover;
  margin-top: -85px;
  margin-bottom: -85px;
  margin-left: -30px;
}

.review-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 50px;
  margin-bottom: -50px;
}

.review-image-1 {
  width: 90%;
  height: auto;
  object-fit: cover;
  margin-top: 50px;
  margin-bottom: -50px;
}

.our-company-ryt {
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 0;
}
.our-company-bg img {
  position: absolute;
  top: -160px;
  z-index: -1;
  left: -10px;
}
.our-company-img {
  margin-top: -160px;
}
.about-our-company {
  position: relative;
  background: url(../img/oru-company-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;
}
.our-company-six p {
  color: #74788d;
  font-weight: 400;
  max-width: 559px;
  line-height: 30px;
  margin-bottom: 40px;
}
.our-company-first-content {
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  bottom: 47px;
  left: -30px;
}
.company-top-content {
  margin-right: 24px;
}
.company-top-content p {
  margin-bottom: 0;
  color: #74788d;
}
.company-top-content h3 {
  font-size: 38px;
  color: #28cea2;
  margin-bottom: 0;
}
.our-company-first-content a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 50%;
  background: #2229c1;
  color: #fff;
  transition: 0.7s;
}
.our-company-first-content a:hover {
  background: #fff;
  color: #2229c1;
  transition: 0.7s;
}
.our-company-first-content a i {
  font-size: 22px;
}
.our-company-two-content {
  position: absolute;
  top: -158px;
  right: 117px;
  padding: 15px;
  background: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.company-two-top-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-two-top-content h4 {
  margin-bottom: 0;
  color: #28cea2;
  font-size: 38px;
}
.our-company-two-content p {
  color: #74788d;
  margin-bottom: 0;
}
.afford-btn {
  display: inline-flex;
  justify-content: flex-end;
  text-decoration: underline;
  color: #2229c1;
  float: right;
}
.aboutus-companyimg {
  position: relative;
}
.aboutus-companyimg img {
  max-width: 100%;
}
.playicon {
  position: absolute;
  right: 61%;
  top: 37%;
}
.playicon span {
  width: 48px;
  height: 48px;
  background: #2229c1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.modal-content-video {
  padding: 0 !important;
}
.modal-content-video .modal-content {
  background: #000;
}
.modal-content-video .modal-header {
  border: 0;
}
.modal-content-video .modal-header button.close {
  color: #fff;
  opacity: 1;
}
.video-home video {
  width: 100%;
}
.satisfied-service-section {
  position: relative;
  padding: 80px 0 45px 0;
}
.get-service-main {
  padding: 30px;
  text-align: center;
  background: #f7f8f9;
  border: 1px solid #f7f8f9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
}
.get-service-main:hover {
  border: 1px solid #2229c1;
  background: rgba(255, 0, 138, 0.01);
}
.get-service-main:hover .get-service-btn a {
  color: #2229c1;
}
.get-service-main:hover .get-service-btn a i {
  background: rgba(255, 0, 138, 0.1);
}
.get-service-main span {
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.get-service-main span img {
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.get-service-main img {
  margin-bottom: 25px;
}
.get-service-main h5 {
  color: #28cea2;
  margin-bottom: 25px;
}
.get-service-btn a {
  display: flex;
  align-items: center;
  color: #74788d;
  font-weight: 500;
}
.get-service-btn a i {
  padding: 4px;
  border-radius: 50%;
  background: #6262621a;
}
.see-works-six-section {
  position: relative;
  padding: 80px 0;
  background: url(../img/oru-company-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.how-it-works-six {
  margin-bottom: 25px;
}
.works-six-num {
  display: flex;
  align-items: center;
  justify-content: center;
}
.works-six-num h2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 1);
  font-size: 50px;
  width: 82px;
  height: 82px;
  background: rgba(255, 0, 138, 0.5);
  border-radius: 10px;
  z-index: 0;
}
.works-six-num h2::before {
  position: absolute;
  content: '';
  width: 82px;
  height: 82px;
  background: rgba(255, 0, 138, 0.5);
  border-radius: 10px;
  transform: rotate(14.46deg);
  z-index: -1;
}
.works-six-num h2::after {
  position: absolute;
  content: '';
  width: 2px;
  height: 51px;
  background: rgba(255, 0, 138, 1);
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
}
.works-six-num.works-six-num-two h2 {
  background: rgba(247, 208, 74, 0.5);
}
.works-six-num.works-six-num-two h2::before {
  background: rgba(247, 208, 74, 0.5);
}
.works-six-num.works-six-num-three h2 {
  background: rgba(32, 48, 102, 0.5);
}
.works-six-num.works-six-num-three h2::before {
  background: rgba(32, 48, 102, 0.5);
}
.work-online-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.work-online-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.work-online-schedule {
  position: relative;
}
.work-online-bottom h4 {
  max-width: 160px;
  text-align: center;
}
.work-online-bottom img {
  margin-bottom: 20px;
}
.work-online-bottom.work-online-bottom-two img {
  margin-bottom: 0;
}
.work-online-bottom.work-online-bottom-two h4 {
  margin-bottom: 25px;
}
.blogs-service-section {
  position: relative;
  padding: 80px 0 56px 0;
}
.latest-blog-content {
  width: 76px;
  height: 76px;
  border-radius: 10px;
  background: #2229c1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
}
.latest-blog-content h5 span {
  display: block;
}
.latest-blog-content h5 {
  text-align: center;
  color: #fff;
  font-size: 24px;
}
.latest-profile-name img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 5px;
}
.latest-profile-name h6 {
  margin-bottom: 0;
  font-size: 14px;
  color: #74788d;
  font-weight: normal;
}
.latest-blog-six {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}
.latest-profile-name {
  display: flex;
  align-items: center;
}
.service-content.service-content-six {
  padding: 20px 0 0;
  margin-top: -60px;
  z-index: 1;
  position: relative;
}
.service-widget.service-six {
  padding: 20px;
  background: #f8f8f8;
}
.service-content.service-content-six .blog-import-service {
  color: #28cea2;
  font-size: 20px;
}
.service-content.service-content-six p {
  color: #74788d;
  white-space: normal;
}
.service-content.service-content-six a {
  color: #2229c1;
}
.service-content.service-content-six a:hover {
  color: #28cea2;
}
.our-expert-six-section {
  position: relative;
  padding: 80px 0;
  background: url(../img/oru-company-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.our-expert-six {
  position: relative;
}
.our-expert-six-content {
  display: flex;
  align-items: self-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9;
  border-radius: 10px 204px 10px 10px;
  background: rgba(0, 0, 0, 0.53);
  transform: scale(0);
  transition: all 0.5s;
}
.our-expert-six:hover .our-expert-six-content {
  transform: scale(1);
  z-index: 0;
}
.our-expert-six-content h6 {
  color: #fff;
  font-size: 18px;
}
.our-expert-six-content p {
  color: #2229c1;
  margin-bottom: 10px;
}
.our-expert-six {
  margin-bottom: 24px;
}
.our-expert-img {
  border-radius: 10px;
}
.our-expert-img img {
  border-radius: 10px;
  width: 100%;
}
.our-expert-six-content .rating {
  font-size: 12px;
  margin-bottom: 12px;
}
.our-expert-six-content h5 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 17px;
}
.our-expert-six-content h5 span {
  font-size: 14px;
  color: rgba(194, 201, 209, 1);
}
.our-expert-six-content a {
  padding: 8px 16px;
  border-radius: 30px;
  background: #2229c1;
  border: 1px solid #2229c1;
}
.customer-reviews-six-section {
  position: relative;
  padding: 80px 0;
}
.customer-review-main-six {
  background: #f8f8f8;
  border-radius: 10px;
  position: relative;
  margin-bottom: 24px;
}
.customer-review-top img {
  width: 97px;
  height: 97px;
  border-radius: 50%;
  margin-bottom: 30px;
}
.customer-review-top {
  position: relative;
  padding: 30px 56px;
  text-align: center;
  border-bottom: 2px solid #e7e7e7;
}
.customer-review-top::before {
  position: absolute;
  content: '';
  bottom: -2px;
  left: 50%;
  width: 54px;
  height: 2px;
  background: #2229c1;
  transform: translate(-50%, -50%);
}
.customer-review-top p {
  color: #74788d;
  margin-bottom: 0;
}
.customer-review-bottom {
  padding: 28px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-review-bottom .rating {
  margin-bottom: 0;
}
.customer-review-quote img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.hidden-charge-section {
  position: relative;
  background: rgba(255, 0, 138, 0.02);
  padding: 80px 0;
}
.hidden-charge-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin-bottom: 24px;
}
.hidden-charge-main.hidden-charge-main-two {
  border: 1px solid #2229c1;
  box-shadow: 10px 24px 17px rgba(255, 0, 138, 0.04);
}
.hidden-charge-img {
  margin-bottom: 30px;
}
.hidden-charge-main h4 {
  color: #28cea2;
  margin-bottom: 30px;
}
.hidden-charge-main ul {
  text-align: center;
  height: 213px;
  width: 100%;
  margin-bottom: 30px;
}
.hidden-charge-main ul li {
  line-height: 30px;
  color: #74788d;
}
.hidden-charges-bottom-mark h1 {
  color: #28cea2;
}
.hidden-charges-bottom-mark h1 span {
  font-size: 18px;
  color: rgba(218, 218, 218, 1);
}
.hidden-charges-bottom-mark h6 {
  color: rgba(32, 48, 102, 0.5);
  font-weight: 400;
  margin-bottom: 30px;
}
.btn-charges {
  border-radius: 30px;
  background: #28cea2;
  padding: 8px 16px;
  border: 1px solid #28cea2;
}
.btn-charges.btn-plan {
  background: #2229c1;
  border: 1px solid #2229c1;
}
.btn-charges.btn-plan:hover {
  border-color: #2ca384;
}
.app-six-section {
  position: relative;
  padding: 80px 0;
}
.app-sec.app-sec-six {
  background: #28cea2;
  border-radius: 10px;
  padding: 0;
}
.app-sec-main {
  position: relative;
  background: url(../img/new-app-bg.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 50% 100%;
  border-radius: 10px;
}
.app-imgs-six-bg img {
  position: absolute;
  top: 18%;
  left: 30%;
}
.appimg-six {
  position: relative;
  margin-left: -16px;
}

.app-img-sec img {
  position: absolute;
  top: 80px;
  left: 60px;
  z-index: -1;
}
.new-app-six-bottom {
  display: flex;
}
.new-app-six-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 27px;
}
.new-app-six-middle {
  padding-left: 50px;
}
.new-app-six-middle h2 {
  font-size: 50px;
  max-width: 340px;
}
.new-app-six-middle h2 span {
  font-size: 40px;
  font-weight: 600;
}
.new-app-six-middle p {
  max-width: 450px;
}
.new-six-img {
  margin-bottom: 15px;
}

/*-----------------
    61. Home seven
-----------------------*/

.hero-section-seven {
  background: #fff6fb;
}
.hero-sectionseven-top {
  background: url(../img/hero-section-seven.png);
  background-repeat: no-repeat;
  background-position: right center;
}
.total-client-avatar-seven li a img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 5px solid #fff;
}
.total-client-avatar.total-client-avatar-seven li {
  margin: 0 0 0 -20px;
}
.total-client-avatar.total-client-avatar-seven li:first-child {
  margin: 0;
}
.solution-seven {
  display: inline-block;
  background: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 10px 20px -5px rgba(255, 0, 138, 0.08);
  border-radius: 10px;
  padding: 20px;
}
.solution-seven h6 {
  color: #28cea2;
}
.home-banner.homer-banner-seven .section-search h5 {
  color: #67687a;
  font-size: 20px;
}
.home-banner.homer-banner-seven {
  min-height: 535px;
}
.home-banner.homer-banner-seven .section-search h1 {
  color: #2229c1;
}
.home-banner.homer-banner-seven .section-search h1 span {
  color: #28cea2;
}
.search-box-two.search-box-seven {
  max-width: 1068px;
  margin: -47px auto 0 auto;
  background: #f9f9f9;
}
.search-box-two.search-box-seven .search-input-new input,
.search-box-two.search-box-seven .search-input-new.line input {
  background: #f9f9f9;
}
.search-box-two.search-box-seven .search-input-new > i.bficon {
  color: #67687a;
}
.search-box-two.search-box-seven .search-btn .btn {
  background: #28cea2;
}
.search-box-two.search-box-seven .search-btn .btn i {
  margin-right: 10px;
}
.popularsearch-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.popularsearch-top h6 {
  margin: 0 24px 0 0;
  color: #28cea2;
  font-size: 18px;
  position: relative;
}
.popularsearch-top h6::before {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2229c1;
  position: absolute;
  top: 3px;
  content: '';
  left: -23px;
}
.popularsearch-top ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.popularsearch-top ul li {
  padding: 7px 20.0469px 8.5px 19px;
  background: rgba(32, 48, 102, 0.05);
  border-radius: 25px;
  margin-right: 10px;
  font-size: 15px;
  color: #28cea2;
}
.hero-banner-ryt-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
}
.hero-banner-ryt {
  position: relative;
}
.hero-banner-ryt-content {
  position: absolute;
  bottom: 60px;
  left: -150px;
}
.hero-banner-ryt-top {
  margin-right: 15px;
}
.hero-banner-ryt-top h5 {
  color: #28cea2;
  margin-bottom: 4px;
}
.hero-banner-ryt-top p {
  margin-bottom: 0;
  color: #67687a;
}
.hero-banner-ryt-content span {
  width: 54px;
  height: 54px;
  background: #2229c1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-box.feature-box-seven {
  padding: 30px;
  border: 2px solid #f0f0f0;
  box-shadow: 0px 10px 20px -5px rgba(255, 0, 138, 0.08);
  border-radius: 10px;
  margin-bottom: 40px;
}
.feature-icon.feature-icon-seven span {
  width: 113px;
  height: 113px;
  background: #f9f5ff;
}
.feature-box.feature-box-seven h5 {
  color: #28cea2;
  font-size: 18px;
}
.feature-box.feature-box-seven:hover .feature-overlay:before {
  left: 0;
}
.feature-box.feature-box-seven:hover h5 {
  color: #fff;
}
.owl-nav.mynav.mynav-seven button.owl-next:hover,
.owl-nav.mynav.mynav-seven button.owl-prev:hover {
  background-color: #2229c1 !important;
}
.owl-carousel.categories-slider-seven .owl-item img {
  width: auto;
}
.service-section-seven {
  padding: 80px 0;
}
.popular-service-seven-section {
  background: #f8fcfd;
  padding: 80px 0;
}
.service-widget.service-two.service-seven .item-cat {
  background: rgba(255, 255, 255, 0.8);
}
.service-widget.service-two.service-seven .fav-item .fav-icon:hover,
.fav-icon.selected {
  background: #28cea2;
}
.service-widget.service-two.service-seven .fav-item {
  padding: 10px 10px 0;
}
.service-widget.service-two.service-seven .item-info {
  padding: 0 10px 10px;
}
.service-widget.service-two.service-seven .item-img img {
  width: 34px;
  height: 34px;
}
.service-content.service-content-seven .title a {
  color: #28cea2;
}
.service-content.service-content-seven p .rate i {
  margin-right: 10px !important;
  color: rgba(32, 48, 102, 0.3);
}
.service-widget.service-two.service-seven .service-content p .rate {
  color: rgba(32, 48, 102, 0.7);
}
.service-widget.service-two.service-seven .service-content p {
  color: rgba(32, 48, 102, 0.7);
}
.service-content.service-content-seven p i {
  color: rgba(32, 48, 102, 0.3);
}
.service-widget.service-two.service-seven .serv-info h6 {
  color: #28cea2;
}
.section-heading.section-heading-seven h2 {
  color: #28cea2;
}
.section-heading.section-heading-seven p {
  color: #67687a;
}
.work-section-seven {
  padding: 80px 0;
}
.work-icon-seven {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.work-box-seven {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.work-icon-seven span {
  padding: 45px 43px;
  border: 2px solid #dedede;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 25px;
}
.work-icon-seven h1 {
  position: absolute;
  top: 22%;
  left: -30%;
  font-size: 80px;
  color: #dedede;
}
.work-box-seven h5 {
  color: #28cea2;
}
.work-box-seven p {
  color: #67687a;
  max-width: 311px;
  text-align: center;
}
.providers-section-seven {
  padding: 80px 0;
}
.providerset-img.providerset-img-seven {
  position: relative;
}
.providerset-img.providerset-img-seven .fav-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 15px 0;
}
.providerset-img.providerset-img-seven .fav-item .fav-icon {
  width: 28px;
  height: 28px;
}
.providerset-img.providerset-img-seven .fav-item .fav-icon:hover {
  background: #2229c1;
}
.providerset-img.providerset-img-seven .fav-item .fav-icons {
  font-size: 22px;
  color: #159c5b;
}
.provider-rating-seven {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.provider-rating-seven h6 {
  color: #28cea2;
}
.provider-rating-seven a {
  background: #28cea2;
  padding: 8px 16px;
  color: #fff;
  border-radius: 30px;
}
.providerset-content.providerset-content-seven .rate {
  padding: 6px 14px;
  background: #fff4fa;
  border-radius: 50px;
  font-size: 12px;
  color: #58627b;
}
.providerset-content.providerset-content-seven .rate i {
  color: #ffbc35;
  margin-right: 6px;
}
.providerset-content.providerset-content-seven .providerset-name h4 a {
  color: #353535;
}
.providerset-img.providerset-img-seven a img {
  border-radius: 10px;
}
.price-sections-seven {
  padding: 80px 0 60px 0;
  background: #f8fcfd;
}
.pricing-plans.price-new.pricing-plans-seven {
  background: #fff;
  border-radius: 20px;
  margin-top: 40px;
}
.pricing-plans.price-new.pricing-plans-seven .pricing-plans-img {
  margin-bottom: 17px;
}
.pricing-plans.price-new.pricing-plans-seven .pricing-planshead h5 {
  color: #28cea2;
  font-size: 20px;
  margin-bottom: 24px;
}
.pricing-plans.price-new.pricing-plans-seven .pricing-planshead h6 {
  font-size: 38px;
  color: #28cea2;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pricing-plans.price-new.pricing-plans-seven .pricing-planshead h6 span {
  font-size: 24px;
  color: #28cea2;
}
.pricing-plans.price-new.pricing-plans-seven .pricing-planshead {
  border: none;
}
.pricing-planscontent.pricing-planscontent-seven {
  padding-top: 0;
}
.pricing-planscontent.pricing-planscontent-seven ul {
  margin-bottom: 40px;
}
.pricing-planscontent.pricing-planscontent-seven ul li {
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pricing-plans.price-new.pricing-plans-seven
  .pricing-planscontent.pricing-planscontent-seven
  ul
  li
  i {
  width: 24px;
  height: 24px;
  background: #28cea2;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
}
.pricing-plans.price-new.pricing-plans-seven
  .pricing-planscontent.pricing-planscontent-seven
  ul
  li
  span {
  color: #67687a;
}
.pricing-plans.price-new.pricing-plans-seven .btn {
  display: inline-block;
  padding: 10px 24px;
  background: #28cea2;
}
.pricing-plans.price-new.pricing-plans-seven .pricing-btn {
  text-align: start;
}
.price-toggle.price-toggle-seven {
  color: #28cea2;
}
.price-toggle.price-toggle-seven .toggle-pink .checktoggle {
  background: #fff;
  border: 1px solid #28cea2;
}
.price-toggle.price-toggle-seven .toggle-pink .checktoggle:after {
  background: #28cea2;
}
.pricing-plans.price-new.active-seven {
  background: #28cea2;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 0;
}
.pricing-plans.price-new.pricing-plans-seven.active-seven .price-block {
  text-align: left;
}
.pricing-plans.price-new.pricing-plans-seven.active-seven .price-block span {
  padding: 7px 18px;
  background: #fff;
  border-radius: 13.5px;
  color: #28cea2;
  font-size: 10px;
  margin-bottom: 44px;
}
.pricing-plans.price-new.pricing-plans-seven.active-seven
  .pricing-planshead
  h5 {
  color: #2229c1;
}
.pricing-plans.price-new.pricing-plans-seven.active-seven .pricing-planshead h6,
.pricing-plans.price-new.pricing-plans-seven.active-seven
  .pricing-planshead
  h6
  span {
  color: #fff;
}
.pricing-plans.price-new.pricing-plans-seven.active-seven
  .pricing-planscontent.pricing-planscontent-seven
  ul
  li
  i {
  background: #2229c1;
}
.pricing-plans.price-new.pricing-plans-seven.active-seven
  .pricing-planscontent.pricing-planscontent-seven
  ul
  li
  span {
  color: #fff;
}
.pricing-plans.price-new.pricing-plans-seven.active-seven .pricing-btn .btn {
  background: #2229c1;
}
.app-sec.app-sec-seven {
  background: #f8fcfd;
  border-radius: 50px;
  padding: 0;
}
.appimg-seven {
  background: url(../img/app-seven-bg.png);
  background-repeat: no-repeat;
  background-position: right center;
}
.new-app-seven-middle h2 {
  color: #28cea2;
  max-width: 280px;
}
.new-app-seven-middle p {
  color: #28cea2;
  max-width: 475px;
}
.appimg-seven img {
  margin: 122px 0 0 90px;
}
.new-app-seven-middle {
  padding-left: 117px;
}
.app-seven-section {
  padding: 80px 0;
}
.service-widget.service-seven-blog {
  padding: 15px;
  margin-bottom: 40px;
}
.service-content.service-content-seven {
  padding: 15px 0 0 0;
}
.service-bottom-seven {
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.service-bottom-icons i {
  color: #28cea2;
  font-size: 14px;
}
.service-bottom-icons span {
  color: rgba(107, 107, 107, 0.8);
  font-size: 14px;
}
.service-bottom-seven {
  margin-bottom: 14px;
}
.service-bottom-seven h6 {
  font-size: 12px;
  padding: 6px 14px;
  background: #fff4fa;
  border-radius: 50px;
  color: #58627b;
  margin-bottom: 0;
}
.service-content.service-content-seven .title {
  white-space: normal;
  margin-bottom: 14px;
}
.service-content.service-content-seven p {
  white-space: normal;
  color: #67687a;
  margin-bottom: 14px;
}
.service-content.service-content-seven .usefull-bottom a {
  color: #2229c1;
}
.service-content.service-content-seven .usefull-bottom .useful-img img {
  width: 36px;
  height: 36px;
  margin-right: 5px;
  border-radius: 50%;
}
.service-content.service-content-seven .usefull-bottom .useful-img span {
  color: #28cea2;
}
.service-content.service-content-seven .usefull-bottom .useful-img {
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.our-partners-seven {
  padding: 80px 0;
}
.testimonals-seven-section {
  padding: 80px 0;
}
.testimonials-seven-img img {
  width: 60px !important;
  height: 60px;
  border-radius: 50%;
}
.testimonials-content-seven img {
  width: 57px !important;
  height: 100%;
  margin-right: 16px;
}
.testimonials-img-content .rating {
  margin-bottom: 0;
}
.testimonials-img-content .rating i {
  font-size: 13px;
}
.testimonials-content-seven {
  margin-bottom: 30px;
}
.testimonials-img-content h6 {
  font-size: 20px;
  margin-bottom: 0;
}
.testimonials-seven-img {
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonials-content-seven {
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonials-main-ryt {
  background: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
}

.footer.footer-seven {
  background: #252525;
}
.footer-selects.footer-selects-seven .subscribe-form .form-control {
  height: 48px;
  min-height: 48px;
  background: rgba(255, 255, 255, 0.79);
  border-radius: 30px;
  padding: 6px 102px 6px 19px;
}
.footer-selects.footer-selects-seven
  .subscribe-form
  .form-control::placeholder {
  color: #28cea2;
}
.footer-selects.footer-selects-seven .subscribe-form .footer-btn {
  font-size: 14px;
  padding: 7px 21px;
  background: #2229c1;
  color: #fff;
  border-radius: 30px;
  right: 8px;
}
.footer-widget.footer-widget-seven .footer-content p {
  color: #f0f0f0;
}
.footer-selects.footer-selects-seven .footer-subtitle {
  color: #fff;
}
.footer.footer-seven .footer-title {
  color: #fff;
}
.footer.footer-seven .footer-menu ul li a {
  color: #f0f0f0;
}
.footer.footer-seven .social-icon ul li a {
  background: none;
  color: #2229c1;
}
.footer.footer-seven .social-icon ul {
  margin-bottom: 0;
}
.footer.footer-seven .footer-widget .footer-six-main .footer-six-left img {
  filter: invert(1) brightness(100);
  width: 18px;
  height: 18px;
  margin-right: 0;
}
.footer.footer-seven
  .footer-widget
  .footer-six-main
  .footer-six-left
  .footer-seven-icon {
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 30px;
  background: #2229c1;
  margin-right: 12px;
  flex-shrink: 0;
}
.footer.footer-seven
  .footer-widget
  .footer-six-main
  .footer-six-left
  .footer-six-ryt
  span {
  color: rgba(255, 255, 255, 0.7);
}
.footer.footer-seven
  .footer-widget
  .footer-six-main
  .footer-six-left
  .footer-six-ryt
  h6 {
  color: #fff;
}
.footer-bottom.footer-bottom-seven .copyright .copyright-text p {
  color: #fff;
  margin-bottom: 0;
}
.footer.footer-seven .footer-bottom.footer-bottom-seven .copyright {
  border-top: 1px solid #454545;
}

/*-----------------
    56. Home Eight
-----------------------*/

.home-banner-eight {
  min-height: 625px;
}
.home-banner-eight .section-search {
  max-width: none;
  text-align: center;
  padding-top: 67px;
}
.home-banner-eight .section-search h1 {
  position: relative;
}
.home-eight-bg img {
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 0;
}
.home-eight-dog {
  position: relative;
}
.home-eight-bg-two img {
  position: absolute;
  bottom: 127px;
  left: 100px;
  z-index: 0;
}
.home-eight-dog-feet img {
  position: absolute;
  bottom: 50%;
  left: 14%;
  z-index: -2;
}
.home-banner-eight .section-search h1 span {
  color: #2229c1;
}
.home-banner-eight .section-search p {
  margin-bottom: 30px;
}
.home-banner-eight-icon {
  margin-bottom: 32px;
}
.home-banner-eight-icon ul {
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.home-banner-eight-icon ul li {
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
}
.home-banner-eight-icon ul li i {
  font-size: 20px;
  margin-right: 7px;
  color: #2229c1;
}
.hero-section-eight {
  background: #f9f9f9;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
}
.search-box-two.search-box-eight {
  max-width: 450px;
  margin: auto;
  border-radius: 0;
  padding: 0;
}
.search-box-two.search-box-eight {
  margin-bottom: 30px;
}
.search-box-two.search-box-eight .search-input-new {
  width: 40%;
}
.search-box-two.search-box-eight .search-btn {
  width: 20%;
}
.search-box-two.search-box-eight .search-btn .btn {
  border-radius: 0;
  padding: 9px 10px;
}
.search-box-two.search-box-eight
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  text-align: start;
  color: #6c6c6c;
  padding-left: 10px;
}
.search-box-two.search-box-eight
  .search-input-new.line-six
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #424242;
}
.search-box-two.search-box-eight .search-input-new i {
  left: 15px;
  right: auto;
  color: #424242;
}
.search-box-two.search-box-eight .search-input-new.line-two-six input {
  padding: 0px 10px 0px 40px;
}
.search-box-two.search-box-eight
  .search-input-new.line-two-six
  input::placeholder {
  color: #6c6c6c;
  font-size: 14px;
}
.search-box-two.search-box-eight .search-input-new.line-six {
  position: relative;
}
.search-box-two.search-box-eight .search-input-new.line-six::before {
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  height: 22px;
  background: rgba(42, 57, 109, 0.1);
  content: '';
  z-index: 1;
  margin-top: -10px;
}
.category-sections-eight {
  padding: 80px 0;
}
.category-eight-main {
  text-align: center;
  position: relative;
  margin-bottom: 40px;
}
.category-eight-img img {
  border-radius: 10px;
}
.category-eight-img {
  margin-bottom: 15px;
}
.category-eight-main span {
  padding: 5px 10px;
  background: rgba(42, 57, 109, 0.05);
  border-radius: 10px;
  font-size: 14px;
}
.category-eight-main:hover .category-eight-img-inside {
  transform: scale(1);
}
.category-eight-main:hover h6 {
  color: #ff0080;
}
.category-eight-img {
  position: relative;
  transition: 0.5s all;
}
.category-eight-img::before {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 0, 128, 0.7),
    rgba(255, 0, 128, 0.7)
  );
  opacity: 0;
  transform: scale(0);
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s all;
  border-radius: 10px;
}
.category-eight-main:hover .category-eight-img::before {
  opacity: 1;
  transform: scale(1);
  transition: 0.5s all;
}
.category-eight-main:hover span {
  background: rgba(255, 0, 128, 0.05);
  color: #ff0080;
}
.category-eight-img-inside {
  position: absolute;
  width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 9;

  border-radius: 10px;
  transform: scale(0);
  transition: all 0.5s;
}
.category-eight-img-inside a img {
  width: 41px !important;
  margin: auto;
}
.category-eight-img-inside a {
  color: #fff;
}
.category-eight-slider .owl-nav {
  text-align: center;
}
.category-eight-slider .owl-nav button.owl-prev,
.category-eight-slider .owl-nav button.owl-next {
  background: none !important;
  box-shadow: none;
}
.category-eight-slider .owl-nav button.owl-prev:hover,
.category-eight-slider .owl-nav button.owl-next:hover {
  color: #ff0080;
}
.popular-eight-section {
  padding: 80px 0;
  background: #f9f9f9;
}
.shop-content-logo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 17px;
}
.service-feature-eight {
  margin-top: -40px;
  position: relative;
  z-index: 1;
}
.features-service-five.features-service-eight h6 {
  padding: 5px 10px;
  color: #545454;
  background: #f9f9f9;
}
.service-content.service-feature-eight p i {
  color: #2a396d;
}
.feature-services-eight {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popular-service-eight {
  display: flex;
  align-items: flex-end;
  justify-content: start;
}
.popular-service-eight h6 {
  color: #ff0080;
  font-size: 24px;
  margin-bottom: 0;
}
.popular-service-eight h6 span {
  color: rgba(84, 84, 84, 1);
  font-size: 20px;
  margin-right: 4px;
}
.popular-service-eight p {
  margin-bottom: 0;
  color: #9c9c9c;
  text-decoration: line-through;
}
.feature-services-eight .rate {
  display: flex;
  align-items: center;
}
.rate-icon {
  background: #ffd43b;
  border-radius: 5px;
  padding: 2px 10px;
  color: #fff;
  font-size: 14px;
  margin-right: 3px;
}
.rate-icon i {
  margin-right: 2px;
}
.feature-services-eight .rate h6 {
  margin-bottom: 0;
  font-size: 14px;
  color: #9c9c9c;
  font-weight: 400;
}
.btn-pets .btn-primary {
  font-size: 14px;
}
.works-eight-section {
  padding: 80px 0;
}
.works-eights-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 174px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.works-eights-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
}
.works-eights-main p {
  margin-bottom: 0;
}
.works-eights-arrow {
  position: absolute;
  top: 0;
  left: 160px;
}
.works-eights-arrow.works-eights-arrow-two {
  top: auto;
  bottom: 0;
}
.about-us-eight-section {
  padding: 80px 0;
  background: #f9f9f9;
}
.passion-content-top {
  display: flex;
  align-items: center;
}
.passion-eight-heading {
  margin-bottom: 25px;
}
.passion-eight-all ul {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
}
.passion-eight-all ul li {
  line-height: 44px;
  width: 50%;
}
.passion-eight-content {
  display: inline-block;
  background: #fff;
  border: 1px solid #f1f1f1;
  padding: 10px 20px;
}
.passion-content-top img {
  margin-right: 10px;
}
.passion-content-bottom {
  margin-right: 65px;
}
.passion-content-bottom h2 {
  margin-bottom: 0;
  font-size: 38px;
}
.passion-content-bottom p {
  margin-bottom: 0;
}
.passion-content-top .btn-primary {
  padding: 15px 40px;
  background: #ff0080;
  border: 1px solid #ff0080;
}
.passion-content-top .btn-primary:hover {
  border: 1px solid #2ca384;
}
.about-eight-main {
  position: relative;
}
.truely-eight-bg img {
  position: absolute;
  top: 0;
  right: 50px;
}
.truely-eight-bg-two img {
  position: absolute;
  bottom: 0;
  left: 0;
}
.cat-dog-eight-section {
  padding: 80px 0;
}
.clients-eights-all {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 24px;
}
.clients-eights-all h3 {
  font-size: 48px;
  margin-bottom: 0;
}
.clients-eights-all span {
  color: #ff0080;
  font-size: 40px;
  font-weight: 600;
}
.clients-eight-span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients-eights-all p {
  margin-bottom: 0;
}
.professional-eight-img {
  position: relative;
}
.professional-eight-bg img {
  position: absolute;
  top: 0;
  right: 0;
}
.meet-eight-section {
  padding: 80px 0;
  background: #f9f9f9;
}
.owl-carousel .professional-eight-img-ryt img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.professional-eight-img-inside img {
  width: 41px !important;
  margin: auto;
}
.professional-eight-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}
.professional-eight-img-ryt {
  position: relative;
}
.professional-eight-img-inside {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 0, 128, 0.7),
    rgba(255, 0, 128, 0.7)
  );
  border-radius: 10px;
  transform: scale(0);
  transition: all 0.5s;
}
.professional-eight-img-ryt:hover {
  transform: scale(1);
}
.professional-eight-main h6 {
  font-size: 20px;
  margin-bottom: 15px;
}
.professional-eight-main .act {
  padding: 5px 10px;
  background: rgba(42, 57, 109, 0.05);
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 12px;
}
.professional-eight-main .rating {
  font-size: 14px;
}
.professional-eight-slider .owl-nav {
  text-align: center;
}
.professional-eight-slider .owl-nav button.owl-prev,
.professional-eight-slider .owl-nav button.owl-next {
  background: none !important;
  box-shadow: none;
  margin-bottom: 0;
}
.professional-eight-slider .owl-nav button.owl-prev:hover,
.professional-eight-slider .owl-nav button.owl-next:hover {
  color: #ff0080;
}
.customers-eight-section {
  padding: 80px 0;
}
.customers-eight-main {
  margin-bottom: 40px;
}
.customers-eight-main .testimonials-seven-img img {
  width: 100px !important;
  height: 100px;
  border: 4px solid #fff;
  filter: drop-shadow(0px 4px 24px rgba(205, 205, 205, 0.25));
}
.testimonials-main-ryt.customers-eight-main {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.testimonials-main-ryt.customers-eight-main .testimonials-content-seven {
  margin-bottom: 10px;
}
.testimonials-main-ryt.customers-eight-main .testimonials-img-content {
  margin-bottom: 20px;
}
.testimonials-main-ryt.customers-eight-main p {
  margin-bottom: 0;
}
.customers-eight-heading h2 {
  max-width: 500px;
}
.customers-eight-heading {
  margin-bottom: 20px;
}
.customers-eights-all p {
  max-width: 500px;
}
.customers-icons i {
  color: #ffb800;
}
.customers-icons span {
  color: #6c6c6c;
}
.customers-icons p {
  color: #6c6c6c;
}
.customers-eights-all .total-client-avatar-seven {
  margin-bottom: 15px;
}
.testimonals-eight-slider .owl-nav {
  text-align: end;
}
.testimonals-eight-slider .owl-nav button.owl-prev,
.testimonals-eight-slider .owl-nav button.owl-next {
  background: none !important;
  box-shadow: none;
  margin-bottom: 0;
}
.testimonals-eight-slider .owl-nav button.owl-prev:hover,
.testimonals-eight-slider .owl-nav button.owl-next:hover {
  color: #ff0080;
}
.blog-eight-section {
  padding: 80px 0;
  background: #f9f9f9;
}
.service-widget.service-widget-eight {
  position: relative;
}
.service-eight-img-inside {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 0, 128, 0.7),
    rgba(255, 0, 128, 0.7)
  );
  border-radius: 10px;
  transform: scale(0);
  transition: all 0.5s;
  z-index: 2;
}
.service-widget.service-widget-eight:hover .service-eight-img-inside {
  transform: scale(1);
  z-index: 1;
}
.service-eight-img-inside a img {
  width: 41px !important;
  margin: auto;
}
.service-eight-img-inside a {
  color: #fff;
}
.service-widget-eight .service-img {
  border-radius: 0;
}
.service-content.service-eight-blogs p {
  white-space: normal;
}
.useful-bottom-eight .useful-img img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  border-radius: 50%;
}
.useful-bottom-eight .useful-img span {
  color: #28cea2;
}
.useful-bottom-eight .useful-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.useful-bottom-eight h6 {
  margin-bottom: 0;
  color: #28cea2;
}
.useful-bottom-eight h6 i {
  color: #2229c1;
  margin-right: 6px;
}
.blog-eight-slider .owl-nav {
  text-align: center;
}
.blog-eight-slider .owl-nav button.owl-prev,
.blog-eight-slider .owl-nav button.owl-next {
  background: none !important;
  box-shadow: none;
  margin-bottom: 0;
}
.blog-eight-slider .owl-nav button.owl-prev:hover,
.blog-eight-slider .owl-nav button.owl-next:hover {
  color: #ff0080;
}
.healthy-eight-section {
  padding: 80px 0;
}
.pets-content-all h2 {
  color: #ff0080;
  font-size: 38px;
}
.pets-content-all h1 {
  font-size: 48px;
  margin-bottom: 20px;
}
.pets-content-all p {
  margin-bottom: 30px;
}
.pets-content-all .btn-primary {
  padding: 15px 30px;
  background: #ff0080;
  border-radius: 0;
  border: 1px solid #ff0080;
}
.pets-content-all .btn-primary:hover {
  border-color: #2ca384;
}
.healthy-pets-img {
  position: relative;
}
.healthy-eight-bg img {
  position: absolute;
  top: 0;
  right: 0;
}
.healthy-eight-bg-two img {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.footer.footer-eight {
  background: #f9f9f9;
}
.footer.footer-eight .footer-top {
  padding: 80px 0 70px 0;
}
.footer-menu-eight ul li img {
  margin-right: 10px;
}
.footer.footer-eight .footer-widget .footer-logo {
  margin-bottom: 20px;
}
.footer.footer-eight .social-icon ul {
  margin: 0 0 0px;
}
.footer.footer-eight .footer-contact-info span {
  color: #ff0080;
}
.new-app-eight-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.app-eight-bottom {
  display: flex;
}
.app-eight-bottom-inner {
  margin-right: 7px;
}
.app-eight-content-inner {
  margin-bottom: 10px;
}
.footer.footer-eight .footer-bottom .copyright {
  border-top: 1px solid rgba(156, 156, 156, 0.5);
}
.footer-bottom-eight .copyright .copyright-text p {
  margin-bottom: 0;
}

/*-----------------
    57. Home Nine
-----------------------*/
.hero-section-nine {
  background: rgba(32, 48, 102, 0.05);
  min-height: 700px;
}
.home-banner.home-banner-nine {
  min-height: 620px;
}
.banner-imgs.banner-imgs-nine img {
  border-radius: 50px 50px 50px 0;
}
.banner-imgs.banner-imgs-nine .banner-1 {
  max-width: none;
  max-height: none;
  top: 50px;
}
.banner-imgs.banner-imgs-nine .banner-1::before {
  position: absolute;
  content: '';
  background: url(../img/icons/banner-nine-dot.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  left: -91px;
  bottom: -70px;
  z-index: -1;
}
.banner-imgs.banner-imgs-nine .banners-3 {
  position: absolute;
  z-index: 2;
  bottom: -60px;
  left: 0px;
}
.banner-imgs.banner-imgs-nine .banners-3::before {
  position: absolute;
  content: '';
  background: url(../img/icons/banner-nine-icon-1.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  left: -67px;
  bottom: -130px;
  z-index: -1;
}
.banner-imgs.banner-imgs-nine .banner-4 {
  max-width: none;
  right: 35px;
  bottom: -80px;
}
.banner-imgs.banner-imgs-nine .banner-2 {
  max-width: none;
  top: 90px;
}
.banner-imgs.banner-imgs-nine .banner-2::before {
  position: absolute;
  content: '';
  background: url(../img/icons/banner-nine-icon-2.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  top: -30px;
  left: 17px;
  z-index: -1;
}
.banner-appointment-nine img {
  width: 67px;
  height: 67px;
  border-radius: 50%;
  margin-right: 15px;
}
.section-search-nine h6 {
  color: #2229c1;
}
.arrow-ryt-all {
  position: relative;
}
.arrow-ryt img {
  position: absolute;
  top: 0;
  right: 0;
}
.section-search-nine p {
  margin-bottom: 20px;
}
.appoints-btn {
  padding: 8px 16px;
  background: #2229c1;
  border: 1px solid #2229c1;
  border-radius: 0;
  margin-bottom: 60px;
}
.banner-appointment-nine {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.banner-appointment-content p {
  margin-bottom: 0;
  font-weight: 400;
}
.banner-appointment-content h5 {
  margin-bottom: 0;
}
.safety-section {
  padding: 80px 0;
}
.section-heading-nine p {
  color: #2229c1;
}
.section-heading-nine.safety-heading-nine h2 {
  position: relative;
  max-width: 400px;
  margin-bottom: 0;
}
.section-heading-nine h2 {
  position: relative;
  display: inline-block;
}
.section-heading-nine h2::after {
  position: absolute;
  content: '';
  width: 12px;
  height: 9px;
  background: #2229c1;
  transform: skew(-14deg);
  bottom: 12px;
  right: -20px;
}
.safety-heading-nine h2::after,
.journey-heading-nine h2::after {
  display: none;
}
.safety-para {
  position: relative;
  text-transform: capitalize;
  padding-left: 24px;
  line-height: 31px;
  margin-bottom: 30px;
}
.safety-para::before {
  position: absolute;
  content: '';
  width: 5px;
  height: 55px;
  top: 0;
  left: 0;
  background: #2229c1;
}
.safety-ryt-main img {
  border-radius: 50px 50px 0 50px;
  margin: 50px 0 33px 0;
}
.safety-ryt-main ul li {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.safety-ryt-main ul li:last-child {
  margin-bottom: 0;
}
.safety-ryt-main ul li i {
  color: #2229c1;
  font-size: 17px;
  background: rgba(255, 0, 138, 0.1);
  padding: 9px 7px;
  margin-right: 10px;
}
.safety-ryt-two img {
  margin: 0 0 60px 0;
  border-radius: 50px 50px 50px 0;
}
.safety-ryt-main.safety-ryt-two {
  position: relative;
}
.safety-ryt-main.safety-ryt-two::after {
  position: absolute;
  content: '';
  background: #2229c1;
  width: 62.15px;
  height: 23px;
  border-radius: 30px 30px 30px 0px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  top: 56%;
}
.service-nine-section {
  padding: 80px 0;
  background: #fff9f9;
}
.service-feature-nine {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  margin-top: -58px;
  z-index: 1;
  position: relative;
}
.service-feature-nine .shop-content-logo {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: 60px;
  height: 60px;
  background: #28cea2;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
  border-radius: 41px;
  margin-bottom: 20px;
}
.service-feature-nine .shop-content-logo img {
  margin-bottom: 0;
  border-radius: 0;
  width: auto;
  height: auto;
}
.service-feature-nine span {
  color: #2229c1;
  text-transform: capitalize;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 10px;
}
.service-feature-nine p {
  white-space: normal;
  text-align: center;
  color: #28283c;
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 18px;
}
.service-widget-nine .service-img,
.service-widget-nine .service-img .serv-img {
  border-radius: 0;
}
.service-nine-slider .owl-dots .owl-dot span {
  width: 30px;
  height: 7px;
  border-radius: 0;
}
.btn-service-nine .btn-primary {
  margin-top: 40px;
  background-color: #2229c1;
  padding: 8px 16px;
  border: 1px solid #ff0080;
  border-radius: 0;
}
.btn-service-nine .btn-primary:hover {
  border: 1px solid #2ca384;
}
.feature-service-nine {
  padding: 80px 0;
}
.items-nine {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: start;
  -webkit-justify-content: start;
}
.items-nine img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.items-nine .rating {
  margin-bottom: 0;
}
.items-nine .rating span {
  color: #fff;
}
.service-content-nine .sub-title {
  color: #2229c1;
  margin-bottom: 10px;
  display: inline-block;
}
.service-content-nine .saloon-right span {
  font-weight: 700;
}
.service-bottom-nine .btn-primary {
  padding: 8px 16px;
  background-color: #fff;
  border: 1px solid #28cea2;
  border-radius: 0;
  color: #28cea2;
  width: 100%;
  text-align: center;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.service-bottom-nine .btn-primary:hover {
  background: #2229c1;
  border: 1px solid #2229c1;
  box-shadow: inset 0 70px 0 0 #2229c1;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.service-widget.service-widget-nine {
  border-radius: 0;
}
.journey-nine-section {
  position: relative;
  padding: 60px 0;
  background: url(../img/journey-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  background-size: cover;
}
.journey-nine-section::before {
  content: '';
  background: rgba(32, 48, 102, 0.83);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -6;
}
.journey-heading-nine {
  margin-bottom: 0;
}
.journey-heading-nine h2 {
  color: #fff;
  max-width: 300px;
  margin-bottom: 0;
}
.journey-client-main {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  text-align: center;
}
.journey-client-main h2 {
  color: #fff;
  font-size: 48px;
  margin-bottom: 0;
}
.journey-client-counter {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}
.journey-client-main span {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}
.journey-client-main h5 {
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
}
.choose-nine-section {
  padding: 80px 0 56px 0;
  background: #fff9f9;
}
.reasonable-all {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  padding: 40px;
  background: #28cea2;
  margin-bottom: 24px;
}
.reasonable-all img {
  margin-bottom: 20px;
}
.reasonable-all h5 {
  color: #fff;
  text-transform: capitalize;
  text-align: center;
}
.reasonable-all p {
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  text-transform: capitalize;
}
.expert-all {
  background: #2229c1;
}
.delivery-all {
  background: #1c1c1c;
}
.workshop-all {
  background: rgba(32, 48, 102, 0.2);
}
.workshop-all h5,
.workshop-all p {
  color: #28cea2;
  text-align: center;
}
.mechanics-section {
  padding: 80px 0;
}
.mechanics-section .nav-pills {
  justify-content: center;
  margin: auto;
}
.mechanics-section .nav-pills .nav-link {
  border-radius: 0;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #e7e7e7;
  color: #28cea2;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #28cea2;
  color: #fff;
}
.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link:hover {
  background: #28cea2;
}
.providerset.providerset-nine {
  background: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
  border-radius: 0;
}
.providerset-nine .providerset-content {
  text-align: center;
}
.providerset-nine .providerset-content h6 {
  color: #2229c1;
  font-size: 14px;
  font-weight: 400;
}
.providerset-nine .providerset-content h4 a {
  font-size: 20px;
}
.provider-rating-nine .rating i {
  font-size: 14px;
}
.provider-nine-slider .owl-dots .owl-dot span {
  width: 30px;
  height: 7px;
  border-radius: 0;
}
.customer-review-section {
  padding: 80px 0;
  background: #fff9f9;
}
.customer-reviews-all {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
}
.customer-reviews-main {
  position: relative;
}
.customer-reviews-main img {
  border-radius: 50px 50px 50px 0;
}
.customer-quote img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 66px !important;
}
.customer-reviews-main {
  margin-bottom: 20px;
}
.customer-reviews-all span {
  margin-bottom: 20px;
}
.customer-reviews-all {
  position: relative;
  max-width: 724px;
  margin: auto;
  text-align: center;
}
.customer-side-main-all {
  position: relative;
}
.customer-side-left-img img {
  position: absolute;
  content: '';
  left: 8%;
  top: 150px;
}
.customer-side-right-img img {
  position: absolute;
  content: '';
  right: 8%;
  top: 150px;
}
.customer-review-slider .owl-dots .owl-dot span {
  width: 30px;
  height: 7px;
  border-radius: 0;
}
.additional-service-section {
  padding: 80px 0;
}
.service-widget-nine-two .service-feature-nine {
  display: block;
  margin-top: 0;
}
.service-widget-nine-two .service-content .title {
  white-space: normal;
  margin-bottom: 0;
}
.items-nine-two img {
  width: 70px !important;
  height: 70px;
}
.blogs-nine-section {
  padding: 80px 0;
  background: #fff9f9;
}
.blogs-nine-section .blog {
  border-radius: 0;
  padding: 0;
}
.blogs-nine-section .blog.blog-new .blog-image {
  border-radius: 0;
}
.blogs-nine-section .blog.blog-new .blog-image img {
  border-radius: 0;
}
.blogs-nine-section .blog-content {
  padding: 20px;
}
.blogs-nine-section .blog.blog-new .blog-content p {
  margin-bottom: 1rem;
}
.blogs-nine-section .blog.blog-new .blog-image .date {
  background-color: #ff0080;
  color: #fff;
}
.useful-bottom-nine {
  margin-bottom: 20px;
}
.service-blog-nine h5 {
  color: #28cea2;
  font-size: 18px;
}
.useful-bottom-eight.useful-bottom-nine h6 {
  font-weight: 400;
}
.service-blog-nine p {
  white-space: normal;
}
.latest-date.latest-date-nine {
  padding: 10px 23px;
}
.latest-date.latest-date-nine span {
  border-bottom: 1px solid #d9d9d9;
}
.blogs-nine-slider .owl-dots .owl-dot span {
  width: 30px;
  height: 7px;
  border-radius: 0;
}
.free-service-all {
  background: #28cea2;
  z-index: 1;
  position: relative;
  margin-top: 80px;
}
.free-service-nine {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px;
}
.free-service-img-one img {
  border-radius: 50px 50px 0 50px;
  margin-right: 10px;
}
.free-service-img-two img {
  margin-top: -60px;
  border-radius: 50px 50px 50px 0;
}
.free-service-bottom-content {
  padding: 76px 58px 76px 0;
}
.free-heading-nine {
  margin-bottom: 0;
}
.free-service-bottom-content p {
  color: rgba(255, 255, 255, 0.7);
}
.free-service-bottom-content .btn-primary {
  padding: 8px 16px;
  background: #2229c1;
  border: 1px solid #2229c1;
  font-weight: 600;
}
.free-service-bottom-content .btn-primary:hover {
  border: 1px solid #2ca384;
  transition: 0.7s;
}
.free-heading-nine p {
  color: #2229c1;
}
.free-heading-nine h2 {
  color: #fff;
}
.footer-nine .footer-top {
  background-color: #fff9f9;
}

.footer-nine p a:hover {
  color: #2229c1;
}
.footer.footer-nine .footer-widget.footer-menu ul li a:hover {
  color: #2229c1;
}
.footer-nine .footer-contact-info .footer-address p {
  padding-left: 16px;
  font-weight: 700;
  font-size: 16px;
}
.footer-nine .footer-contact-info h6 {
  padding-left: 16px;
  font-size: 12px;
  color: rgba(32, 48, 102, 0.7);
}
.footer-nine .footer-contact-info .footer-address span {
  position: relative;
  font-size: 24px;
}
.footer-nine .footer-contact-info .footer-address:last-child p {
  margin-bottom: 0;
}
.footer-nine .footer-tags {
  display: flex;
  flex-wrap: wrap;
}
.footer-nine .footer-menu .footer-tags li a {
  font-weight: 400;
  padding: 6px 10px;
  margin-bottom: 0;
  border: 1px solid #e7e7e7;
  margin-right: 0;
  font-size: 14px;
  transition: 0.5s;
}
.footer-nine .footer-widget .read-more {
  color: #2229c1;
}
.footer-nine .footer-address i {
  color: #2229c1;
}
.footer-nine .social-media-icon-img i {
  color: #2229c1;
  font-size: 18px;
}
.footer-nine .footer-menu .footer-tags li a:hover {
  color: #fff !important;
  background: #2229c1;
  transition: 0.5s;
}
.footer .footer-menu .footer-tags li:last-child {
  margin-bottom: 9px;
}
.social-media-icon-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding-top: 40px;
}
.social-media-icon-img img {
  width: 20px;
  height: 20px;
}
.service-section-nine .offer-paths {
  background-color: #28cea2;
  border-radius: 0;
}
.offer-sec .section-heading-two.section-heading-nine p {
  color: #2229c1;
}
.offer-paths.service-path::after {
  background: none;
  content: none;
}
.section-heading-two.section-heading-nine::before {
  background: none;
  content: none;
}
.offer-paths.service-path.offer-sec {
  align-items: center;
}
.offer-paths.service-path.offer-sec .service-img-ten {
  border-radius: 50px 50px 50px 0;
}
.offer-paths.service-path.offer-sec .service-img-nine {
  border-radius: 50px 50px 0 50px;
  margin-right: 20px;
  margin-top: 50px;
}
.offer-sec .offer-path-content.service-path-content {
  padding-left: 50px;
}
.partner-section-nine {
  margin-top: -250px;
  padding: 230px 0 56px 0;
  background: #f4eff2;
}
/*-----------------
	58. Provider Subscription
-----------------------*/

.provider-price .price-card {
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  border: 2px solid #f5f5f5;
}
.provider-price .price-card .btn-choose {
  background: #aaadf9;
  color: #fff;
  width: 100%;
  justify-content: center;
}
.provider-price .price-card.active {
  background: #f7f7ff;
  border-radius: 8px;
}
.provider-price .price-card.active:hover {
  background: #28cea2;
}
.provider-price .price-card.active:hover .btn-choose {
  background: #aaadf9;
}
.provider-price .price-card.active .btn-choose {
  background: #28cea2;
}
.subs-list {
  border-radius: 8px;
  margin-bottom: 24px;
}
.subs-list li {
  display: inline-block;
  margin-right: -4px;
}
.subs-list li a.active {
  background: #fff;
}
.subs-list li a {
  display: inline-block;
  background: #f3f4f6;
  border: 4px solid #f3f4f6;
  font-weight: 500;
  font-size: 14px;
  color: #1f2937;
  padding: 10px 20px;
}
.choose-title {
  margin-bottom: 1.5rem;
}
.choose-title h6 {
  font-weight: 600;
  font-size: 20px;
  margin: 0 30px 10px 0;
  flex: 0 0 100%;
}
.choose-title .status-toggle {
  margin-bottom: 10px;
  font-weight: 500;
}
.choose-title .status-tog .checktoggle {
  margin: 0 16px;
}
.addon-box {
  background: #fff;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  padding: 24px 24px 14px;
  margin-bottom: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.addon-box:last-child {
  margin: 0;
}
.addon-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.addon-name h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
}
.addon-name p {
  font-size: 14px;
  margin-bottom: 10px;
}
.addon-name .custom_check {
  margin-bottom: 25px;
}
.addon-info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
}
.addon-info ul li {
  margin: 0 24px 10px 0;
}
.addon-info ul li:last-child {
  margin-right: 0;
}
.addon-info ul li h6 {
  font-size: 20px;
  margin: 0;
}
.addon-info .btn {
  font-weight: 500;
  padding: 5px 15px;
  white-space: nowrap;
}
.addon-info .select2-container--default .select2-selection--single {
  min-width: 161px;
}

/*-----------------
	59. Footer
-----------------------*/

.footer {
  background-color: #fff;
  border-top: 1px solid rgba(205, 205, 205, 0.2);
  text-align: left;
  font-size: 16px;
  margin: 0;
  color: #74788d;
}
.footer-content {
  max-width: 304px;
}
.footer-content p {
  color: #74788d;
  margin-bottom: 15px;
}
.footer .footer-top {
  padding: 50px 0;
}
.footer-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.footer-subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.footer .footer-widget.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
}
.footer .footer-widget .footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-height: 35px;
}
.footer .footer-menu ul li {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  margin-right: 0;
}
.footer .footer-menu ul li:last-child {
  margin-bottom: 0;
}
.footer .footer-menu ul li a {
  color: #74788d;
}
.footer .footer-widget.footer-menu ul li a:hover {
  color: #28cea2;
}
.footer-contact-info .footer-address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-contact-info p {
  color: #74788d;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.footer-contact-info span {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 17px;
  color: #28cea2;
}
.subscribe-form {
  position: relative;
}
.subscribe-form .form-control {
  min-height: 42px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 6px 50px 6px 15px;
}
.subscribe-form .form-control::placeholder {
  color: #74788d;
}
.subscribe-form .footer-btn {
  font-size: 20px;
  color: #68616d;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  padding: 0;
  display: inline-flex;
}
.subscribe-form .footer-btn i:hover {
  color: #28cea2;
}
.footer .footer-bottom .copyright {
  border-top: 1px solid #f5f5f5;
  padding: 30px 0;
}
.footer .footer-bottom .copyright-text p a {
  color: #28cea2;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.footer .footer-bottom .copyright-text p a:hover {
  color: #28cea2;
}
.footer .footer-bottom .copyright-text p.title {
  font-weight: 400;
  margin: 10px 0 0;
}
.footer .social-icon ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 0 0 35px;
}
.footer .social-icon ul li {
  margin-right: 10px;
}
.footer .social-icon ul li:last-child {
  margin-right: 0;
}
.footer .social-icon ul li a {
  color: #74788d;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  transition: all 0.4s ease 0s;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
}
.footer .social-icon ul li a:hover {
  background-color: #28cea2;
  color: #fff;
}
.footer .footer-bottom .copyright-text p {
  color: #74788d;
}
.policy-menu {
  margin: 0;
  padding: 0;
  text-align: right;
}
.policy-menu li {
  display: inline-block;
  color: #74788d;
  margin-right: 15px;
}
.footer-select {
  position: relative;
  width: 108px;
}
.footer-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 14px;
  color: #d9d9d9;
}
.select2-results__option {
  font-size: 14px;
}
.footer-select .select2-container--default .select2-selection--single {
  border: 1px solid #d9d9d9;
  height: 40px;
}
.footer-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: rgba(0, 0, 0, 0.5);
  line-height: 40px;
}
.footer-select:last-child {
  margin-left: 20px;
}
.footer-select > img {
  position: absolute;
  z-index: 9;
  right: 10px;
  top: 10px;
}
.footer-select > img.footer-dropdown {
  top: 18px;
}
.footer-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 47px;
  right: 6px;
  display: none;
}
.policy-menu li:last-child {
  margin-right: 0;
}
.policy-menu li a {
  color: #74788d;
}
.policy-menu li a:hover,
.policy-menu li a:focus {
  color: #28cea2;
}
.payment-image ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-image ul li:not(:last-child) {
  margin-right: 11px;
}
.policy-menu li::after {
  color: #74788d;
  content: '|';
  font-weight: 300;
  position: relative;
  left: 10px;
}
.policy-menu li:last-child::after {
  content: '';
}
.footer-two {
  background: #fff4fa;
}
.footer-two .footer-contact-info .footer-address {
  position: relative;
}
.footer-two .footer-contact-info .footer-address:last-child p {
  margin: 0;
}
.footer-two .footer-contact-info p {
  color: #28cea2;
  font-size: 16px;
  font-weight: 700;
  padding-left: 37px;
}
.footer-two .footer-contact-info span {
  font-size: 25px;
  color: #b4b7c9;
}
.footer-contact-info h6 {
  color: #58627b;
  font-weight: 500;
  position: relative;
  padding-left: 39px;
  margin-bottom: 2px;
}
.footer-two .social-icon {
  text-align: center;
}
.footer-two .social-icon ul {
  margin: 0;
}
.footer-two .social-icon ul li a {
  background: rgba(212, 216, 229, 0.26);
  color: #b4b7c9;
}
.footer-two .footer-bottom .copyright-text p {
  color: #58627b;
  text-align: right;
}
.footer-two .payment-image ul {
  justify-content: start;
}
.insta-row {
  margin: 0;
}
.insta-row li {
  padding: 0 4px 8px;
}
.footer-two .footer-widget.footer-menu ul li a:hover {
  color: #2229c1;
}
.footer-two .social-icon ul li a:hover {
  background: #2229c1;
}
.footer-menu li a {
  color: #151519;
}
.footer-menu li {
  display: inline-block;
  color: #151519;
  margin-right: 15px;
}
.footer-title-three {
  color: #2a396d;
}
.footer-menu-three {
  text-align: left;
}
.footer-three .policy-menu li a:hover,
.footer-three .policy-menu li a:focus {
  color: #ff0080;
}
.footer .footer-menu-three ul li a {
  margin-left: 20px;
}
.footer .footer-menu-three ul li {
  position: relative;
  display: flex;
  align-items: center;
}
.footer .footer-menu-three ul li::before {
  content: '';
  background-color: #2229c1;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
}
.footer-subtitle-three {
  margin-bottom: 6px;
  color: #2a396d;
  font-weight: 500;
}
.footer .footer-widget.footer-menu.footer-menu-three ul li a:hover {
  color: #ff0080;
}
.footer-selects .footer-expert p {
  display: flex;
  align-items: center;
}
.footer-selects .footer-expert p i {
  font-size: 20px;
  margin-right: 14px;
}
.footer.footer-four .footer-widget.footer-widget-four .footer-logo {
  margin-bottom: 24px;
}
.footer.footer-four {
  background-color: #f9f9f9;
}
.footer-subtitle.footer-subtitle-four {
  font-size: 18px;
  color: #101522;
}
.footer-top.footer-top-four .subscribe-form .form-control {
  margin-bottom: 24px;
  height: 56px;
  min-height: 56px;
  border: none;
  padding-right: 70px;
}
.footer-top.footer-top-four .subscribe-form .footer-btn {
  background-color: #4c40ed;
  padding: 8px 10px;
  color: #fff;
}
.footer-top.footer-top-four .subscribe-form .footer-btn i:hover {
  color: #fff;
}
.footer.footer-four .social-icon ul li a {
  background-color: #fff;
  color: #4c40ed;
}
.footer.footer-four .social-icon ul li a:hover {
  background-color: #4c40ed;
  color: #fff;
}
.policy-menu.policy-menu-four li::after {
  content: '';
  left: 0;
  color: transparent;
}
.footer .social-icon.social-icon-four ul {
  margin: 0 0 0;
}
.footer .footer-bottom .copyright .copyright-four {
  border: 1px solid #ebebeb;
}
.footer .footer-top.footer-top-four {
  padding: 50px 0 30px 0;
}

.footer.footer-six {
  background: url(../img/oru-company-bg.png);
  background-repeat: no-repeat;
  padding-top: 80px;
  border: none;
  position: relative;
}
.footer-top-content img {
  position: absolute;
  top: 0;
  z-index: -1;
}
.footer.footer-six .footer-top {
  padding: 0;
}
.footer-widget.footer-wid-six .footer-content {
  max-width: none;
}
.footer-widget.footer-wid-six .footer-content p {
  margin-bottom: 30px;
}
.footer-widget.footer-wid-six .footer-content p a {
  color: #2229c1;
  text-decoration: underline;
}
.footer-widget.footer-wid-six .footer-content p a:hover {
  color: #28cea2;
}
.footer-six-left {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.footer-six-left img {
  margin-right: 15px;
}
.footer-six-ryt span {
  font-size: 14px;
  color: #74788d;
}
.footer-six-ryt h6 {
  color: #28cea2;
  margin-bottom: 0;
}
.footer.footer-six .footer-menu ul li {
  margin-bottom: 15px;
  color: #74788d;
}
.footer-btns {
  color: #2229c1 !important;
  text-decoration: underline;
}
.footer-btns:hover {
  color: #28cea2 !important;
  text-decoration: underline;
}
.footer-bottom.footer-bottom-six .copyright-menu ul {
  display: flex;
}
.footer-bottom.footer-bottom-six .copyright-menu ul li {
  padding-right: 10px;
}
.footer-bottom.footer-bottom-six .copyright-menu ul li a {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(32, 48, 102, 0.6);
  color: #dadada;
  font-size: 12px;
}
.footer-bottom.footer-bottom-six .copyright-menu ul li a:hover {
  background: #2229c1;
  color: #fff;
}
.footer-widget.footer-menu.footer-menu-six {
  margin-top: 50px;
}

/*-----------------
	60. Add Services
-----------------------*/

.add-service {
  padding: 24px;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  border: 0;
}
.add-service .sub-title h6 {
  padding-bottom: 24px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 20px;
}
.add-service .col-form-label {
  padding: 0 0 10px;
}
.add-service .add-service-toggle {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e1e1e1;
}
.add-service .add-service-toggle .sub-title h6 {
  margin: 0;
  padding: 0;
  border: 0;
}
#progressbar li.active.activated span {
  background: #333;
}
.field-bottom-btns {
  display: flex;
  justify-content: center;
}
.field-bottom-btns button.btn {
  padding: 9px 15px;
  display: inline-flex;
  align-items: center;
  min-width: 78px;
  justify-content: center;
}
.field-bottom-btns .prev_btn {
  background: #333;
  border-color: #333;
  margin-right: 10px;
}
.field-bottom-btns .prev_btn:hover {
  border-color: #2ca384;
}
.field-bottom-btns .prev_btn i {
  margin-right: 5px;
}
.field-bottom-btns .next_btn i {
  margin-left: 5px;
}
.add-service .file-preview ul {
  display: flex;
}
.add-service .file-preview ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.field-bottom-btns .done_btn {
  padding: 9px 15px;
  display: inline-flex;
  align-items: center;
  width: 78px;
}
.field-bottom-btns .done_btn i {
  margin-left: 5px;
}
.add-service .file-preview ul li .img-preview {
  margin-bottom: 15px;
}
.add-service .file-preview ul li .img-preview a {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #ff0101;
  border-radius: 5px;
}
.add-service .file-preview ul li .custom_check .checkmark {
  position: static;
  display: inline-flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}
.add-service .file-preview ul li .custom_check {
  padding: 0;
}
.add-service .file-preview ul li .custom_check .checkmark::after {
  position: static;
  display: inline-flex;
  transform: none;
  font-size: 15px;
}
.add-service-modal .modal-content {
  padding: 40px;
  text-align: center;
}
.add-service-modal .modal-content .modal-body {
  padding: 0;
}
.add-service-modal .modal-content .modal-body span {
  margin-bottom: 10px;
  display: block;
}
.add-service-modal .modal-content .modal-body span i {
  font-size: 40px;
  color: #6dcc76;
}
.add-service-modal .modal-content .modal-body h3 {
  font-size: 28px;
  margin-bottom: 15px;
}
.add-service-modal .modal-content .modal-body p {
  margin-bottom: 15px;
}
.booking-success-modal p {
  max-width: 250px;
  margin: 0 auto 15px auto;
}
.add-service-modal .modal-content .modal-body .popup-btn a {
  padding: 9px 15px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}
.add-service-modal .modal-content .modal-body .popup-btn a i {
  margin-left: 5px;
}
.add-service-modal.show::after {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: 0.5s all;
}
.booking-action.action-popup-btn {
  flex-direction: column;
  align-items: flex-end;
}
.booking-action.action-popup-btn a {
  min-width: 132px;
  padding: 9px 15px;
  justify-content: center;
  margin: 0;
}
.booking-action.action-popup-btn a i {
  margin-right: 5px;
}
.booking-action.action-popup-btn a + a {
  margin-top: 10px;
}
.booking-details li span.badge-grey.badge-new {
  background: #e8e8e8;
  color: #333;
  margin-left: 7px;
}
.reshchedule-modal .modal-dialog {
  max-width: 1295px;
}
.reshchedule-modal.custom-modal .modal-body {
  padding: 0;
}
.modal-active-dots ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.modal-active-dots {
  padding: 24px 0;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
}
.booking-two {
  margin: 60px 0;
}
.modal-active-dots .active-dot {
  width: 12px;
  height: 12px;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  margin-right: 12px;
  position: relative;
}
.modal-active-dots .active-dot.active::after {
  content: '';
  width: 7px;
  height: 7px;
  background: #28cea2;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  transform: translate(-50%, -50%);
}
.modal-active-dots .active-dot.activated::after {
  display: none;
}
.modal-active-dots .active-dot.activated {
  border-color: #28cea2;
  background-color: #28cea2;
}
.modal-active-dots ul .active-dot:last-child {
  margin-right: 0;
}
.calender-modal {
  margin: 145px 0;
}
.calender-modal img {
  margin-bottom: 33px;
}
.calender-modal p {
  max-width: 200px;
  margin: auto;
}
.call-help h6 {
  font-size: 14px;
  color: #333;
  margin-bottom: 0;
}
.call-help h6 span {
  font-size: 14px;
  font-weight: 400;
  display: block;
  margin-top: 10px;
}
.rechedule-calender {
  background: #fafafa;
  padding: 24px;
  display: flex;
  flex-direction: column;
}
.rechedule-calender .book-title {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.rechedule-calender .book-title h5 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
}
.rechedule-calender
  #datetimepickershow
  .bootstrap-datetimepicker-widget
  table
  td.day {
  width: 50px;
  height: 40px;
  line-height: normal;
  border-radius: 0;
}
.rechedule-calender
  #datetimepickershow
  .bootstrap-datetimepicker-widget
  table
  td.active {
  width: 50px;
  height: 40px;
  border-radius: 0;
}
.rechedule-calender .bootstrap-datetimepicker-widget table td.active,
.rechedule-calender .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #05a144;
  border-radius: 0;
}
.rechedule-calender
  .bootstrap-datetimepicker-widget
  table
  td.active.today:before {
  display: none;
}
.rechedule-calender .bootstrap-datetimepicker-widget table td.today:before {
  display: none;
}
.rechedule-calender .bootstrap-datetimepicker-widget table th {
  height: 35px;
}
.rechedule-calender
  #datetimepickershow
  .bootstrap-datetimepicker-widget
  table
  th.prev {
  margin: 0;
}
.rechedule-calender
  #datetimepickershow
  .bootstrap-datetimepicker-widget
  table
  th.next {
  margin: 0;
}
.rechedule-calender .bootstrap-datetimepicker-widget table th.picker-switch {
  vertical-align: top;
}
.pick-slot {
  margin: 24px 0 0;
}
.pick-slot h6 {
  text-align: center;
  color: #858585;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}
.pick-slot h6 span {
  color: #333;
}
.pick-slot .token-slot {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.pick-slot .visits span.visit-rsn {
  background: #fff;
  color: #858585;
  border: 0;
  border-radius: 0;
}
.pick-slot .visit-btns {
  margin-bottom: 0;
}
.pick-slot .visits input:checked ~ .visit-rsn,
.pick-slot .visits .visit-rsn:hover {
  background: #05a144;
  color: #fff;
  border-radius: 0;
}
.pick-slot .token-slot .visits {
  width: auto;
  padding: 0;
  margin: 0 !important;
}
.pick-slot .token-slot .visits span.visit-rsn {
  width: 80px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.pick-slot .visits input:checked ~ .visit-rsn::after {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  background: #05a144;
  border-radius: 50%;
  top: 0;
  right: 0;
  z-index: 1;
  top: -10px;
  right: -10px;
}
.pick-slot .visits input:checked ~ .visit-rsn::before {
  content: '\f00c';
  color: #fff;
  font-size: 12px;
  position: absolute;
  font-family: 'fontawesome';
  top: -7px;
  right: -4px;
  z-index: 2;
}
.booking-summary-card {
  border: 0;
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  width: 100%;
  margin-bottom: 0;
}
.booking-summary-card .card-body {
  padding: 24px;
}
.booking-summary-card .sub-title h5 {
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.appointment-details ul li {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
}
.appointment-details ul li:last-child {
  margin: 0;
}
.appointment-details ul li .detail-list h5 {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  font-weight: 500;
}
.appointment-details ul li .detail-list h6 {
  font-size: 14px;
  color: #858585;
  font-weight: 400;
  margin: 0;
}
.appointment-details ul li .detail-list h6.date-red {
  color: #28cea2;
}
.booking-summary-card .card-footer {
  padding: 24px;
  border-top: 1px solid #e1e1e1;
}
.booking-summary-card .card-footer li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booking-summary-card .card-footer li h6 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.booking-summary-card .card-footer li.total-amount h6 {
  font-size: 20px;
}
.booking-summary-card .card-footer li span {
  font-size: 16px;
  color: #858585;
}
.booking-summary-card .card-footer li.total-amount span {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}
.booking-summary-card .card-footer li:last-child {
  margin-bottom: 0;
}
.booking-info-tab {
  border: 0;
  padding: 24px;
  margin-bottom: 0;
}
.booking-info-tab .nav-pills {
  border-bottom: 1px solid #e1e1e1;
}
.booking-info-tab .nav-pills .nav-link {
  border: 0;
  color: #333;
  padding: 0 0 5px;
  margin: 0;
  background: transparent;
  border-radius: 0;
  position: relative;
  transition: 0.5s all;
  font-size: 16px;
}
.booking-info-tab .nav-pills .nav-link.active {
  color: #28cea2;
  transition: 0.5s all;
}
.booking-info-tab .nav-pills .nav-item + .nav-item {
  margin-left: 40px;
}
.guest-country .select2-container--default .select2-selection--single {
  height: 42px;
}
.booking-info-tab .nav-pills .nav-link.active:hover,
.booking-info-tab .nav-pills .show > .nav-link:hover {
  background: transparent;
  color: #28cea2;
}
.booking-info-tab .nav-pills .nav-link.active::after {
  content: '';
  width: 100%;
  height: 3px;
  background: #28cea2;
  position: absolute;
  bottom: -2px;
  left: 0;
  transition: 0.5s all;
}
.booking-info-tab .tab-content .sub-title h5 {
  margin-bottom: 20px;
}
.booking-info-tab .tab-content .form-group {
  margin-bottom: 10px;
}
.booking-info-tab .tab-content .guest-user-tab .form-group {
  margin-bottom: 20px;
}
.get-new-password p {
  font-size: 14px;
  color: #858585;
  margin-bottom: 20px;
}
.get-new-password p a {
  color: #333;
}
.main-booking-form {
  flex-direction: column;
}
.guest-user-tab .join-user {
  text-align: center;
  margin-top: 4px;
}
.guest-user-tab .join-user a {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  background: #fafafa;
  padding: 10px;
  color: #333;
}
.booking-info-tab .payment-card.payment-bg {
  border-color: #28cea2;
  background: #f7f7ff;
}
.booking-info-tab .payment-card .custom_radio {
  padding: 0;
}
.booking-info-tab .payment-card {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}
.booking-info-tab .payment-card h6 {
  color: #858585;
  font-size: 14px;
}
.booking-info-tab .payment-card.payment-bg h6 {
  color: #28cea2;
}
.field-bottom-btns .next_btn.submit-btn {
  min-width: 98px;
}
.booking-info-tab .sub-title h5 {
  font-size: 16px;
  font-weight: 500;
}
.booking-info-tab .payment-card.save-cards .custom_radio {
  padding-left: 27px;
}
.booking-info-tab .payment-card.save-cards img {
  margin: 0 10px 0 4px;
}
.payment-card.save-cards .payment-title .custom_radio .checkmark {
  width: 24px;
  height: 24px;
  border-radius: 0;
}
.payment-card.save-cards .custom_radio input:checked ~ .checkmark {
  background: #28cea2;
  border-color: #28cea2;
  border-radius: 5px;
}
.payment-card.save-cards .custom_radio input:checked ~ .checkmark::after {
  content: '\f00c';
  color: #fff;
  font-size: 16px;
  position: absolute;
  font-family: 'fontawesome';
  top: -1px;
  left: 4px;
  z-index: 2;
  width: 0;
  height: 0;
  border: 0;
}
.booking-info-tab .payment-card.payment-bg.save-cards {
  border-color: #e1e1e1;
  background: #fff;
}
.booking-info-tab .payment-card.payment-bg.save-cards h6 {
  color: #858585;
}
.secure-transaction {
  background: #fafafa;
  display: flex;
  align-items: center;
  padding: 24px;
}
.booking-info-tab .add-more-card {
  margin-bottom: 24px;
  margin-top: 24px;
}
.booking-info-tab .add-more-cards {
  margin-bottom: 24px;
  margin-top: 24px;
}
.secure-transaction span {
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  line-height: 0;
  margin-right: 10px;
}
.secure-transaction p {
  font-size: 14px;
  color: #858585;
  margin-bottom: 0;
}
.booking-info-tab .total-price h5 {
  padding: 10px;
  border-radius: 10px;
  background: rgba(109, 204, 118, 0.1);
  display: flex;
  justify-content: center;
  color: #05a144;
  font-weight: 400;
  font-size: 14px;
  margin: 20px 0 0;
}
.save-later .custom_check .checkmark {
  width: 17px;
  height: 17px;
  top: 0;
}
.save-later .custom_check {
  padding-left: 25px;
}
.save-later .custom_check .checkmark::after {
  font-size: 11px;
  left: 2px;
  top: 8px;
}
.booking-confirmation-info {
  border: 0;
}
.booking-confirmation-info .appointment-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.booking-confirmation-info .appointment-details span {
  font-size: 18px;
  font-weight: 500;
}
.booking-confirmation-info .appointment-details .add-calender a {
  border-radius: 5px;
  border: 1px solid #333;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.appointment-details .add-calender a span {
  margin-right: 5px;
  font-size: 15px;
}
.confirmation-product-card {
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 15px;
  margin-bottom: 15px;
}
.confirmation-product-card .service-item {
  display: flex;
  align-items: center;
}
.confirmation-product-card .service-item span img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  margin-right: 8px;
}
.confirmation-product-card .product-info h5 {
  font-size: 18px;
  margin-bottom: 0;
}
.confirmation-product-card .product-info span {
  font-size: 14px;
  color: #858585;
  display: block;
}
.confirmation-product-card .product-info span.date-time {
  color: #28cea2;
}
.product-info h6 {
  font-size: 14px;
  margin-bottom: 0;
}
.name-card {
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 15px;
  margin-bottom: 15px;
}
.name-card span.profile-pic {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 5px;
  flex-shrink: 0;
}
.name-card h6 {
  font-weight: 500;
  margin-bottom: 10px;
}
.name-card span img {
  border-radius: 5px;
  width: 100%;
}
.name-card .profile-detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
}
.profile-detail .email-name span {
  color: #333;
  font-size: 14px;
}
.profile-detail .email-name p {
  font-size: 14px;
  color: #858585;
  margin-bottom: 0;
  line-break: anywhere;
}
.profile-detail .email-name a {
  color: #28cea2;
  font-size: 14px;
  display: block;
  line-height: normal;
  line-break: anywhere;
}
.payed-method {
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  background: #fafafa;
  line-height: normal;
  padding: 10px;
  font-size: 14px;
}
#appointment-confirmation .calender-modal {
  margin: 110px 0;
}
.booking-date-time {
  background: #fafafa;
  padding: 10px;
  text-align: center;
  line-height: normal;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.booking-date-time i {
  color: #28cea2;
  margin-right: 5px;
}

/*-----------------
	61. Chat Profile
-----------------------*/

.chat-search .input-group .form-control.chat-search {
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  padding: 10px;
}
.search-icon-right {
  position: absolute;
  right: 10px;
  top: 50%;
  line-height: normal;
  transform: translateY(-50%);
  padding-bottom: 0;
}
.input-group > .form-control:focus {
  z-index: 0;
}
.chat-search.chat-search-head {
  padding-bottom: 0;
}
.received .drop-item.message-more-option {
  position: absolute;
  right: -40px;
  top: 0px;
}
.chat-time i.fa-check-double {
  color: #858585;
  margin-left: 5px;
}
.chat-time i.fa-check-double.read {
  color: #05a144;
}
.chat-time i.fa-check {
  color: #858585;
  margin-left: 5px;
}
.msg_card_body .media .avatar {
  width: 40px;
  height: 40px;
}
.sent .drop-item.message-more-option {
  position: absolute;
  left: -40px;
  top: 0px;
}
.msg_card_body .media.received .media-body .msg-box div.msg-img {
  border-radius: 15px 0px 0px 0px;
  padding: 8px;
}
.drop-item {
  opacity: 0;
  transform: scale(0);
  transition: 0.5s all;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  z-index: 1;
}
.drop-item a.more-vertical-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.msg_card_body .media .chat-msg-info li .dropdown-item {
  padding: 4px 15px;
}
.msg-box:hover .drop-item {
  opacity: 1;
  transform: scale(1);
  transition: 0.5s all;
}
.msg_card_body .media.sent {
  align-items: flex-end;
}
.msg_card_body .media.sent .avatar {
  margin-bottom: 30px;
}
.drop-item.message-more-option .dropdown-menu {
  min-width: 130px !important;
  padding: 0;
}
.drop-item.message-more-option .dropdown-menu a {
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  padding: 5px 15px;
}
.drop-item.message-more-option .dropdown-menu .dropdown-item:active {
  background-color: #28cea2;
}
.drop-item.message-more-option .dropdown-menu .dropdown-item.active {
  background-color: #28cea2;
}
.pbutton {
  float: left;
  color: #28cea2;
}
.audio-sample {
  display: flex;
  align-items: center;
  width: 300px;
}
.timeline {
  width: 100%;
  height: 2px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: #e1e1e1;
}

.pbutton {
  cursor: pointer;
}

.playhead {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-top: -3px;
  background: #28cea2;
  cursor: pointer;
}
span.audio-length {
  font-size: 14px;
}
.msg_card_body
  .media.sent
  .media-body
  .msg-box:first-child
  > div.audio-file-sent {
  border-radius: 0 15px 15px 15px;
}

/*-----------------
	62. Installation
-----------------------*/

.wizard ul {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
}
.wizard ul li {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.wizard ul li h5 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: 400;
}
.wizard ul li span.dot-active {
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  background-color: #fff;
  display: flex;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 0;
  z-index: 1;
}
.wizard ul li::after {
  content: '';
  width: 50%;
  height: 1px;
  background: #ddd;
  position: absolute;
  bottom: 9px;
  left: 0;
}
.wizard ul li::before {
  content: '';
  width: 100%;
  height: 1px;
  background: #ddd;
  position: absolute;
  bottom: 9px;
  right: 0;
}
.profile-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wizard ul li.progress-activated::after {
  background: #28cea2;
}
.wizard ul li.progress-active::after {
  background: #28cea2;
}
.wizard ul li.progress-activated::before {
  background: #28cea2;
}
.wizard ul li.progress-active span.dot-active {
  border-color: #2ca384;
}
.wizard ul li.progress-activated span.dot-active {
  border-color: #2ca384;
  background-color: #2ca384;
}
.wizard ul li:first-child:before {
  width: 50%;
}
.wizard ul li:first-child::after {
  display: none;
}
.wizard ul li:last-child::before {
  display: none;
}
.wizard-form-card {
  padding: 24px;
  background: #fff;
}
.wizard-form-card .card-title {
  margin-bottom: 30px;
}
.install-step {
  border-radius: 10px;
  background: #f9f9f9;
  padding: 24px;
  margin-bottom: 40px;
}
.add-form-btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.add-form-btn .pre-btn {
  background: #e8e8e8;
  border-color: #e8e8e8;
  box-shadow: none;
  color: #fff;
}
.initialization-form-set #first {
  display: block;
}
.initialization-form-set .main-btn {
  display: inline-flex;
  align-items: center;
  gap: 2px;
}
.initialization-form-set fieldset {
  display: none;
}
.install-step .requirment-field {
  border-radius: 5px;
  background: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.install-step .requirment-field:last-child {
  margin-bottom: 0;
}
.install-step .requirment-field .elements-name i {
  color: #05a144;
  margin-right: 10px;
}
.install-step .requirment-field .elements-name {
  display: inline-flex;
  align-items: baseline;
}
.install-step .requirment-field .elements-name i.fa-circle-xmark {
  color: #ff0101;
}
.install-step .requirment-field span.version {
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #05a144;
  background: rgba(5, 161, 68, 0.04);
  color: #05a144;
  padding: 5px 15px;
}
.install-step .requirment-field span.error-msg {
  color: #ff0101;
}
.install-step .files-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e1e1;
  color: #858585;
}
.install-step .files-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}
.install-step .files-list li span.folder i {
  color: #ffb800;
  margin-right: 10px;
}
.install-step .files-list li span.folder {
  display: inline-flex;
  align-items: baseline;
}
.install-step .files-list li span i {
  color: #05a144;
}
.install-step .install-user tr td {
  color: #333;
}
.install-step .install-user tr td:first-child {
  width: 120px;
}
.install-step .install-user tr {
  margin-bottom: 15px;
  display: table;
}
.install-step .install-user tr:last-child {
  margin-bottom: 0;
}
.rechange-details {
  padding-bottom: 20px;
}
.rechange-details span {
  border-radius: 10px;
  background: rgba(5, 161, 68, 0.13);
  color: #05a144;
  padding: 20px;
  display: block;
  text-align: center;
}
.appointment-details ul.customer-detail-list li {
  display: block;
}
.appointment-details ul.customer-detail-list {
  position: absolute;
  background: white;
  padding: 24px;
  top: 0px;
  opacity: 0;
  right: -120px;
  z-index: 4;
  width: 90%;
  display: none;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
}
.droped-item h5 {
  position: relative;
}
.droped-item:hover h5::after {
  opacity: 1;
}
.appointment-details ul.customer-detail-list::after {
  content: '';
  border: 7px solid #fff;
  border-color: transparent transparent #fff #fff;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  position: absolute;
  top: 87px;
  left: -7px;
  -webkit-box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
}
.appointment-details ul li.droped-item:hover .customer-detail-list {
  opacity: 1;
  display: block;
}
.appointment-details ul.customer-detail-list li h6 {
  font-size: 14px;
  font-weight: 500;
}
.appointment-details ul.customer-detail-list li p {
  font-size: 14px;
  margin-bottom: 0;
}
.appointment-details ul.customer-detail-list li {
  margin-bottom: 10px;
}
.appointment-details ul.customer-detail-list li:last-child {
  margin-bottom: 0;
}
.chat-cont-right.chat-window-long {
  display: flex;
}
.card.chat-window {
  width: 100%;
  margin-right: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.chat-cont-profile.chat-profile-right {
  width: 0;
  display: none;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.chat-cont-right.chat-window-long.window-full-width .card.chat-window {
  width: 65%;
  margin-right: 24px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.chat-cont-right.chat-window-long.window-full-width
  .chat-cont-profile.chat-profile-right {
  width: 35%;
  display: flex;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.chat-options ul li a:hover {
  background: #28cea2;
}
.chat-options ul li a:hover i {
  color: #fff;
}
.chat-page-body .sidebar-overlay.opened {
  display: none;
}
.form-control.type_msg.empty_check {
  padding-left: 50px;
  padding-right: 130px;
}

/*-----------------
	63. Verification
-----------------------*/

.settings-widget {
  border: 1px solid #edecf8;
}
.setting-img-profile {
  text-align: start;
}
.setting-img-profile h6 {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}
.setting-img-profile p {
  font-size: 16px;
  font-weight: 400;
  color: #9e9e9e;
  margin-bottom: 0px;
}
.settings-menu ul li:not(:last-child) {
  margin-bottom: 24px;
}
.settings-widget .settings-menu ul li a {
  display: flex;
  align-items: center;
  color: #858585;
  font-weight: 400;
  font-size: 16px;
}
.settings-widget .settings-menu ul li a:hover {
  color: #28cea2;
}
.settings-menu ul li a img {
  margin-right: 8px;
}
.widget-title h4 {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
}
.linked-item .linked-acc {
  margin-bottom: 0px;
}
:root {
  --tooltip-color: red;
}
.linked-item a.link-close {
  margin-right: 32px !important;
}
.linked-item {
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 24px;
  margin-bottom: 20px;
}
.tooltip-inner {
  color: #fff;
  background: #000;
}
input[type='password'] {
  padding-right: 45px;
}
.settings-menu ul li a img {
  min-width: 16px;
}
.verify-modal .modal-dialog .modal-content {
  padding: 40px;
}
.verify-modal.custom-modal .modal-body {
  padding: 0;
}
.modal-dialog .modal-content .modal-header.verfication-modal-head {
  display: block;
  text-align: center;
  padding: 0px;
  margin-bottom: 40px;
  border-bottom: none;
}
.modal-dialog .modal-content .modal-header.verfication-modal-head h5 {
  color: #333;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 5px;
}
.modal-dialog .modal-content .modal-header.verfication-modal-head p {
  color: #858585;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}
#otp .doctor-profile .modal-body .wallet-add .form-group {
  margin-bottom: 20px;
}
#otp .doctor-profile .modal-body .wallet-add .otp-timer h6 {
  font-size: 16px;
  font-weight: 400;
  color: #05a144;
}
#otp .doctor-profile .modal-body .wallet-add .otp-timer {
  margin-bottom: 20px;
}
#otp .doctor-profile .modal-body .wallet-add .resend-code-otp {
  margin-bottom: 20px;
}
#otp .doctor-profile .modal-body .wallet-add .resend-code-otp p {
  color: #858585;
  font-size: 14px;
  font-weight: 400;
}
#otp .doctor-profile .modal-body .wallet-add .resend-code-otp p span {
  color: #333;
}
#otp .doctor-profile .modal-body .wallet-add .resend-code-otp p a {
  color: #ff0101;
}
.success-modal .modal-body .success-modal-body {
  color: #858585;
  font-size: 16px;
  font-weight: 400;
  max-width: 270px;
  margin: auto;
}
.success-modal .modal-content .success-body {
  margin-bottom: 40px;
}
.success-modal .modal-content .modal-body span {
  display: block;
}
.success-modal .modal-content .modal-body span i {
  font-size: 40px;
  color: #6dcc76;
}
.success-modal .modal-content .modal-body h3 {
  font-size: 28px;
  margin-bottom: 20px;
}
#change-mail .modal-dialog .doctor-profile .modal-body .wallet-add .form-group {
  margin-bottom: 10px;
}
#change-mail .modal-dialog .doctor-profile .modal-body .mail-check-verify {
  margin-bottom: 40px;
}
#change-mail .modal-dialog .doctor-profile .modal-body .mail-check-verify p {
  color: #858585;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .wallet-add
  .form-group {
  margin-bottom: 20px;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .wallet-add
  .form-group
  label {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  margin-bottom: 10px;
}
#change-document .modal-dialog .modal-content .modal-body .file-uploaded-mail {
  width: 100%;
  background: #fff;
  border: 1px solid #c2c9d1;
  border-radius: 5px;
  height: 188px;
  padding: 10px 0px 10px 8px;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .file-uploaded-mail
  #file-input {
  width: 100%;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .file-uploaded-mail
  img {
  margin-bottom: 20px;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .file-uploaded-mail
  h4 {
  font-size: 20px;
  color: #333;
  font-weight: 500;
  margin-bottom: 6px;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .file-uploaded-mail
  h4
  span {
  color: #28cea2;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .file-uploaded-mail
  p {
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;
  margin-bottom: 0px;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .document-upload-file {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #fafafa;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .document-upload-file
  p {
  color: #858585;
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
  margin-bottom: 0;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .document-update-success {
  margin-bottom: 40px;
}
#change-document
  .modal-dialog
  .modal-content
  .modal-body
  .document-update-success
  p {
  font-size: 16px;
  color: #05a144;
  font-weight: 400;
  margin-bottom: 0px;
}
#success-document
  .modal-dialog
  .modal-content
  .success-body
  .success-modal-body {
  color: #858585;
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  max-width: inherit;
}
.modal-content {
  padding: 20px;
}
.settings-widget .settings-menu ul li a.active {
  color: #28cea2;
}
/* Appointment-settings */

.settings-widget.setting-appointment .settings-header .settings-img {
  position: relative;
}
.settings-widget.setting-appointment .settings-header .settings-img img {
  border: none;
  outline: none;
}
.settings-widget.setting-appointment
  .settings-header
  .settings-img
  .settings-check
  img {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: -6px;
}
.appointment-set-two .coupon-title {
  margin-bottom: 40px;
}
.appointment-set-two .coupon-title h4 {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0px;
}
.appointment-set-two .appointment-setting-list {
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.appointment-set-two .appointment-setting-list .appoint-wrapper h5 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 6px;
}
.appointment-set-two .appointment-setting-list .appoint-wrapper p {
  font-size: 14px;
  font-weight: 400;
  color: #858585;
  margin-bottom: 0px;
}
.appointment-set-two .appointment-setting-list .form-group {
  width: 140px;
  height: 24px;
}
.appointment-set-two .appointment-setting-list .form-group .select {
  font-size: 16px;
  font-weight: 400;
  color: #74788d;
}
.appoint-submit .btn {
  padding: 10px 15px;
  font-size: 14px;
}
.appoint-submit .btn + .btn {
  margin-left: 10px;
}
.grey-tog .check:checked + .checktoggle {
  background-color: #cccfe0;
  border: 1px solid #cccfe0;
}
.grey-tog .checktoggle {
  background-color: #6dcc76;
  border: 1px solid #6dcc76;
  height: 24px;
}

/* Notification */

.notify-content-swap .notify-wrap-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  margin-bottom: 50px;
}
.notify-content-swap .notify-wrap-head h4 {
  margin-bottom: 0;
}
.notify-content-swap .notify-wrap-head .notify-wrap-service-home h6 {
  color: #858585;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
}
.notify-content-swap .notify-wrap-head .notify-wrap-service-home h6 a {
  color: #858585;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
}
.notify-content-swap .noti-content ul li.notification-message {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid#E1E1E1;
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
}
.notify-content-swap .noti-content ul li.notification-message .media span {
  margin-right: 15px;
}
.notify-content-swap .noti-content ul li.notification-message .media span img {
  border: 2px solid #e1e1e1;
}
.notify-content-swap
  .noti-content
  ul
  li.notification-message
  .media
  .media-body
  p.noti-details {
  margin-bottom: 5px;
}
.notify-content-swap
  .noti-content
  ul
  li.notification-message
  + li.notification-message {
  margin-top: 24px;
}
.notification-delete-wrap .notification-delete a.btn-acc {
  background: rgba(255, 1, 1, 0.1);
  padding: 10px;
  color: #ff0101;
  border: none;
  display: inline-block;
  line-height: normal;
  margin-bottom: 0;
}
.notification-delete .notification-dropdown {
  margin-right: 20px;
  width: 34px;
  height: 34px;
  background: #fafafa;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  display: none;
  transition: 0.5s all;
}
.notification-delete-wrap {
  transition: 0.5s all;
}
.notification-delete-wrap:hover .notification-delete .notification-dropdown {
  opacity: 1;
  display: flex;
  transition: 0.5s all;
}
ul.noti-pop-detail li.has-arrow a.notify-link:hover > img {
  filter: brightness(0) invert(1);
}
ul.noti-pop-detail li.has-arrow a.notify-link:focus > img {
  filter: brightness(0) invert(1);
}
.notification-delete .notification-dropdown .dropdown-menu {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 10px;
}
.notification-wrap-date .noti-read-delete .notification-read a.mark-all {
  color: #3f4254;
  margin-bottom: 0;
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  background: #fff;
  margin-right: 10px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-wrap-date
  .noti-read-delete
  .notification-read
  h6
  i.feather-check {
  margin-right: 5px;
}
.notification-wrap-date .noti-read-delete .notification-delete-content a {
  border: 1px solid #e1e1e1;
  background: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ed3a3a;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.notification-wrap-date .search-calendar-line {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  width: 130px;
  height: 42px;
}
.search-calendar-line input {
  padding-right: 0;
  line-height: normal;
}
.notification-wrap-date .search-calendar-line i.feather-calendar {
  margin-right: 5px;
}
.notify-content-swap .noti-content {
  padding: 40px 0 0px 0;
}
.sidebar-notification {
  display: none;
}

/* Booking-Details */

.section-booking {
  padding: 80px 0;
  background: #fff;
}
.service-view .service-header h1.electric-header {
  font-size: 36px;
  color: #000;
}
.service-header {
  margin-bottom: 30px;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
  font-size: 18px;
  color: #555a64;
}
.service-header .rating {
  list-style: none;
  margin: 0 0 7px;
  padding: 0;
  width: 100%;
}
.rating i.filled {
  color: #fbc418;
}
.rating i {
  color: #dedfe0;
}
.service-cate a {
  color: #fff;
  padding: 5px;
  text-transform: uppercase;
  background: #d9c505;
  border-radius: 4px;
  font-size: 0.8125rem;
  display: inline-block;
}
.service-cate span {
  font-size: 20px;
  color: #28cea2;
}
.service-view .service-images img {
  border-radius: 0;
  margin-bottom: 24px;
}
.service-details ul li + li {
  margin-left: 10px;
}
.service-details ul {
  margin-bottom: 24px;
}
.service-details .tab-content {
  padding-top: 0;
}
.service-details ul li a:hover {
  background: #28cea2;
  color: #fff;
}
.service-details .tab-content .tab-pane .card {
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  margin-bottom: 40px;
}
.service-details .tab-content .tab-pane .card .card-body {
  padding: 24px;
}
.service-details .tab-content .tab-pane .card .card-body h5.card-title {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.service-details
  .tab-content
  .tab-pane
  .card
  .card-body
  .serviced-offer
  ul.list-bullet
  span
  i {
  color: #6dcc76;
  margin-right: 5px;
}
.service-details
  .tab-content
  .tab-pane
  .card
  .card-body
  .serviced-offer
  ul.list-bullet {
  color: #858585;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.service-details
  .tab-content
  .tab-pane
  .card
  .card-body
  .serviced-offer
  ul.list-bullet
  li
  + li {
  margin-top: 18px;
  margin-left: 0;
}
.section-booking h4.card-title {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.service-content .user-info h6 {
  color: #74788d;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  overflow: hidden;
  align-items: center;
}
.service-content .user-info i {
  font-size: 12px;
  line-height: 26px;
  color: #171717;
  text-align: center;
  width: 27px;
  height: 27px;
  border: 1px dashed #171717;
  border-radius: 50%;
}
.service-content .user-info i:hover {
  background: #28cea2;
  color: #fff;
}
.service-carousel .service-widget .service-img::before {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: -webkit-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: -ms-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1;
}
.service-widget .service-img .item-info .service-user {
  font-size: 20px;
  line-height: 28px;
  color: #fff;
  font-weight: bold;
  float: left;
}
.service-widget .service-user img {
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block !important;
}
.service-widget .service-img .item-info .cate-list {
  float: right;
}
.cate-list a {
  color: #fff;
  padding: 2px 15px;
  font-size: 13px;
  overflow: hidden;
  line-height: 22px;
  border-radius: 15px;
  position: relative;
  display: inline-block;
  background: #d9c505;
}
.widget {
  margin-bottom: 30px;
}
.sidebar-widget {
  background: #f9f9f9;
  padding: 14px;
  border-radius: 5px;
  text-align: center;
}
.sidebar-widget .service-amount {
  color: #2c3038;
  font-size: 36px;
  font-weight: 700;
}
.service-book,
.service-book a {
  width: 100%;
}
.about-provider-img {
  background-color: #fff;
  height: 80px;
  overflow: hidden;
  position: absolute;
  width: 80px;
}
.provider-details {
  margin-left: 100px;
}
.provider-details p.last-seen {
  margin-bottom: 0px;
}
.provider-details .ser-provider-name {
  display: inline-block;
  margin-bottom: 5px;
  color: #272b41;
  font-size: 16px;
  font-weight: 600;
}
.provider-details p.last-seen i {
  color: #05a144;
}
.provider-info {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 0;
}
.provider-info i {
  margin-right: 5px;
}
.provider-info p {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
}
.booking-report-provider {
  padding: 10px;
  background-color: #28cea2;
  border: 1px solid #28cea2;
  box-shadow: inset 0 0 0 #2ca384;
  border-radius: 5px;
  width: 195px;
  margin-bottom: 20px;
}
.booking-report-provider h6 {
  margin-bottom: 0;
  color: #fff;
}
.review-box .card-body span {
  color: #555a64;
  font-size: 16px;
  font-weight: 500;
}
.available-widget.widget-book-detail .card-body h5.card-title {
  margin-bottom: 15px;
}
.available-widget.widget-book-detail ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.available-widget.widget-book-detail ul li {
  color: #858585;
  overflow: hidden;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
.available-widget.widget-book-detail ul li span {
  float: left;
}
.available-widget.widget-book-detail ul li:first-child {
  padding-top: 0;
}
.available-widget.widget-book-detail ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

/* Service-Select */

.computer-service {
  padding: 15px;
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
}
.comp-serv-img {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.comp-serv-img img {
  border-radius: 8px;
}
.comp-serv-header h5 {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
}
.comp-serv-header p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
}
.comp-service-amount h5 {
  color: #28cea2;
}
.additional-title {
  margin: 15px 0;
}
.additional-title h5 {
  margin-bottom: 0;
}

.service-wrap-content {
  height: 94%;
}
.service-add-server {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 15px;
  margin-bottom: 15px;
}
.service-add-server .custom_check {
  margin-bottom: 0;
}
.service-add-server:last-child {
  margin-bottom: 0;
}
.service-add-server .save-later-amount h5 {
  margin-bottom: 0;
  color: #28cea2;
}
.body-one .fav-item .fav-icon:hover,
.body-one .fav-icon.selected {
  background: #28cea2;
}
.home-page-four .fav-item .fav-icon:hover,
.home-page-four .fav-item .fav-icon.selected {
  background: #28cea2;
}
.settings-widget.setting-appointment .settings-menu ul li i {
  font-size: 16px;
  min-width: 16px;
}
.notify-head.notification-details.notify-content-swap {
  background-color: #fafafa;
  margin-bottom: 50px;
}
.notify-head.notification-details.notify-content-swap .notify-wrap-head {
  margin-bottom: 0;
}
.search-form .btn-primary:active:not(:disabled):not(.disabled),
.search-form .btn-primary:focus {
  border: 0;
  box-shadow: none;
  background-color: transparent;
}
.chat-search.chat-search-head .input-group input.form-control {
  padding-right: 30px;
}
.user-menu.nav > li > a.viewsite:focus {
  background-color: transparent;
}
.user-menu.nav > li > a.viewsite:focus i {
  color: #74788d;
}
.rechedule-calender .datepicker .day {
  position: relative;
}
.rechedule-calender .datepicker .day::after {
  content: '6 Slots Available';
  background: #000;
  width: 110px;
  padding: 5px 10px;
  height: 25px;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  position: absolute;
  top: -20px;
  left: -25px;
  opacity: 0;
  display: none;
}
.rechedule-calender .datepicker .day:hover::after {
  opacity: 1;
  display: block;
}
.rechedule-calender .datepicker .day::before {
  border: 4px solid #fff;
  border-color: #000 #000;
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  content: '';
  position: absolute;
  top: 5px;
  left: 32px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  opacity: 0;
  display: none;
}
.rechedule-calender .datepicker .day:hover::before {
  opacity: 1;
  display: block;
}
.installation-footer {
  display: flex;
  justify-content: center;
}
.installation-footer p {
  margin-bottom: 0;
  font-size: 16px;
}
.notification-list li.notification-message p.noti-details {
  position: relative;
}
.notification-list li.notification-message:first-child p.noti-details::after,
.notification-list li.notification-message:nth-child(2) p.noti-details::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: red;
  position: absolute;
  bottom: 5px;
  margin-left: 5px;
}
.nameof-day {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.provider-body .sidebar-notification.opened {
  z-index: 1038;
}
.noti-wrapper .notify-link {
  position: relative;
}
.noti-wrapper .noti-message {
  width: 17px;
  color: #fff;
  height: 17px;
  border-radius: 50%;
  background: #28cea2;
  position: absolute;
  display: block !important;
  right: 7px;
  top: 0;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}
.noti-wrapper .notify-link:hover .noti-message {
  background: #fff;
  color: #28cea2;
}
.reshchedule-modal {
  overflow: hidden;
}
.reshchedule-modal .modal-content.doctor-profile {
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
}
.appointment-details ul.customer-detail-list {
  right: unset;
  top: 120px;
}
.appointment-details ul.customer-detail-list::after {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  top: -7px;
  left: 111px;
}
.msg_card_body .chat-date span {
  background-color: #e8e8e8;
  color: #333;
}
form .otp-box {
  margin-bottom: 34px;
}
form .otp-box input {
  width: 74px;
  height: 74px;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  text-align: center;
  margin-right: 15px;
  font-weight: 700;
  font-size: 26px;
  color: #74788d;
  outline: none;
}
form .otp-box input::placeholder {
  color: #74788d;
}
form .otp-box input:last-child {
  margin-right: 0px;
}
.link-close .fa-circle-check {
  color: rgba(27, 163, 69, 1);
}
.link-close i:hover {
  background: #2ca384;
}
.media-list ul {
  justify-content: start;
}
.chat-profile-right .back-user-list {
  display: none;
}
header.header nav ul li + li {
  margin-left: 0;
}
header.header nav ul li a:hover {
  text-decoration: none;
}
.add-service .file-preview ul.gallery-selected-img li .custom_check .checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
}
.add-service .file-preview ul li .custom_check .checkmark::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  background: #28cea2;
  opacity: 0;
  top: 4px;
  left: 4px;
  display: none;
}
.add-service
  .file-preview
  ul
  li
  .custom_check
  input:checked
  ~ .checkmark::after {
  opacity: 1;
  display: block;
}
.add-service
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 50px;
}
.add-service .select2-container--default .select2-selection--single {
  height: 50px;
}
.hide-cards-group {
  display: none;
}
.show-card-details .hide-cards-group {
  display: block;
}
.book-main .modal-active-dots {
  width: 27%;
}
.book-main .middle-items {
  margin: 0 24px;
}
.book-main .hide-show-main {
  width: 73%;
}
.hide-show#show-first {
  display: block;
}
.hide-show {
  display: none;
}
.field-bottom-btns .prev_btnn i {
  margin-right: 5px;
}
.field-bottom-btns .next_btnn i {
  margin-left: 5px;
}
.field-bottom-btns .prev_btnn {
  background: #333;
  border-color: #333;
}
.field-bottom-btns .prev_btnn:hover {
  border-color: #28cea2;
}
.field-bottom-btns .prev_btnn.disabled {
  background: #333;
  border-color: #333;
  color: #fff;
  box-shadow: none;
}
.img-preview a.remove-item {
  display: none;
}
.booking-confirm-active-dots .calender-modal {
  margin: 110px 0;
}
.booking-confirm-active-dots .rechedule-calender {
  padding: 15px;
}
.booking-confirm-active-dots .booking-confirmation-info .appointment-details {
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.page-link:focus {
  box-shadow: none;
}
.window-full-width .received .drop-item.message-more-option {
  right: 0;
}
/*-----------------
	52. Responsive
-----------------------*/

@media only screen and (min-width: 992px) {
  .main-nav > li {
    margin-right: 25px;
  }
  .main-nav > li:last-child {
    margin-right: 0;
  }
  .main-nav li {
    display: block;
    position: relative;
  }
  .main-nav > li > a {
    line-height: 85px;
    padding: 0 !important;
  }
  .header.header-nine .main-nav > li > a {
    line-height: 56px;
  }
  .header.header-nine.fixed .main-nav > li > a {
    line-height: 85px;
  }
  .main-nav > li > a > i {
    font-size: 12px;
    margin-left: 3px;
  }
  .main-nav li.megamenu {
    position: unset;
  }
  .single-demo a {
    padding: 0 !important;
  }
  .main-nav li > ul {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    display: block;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 200px;
    opacity: 0;
    padding: 0;
    position: absolute;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    top: 100%;
    visibility: hidden;
    z-index: 1000;
  }
  .main-nav li.has-submenu:hover > .submenu {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
  }
  .main-nav .has-submenu.active > a {
    color: #28cea2;
  }
  .header-six .main-nav .has-submenu.active > a {
    color: #2229c1;
  }
  .header.header-five .main-nav .has-submenu.active > a {
    color: #2229c1;
  }
  .main-nav .has-submenu.active .submenu li.active > a {
    color: #28cea2;
  }
  .header.header-five .main-nav .has-submenu.active > a,
  .header.header-five .main-nav .has-submenu.active .submenu li.active > a,
  .header-five .main-nav li .submenu a:hover {
    color: #2229c1;
  }
  .header.header-seven .main-nav .has-submenu.active > a,
  .header.header-seven .main-nav .has-submenu.active .submenu li.active > a,
  .header-seven .main-nav li .submenu a:hover {
    color: #2229c1;
  }
  .header.header-eight .main-nav .has-submenu.active > a,
  .header.header-eight .main-nav .has-submenu.active .submenu li.active > a,
  .header-eight .main-nav li .submenu a:hover {
    color: #2229c1;
  }
  .header.header-nine .main-nav .has-submenu.active > a,
  .header.header-nine .main-nav .has-submenu.active .submenu li.active > a,
  .header-nine .main-nav li .submenu a:hover {
    color: #2229c1;
  }
  .main-nav > li .submenu li:first-child a {
    border-top: 0;
  }
  .main-nav
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: -1px;
    margin-right: 0;
  }
  .main-nav > li .submenu > li .submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
  .main-nav li .submenu a:hover {
    color: #28cea2;
  }
  .main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
    top: 20px;
    margin-left: -35px;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
    border-color: transparent #fff #fff transparent;
  }
  .header-two .main-nav li .submenu a:hover {
    color: #2229c1;
  }
  .header-two .main-nav .has-submenu.active > a {
    color: #2229c1;
  }
  .header-two .main-nav .has-submenu.active .submenu li.active > a {
    color: #2229c1;
  }
  .header-three .main-nav li .submenu a:hover {
    color: #2229c1;
  }
  .header-three .main-nav .has-submenu.active > a {
    color: #2229c1;
  }
  .header-three .main-nav .has-submenu.active .submenu li.active > a {
    color: #2229c1;
  }
  .header-navbar-rht .dropdown-toggle.show + .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .header-navbar-rht li .dropdown-menu {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    min-width: 200px;
    visibility: hidden;
    opacity: 0;
    top: 115%;
    left: auto;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: block;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  .header-navbar-rht li .dropdown-menu.notifications {
    left: -310px;
  }
  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 40px;
    width: auto;
  }
  .mini-sidebar .header .header-left .logo {
    display: none;
  }
  .mini-sidebar .header-left .logo.logo-small {
    display: block;
  }
  .mini-sidebar .header .header-left {
    padding: 0 5px;
    width: 80px;
  }
  .mini-sidebar .header .header-left #toggle_btn i {
    transform: rotate(180deg);
    color: #7e84a3;
    padding: 0 7px;
  }
  .mini-sidebar .sidebar {
    width: 80px;
  }
  .mini-sidebar.expand-menu .sidebar {
    width: 270px;
  }
  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }
  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }
  .mini-sidebar .menu-title a {
    visibility: hidden;
  }
  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible;
  }
  .modal-open.mini-sidebar .sidebar {
    z-index: 1051;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1;
  }
  .mini-sidebar .page-wrapper {
    margin-left: 78px;
  }
  .main-nav li > ul.mega-submenu {
    padding: 0;
    border: 0;
    display: block;
    opacity: 0;
    visibility: hidden;
    min-width: 250px;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform-origin: center top 0;
    -webkit-transform-origin: center top 0;
    -moz-transform-origin: center top 0;
    -ms-transform-origin: center top 0;
    -o-transform-origin: center top 0;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    -o-box-shadow: 0 16px 50px rgba(0, 0, 0, 0.08);
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
  }
}

@media only screen and (max-width: 1399.98px) {
  .home-banner {
    background-size: 550px;
  }
  .catering-banner-img img {
    max-width: 660px;
  }
  .section-search.section-search-four h1 {
    font-size: 40px;
  }
  .home-banner.slider-service {
    min-height: 600px;
  }
  .service-content p {
    font-size: 15px;
  }
  .banner-imgs .banner-1 {
    max-width: 160px;
  }
  .service-content.service-four-content ul li {
    margin-right: 10px;
  }
  .service-content.service-four-content ul li:last-child {
    margin-right: 0;
  }
  .banner-imgs .banner-2 {
    max-width: 270px;
  }
  .banner-imgs.banner-imgs-nine img {
    max-width: 250px;
  }
  .banner-imgs .banner-3 {
    max-width: 250px;
    bottom: 0;
  }
  .testimonial-slider .owl-nav button.owl-next {
    right: -40px;
  }
  .testimonial-slider .owl-nav button.owl-prev {
    left: -40px;
  }
  .footer .footer-menu-three ul li a {
    margin-left: 15px;
  }
  .footer-subtitle-three {
    font-size: 18px;
  }
  .booking-details li p {
    padding-left: 10px;
  }
  .booking-details li p:last-child {
    margin-left: 110px;
    margin-top: 10px;
    display: block;
  }
  .slot-action .btn {
    padding: 5px 11px;
  }
  .main-nav > li {
    margin-right: 18px;
  }
  .section-search h1 {
    font-size: 44px;
  }
  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-name,
  .chat-cont-left
    .chat-users-list
    a.media
    .media-body
    > div:first-child
    .user-last-chat {
    max-width: 70px;
  }
  .chat-cont-left .chat-scroll {
    max-height: calc(100vh - 325px);
  }
  .grid-blog .blog-category ul li {
    margin: 0 5px 12px 0;
  }
  .search-btn .btn {
    padding: 11px 10px;
  }
  .dash-wallet .dash-icon {
    width: 42px;
    height: 42px;
    margin: 0 7px 0 0;
  }
  .card-remove {
    flex-shrink: 0;
  }
  .card-payments a {
    font-size: 12px;
    padding: 6px 6px;
  }
  .balance-crad h3 {
    font-size: 28px;
  }
  .balance-crad .view-transaction,
  .balance-crad .btn-withdraw {
    padding: 5px 6px;
  }
  .servicecontent.service-widget .serv-info h6 {
    font-size: 20px;
  }
  .work-wrap-box.work-first:before {
    background-size: 156px;
  }
  .work-wrap-box.work-last:after {
    background-size: 143px;
  }
  .slick-next {
    right: 30px;
  }
  .slick-prev {
    left: 30px;
  }
  .provider-img span {
    bottom: 39px;
  }
  .price {
    font-size: 18px;
  }
  .price .old-price,
  .main-nav li a,
  .flag-dropdown .nav-link,
  .flag-dropdown .nav-link {
    font-size: 14px;
  }
  .support-title {
    font-size: 16px;
  }
  .offer-sec .offer-path-content {
    padding: 80px 0;
  }
  .servicecontent .btn-book {
    padding: 10px 14px;
  }
  .header-three .header-navbar-rht li a.header-login {
    font-size: 14px;
  }
  .header-three .navbar {
    padding: 0;
  }
  .header-three .header-navbar-rht li > a.header-login {
    padding: 9px 6px;
  }
  .categories-main-all {
    padding: 30px 25px;
  }
  .trust-us-main {
    padding: 30px;
  }
  .works-it-lines span::before {
    width: 315px;
    left: 207px;
  }
  .floating-five-buttoms {
    left: 400px;
  }
  .offering-five-img {
    margin: -60px 0 0 -60px;
  }
  .cta-btn {
    padding: 7px 9px;
    min-width: auto;
  }
  .search-box-two.search-box-six .search-btn .btn {
    padding: 8px 10px;
  }
  .home-banner-six-bg img {
    left: -95px;
  }
  .our-company-bg img {
    left: -100px;
  }
  .app-imgs-six-bg img {
    top: 15%;
    left: 25%;
  }
  .search-box-two.search-box-six {
    width: 100%;
  }
  .professional-eight-img-ryt img {
    height: auto;
  }
  .cta-btn a {
    font-size: 14px;
    font-weight: 500;
  }
  .reshchedule-modal .modal-dialog {
    max-width: 1120px;
  }
  .calender-modal {
    margin: 170px 0;
  }
  .secure-transaction {
    padding: 20px;
  }
  .providerset-content.providerset-content-seven .rate {
    padding: 6px 10px;
  }
  .book-submit .btn + .btn {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .logo img {
    max-width: 170px;
  }
  .dropdown.noti-dropdown {
    margin-top: 0px !important;
  }
  .header-navbar-rht > li.desc-list a > span {
    display: none;
  }
  .provider-box .btn {
    font-size: 14px;
    padding: 5px 12px;
  }
  .provider-box .rate {
    font-size: 14px;
  }
  .register-content h2 {
    font-size: 35px;
  }
  .banner-imgs.banner-imgs-nine img {
    max-width: 220px;
  }
  .safety-ryt-main.safety-ryt-two::after {
    top: 46%;
  }
  .header .has-arrow .dropdown-toggle::after {
    display: none;
  }
  .howwork {
    padding: 0;
  }
  .security-verifi .btn-acc {
    padding: 9px 15px;
  }
  .security-verifi .btn-connect {
    padding: 9px 15px;
  }
  .howworksec {
    background-size: contain;
  }
  .dash-widget-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    flex: 0 0 50px;
  }
  .dash-widget {
    padding: 15px;
  }
  .dash-widget-info > span {
    font-size: 16px;
  }
  .main-nav > li {
    margin-right: 10px;
  }
  .main-nav li a {
    font-size: 14px;
  }
  .section-heading h2,
  .section-heading-two h2 {
    font-size: 30px;
  }
  .section-heading span,
  .category-name h6 {
    font-size: 18px;
  }
  .pricing-plans.price-new .pricing-planshead h4 {
    font-size: 24px;
  }
  .language-list a {
    padding: 11px 13px;
    font-size: 14px;
  }
  .language-list a i {
    margin-left: 10px;
  }
  .main-nav > li {
    margin-right: 20px;
  }
  .app-section {
    padding: 20px 0 90px;
  }
  .app-sec h2 {
    font-size: 40px;
  }
  .appimg-set {
    margin-top: -70px;
  }
  .app-sec {
    padding: 45px;
  }
  .testimonial-slider .owl-nav button.owl-prev {
    left: -25px;
  }
  .testimonial-slider .owl-nav button.owl-next {
    right: -25px;
  }
  .home-banner {
    background-size: 470px;
  }
  .section-search h1 {
    font-size: 34px;
    max-width: 500px;
  }
  .serv-profile h2 {
    font-size: 36px;
  }
  .service-book .serv-profile h2 {
    font-size: 20px;
  }
  .serv-profile ul li {
    font-size: 14px;
    margin: 0 15px 15px 0;
  }
  .contact-info span {
    font-size: 20px;
    width: 60px;
    height: 60px;
  }
  .provide-info p {
    font-size: 14px;
  }
  .service-book-img {
    margin: 0 25px 30px 0;
  }
  .progress-wrap.active-progress {
    display: none;
  }
  .available-widget span.avail-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .available-info h5,
  .package-widget h5,
  .service-wrap h5 {
    font-size: 18px;
  }
  .available-info ul li,
  .available-info ul li span {
    font-size: 14px;
  }
  .card-provide .btn {
    padding: 12px;
  }
  .dash-info h6 {
    font-size: 13px;
  }
  .dash-wallet .dash-info h5,
  .widget-title h4 {
    font-size: 20px;
  }
  .maintenance-wrap h2,
  .balance-crad h3 {
    font-size: 26px;
  }
  .booking-list .booking-widget {
    flex: 0 0 100%;
  }
  .booking-action {
    display: flex;
    flex: 0 0 100%;
    margin-top: 15px;
  }
  .main-content .linked-wrap {
    display: block;
  }
  .linked-item a.btn-gconnect {
    margin-right: 15px;
  }
  .plan-btns .btn {
    margin: 5px 5px 0 0;
  }
  .dash-wallet .dash-info h6 {
    font-size: 13px;
  }
  .slot-action .btn {
    padding: 7px 15px;
  }
  .linked-item:last-child .linked-acc {
    margin-bottom: 25px;
  }
  .main-nav > li {
    margin-right: 9px;
  }
  .chat-cont-profile.opened {
    margin-right: 0;
  }
  .header-four .main-nav > li {
    margin-right: 5px;
  }
  p.total-client-content {
    max-width: 400px;
  }
  .chat-cont-profile .card {
    border-radius: 10px 0 0 10px;
  }
  .window-full-width .sidebar-overlay.opened {
    display: none;
  }
  .chat-cont-profile .card-body {
    max-height: calc(100vh - 136px);
  }
  .chat-cont-left .chat-scroll {
    max-height: calc(100vh - 292px);
  }
  .customer-chat .chat-cont-profile .card-body {
    max-height: calc(100vh - 330px);
  }
  .search-btn .btn {
    font-size: 14px;
    padding: 10px;
  }
  .search-input label {
    font-size: 14px;
  }
  .header-navbar-rht > li {
    padding-right: 5px;
  }
  .header-navbar-rht li > a.header-reg {
    font-size: 13px;
  }
  .work-wrap h1 {
    font-size: 30px;
  }
  .work-wrap-acc:after,
  .work-wrap-post:after {
    bottom: -53%;
    width: 60%;
    height: 60%;
  }
  .visits {
    width: 16.6%;
  }
  .book-submit {
    margin-top: 25px;
  }
  .offer-paths {
    padding: 0 50px;
  }
  .offer-path-content {
    padding-left: 30px;
  }
  .offer-path-content h3 {
    font-size: 28px;
  }
  .providerset-name h4,
  .providerset-prices h6,
  .feature-title h5 {
    font-size: 16px;
  }
  .page-headers .search-bar {
    width: 190px;
  }
  .appimg-set img {
    max-width: 450px;
  }
  .provider-img span {
    padding: 10px;
    font-size: 14px;
    bottom: 32px;
  }
  .service-action .btn {
    margin-left: 10px;
    padding: 10px 12px;
  }
  .service-action h6,
  .service-widget.service-two .service-content .title {
    font-size: 18px;
  }
  .work-wrap-box {
    width: 296px;
    height: 296px;
    padding: 40px;
    margin: auto;
  }
  .work-wrap-box .work-icon span {
    width: 80px;
    height: 80px;
  }
  .work-wrap-box.work-first:before {
    background-size: 130px;
  }
  .work-wrap-box.work-last:after {
    background-size: 122px;
    right: -245px;
  }
  .support-title {
    font-size: 15px;
  }
  .about-content h2,
  .choose-content h2 {
    font-size: 30px;
  }
  .choose-info h5 {
    font-size: 28px;
  }
  .about-img .about-exp span {
    font-size: 20px;
    margin: 190px -85px;
  }
  .choose-info p {
    font-size: 14px;
  }
  .header-navbar-rht > li.lang-nav {
    display: none;
  }
  .header.header-four .header-nav-new {
    padding: 0;
    justify-content: start;
  }
  .header-four .header-navbar-rht li a.header-login {
    padding: 9px 9px;
    font-size: 14px;
  }
  .works-it-lines span::before {
    width: 265px;
    left: 175px;
  }
  .offering-five-img {
    margin: -13px 0 0 -100px;
  }
  .search-box-two.search-box-six .search-input-new.line-six {
    width: 40%;
  }
  .search-box-two.search-box-six .search-input-new.line-two-six {
    width: 35%;
  }
  .search-box-two.search-box-six .search-btn {
    width: 25%;
  }
  .customer-review-top {
    padding: 30px 30px;
  }
  .home-banner-eight .section-search h1 {
    max-width: none;
  }
  .about-eight-main img {
    max-width: 100%;
  }
  .booking-info-tab .nav-pills .nav-item + .nav-item {
    margin-left: 15px;
  }
  .booking-info-tab .nav-pills .nav-link {
    font-size: 15px;
  }
  .appointment-details ul.customer-detail-list {
    right: unset;
    top: 120px;
  }
  .appointment-details ul.customer-detail-list::after {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    top: -7px;
    left: 111px;
  }
  .settings-widget .settings-header {
    display: block;
  }
  .setting-img-profile {
    text-align: center;
  }
  .linked-item a.btn-connect {
    margin-right: 5px;
  }
  .service-content {
    padding: 16px;
  }
  .service-content .user-info h6,
  .available-widget ul li {
    font-size: 14px;
  }
  .our-company-first-content a {
    padding: 15px;
  }
  .company-top-content h3 {
    font-size: 32px;
  }
  .passion-content-top .btn-primary {
    padding: 15px 30px;
  }
  .book-submit .btn + .btn {
    margin-left: 0;
  }
  .book-submit .btn {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 991.98px) {
  .sidebar-overlay.opened {
    display: block;
  }
  .booking-details li p:last-child {
    margin-left: 0;
    margin-top: 0;
    display: unset;
  }
  .linked-item .security-verifi .btn-connect {
    padding: 9px 15px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .settings-widget {
    margin-bottom: 30px;
  }
  .works-five-main {
    margin-bottom: 30px;
  }
  .provider-box .btn {
    font-size: 15px;
    padding: 5px 15px;
  }
  .provider-box .rate {
    font-size: 15px;
  }
  .header-navbar-rht > li {
    padding-right: 15px;
  }
  .providers-section {
    padding-bottom: 50px;
  }
  .login-wrap .col-form-label {
    font-size: 14px;
  }
  .offer-paths {
    background-color: #28cea2;
    border-radius: 5px;
    margin-bottom: 80px;
    margin-top: 60px;
    padding: 15px;
    top: 0;
  }
  .booking-details li p:first-child {
    margin-left: 110px;
    margin-top: 10px;
  }
  .offer-paths::after {
    background: transparent;
    z-index: -1;
  }
  .offer-path-content h3 {
    font-size: 28px;
  }
  .offer-pathimg {
    display: none;
  }
  .grid-listview a {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
  .megamenu-wrapper {
    padding: 0;
  }
  .demo-img {
    display: none;
  }
  .demo-info {
    opacity: 1;
    position: relative;
    bottom: 0;
    text-align: left;
  }
  .single-demo:hover .demo-info {
    bottom: 0;
  }
  .select2-container--default .select2-selection--single {
    outline: 0;
  }
  .review-sort .select2-container--default .select2-selection--single,
  .review-sort
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 32px;
  }
  .review-sort
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 31px;
  }
  .offer-path-content {
    padding-left: 0;
  }
  .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .main-nav ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
  .main-nav > li {
    margin-left: 0;
  }
  .main-nav li + li {
    margin-left: 0;
  }
  .main-nav > li > a {
    line-height: 1.5;
    padding: 15px 20px !important;
    font-size: 14px;
    font-weight: 500;
  }
  .main-nav > li > a > i {
    float: right;
    margin-top: 5px;
  }
  .main-nav > li .submenu li a {
    border-top: 0;
    color: #28283c;
    padding: 10px 15px 10px 35px;
  }
  .main-nav > li .submenu ul li a {
    padding: 10px 15px 10px 45px;
  }
  .main-nav > li .submenu > li.has-submenu > a::after {
    content: '\f078';
  }
  .main-nav .has-submenu.active > a {
    color: #28cea2;
  }
  .header-three .main-nav .has-submenu.active > a {
    color: #2229c1;
  }
  .header-six .main-nav .has-submenu.active > a {
    color: #2229c1;
  }
  .header-nine .main-nav .has-submenu.active > a {
    color: #2229c1;
  }
  .header-eight .main-nav .has-submenu.active > a {
    color: #2229c1;
  }
  .main-nav .has-submenu.active .submenu li.active > a {
    color: #28cea2;
  }
  .main-nav > li > a:hover::after,
  .main-nav > li.active > a::after {
    top: 0;
  }
  .header-two .main-nav .has-submenu.active > a,
  .header-two .main-nav .has-submenu.active .submenu li.active > a {
    color: #2229c1;
  }
  .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 11111;
    transform: translateX(-260px);
    transition: all 0.4s;
    background-color: #fff;
    margin: 0;
  }
  .menu-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .main-nav > li {
    border-bottom: 1px solid #e1e2e5;
    margin-right: 0;
  }
  #mobile_btn {
    line-height: 0;
    display: inline-block;
  }
  .sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1038;
  }
  .provider-body .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .review-pagination ul li a,
  .page-item .page-link {
    width: 25px;
    height: 25px;
  }
  .login-wrap .login-btn {
    font-size: 14px;
    padding: 10px;
  }
  .blog-pagination .page-prev,
  .blog-pagination .page-next {
    line-height: 25px;
  }
  .provider-body .header {
    left: 0;
    z-index: 1039;
  }
  .provider-body .user-menu.nav > li > a.viewsite,
  .provider-body .user-menu.nav > li.flag-nav,
  .provider-body .user-menu.nav > li > a.win-maximize {
    display: none;
  }
  .mobile_btns {
    color: #28cea2;
    cursor: pointer;
    display: flex;
    font-size: 26px;
    height: 76px;
    left: 0;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 10;
    justify-content: center;
    align-items: center;
  }
  .bg-img,
  .page-headers,
  .siderbar-toggle,
  .header .header-left .logo {
    display: none;
  }
  .header-split {
    justify-content: flex-end;
  }
  .header .header-left {
    position: absolute;
    width: 100%;
    display: block;
    height: auto;
    top: 0;
    left: 0;
  }
  .header .header-left .sidebar-logo {
    padding: 26px 0;
  }
  .provider-body .logo-small {
    display: none;
    margin: 0 auto;
  }
  .content {
    padding: 30px 0;
  }
  .header-navbar-rht > li.desc-list {
    display: none;
  }
  .container {
    max-width: 100%;
  }
  .footer-widget {
    margin-bottom: 30px;
  }
  .footer .footer-top {
    padding-bottom: 10px;
  }
  .section-search {
    margin: 60px 0;
  }
  .breadcrumbs {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .breadcrumbs li {
    float: none;
    display: inline-block;
    padding: 0 13px;
  }
  .hero-section,
  .home-banner {
    min-height: 400px;
  }
  .section-search {
    max-width: 100%;
  }
  .search-box form .search-btn {
    width: auto;
    float: right;
    margin: 0;
  }
  .search-box,
  .search-box-two {
    border-radius: 30px;
  }
  .search-input.line,
  .search-input-new.line {
    width: 100%;
  }
  .search-input,
  .search-input-new {
    width: 100%;
  }
  .search-box form > div {
    width: 100%;
  }
  .search-input > i {
    right: 20px;
  }
  .search-box form .search-btn,
  .search-box-two form .search-btn {
    float: left;
    width: 100%;
    padding: 0;
  }
  .search-input.line::before,
  .search-input-new.line::before {
    display: none;
  }
  .search-input.line {
    margin-right: 0;
  }
  .search-input {
    margin: 0 0 10px 0;
  }
  .viewall span {
    font-size: 1.875rem;
  }
  .howworksec {
    background: none;
  }
  .heading h2 {
    font-size: 1.625rem;
  }
  .chat-cont-left,
  .chat-cont-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: all 0.4s;
    width: 100%;
    max-height: 100%;
  }
  .chat-cont-left {
    z-index: 9;
  }
  .chat-cont-right {
    position: absolute;
    right: -100%;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
  }
  .chat-cont-right .chat-header {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }
  .chat-cont-right .msg_head .back-user-list {
    display: flex;
    align-items: center;
  }
  .chat-cont-right .msg_head .chat-options {
    margin-left: auto;
  }
  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .chat-cont-right .chat-scroll {
    max-height: calc(100vh - 257px);
  }
  .chat-cont-left .chat-scroll {
    max-height: calc(100vh - 292px);
  }
  .chat-cont-profile {
    z-index: 999;
  }
  .msg_card_body .media.sent .media-body .msg-box {
    margin-left: 0px;
  }
  .chat-options ul li a {
    width: 20px;
    height: 20px;
  }
  .user_img {
    height: 30px;
    width: 30px;
  }
  .content {
    padding: 30px 0;
  }
  .section-search-box {
    min-height: 330px;
  }
  .download-app .app-world .heading h2 {
    margin-bottom: 30px;
    font-size: 24px;
  }
  .download-app .app-world .heading span,
  .section-heading span {
    font-size: 16px;
  }
  .true-search-box {
    position: relative;
    top: 0;
    padding-top: 25px;
  }
  .feature-category {
    padding-top: 60px;
  }
  .section-heading h2,
  .section-heading-two h2 {
    font-size: 25px;
  }
  .blog-title a {
    font-weight: 500;
  }
  .blog-read-more a,
  .blog-date p {
    font-size: 15px;
  }
  .blog-inner-img img:nth-child(1) {
    margin-right: 10px;
  }
  .blog-item.left-box {
    margin-right: 30px;
  }
  .blog-item p {
    padding: 30px 35px;
  }
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
  .feature-section,
  .work-section,
  .client-section,
  .feature-section-two,
  .service-section-two,
  .work-section-two,
  .providers-section-two {
    padding: 60px 0 36px;
  }
  .popular-section {
    padding-bottom: 36px;
  }
  .app-section-two {
    padding-bottom: 60px;
  }
  .service-section,
  .client-section-two,
  .about-sec {
    padding: 60px 0;
  }
  .chooseus-sec {
    padding: 60px 0 40px;
  }
  .blog-section {
    padding: 60px 0 40px;
  }
  .app-section {
    padding: 0 0 60px;
  }
  .about-banner {
    margin-bottom: 60px;
  }
  .about-content {
    margin: 0;
  }
  .about-content p {
    margin-bottom: 15px;
  }
  .btn-view {
    padding: 11px 25px;
    font-weight: 500;
    font-size: 14px;
  }
  .fav-item .serv-rating {
    padding: 6px 8px;
    font-size: 14px;
  }
  .about-content h2,
  .choose-content h2,
  .choose-info h5 {
    font-size: 24px;
  }
  .service-content .title,
  .work-box h5,
  .book-title h5,
  .contact-data h4,
  .category-name h6 {
    font-size: 18px;
  }
  .service-widget .serv-info h6,
  .section-search p,
  .widget-title h4 {
    font-size: 20px;
  }
  .choose-info p,
  .grid-blog .blog-content p {
    font-size: 14px;
  }
  .blog-category ul li,
  .blog-category .post-author span {
    font-size: 12px;
  }
  .breadcrumb-bar .page-breadcrumb ol li a,
  .breadcrumb-bar .page-breadcrumb ol li {
    font-size: 15px;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    padding: 7px 15px 0 15px;
  }
  .about-offer {
    padding: 30px;
  }
  .service-offer {
    padding-bottom: 60px;
  }
  .about-offer .offer-path-content {
    padding: 0;
  }
  .btn-book {
    padding: 10px 16px;
  }
  .blog-content .blog-title {
    font-size: 16px;
  }
  .grid-blog .blog-content .blog-title {
    font-size: 18px;
  }
  .home-banner {
    background: none;
  }
  .section-search h1 {
    font-size: 34px;
    margin-bottom: 15px;
    max-width: 100%;
  }
  .search-box {
    margin-top: 35px;
  }
  .testimonial-slider .owl-nav button.owl-prev {
    left: -10px;
  }
  .testimonial-slider .owl-nav button.owl-next {
    right: -10px;
  }
  .appimg-set {
    margin-top: 20px;
    position: relative;
    text-align: center;
    right: 0;
  }
  .appimg-set img {
    max-width: 100%;
  }
  .app-sec:before {
    content: none;
  }
  .serv-profile h2,
  .contact-queries h2 {
    font-size: 30px;
  }
  .serv-action ul li,
  .step-register li .multi-step-icon {
    margin-right: 10px;
  }
  .serv-action ul li a {
    width: 40px;
    height: 40px;
  }
  .service-wrap {
    margin-bottom: 20px;
  }
  .service-amount h5 {
    font-size: 20px;
  }
  .booking-done h6 {
    font-size: 20px;
  }
  .service-amount h5 span {
    font-size: 18px;
  }
  .package-widget ul li:before {
    top: 2px;
  }
  .book-submit .btn,
  .booking-done p,
  .step-register li .multi-step-info p,
  .grid-blog .blog-content .read-more {
    font-size: 14px;
  }
  .blog-list .blog-content .blog-title {
    font-size: 16px;
  }
  .page-title h2,
  .login-header h3,
  .dash-info h5 {
    font-size: 24px;
  }
  .step-register li .multi-step-info h6 {
    font-size: 16px;
  }
  .category-name h6 {
    font-size: 18px;
  }
  .contact-data p,
  .category-info p {
    font-size: 15px;
  }
  .header-navbar-rht.log-rht li {
    margin-right: 20px;
  }
  .login-back,
  .recent-booking,
  .login-back.manage-log {
    margin-bottom: 25px;
  }
  .banner-imgs {
    display: none;
  }
  .settings-header,
  .settings-menu {
    padding: 20px;
  }
  .price-head h1,
  .error-wrap h2 {
    font-size: 32px;
  }
  .maintenance-sec {
    padding: 60px 0;
  }
  .maintenance-wrap {
    margin-bottom: 30px;
  }
  .maintenance-wrap h2,
  .balance-crad h3 {
    font-size: 22px;
  }
  .maintenance-wrap p {
    margin-bottom: 24px;
  }
  .main-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .balance-head h6 {
    font-size: 13px;
  }
  .balance-head p {
    font-size: 12px;
  }
  .user-menu li.dropdown.dropdown-heads {
    margin-right: 0;
  }
  .filter-div .filter-content:last-child,
  .filter-div .btn {
    margin-bottom: 25px;
  }
  .new-comment {
    margin-bottom: 30px;
  }
  .chat-page-body {
    overflow: hidden;
  }
  .customer-chat .chat-cont-left .chat-scroll {
    max-height: calc(100vh - 235px);
  }
  .customer-chat .chat-cont-profile .card-body {
    max-height: calc(100vh - 260px);
  }
  .chat-cont-right .chat-scroll {
    max-height: calc(100vh - 254px);
  }
  .work-wrap h1 {
    font-size: 26px;
  }
  .work-wrap-acc,
  .work-wrap-earning {
    padding-right: 0;
  }
  .work-wrap-post {
    padding-left: 0;
  }
  .work-wrap,
  .work-img {
    margin-bottom: 100px;
  }
  .work-wrap-acc:after {
    bottom: -40%;
    width: 50%;
    height: 50%;
  }
  .work-wrap-post:after {
    bottom: -40%;
    width: 50%;
    height: 50%;
  }
  .work-wrap span {
    width: 45px;
    height: 45px;
    font-size: 15px;
    margin-bottom: 15px;
  }
  .breadcrumb-title {
    font-size: 30px;
  }
  .countdown-container .countdown-el p {
    font-size: 26px;
  }
  #datetimepickershow {
    margin-bottom: 24px;
  }
  .provider-img {
    margin: 30px 0 40px 30px;
  }
  .provider-info h2 {
    font-size: 28px;
  }
  .provider-info,
  .provider-details {
    margin-bottom: 40px;
  }
  .provider-details.provide-area {
    margin-bottom: 20px;
  }
  .banner-search h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .banner-search p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .search-input-new input,
  .search-input-new.line input {
    height: 35px;
  }
  .search-box-two .search-btn .btn {
    font-size: 14px;
    padding: 6px 10px;
  }
  .section-heading-two,
  .btn-viewall {
    margin-bottom: 24px;
  }
  .feature-title h5 {
    font-size: 15px;
  }
  .work-wrap-box .work-icon span {
    width: 60px;
    height: 60px;
    margin: 0 auto 15px;
  }
  .work-wrap-box .work-icon span img {
    width: 20px;
  }
  .work-wrap-box h5,
  .service-widget.service-two .service-content .title {
    font-size: 16px;
  }
  .work-wrap-box.work-first:before,
  .work-wrap-box.work-last:after {
    content: none;
  }
  .offer-sec.offer-paths {
    padding: 0 30px;
  }
  .offer-sec .offer-path-content {
    padding: 30px 0;
  }
  .footer-two .payment-image ul {
    margin-bottom: 15px;
  }
  .footer-two .social-icon {
    text-align: right;
    margin-bottom: 15px;
  }
  .footer-two .footer-bottom .copyright-text p {
    text-align: left;
  }
  .header-nav-new {
    height: 65px;
  }
  .provider-info {
    padding-left: 0;
  }
  .download-sec {
    padding-left: 0;
  }
  .choose-content {
    margin-right: 0;
  }
  .contact-queries h2 {
    margin-bottom: 20px;
  }
  .contact-queries .btn {
    margin-top: 2px;
  }
  .contact-details {
    margin-bottom: 30px;
  }
  .policy-menu li,
  .footer .footer-bottom .copyright-text p {
    font-size: 13px;
  }
  .blog-info ul li,
  .blog-info .post-author span,
  .available-info ul li {
    font-size: 14px;
  }
  .side-title {
    font-size: 20px;
  }
  .review-box p {
    font-size: 14px;
  }
  .about-img {
    margin-bottom: 24px;
  }
  .search-box-three .search-form-profile {
    flex-direction: column;
  }
  .search-btn-three .btn-primary {
    display: block;
    text-align: center;
  }
  .section-section-three {
    text-align: center;
  }
  .section-section-three h4 {
    font-size: 16px;
  }
  .services-header .section-content h2 {
    font-size: 30px;
  }
  .make-appointment {
    padding: 15px;
  }
  .saloon-bottom a {
    padding: 10px;
    font-size: 14px;
  }
  .appointment-main h1 {
    font-size: 36px;
  }
  .appointment-btn .btn-primary {
    font-size: 14px;
  }
  .stylists-foot h4 {
    font-size: 22px;
  }
  .stylists-foot h6 {
    font-size: 14px;
  }
  .works-bottom h2 {
    font-size: 26px;
  }
  .latest-date span {
    font-size: 20px;
  }
  .latest-date {
    padding: 10px;
    margin: 10px;
  }
  .latest-content p {
    font-size: 18px;
  }
  .register-content h2 {
    font-size: 34px;
    margin-bottom: 20px;
  }
  .register-content {
    flex-direction: column;
  }
  .latest-section {
    padding: 55px 0 33px;
  }
  .works-section {
    padding: 55px 0 33px;
  }
  .services-section {
    padding: 75px 0 15px;
  }
  .appointment-section {
    padding: 45px 0;
  }
  .services-section.stylists-section {
    padding: 45px 0 0px;
  }
  .services-section .owl-nav {
    margin-top: 30px;
  }
  .service-section {
    padding: 40px 0;
  }
  .client-sections {
    padding: 55px 0 33px;
  }
  .sidecircle-ryt {
    display: none;
  }
  .saloon-section-circle {
    display: none;
  }
  .section-search.section-search-four {
    max-width: none;
    text-align: center;
  }
  .catering-banner-img {
    display: none;
  }
  .section-search.section-search-four h1 {
    font-size: 40px;
  }
  .catering-banner-botton {
    display: flex;
    justify-content: center;
  }
  .trust-us-main {
    margin-bottom: 20px;
  }
  .working-four-main {
    margin-bottom: 20px;
  }
  .header.header-four .header-nav-new {
    justify-content: space-between;
  }
  .search-box.search-box-four .search-btn .btn {
    justify-content: center;
    width: auto;
  }
  .search-box.search-box-four .search-btn {
    text-align: end;
  }
  .search-input.search-input-three.search-input-four
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    text-align: start;
  }
  .section-heading.section-heading-four .btn-view {
    padding: 15px 20px;
  }
  .btn-catering .btn-view {
    padding: 18px 20px;
  }
  .service-content.service-four-blogs h3 {
    font-size: 20px;
  }
  .floating-img {
    display: none;
  }
  .section-search.section-section-five {
    text-align: center;
  }
  .section-section-five p {
    max-width: none;
  }
  .works-it-lines span::before {
    width: 250px;
    left: 171px;
  }
  .offering-five-img {
    display: none;
  }
  .feature-service-btn a {
    padding: 10px 20px;
  }
  .app-sec.app-sec-five .appimg-set img {
    display: none;
  }
  .app-sec.app-sec-five {
    padding: 45px;
    text-align: center;
    border-radius: 0;
  }
  .app-sec.app-sec-five .downlaod-btn {
    justify-content: center;
  }
  .works-it-five-button-main {
    display: none;
  }
  .works-five-bottom i {
    padding: 10px;
  }
  .providers-five-bg-car img {
    display: none;
  }
  .featured-category-bg-img img {
    display: none;
  }
  .works-five-section::before {
    background: none;
  }
  .offering-five-all::before {
    background: none;
  }
  .feature-category-section-five::before {
    background: none;
  }
  .pricing-service-five::before {
    background: none;
  }
  .car-blogs-section::before {
    background: none;
  }
  .floating-five-buttom {
    display: none;
  }
  .floating-five-buttoms {
    display: none;
  }
  .header-five .main-nav .has-submenu a {
    color: #000000;
  }
  .hero-section-five {
    position: static;
  }
  .featured-categories-section {
    padding: 45px 0;
  }
  .feature-category-section {
    padding: 45px 0;
  }
  .providers-section-five {
    padding: 45px 0;
  }
  .works-five-section {
    padding: 45px 0;
  }
  .feature-category-section-five {
    padding: 45px 0;
  }
  .car-five-arrow-img {
    display: none;
  }
  .offering-five-main h1 {
    font-size: 34px;
  }
  .offering-five-main {
    text-align: center;
  }
  .pricing-service-five {
    padding: 45px 0;
  }
  .testimonals-five-section {
    padding: 45px 0;
  }
  .testimonials-five-top {
    padding: 24px 20px;
    border-radius: 0;
  }
  .blog-section.blog-five-section {
    padding: 45px 0;
  }
  .car-blogs-section {
    padding: 45px 0 0 0;
  }
  .car-wash-bg-five::before {
    background: none;
  }
  .car-blog-slider .owl-nav {
    margin-bottom: 0;
  }
  .app-five-section {
    padding: 45px 0;
  }
  .pricing-service-topimg .pricing-five-img-one {
    display: none;
  }
  .pricing-service-topimg .pricing-five-img-two {
    display: none;
  }
  .search-box.search-box-five {
    border-radius: 0;
  }
  .search-box.search-box-three {
    border-radius: 0;
  }
  .footer .footer-top-three {
    padding: 50px 0 30px 0;
  }
  .search-input.search-input-three.search-input-four {
    margin: 0;
  }
  .nearby-section,
  .categories-section,
  .features-four-section,
  .trust-us-section,
  .popular-four-section,
  .Working-four-section,
  .around-world-section,
  .review-client-section,
  .useful-blog-section {
    padding: 45px 0;
  }
  .feature-category-section::before {
    background: none;
  }
  .search-box.search-box-five
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    text-align: start;
  }
  .search-box.search-box-five .search-input .form-group label {
    display: flex;
  }
  .home-banner-main {
    display: none;
  }
  .side-social-media-six {
    display: none;
  }
  .banner-six-side-img {
    display: none;
  }
  .home-banner-six-bottom {
    padding-top: 30px;
    text-align: -webkit-center;
    text-align: -moz-center;
  }
  .home-banner-six-detergent {
    justify-content: center;
  }
  .home-banner-six-bottom h1 {
    max-width: 550px;
  }
  .hero-section-six {
    background: #f8f8f8;
  }
  .reason-choose-us {
    padding: 40px 0;
  }
  .our-company-ryt {
    display: none;
  }
  .our-company-six {
    text-align: -webkit-center;
  }
  .aboutus-companyimg {
    text-align: center;
  }
  .about-our-company {
    background: #f8f8f8;
    padding: 50px 0;
  }
  .satisfied-service-section {
    padding: 50px 0;
  }
  .search-box-two.search-box-six
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    text-align: start;
  }
  .see-works-six-section {
    padding: 50px 0;
    background: #f8f8f8;
  }
  .blogs-service-section {
    padding: 50px 0;
  }
  .our-expert-six-section {
    background: #f8f8f8;
    padding: 50px 0;
  }
  .customer-reviews-six-section {
    padding: 50px 0;
  }
  .hidden-charge-section {
    padding: 50px 0;
  }
  .app-six-section {
    padding: 50px 0;
  }
  .app-sec-main {
    background: none;
  }
  .appimg-six {
    display: none;
  }
  .app-imgs-six-bg {
    display: none;
  }
  .new-app-six-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0 25px 0;
  }
  .new-app-six-middle h2 {
    font-size: 40px;
    max-width: none;
  }
  .footer.footer-six {
    background: #f8f8f8;
    padding: 50px 0;
  }
  .footer-widget.footer-menu.footer-menu-six {
    margin-top: 0;
  }
  .playicon {
    right: 46%;
  }
  .service-content.service-content-six {
    padding: 25px 0 0;
  }
  .hero-sectionseven-top {
    background: none;
  }
  .hero-banner-ryt {
    display: none;
  }
  .appimg-seven {
    display: none;
  }
  .testimonals-top-seven {
    display: none;
  }
  .home-banner.homer-banner-seven {
    min-height: auto;
  }
  .home-banner.homer-banner-seven .section-search {
    text-align: center;
    margin: 60px 0 90px 0;
  }
  .search-box-two.search-box-seven {
    border-radius: 0;
  }
  .search-box-two.search-box-seven .search-btn .btn {
    border-radius: 0;
  }
  .app-sec.app-sec-seven {
    border-radius: 0;
  }
  .new-app-seven-middle {
    padding: 30px 30px 0 30px;
    text-align: center;
  }
  .new-app-seven-middle h2 {
    max-width: none;
  }
  .new-app-seven-middle p {
    max-width: none;
  }
  .new-app-seven-middle .new-app-six-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer.footer-seven .footer-top {
    padding: 45px 0 0px 0;
  }
  .service-section-seven {
    padding: 45px 0;
  }
  .popular-service-seven-section {
    padding: 45px 0;
  }
  .work-section-seven {
    padding: 45px 0;
  }
  .providers-section-seven {
    padding: 45px 0;
  }
  .price-sections-seven {
    padding: 45px 0;
  }
  .app-seven-section {
    padding: 45px 0;
  }
  .our-partners-seven {
    padding: 45px 0;
  }
  .home-banner-eight .section-search {
    padding-top: 0;
  }
  .home-eight-bg-two {
    display: none;
  }
  .home-eight-bg {
    display: none;
  }
  .works-eights-arrow {
    display: none;
  }
  .about-eight-main {
    display: none;
  }
  .professional-eight-img {
    display: none;
  }
  .testimonals-eight-slider .owl-nav {
    text-align: center;
  }
  .healthy-pets-img {
    display: none;
  }
  .pets-content-all {
    text-align: center;
  }
  .customers-eights-all {
    text-align: center;
  }
  .customers-eights-all p {
    max-width: none;
  }
  .customers-eight-heading h2 {
    max-width: none;
  }
  .passion-eight-all {
    text-align: center;
  }
  .category-sections-eight,
  .popular-eight-section,
  .about-us-eight-section,
  .cat-dog-eight-section,
  .meet-eight-section,
  .customers-eight-section,
  .blog-eight-section,
  .healthy-eight-section,
  .footer.footer-eight .footer-top {
    padding: 50px 0;
  }
  .works-eight-section {
    padding: 50px 0 26px 0;
  }
  .pets-content-all h2 {
    font-size: 30px;
  }
  .pets-content-all h1 {
    font-size: 38px;
  }
  .header.header-eight .bar-icon span {
    background-color: #2229c1;
  }
  .pets-content-all .btn-primary {
    padding: 10px 15px;
  }
  .home-banner.home-banner-nine {
    min-height: auto;
    text-align: center;
  }
  .hero-section-nine {
    min-height: auto;
  }
  .arrow-ryt {
    display: none;
  }
  .banner-appointment-nine {
    display: none;
  }
  .appoints-btn {
    margin-bottom: 0;
  }
  .safety-section,
  .service-nine-section,
  .feature-service-nine,
  .mechanics-section,
  .choose-nine-section,
  .customer-review-section,
  .additional-service-section,
  .blogs-nine-section {
    padding: 50px 0;
  }
  .choose-nine-section {
    padding: 50px 0 26px 0;
  }
  .btn-service-nine .btn-primary {
    margin-top: 15px;
  }
  .free-service-nine {
    display: none;
  }
  .free-service-bottom-content {
    padding: 50px;
    text-align: center;
  }
  .partner-section-nine {
    margin-top: auto;
    padding: 45px 0;
  }
  .free-service-all {
    margin-top: 45px;
  }
  .footer.footer-nine .footer-top {
    padding: 45px 0 10px 0;
  }
  .header.header-nine .navbar-header {
    display: inline-flex;
  }
  .floating-five-main {
    margin-top: 0;
  }
  .footer.footer-five .footer-bottom .copyright .copyright-text p {
    font-size: 14px;
  }
  .footer.footer-five .copyright-menu .policy-menu {
    font-size: 14px;
  }
  .professional-eight-img-ryt img {
    height: 306px;
  }
  .category-eight-slider .owl-nav button.owl-prev,
  .category-eight-slider .owl-nav button.owl-next {
    margin-bottom: 0;
  }
  .header.header-nine .header-nav-new {
    height: 65px;
  }
  .header.header-nine.fixed .header-nav-new {
    height: 65px;
  }
  .single-demo .demo-info a {
    padding: 10px 15px 10px 35px;
  }
  .single-demo:hover {
    transform: translateY(0);
  }
  .single-demo:hover .demo-info {
    bottom: 0;
  }
  .single-demo {
    margin-bottom: 0;
  }
  .reasonable-all {
    padding: 25px;
  }
  .customer-side-main-all {
    display: none;
  }
  .section-heading-nine.safety-heading-nine {
    margin-bottom: 30px;
  }
  .section-heading-nine h2::after {
    bottom: 6px;
  }
  .safety-ryt-two img {
    margin: 30px 0 60px 0;
  }
  .safety-ryt-main img {
    margin: 30px 0 33px 0;
  }
  .safety-ryt-main.safety-ryt-two::after {
    display: none;
  }
  #progressbar {
    flex-wrap: wrap;
  }
  #progressbar li {
    flex-basis: 30%;
  }
  .field-bottom-btns {
    margin-bottom: 20px;
  }
  .reshchedule-modal .modal-dialog {
    margin: 20px;
  }
  .modal-active-dots ul {
    margin-top: 0;
  }
  .calender-modal {
    margin: 80px 0;
  }
  .card.booking-summary-card,
  .booking-info-tab {
    margin-bottom: 0;
  }
  .wizard ul li h5 {
    font-size: 15px;
  }
  .booking-info-tab .nav-pills .nav-item + .nav-item {
    margin-left: 40px;
  }
  .booking-info-tab .nav-pills .nav-link {
    font-size: 16px;
  }
  .appointment-details ul.customer-detail-list {
    width: 60%;
    right: 100px;
    top: 0;
  }
  .appointment-details ul.customer-detail-list::after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    position: absolute;
    top: 87px;
    left: -7px;
  }
  .linked-item a.btn-connect {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .appointment-set-two .appointment-setting-list .form-group {
    width: 100px;
  }
  .section-booking {
    padding: 60px 0;
  }
  .service-wrap-content {
    height: 88%;
  }
  .header-navbar-rht li .dropdown-menu.notifications {
    left: -310px;
  }
  .feature-box .feature-overlay img {
    width: 100%;
  }
  .total-client-all {
    display: block;
  }
  .total-client-half {
    margin-bottom: 24px;
  }
  .journey-nine-section .section-heading {
    margin-bottom: 30px;
    text-align: center;
  }
  .journey-client-main {
    margin-bottom: 24px;
  }
  .modal-active-dots {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 767.98px) {
  body {
    font-size: 0.875rem;
  }
  h1,
  .h1 {
    font-size: 2rem;
  }
  h2,
  .h2 {
    font-size: 1.75rem;
  }
  h3,
  .h3 {
    font-size: 1.375rem;
  }
  h4,
  .h4 {
    font-size: 1rem;
  }
  h5,
  .h5 {
    font-size: 15px;
  }
  h6,
  .h6 {
    font-size: 15px;
  }
  .main-nav > li > a:hover::after,
  .main-nav > li.active > a::after {
    top: 0;
    bottom: inherit;
  }
  .security-verifi {
    margin-top: 29px;
  }
  .content {
    padding: 25px 0;
  }
  .booking-details li p {
    display: block;
    margin-left: 100px;
    margin-top: 5px;
  }
  .booking-details li p::before {
    display: none;
  }
  .booking-details li p:last-child {
    margin-left: 100px;
    margin-top: 5px;
  }
  .footer-title {
    font-size: 1rem;
  }
  .policy-menu {
    margin-top: 10px;
    text-align: left;
  }
  .form-title {
    font-size: 0.875rem;
  }
  .section-search > h3 {
    font-size: 30px;
  }
  .search-box {
    padding: 10px;
  }
  .app-set {
    background: #f5f5f5;
    padding: 25px 0;
  }
  .notificationlist a {
    position: static;
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  .viewall {
    text-align: left;
    padding: 0 0 30px;
  }
  .viewall span,
  .search-cat i {
    display: none;
  }
  .search-cat span {
    display: block;
    margin-right: 0;
  }
  .search-cat a {
    margin: 10px 5px;
  }
  .breadcrumb-title h2 {
    font-size: 1.375rem;
  }
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .section-header h2 {
    font-size: 1.875rem;
  }
  .dropdown-menu {
    font-size: 0.875rem;
  }
  .section-search-box {
    min-height: 290px;
  }
  .true-search-box {
    position: relative;
    top: 0;
    padding-top: 25px;
  }
  .section-heading h2,
  .section-heading-two h2 {
    font-size: 24px;
  }
  .section-heading span {
    font-size: 16px;
  }
  .section-heading {
    margin-bottom: 30px;
  }
  .section-search h1,
  .price-head h1 {
    font-size: 28px;
  }
  .service-widget .serv-info h6,
  .section-search p,
  .new-comment .submit-btn {
    font-size: 16px;
  }
  .owl-nav button.owl-next,
  .owl-nav button.owl-prev {
    width: 35px;
    height: 35px;
  }
  .footer-content {
    max-width: 100%;
  }
  .section-heading p {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .app-sec {
    padding: 30px;
  }
  .serv-profile h2,
  .notification-details h4 {
    font-size: 20px;
  }
  .serv-profile span.badge,
  .btn-back,
  .book-submit .btn,
  .step-register li .multi-step-info p {
    font-size: 14px;
  }
  .serv-profile {
    margin: 0px 0 10px;
  }
  .serv-action {
    text-align: left;
    margin-top: 0px;
  }
  .big-gallery a.btn-show {
    font-size: 14px;
    padding: 8px 8px;
  }
  .btn-review {
    font-size: 14px;
    padding: 10px 20px;
  }
  .service-gal {
    margin-bottom: 30px;
  }
  .provide-widget {
    margin-bottom: 10px;
  }
  .available-info h5 {
    font-size: 18px;
  }
  .package-widget h5 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .serv-profile ul li {
    margin: 0 10px 15px 0;
  }
  .service-images img {
    width: 100%;
  }
  .serv-profile ul li,
  .page-breadcrumb ol {
    font-size: 15px;
  }
  .serv-proimg img {
    width: 60px;
    height: 60px;
  }
  .serv-proimg span {
    font-size: 18px;
  }
  .package-widget {
    padding: 15px;
  }
  .card-provide .btn {
    font-size: 16px;
    padding: 11px;
  }
  .booking-done h6,
  .widget-title h4,
  .client-box .client-content h6 {
    font-size: 18px;
  }
  .book-submit .btn + .btn {
    margin-left: 0;
  }
  .book-submit .btn {
    margin: 5px;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    padding: 8px 7px;
  }
  .step-register li .multi-step-info h6,
  .dash-wallet .dash-info h5 {
    font-size: 16px;
  }
  .step-register li {
    padding-bottom: 20px;
  }
  .step-register li {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }
  .book-option {
    text-align: left;
  }
  .service-book {
    display: block;
    text-align: center;
  }
  .booking-info .service-book-img {
    margin: 0 0 15px 0;
  }
  .booking-info,
  .booking-date,
  .booking-total {
    padding: 20px;
  }
  #calendar-doctor {
    margin-bottom: 20px;
  }
  .service-book-img {
    margin: 0 0 20px 0;
  }
  .booking-date li {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .booking-total ul li span,
  .btn-pay,
  .payment-title h6,
  .maintenance-wrap h6 {
    font-size: 16px;
  }
  .summary-box,
  .provide-table {
    margin-bottom: 25px;
  }
  .page-header .review-sort,
  .page-header .grid-listview,
  .page-header .add-set {
    margin-bottom: 20px;
  }
  .serv-info .serv-edit {
    margin-right: 10px;
  }
  .pay-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .serv-info .serv-edit,
  .serv-info span {
    font-size: 12px;
  }
  .step-register,
  .price-card {
    margin-bottom: 15px;
  }
  .error-wrap h2,
  .contact-queries h2 {
    font-size: 22px;
  }
  .error-wrap p {
    margin-bottom: 24px;
  }
  .contact-details {
    margin-bottom: 10px;
  }
  .contact-queries .form-control {
    min-height: 50px;
    margin-bottom: 30px;
  }
  .login-header h3,
  .faq-sec h4,
  .maintenance-wrap h2,
  .countdown-container .countdown-el p {
    font-size: 20px;
  }
  .choose-signup,
  .settings-widget,
  .settings-menu ul li:not(:last-child),
  .maintenance-wrap .social-icon ul {
    margin-bottom: 20px;
  }
  .search-btn .btn,
  .price-toggle {
    font-size: 15px;
  }
  .header-navbar-rht .lang-nav,
  .header-navbar-rht .flag-nav,
  .account-item .user-info {
    display: none;
  }
  .review-list {
    flex-direction: column;
    text-align: center;
  }
  .review-imgs {
    margin: 0 auto 10px;
    flex: unset;
  }
  .review-userprice-card {
    margin-bottom: 10px;
  }
  .review-count {
    margin: 0 auto;
  }
  .social-widget {
    margin-bottom: 10px;
  }
  .ad-widget ul li {
    margin: 0 10px 10px 0;
  }
  .faq-section {
    margin-bottom: 40px;
  }
  .category-name h6,
  .countdown-container .countdown-el:before {
    font-size: 15px;
  }
  .maintenance-sec {
    padding: 40px 0;
  }
  .coming-back p {
    font-size: 14px;
  }
  .booking-action {
    flex: 0 0 100%;
    justify-content: center;
    margin-top: 20px;
  }
  .booking-list .booking-widget {
    flex: 0 0 100%;
  }
  .header-navbar-rht.log-rht li:last-child {
    margin-right: 0;
  }
  #tablelength,
  .booking-done {
    text-align: center;
  }
  .table-ingopage {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  .balance-crad h3,
  .work-wrap h1 {
    font-size: 22px;
  }
  .balance-amt,
  .book-id {
    margin-bottom: 0;
  }
  .main-content {
    width: 100%;
  }
  .coupon-submit .btn {
    min-width: 150px;
  }
  .page-content {
    display: block;
  }
  .content-side {
    min-width: 100%;
  }
  .content-sidebar {
    padding-bottom: 0;
  }
  #progressbar,
  .book-history {
    margin-bottom: 15px;
  }
  .book-id-action {
    text-align: left;
    margin: 0;
  }
  .slot-action {
    float: left;
  }
  .slot-box,
  .page-topbar .page-breadcrumb ol {
    margin-bottom: 24px;
  }
  .page-topbar .breadcrumb,
  .maintenance-icon {
    margin: 0;
  }
  .payment-summary {
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    flex-direction: column;
  }
  .fc .fc-toolbar-title,
  .fc-toolbar-chunk .btn-primary,
  .fc .fc-col-header-cell-cushion,
  .book-email h6 {
    font-size: 14px;
  }
  .fc-toolbar-chunk .fc-prev-button,
  .fc-toolbar-chunk .fc-next-button {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
  .fc-toolbar-chunk .btn-group > .btn-group:not(:first-child),
  .fc-toolbar-chunk .btn-group > .btn:not(:first-child) {
    margin-left: 5px;
  }
  .leave-action .btn {
    padding: 8px 15px;
    margin: 0 5px 20px 0;
  }
  .file-upload {
    padding: 30px 20px;
  }
  .bk-history h4,
  .file-upload h6 {
    font-size: 16px;
  }
  .blog-head h3 {
    font-size: 22px;
  }
  .new-comment h4,
  .blog-review h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .work-wrap-acc:after,
  .work-wrap-post:after {
    content: none;
  }
  .work-wrap,
  .work-img {
    margin-bottom: 35px;
  }
  .work-wrap,
  .work-img {
    text-align: center;
  }
  .works-main {
    margin-bottom: 24px;
  }
  .reason-six {
    justify-content: center;
    text-align: center;
  }
  .section-heading.section-heading-six h2 {
    text-align: center;
  }
  .breadcrumb-bar {
    padding: 30px 0;
  }
  .breadcrumb-title {
    font-size: 22px;
  }
  .error-wrap .error-logo {
    margin-bottom: 35px;
  }
  .error-wrap .btn {
    margin-bottom: 24px;
  }
  .linked-wrap {
    display: block;
  }
  .linked-item a {
    margin-right: 5px;
  }
  .link-close,
  .link-check {
    margin-right: 10px !important;
  }
  .edit-service h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .provider-subtitle h6 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .booking-det-info h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .provider-info h2 {
    font-size: 22px;
  }
  .provider-img {
    margin: 30px 0 25px 30px;
  }
  .provider-info,
  .provider-details {
    margin-bottom: 25px;
  }
  .provider-details.provide-area {
    margin-bottom: 5px;
  }
  .provider-details ul {
    margin-bottom: 16px;
  }
  .service-list {
    flex-direction: column;
  }
  .service-action,
  .service-cont {
    flex: 0 0 100%;
    width: 100%;
  }
  .search-box-two,
  .pricing-popular .btn {
    display: none;
  }
  .pricing-popular.active .btn {
    display: block;
  }
  .offer-path-content h3 {
    font-size: 22px;
  }
  .pricing-popular .btn {
    padding: 8px;
    font-size: 14px;
  }
  .work-wrap-box {
    margin: 0 auto 24px;
  }
  .pricing-plans.price-new .pricing-planshead h6 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .pricing-plans.price-new .pricing-planshead h4 {
    font-size: 22px;
  }
  .pricing-plans.price-new {
    margin-top: 0;
  }
  .app-section-two .appimg {
    margin-bottom: 25px;
  }
  .footer-two .footer-contact-info p,
  .pricing-plans.price-new .pricing-planshead h5 {
    font-size: 14px;
  }
  .comments-reply li {
    margin-left: 20px;
  }
  .addon-box {
    display: block;
  }
  .addon-info ul {
    justify-content: start;
    flex-wrap: wrap;
  }
  .about-img .about-exp span {
    font-size: 18px;
    margin: 276px -70px;
  }
  .about-img .about-exp {
    width: 230px;
  }
  .choose-icon {
    border: 0;
  }
  .contact-queries .btn {
    font-size: 14px;
  }
  .contact-img {
    text-align: center;
    margin: 0 0 24px 0;
  }
  .contact-img:before {
    left: 60px;
  }
  .contact-img:after {
    right: 40px;
  }
  .payment-image ul {
    justify-content: start;
  }
  .copyright-text {
    margin-bottom: 10px;
  }
  .home-banner {
    display: block;
  }
  .home-banner > div {
    width: auto !important;
  }
  .invoice-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    padding: 9px 7px;
  }
  .invoice-head,
  .invoice-to,
  .invoice-total {
    float: left;
  }
  .invoice-total,
  .invoice-payment {
    margin-bottom: 24px;
  }
  .hero-section-two .banner-search h1 {
    font-size: 24px;
  }
  .hero-section-two .banner-search p,
  .client-name h5 {
    font-size: 16px;
  }
  .search-box {
    border-radius: 10px;
  }
  .searchbox-list {
    flex-direction: column;
  }
  .hero-section-three .search-box .search-input {
    width: 100%;
  }
  .hero-section-three {
    background: #2a396d;
  }
  .search-box.search-box-three {
    margin-top: -70px;
  }
  .appointment-main h1 {
    font-size: 30px;
  }
  .works-bottom h2 {
    font-size: 24px;
  }
  .latest-date {
    padding: 5px;
  }
  .latest-content p {
    font-size: 16px;
  }
  .register-content h2 {
    font-size: 30px;
  }
  .hero-section-three .search-box .search-input {
    height: 40px;
  }
  .copyright-menu {
    float: none;
  }
  .search-box.search-box-four .search-three-form {
    flex-direction: column;
  }
  .search-box.search-box-four {
    border-radius: 0;
  }
  .search-box.search-box-four .search-three-form .form-group {
    width: 100%;
  }
  .search-input.search-input-three.search-input-four
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    text-align: start;
    padding-left: 10px;
  }
  .search-input.search-input-three.search-input-four input {
    min-height: 42px;
  }
  .search-box.search-box-four .search-btn .btn {
    width: 100%;
    padding: 12px 0;
    justify-content: center;
    border-radius: 0;
  }
  .section-search.section-search-four h1 {
    font-size: 32px;
  }
  .works-five-main {
    margin-bottom: 20px;
  }
  .offering-five-main h1 {
    font-size: 30px;
  }
  .services-header .section-content h2 {
    font-size: 28px;
  }
  .search-box-two.search-box-six {
    display: inline-block;
    border-radius: 0;
  }
  .latest-blog-six {
    top: 60%;
  }
  .section-heading.section-heading-six p {
    margin-bottom: 0;
  }
  .search-box-two.search-box-six .search-input-new.line-six {
    width: 100%;
    margin-bottom: 5px;
  }
  .search-box-two.search-box-six .search-input-new.line-two-six {
    width: 100%;
    margin-bottom: 5px;
  }
  .search-box-two.search-box-six .search-btn {
    width: 100%;
  }
  .search-box-two.search-box-six .search-btn .btn {
    border-radius: 0;
  }
  .new-app-six-middle p {
    text-align: center;
  }
  .new-app-six-middle h2 {
    text-align: center;
  }
  .new-app-six-middle h2 span {
    font-size: 22px;
  }
  .search-box-two.search-box-six
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    padding-left: 10px;
  }
  .new-app-six-middle {
    padding: 40px 10px 25px 10px;
  }
  .popularsearch-top {
    display: none;
  }
  .search-box-two.search-box-seven {
    display: block;
  }
  .service-section-seven {
    padding: 150px 0 45px 0;
  }
  .home-banner-eight {
    min-height: 540px;
  }
  .home-eight-dog {
    display: none;
  }
  .search-box-two.search-box-eight {
    display: block;
  }
  .home-banner-eight {
    min-height: 350px;
  }
  .pets-content-all h2 {
    font-size: 24px;
  }
  .pets-content-all h1 {
    font-size: 30px;
  }
  .footer.footer-five .footer-bottom .copyright .payment-image ul {
    justify-content: center;
    margin-bottom: 10px;
  }
  .footer.footer-five .copyright-menu .policy-menu {
    text-align: center;
    margin-top: 0;
  }
  .afford-btn {
    float: unset;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .total-client-all .total-client-half {
    text-align: center;
    justify-content: center;
  }
  p.total-client-content {
    margin: auto;
    text-align: center;
  }
  .top-bar-nine .btn-primary {
    padding: 10px;
  }

  .search-box-eight .select2-container--default .select2-selection--single {
    border-radius: 0;
  }
  .search-box-eight .form-control {
    border-radius: 0;
  }
  .safety-first-all {
    text-align: center;
  }
  .section-heading-nine.safety-heading-nine h2 {
    max-width: none;
  }
  .confirmation-product-card .row {
    row-gap: 15px;
  }
  .appointment-details ul.customer-detail-list {
    width: 60%;
    right: 30px;
    top: 0;
  }
  .chat-cont-right .chat-cont-profile.chat-profile-right {
    position: absolute;
    right: 100%;
    opacity: 0;
    z-index: 105;
    width: 100%;
  }
  .chat-cont-right.chat-window-long.window-full-width
    .chat-cont-profile.chat-profile-right {
    right: 0;
    opacity: 1;
    width: 100%;
    padding: 0 12px 0 12px;
  }
  .card.chat-window {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .linked-item a.btn-connect {
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .appointment-set-two .appointment-setting-list {
    padding: 15px;
  }
  .section-booking {
    padding: 40px 0;
  }
  .settings-header img {
    margin-right: 0;
  }
  .verfification-modal-block {
    margin-top: 20px;
  }
  .modal-dialog .modal-content .modal-header.verfication-modal-head h5 {
    font-size: 22px;
  }
  .footer.footer-six .footer-widget.footer-menu .footer-title {
    border-bottom: 0;
  }
  .customer-chat .chat-cont-profile .card-body {
    max-height: calc(100vh - 228px);
  }
  .chat-profile-right .back-user-list {
    display: block;
  }
  .chat-profile-right .chat-profile-img {
    margin-left: 10px;
  }
  .book-submit .btn {
    margin-left: 0;
  }
}
@media only screen and (max-width: 575.98px) {
  body {
    font-size: 0.8125rem;
  }
  h1,
  .h1 {
    font-size: 1.75rem;
  }
  h2,
  .h2 {
    font-size: 1.5rem;
  }
  h3,
  .h3 {
    font-size: 1.25rem;
  }
  h4,
  .h4 {
    font-size: 1rem;
  }
  h5,
  .h5 {
    font-size: 15px;
  }
  h6,
  .h6 {
    font-size: 15px;
  }
  .card {
    margin-bottom: 0.9375rem;
  }
  .card-body {
    padding: 1.25rem;
  }
  .card-header {
    padding: 0.75rem 1.25rem;
  }
  .card-footer {
    padding: 0.75rem 1.25rem;
  }
  .header-nav-new {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-nav li.login-link {
    display: block;
  }
  .navbar-header {
    width: 100%;
  }
  #mobile_btn {
    left: 0;
    margin-right: 0;
    padding: 0 0px;
    position: absolute;
    z-index: 99;
    margin-top: 20px;
  }
  .navbar-brand.logo {
    display: none;
  }
  .navbar-brand.logo-small {
    display: block;
    width: 100%;
    text-align: center;
    margin-right: 0;
    padding: 0 100px;
    margin-top: 20px;
  }
  .notification-delete-wrap {
    flex-direction: column;
  }
  .notification-delete {
    margin-top: 20px;
  }
  .login-body .navbar-brand.logo-small {
    padding: 0;
    text-align: center;
  }
  .navbar-brand.logo img {
    height: 40px;
  }
  .footer-title {
    font-size: 1.125rem;
  }
  .contacts_card {
    margin-bottom: 15px !important;
  }
  .header-navbar-rht {
    position: absolute;
    right: 10px;
  }
  .section-search > h3 {
    font-size: 1.75rem;
  }
  .heading h2 {
    font-size: 1.375rem;
  }
  .breadcrumb-title {
    text-align: center;
  }
  .breadcrumb-menu {
    display: none;
  }
  .header-navbar-rht li > a.header-login {
    padding: 8px 20px;
  }
  .header-navbar-rht .dropdown-menu {
    min-width: 200px;
    padding: 0;
    max-width: 300px;
    right: auto;
    left: auto;
  }
  .notifications .noti-content {
    width: 240px;
  }
  .noti-details {
    font-size: 14px;
  }
  .offering-five-button .btn-primary {
    padding: 15px;
  }
  .total-client-all .total-client-half {
    display: block;
  }
  .total-client-all .total-client-half .total-client-mini {
    padding: 0;
    margin-bottom: 20px;
  }
  .our-expert-img img {
    width: 100%;
  }
  .owl-carousel .professional-eight-img-ryt img {
    width: 250px;
    height: 250px;
  }
  .header-navbar-rht > li.logged-item:last-child > a {
    padding: 0;
  }
  .header-navbar-rht > li:last-child .dropdown-menu {
    right: 0;
  }
  .blog-title a {
    font-size: 16px;
  }
  .blog-date p {
    font-size: 14px;
  }
  .blog-read-more a {
    font-size: 14px;
  }
  .blog-inner-img {
    flex-direction: column;
  }
  .blog-inner-img img {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .social-links-block {
    margin-top: 25px;
  }
  .tags-links-block,
  .booking-list .booking-widget,
  .service-cont {
    flex-direction: column;
  }
  .service-cont-img {
    flex: 0 0 100%;
    margin: 0 auto 15px;
  }
  .service-cont-info {
    text-align: center;
  }
  .service-action,
  .service-pro-img {
    justify-content: center;
  }
  .social-share > li > a {
    font-size: 16px;
    height: 30px;
    width: 30px;
  }
  .about-author {
    flex-direction: column;
  }
  .blog-inner-img {
    padding: 34px 0px 20px 0px;
  }
  .about-author-img {
    margin-bottom: 15px;
    text-align: center;
    margin-right: 0;
  }
  .author-details {
    text-align: center;
  }
  .social-share {
    justify-content: center;
  }
  .page-top {
    flex-direction: column;
  }
  .balance-crad {
    display: block;
  }
  .balance-head {
    margin-bottom: 15px;
  }
  .balance-crad .btn-withdraw {
    margin: 6px 0 0;
  }
  .recommend-item {
    flex-direction: column;
    align-items: baseline;
  }
  .recommend-item > a {
    margin-bottom: 10px;
  }
  .breadcrumb {
    margin: 15px 0;
  }
  .page-breadcrumb ol {
    background-color: transparent;
    font-size: 14px;
    margin: 0;
  }
  .page-title h2 {
    font-size: 20px;
  }
  .page-title {
    margin-bottom: 20px;
  }
  .filter-content h2,
  .service-wrap h5,
  .provide-info h6 {
    font-size: 16px;
  }
  .available-widget span.avail-icon {
    margin-right: 15px;
  }
  .available-info p span {
    font-size: 14px;
    margin-left: 7px;
  }
  .visits {
    width: 33.33%;
  }
  .visits span.visit-rsn {
    font-size: 14px;
  }
  .visit-btns,
  .coming-back p {
    margin-bottom: 10px;
  }
  .book-submit .btn {
    width: 100%;
  }
  .booking-img,
  .plan-info .plan-term {
    margin: 0 0 15px;
  }
  .passcode-wrap input {
    width: 60px;
    height: 60px;
    font-size: 22px;
    margin-right: 12px;
  }
  .header-navbar-rht li > a.header-reg,
  .header-navbar-rht li > a.header-login {
    display: none;
  }
  .coming-back {
    flex-direction: column;
  }
  .terms-btn .btn {
    margin: 10px 0 0;
  }
  .booking-list .booking-img {
    margin: 0 auto 15px;
  }
  .header-navbar-rht > li.msg-nav,
  .header-navbar-rht > li.noti-nav {
    display: none;
  }
  .slot-chat,
  .blog-content-img {
    flex-direction: column;
  }
  .blogimg {
    flex: 0 0 100%;
    margin: 0 0 15px 0;
  }
  .review-pagination,
  .review-entries {
    text-align: center;
  }
  .review-entries {
    margin-bottom: 20px;
  }
  .plan-info {
    flex-direction: column;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
  .plan-btns .btn {
    margin: 10px 0 0 0;
  }
  .plan-price {
    margin-bottom: 0;
  }
  #progressbar li {
    margin-right: 0;
    width: calc(100% - 18px);
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
  }
  .schedule-nav ul {
    flex-direction: column;
  }
  .schedule-nav ul li {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-right: 0;
  }
  .wizard-book ul li {
    padding-right: 21px;
  }
  .user-drop {
    left: 0px;
    width: 220px;
    bottom: 60px;
  }
  .chat-cont-profile {
    width: 270px;
  }
  .provider-body .dropdown-heads {
    display: none;
  }
  .service-widget.service-two .service-content p .rate {
    display: block;
    float: unset;
  }
  .banner-slider .slick-slide {
    height: 200px;
  }
  .banner-slider .slick-slide img {
    width: 100%;
    height: 320px;
    object-fit: cover;
  }
  .banner:before {
    top: 0;
  }
  .banner-search p {
    margin: 0;
  }
  .footer-two .social-icon {
    text-align: left;
  }
  .top-bar {
    flex-direction: column;
  }
  .top-bar h6 {
    margin: 0 0 10px 0;
  }
  .about-img .about-exp span {
    margin: 116px -70px;
  }
  .contact-img:before {
    left: 0;
  }
  .downlaod-btn {
    display: block;
  }
  .make-appointment {
    padding: 10px;
  }
  .search-box.search-box-three {
    margin-top: -50px;
  }
  .app-sec.app-sec-five .downlaod-btn {
    flex-direction: column;
    align-items: center;
  }
  .app-sec.app-sec-five .downlaod-btn .scan-img {
    margin: 0 0 10px 0;
  }
  .app-sec.app-sec-five .downlaod-btn a {
    margin: 0 0 10px 0;
  }
  .header.header-five .header-navbar-rht {
    display: none;
  }
  .footer.footer-five .footer-bottom .copyright .payment-image {
    margin-bottom: 20px;
  }
  .search-box.search-box-five .search-box-form {
    flex-direction: column;
  }
  .cta-btn {
    display: none;
  }
  .services-section {
    padding: 75px 0 33px;
  }
  .header.header-six .header-navbar-rht {
    display: none;
  }
  .home-banner-eight-icon {
    display: none;
  }
  .search-box-two.search-box-eight .search-btn {
    width: 100%;
  }
  .search-box-two.search-box-eight .search-input-new {
    width: 100%;
  }
  .search-box-two.search-box-eight .search-input-new i {
    left: 10px;
    font-size: 14px;
  }
  .search-box-two.search-box-eight .search-input-new.line-six::before {
    display: none;
  }
  .search-box-two.search-box-eight .search-input-new.line-two-six input {
    padding: 0px 10px 0px 30px;
  }
  .header-navbar-rht-nine .nav-item .nav-link {
    display: none;
  }
  .search-box.search-box-five .search-input {
    width: 100%;
  }
  .passion-eight-all ul li {
    width: 100%;
  }
  .top-bar-nine {
    display: none;
  }
  .safety-ryt-main img {
    margin: 30px 0 30px 0;
  }
  .mechanics-section .nav-pills .nav-link {
    margin-bottom: 10px;
  }
  .section-heading-nine h2::after {
    display: none;
  }
  #progressbar li {
    flex-basis: 100%;
  }
  .calender-modal {
    margin: 50px 0;
  }
  .booking-info-tab .nav-pills .nav-item + .nav-item {
    margin-left: 15px;
  }
  .wizard-form-card .card-title {
    margin-bottom: 20px;
  }
  .appointment-details ul.customer-detail-list {
    right: unset;
    top: 120px;
    width: 90%;
  }
  .appointment-details ul.customer-detail-list::after {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    top: -7px;
    left: 110px;
  }
  .notification-wrap-date {
    flex-direction: column;
  }
  .notification-wrap-date .search-calendar-line {
    margin-bottom: 15px;
  }
  .provider-widget .card-body .about-author {
    flex-direction: row;
  }
  .service-details ul {
    justify-content: center;
  }
  .service-wrap-content {
    height: 90%;
    padding: 10px;
  }
  .modal-dialog .service-profile {
    padding: 20px;
  }
  .save-later .custom_check .service-check {
    top: 5px;
  }
  .wizard ul li span.dot-active {
    display: none;
  }
  .wizard ul li::before,
  .wizard ul li::after {
    display: none;
  }
  .wizard ul {
    flex-direction: column;
    row-gap: 20px;
  }
  .wizard ul li {
    border-bottom: 2px solid #fff;
  }
  .wizard ul li.progress-activated {
    border-color: #28cea2;
  }
  .plan-price .btn-primary {
    margin-top: 20px;
  }
  .add-service-toggle .status-toggle {
    margin-top: 10px;
  }
  .add-service .file-preview ul {
    flex-direction: column;
  }
  .balance-crad .view-transaction {
    display: block;
  }
  .swal2-actions .btn {
    margin: 0;
  }

  .swal2-actions .btn.swal2-cancel {
    margin-left: 10px;
  }
  .header-navbar-rht li .dropdown-menu.notifications {
    left: -180px;
  }
  .noti-pop-detail li.noti-wrapper .notify-blk {
    width: 280px;
    padding: 10px;
  }
  .header-navbar-rht > li.noti-nav {
    display: inherit;
  }
  .noti-pop-detail li.noti-wrapper .notify-blk .topnav-dropdown-header {
    padding: 10px;
  }
  .provider-body .dropdown-heads.noti-wrapper {
    display: inherit;
  }
  .linked-item a.link-close {
    margin-right: 20px !important;
    padding: 0;
  }
  .linked-item a {
    padding: 9px 15px;
  }
  .linked-info h6 a {
    padding: 0;
  }
  .login-body .header-navbar-rht {
    display: none;
  }
}
@media only screen and (max-width: 485.98px) {
  #progressbar li {
    flex-basis: 100%;
  }
  .guest-address,
  .guest-state-pin {
    flex-direction: column;
  }
  .booking-info-tab .nav-pills .nav-item + .nav-item {
    margin-left: 0;
    margin-top: 20px;
  }
  .booking-info-tab .nav.nav-pills {
    flex-direction: column;
    align-items: center;
  }
  .booking-info-tab .nav-pills {
    border-bottom: 0;
  }
  .payment-head,
  .secure-transaction {
    flex-direction: column;
    row-gap: 10px;
  }
  .payment-title .custom_radio .checkmark {
    margin-top: -4px;
  }
  .payment-title {
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .booking-info-tab .card-details {
    flex-direction: column;
  }
  .reshchedule-modal .modal-dialog {
    margin: 10px;
  }
  .booking-info-tab,
  .rechedule-calender,
  .custom-modal .modal-body,
  .booking-summary-card .card-body,
  .booking-summary-card .card-footer {
    padding: 20px;
  }
  #datetimepickershow {
    padding: 10px;
  }
  .rechedule-calender
    #datetimepickershow
    .bootstrap-datetimepicker-widget
    table
    td.active {
    width: 30px;
    height: 30px;
  }
  .rechedule-calender
    #datetimepickershow
    .bootstrap-datetimepicker-widget
    table
    td.day {
    width: 30px;
    height: 30px;
  }
  .booking-confirmation-info .appointment-details {
    flex-direction: column;
    align-items: start;
    row-gap: 10px;
  }
  .confirmation-product-card {
    text-align: center;
  }
  .confirmation-product-card .row {
    row-gap: 15px;
  }
  .confirmation-product-card .service-item {
    flex-direction: column;
  }
  .confirmation-product-card .service-item span img {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .install-step .requirment-field {
    flex-direction: column;
    gap: 15px;
  }
  .service-details ul li:last-child {
    margin-top: 14px;
    margin-left: 0;
  }
  .install-step .install-user tr td:first-child {
    width: 67px;
  }
  .passion-content-top {
    flex-direction: column;
  }
  .passion-eight-content {
    display: block;
  }
  .passion-content-top img,
  .passion-content-bottom {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .countdown-container .countdown-el {
    min-width: 80px;
    min-height: 80px;
  }
  .appointment-set-two .appointment-setting-list {
    display: block;
  }
  .appoint-wrapper {
    margin-bottom: 15px;
  }
  .chat-msg-attachments {
    display: block;
  }
  .chat-msg-attachments .chat-attachment {
    margin-bottom: 10px;
  }
  .chat-msg-attachments .chat-attachment:last-child {
    margin-bottom: 0;
  }
  .chat-attachment img {
    width: 100%;
    height: 100%;
  }
}

.wizard ul li.progress-active span.dot-active {
  border: 2px solid #ff0080;
}
.wizard ul li.progress-activated span.dot-active {
  border-color: #ff0080;
  background-color: #ff0080;
}
.wizard ul li.progress-activated::before {
  background: #ff0080;
}
.wizard ul li.progress-activated::after {
  background: #ff0080;
}
.wizard ul li.progress-active::after {
  background: #ff0080;
}
.project-installation {
  background: #fafafa;
}
/* .btn-primary {
	background: #FF0080;
	border: 1px solid #FF0080;
    box-shadow: inset 0 0 0 #FF0080;
}
.btn-primary:hover {
	background: #FF0080;
	border: 1px solid #FF0080;
    box-shadow: inset 0 0 0 #FF0080;
} */
.installation-header {
  background: #fff;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-----------------
	53. Preloading
-----------------------*/
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #fff;
}

.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: fixed;
  z-index: 10000;
  border: 4px solid #28cea2;
  top: 50%;
  animation: loader 2s infinite ease;
  left: 50%;
  border-radius: 5px;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #28cea2;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
